import { ProductPhotoProfileFormValuesType } from "../../../pages/product-photo-profile/schemas";
import {
  ICreateProductPhotoProfile,
  IFormAttachment,
  IProductItem,
} from "../../interfaces";
import { ProductPhotoProfileSectionEnum } from "../../enums";
import { IToFormPayload } from "./to-form-payload.interface";

export class ProductPhotoProfileFormService {
  static formInitialValues(): ProductPhotoProfileFormValuesType {
    return {
      profileName: "",
      isNameCompleted: false,
      isScreenshotsCompleted: false,
      listingPage: true,
      orderConfirmation: true,
      checkoutReceipt: true,
      screenShotsNotes: "",

      isDocumentsReceiptsWrittenMaterialsCompleted: false,
      allDocuments: true,
      documentsNotes: "",

      isOuterPackagingCompleted: false,
      shippingPackage: true,
      shippingLabelsOnly: true,
      disposeOuterPackaging: false,
      outerPackagingNotes: "",

      // CustomizeProductPackaging
      isCustomizeProductPackagingCompleted: false,
      productPackagingTop: false,
      productPackagingTopNotes: "",
      productPackagingBottom: false,
      productPackagingBottomNotes: "",
      productPackagingFront: false,
      productPackagingFrontNotes: "",
      productPackagingBack: false,
      productPackagingBackNotes: "",
      productPackagingPhotoExamples: [],

      // CustomizePhotoItemOrPurchasedItem
      isPurchasedItemCompleted: false,
      purchasedPhotoExamples: [],
    };
  }

  static toForm(profile?: IToFormPayload): ProductPhotoProfileFormValuesType {
    return {
      profileName: profile?.name || "",
      isNameCompleted: true,
      isScreenshotsCompleted: true,
      listingPage: Boolean(profile?.listingPage),
      orderConfirmation: Boolean(profile?.orderConfirmation),
      checkoutReceipt: Boolean(profile?.checkoutReceipt),
      screenShotsNotes: profile?.screenShotNotes || "",

      isDocumentsReceiptsWrittenMaterialsCompleted: true,
      allDocuments: Boolean(profile?.allDocsIncluded),
      documentsNotes: profile?.docsReceiptMaterialNotes || "",

      isOuterPackagingCompleted: true,
      shippingPackage: Boolean(profile?.shippingPackage),
      shippingLabelsOnly: Boolean(profile?.shippingLabels),
      disposeOuterPackaging: Boolean(profile?.disposeOuterPackage),
      outerPackagingNotes: profile?.outerPackageNotes || "",

      // CustomizeProductPackaging
      isCustomizeProductPackagingCompleted: true,
      productPackagingTop: Boolean(profile?.productPackageTop),
      productPackagingTopNotes: profile?.productPackageTopNotes || "",
      productPackagingBottom: Boolean(profile?.productPackageBottom),
      productPackagingBottomNotes: profile?.productPackageBottomNotes || "",
      productPackagingFront: Boolean(profile?.productPackageFront),
      productPackagingFrontNotes: profile?.productPackageFrontNotes || "",
      productPackagingBack: Boolean(profile?.productPackageBack),
      productPackagingBackNotes: profile?.productPackageBackNotes || "",
      productPackagingPhotoExamples: profile?.productItems
        ?.filter(
          (file) =>
            file.type === ProductPhotoProfileSectionEnum.ProductPackaging
        )
        .sort((fileA, fileB) => fileA.orderInList - fileB.orderInList)
        .map(toFormAttachment),

      // CustomizePhotoItemOrPurchasedItem
      isPurchasedItemCompleted: true,
      purchasedPhotoExamples: profile?.productItems
        ?.filter(
          (file) => file.type === ProductPhotoProfileSectionEnum.PurchasedItem
        )
        .sort((fileA, fileB) => fileA.orderInList - fileB.orderInList)
        .map(toFormAttachment),
    };
  }

  static fromForm(
    formValues: ProductPhotoProfileFormValuesType
  ): ICreateProductPhotoProfile {
    return {
      name: formValues.profileName,
      listingPage: formValues.listingPage,
      orderConfirmation: formValues.orderConfirmation,
      checkoutReceipt: formValues.checkoutReceipt,
      screenShotNotes: formValues.screenShotsNotes,
      allDocsIncluded: formValues.allDocuments,
      docsReceiptMaterialNotes: formValues.documentsNotes,
      shippingPackage: formValues.shippingPackage,
      shippingLabels: formValues.shippingLabelsOnly,
      disposeOuterPackage: formValues.disposeOuterPackaging,
      outerPackageNotes: formValues.outerPackagingNotes,
      productPackageTop: formValues.productPackagingTop,
      productPackageTopNotes: formValues.productPackagingTop
        ? formValues.productPackagingTopNotes
        : "",
      productPackageBottom: formValues.productPackagingBottom,
      productPackageBottomNotes: formValues.productPackagingBottom
        ? formValues.productPackagingBottomNotes
        : "",
      productPackageFront: formValues.productPackagingFront,
      productPackageFrontNotes: formValues.productPackagingFront
        ? formValues.productPackagingFrontNotes
        : "",
      productPackageBack: formValues.productPackagingBack,
      productPackageBackNotes: formValues.productPackagingBack
        ? formValues.productPackagingBackNotes
        : "",
      packageItems: [],
    };
  }
}

const toFormAttachment = (photo: IProductItem): IFormAttachment => ({
  id: photo.id,
  fileId: photo.fileId,
  notes: photo.notes,
  name: photo.name,
  original: photo.url,
  thumbnail: photo.thumbnailUrl,
});
