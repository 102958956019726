import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GridValueFormatterParams,
  GridValueGetterParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { formatDisplayDate } from "../../../../../common/utils";

import { ApmGridColDef } from "../../../../../common/components";
import { IPurchaseListItem } from "../interfaces";
import { PurchaseService } from "../services";

const defaultSorting: GridSortModel = [{ field: "dateUpdated", sort: "desc" }];

const purchaseStatus: { [key: string]: string } = {
  CREATED: "Created",
  REQUESTED: "Requested",
  ORDER_COMPLETED: "Order Completed",
  CAPTURED: "Captured",
  PROCESSING: "Processing",
  SELLER_SHIPPED: "Seller Shipped",
  ARTIFICE_RECEIVED: "Artifice Received",
};

export const usePurchaseList = () => {
  const { t } = useTranslation("portal");
  const [loading, setLoading] = useState<boolean>(true);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSorting);
  const [purchases, setPurchases] = useState<IPurchaseListItem[]>([]);

  useEffect(() => {
    const getPurchases = async () => {
      setLoading(true);
      const profiles = await PurchaseService.fetchEntities();
      setPurchases(profiles);
      setLoading(false);
    };

    getPurchases();
  }, [setPurchases]);

  const columns: ApmGridColDef[] = useMemo(
    () => [
      {
        field: "artificeCaseNumber",
        headerName: t("Artifice Case Number") as string,
        flex: 1,
        handleContentClick: true,
      },
      {
        field: "sellerRef",
        headerName: t("Seller") as string,
        flex: 1,
      },
      {
        field: "createdBy",
        headerName: t("Created By") as string,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.firstName} ${params.row.lastName}`,
      },
      {
        field: "dateUpdated",
        headerName: t("Date Updated") as string,
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) =>
          formatDisplayDate(params.value),
      },
      {
        field: "status",
        headerName: t("Status") as string,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => t(params.row.status),
      },
      {
        field: "reports",
        headerName: t("Reports") as string,
        flex: 1,
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      purchases.map((purchase) => ({
        id: purchase.id,
        artificeCaseNumber: purchase.artificeCaseNumber,
        sellerRef: purchase.sellerRef,
        lastName: purchase.createdBy?.lastName || "",
        firstName: purchase.createdBy.firstName || "",
        dateUpdated: new Date(purchase.updatedAt),
        status:
          purchase.paymentStatus === "AUTHORIZED_FAILED"
            ? "Requested"
            : purchaseStatus[purchase.status],
        reports: purchase.reports,
        canDelete: false,
        canUpdate: false,
      })),
    [purchases]
  );

  return {
    purchases,
    rows,
    columns,
    loading,
    sortModel,
    setSortModel,
  };
};
