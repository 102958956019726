import React from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../../../../../portal";
import { ArtificeRoutes } from "../../../../../../common";
import { useAdminPurchase } from "../../../../common/hooks";

// todo: move it to common/core
import { PurchaseInformation } from "../../../../../portal/pages/purchases/components";

export const PurchaseViewPage = () => {
  const navigate = useNavigate();
  const { purchase, isLoading } = useAdminPurchase();
  const handleSummaryNavigation = () => navigate("checkout");

  return (
    <section>
      <PageTitle
        title="Purchase Details"
        customAction="Go To Summary"
        backUrl={ArtificeRoutes.adminPurchases}
        loading={isLoading}
        onAction={handleSummaryNavigation}
      />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <PurchaseInformation purchase={purchase} showLeads={true} />
      )}
    </section>
  );
};
