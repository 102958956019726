import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { scheduleMeeting } from "../../../../common/utils";
import styles from "./style.module.scss";

export const MainSection = () => {
  const [t] = useTranslation("common");

  return (
    <section className={styles.mainWrapper} id="main">
      <section className={styles.actionBlock}>
        <section className={styles.titleWrapper}>
          <section className={styles.titleSection}>
            <span className={styles.titleLarge}>{t("ARTIFICE GLOBAL")}</span>
          </section>
        </section>
        <hr />
        <section className={styles.actionBlockBottom}>
          <span className={styles.solutionForBrand}>
            {t("Tech Enabled Services & Brand Solutions")}
          </span>

          <section>
            <Button
              variant="contained"
              size="large"
              color="light"
              onClick={scheduleMeeting}
            >
              {t("Schedule a meeting")}
            </Button>
          </section>
        </section>
      </section>
    </section>
  );
};
