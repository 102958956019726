import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { useScrollToTop } from "../../../common/hooks";
import { Footer, ArtificeNavigation } from "../components";
import styles from "./styles.module.scss";

export const HomeLayout = () => {
  useScrollToTop();

  return (
    <Suspense>
      <section className={styles.homeWrapper}>
        <ArtificeNavigation />
        <section className={styles.routerLayout}>
          <Suspense>
            <Outlet />
          </Suspense>
        </section>
        <Footer />
      </section>
    </Suspense>
  );
};
