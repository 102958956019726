import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { ArtificeCheckbox } from "../../../../../../../common/components";
import { ProductPhotoProfileFormType } from "../../../interfaces";
import { Customization } from "../../customization";
import { getFormFieldProps } from "../../../../../../../common/utils";
import { MAX_PRODUCT_PACKAGING_PHOTO_EXAMPLE } from "../../../constants";
import styles from "./style.module.scss";

interface ProductPackagingProps {
  formik: ProductPhotoProfileFormType;
}

export const ProductPackaging = ({ formik }: ProductPackagingProps) => {
  const [t] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const {
    productPackagingTop,
    productPackagingBottom,
    productPackagingFront,
    productPackagingBack,
  } = formik.values;

  const handleProductPackagingTopChange = (val: boolean) =>
    formik.setFieldValue("productPackagingTop", val);
  const handleProductPackagingBottomChange = (val: boolean) =>
    formik.setFieldValue("productPackagingBottom", val);
  const handleProductPackagingFrontChange = (val: boolean) =>
    formik.setFieldValue("productPackagingFront", val);
  const handleProductPackagingBackChange = (val: boolean) =>
    formik.setFieldValue("productPackagingBack", val);
  const formProps = getFormFieldProps(formik, t, tValidation);

  return (
    <>
      <section className={styles.verticalView}>
        <ArtificeCheckbox
          label={t("Top")}
          checked={Boolean(productPackagingTop)}
          onChange={handleProductPackagingTopChange}
        />
        {Boolean(productPackagingTop) && (
          <TextField
            className={styles.notes}
            label={t("Notes")}
            multiline
            rows={2}
            {...formProps("productPackagingTopNotes", t("Notes"))}
          />
        )}
        <ArtificeCheckbox
          label={t("Bottom")}
          checked={Boolean(productPackagingBottom)}
          onChange={handleProductPackagingBottomChange}
        />
        {Boolean(productPackagingBottom) && (
          <TextField
            className={styles.notes}
            label={t("Notes")}
            multiline
            rows={2}
            {...formProps("productPackagingBottomNotes", t("Notes"))}
          />
        )}
        <ArtificeCheckbox
          label={t("Front")}
          checked={Boolean(productPackagingFront)}
          onChange={handleProductPackagingFrontChange}
        />
        {Boolean(productPackagingFront) && (
          <TextField
            className={styles.notes}
            label={t("Notes")}
            multiline
            rows={2}
            {...formProps("productPackagingFrontNotes", t("Notes"))}
          />
        )}
        <ArtificeCheckbox
          label={t("Back")}
          checked={Boolean(productPackagingBack)}
          onChange={handleProductPackagingBackChange}
        />
        {Boolean(productPackagingBack) && (
          <TextField
            className={styles.notes}
            label={t("Notes")}
            multiline
            rows={2}
            {...formProps("productPackagingBackNotes", t("Notes"))}
          />
        )}
      </section>

      <Customization
        form={formik}
        field="productPackagingPhotoExamples"
        max={MAX_PRODUCT_PACKAGING_PHOTO_EXAMPLE}
      />
    </>
  );
};
