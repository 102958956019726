import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProductPhotoProfileFormType } from "../../interfaces";
import { DragAndDrop } from "../drag-and-drop/DragAndDrop";
import styles from "./style.module.scss";

interface CustomizationItemProps {
  attachment: any;
  form: ProductPhotoProfileFormType;
  field: "purchasedPhotoExamples" | "productPackagingPhotoExamples";
  index: number;
}

export const CustomizationItem = ({
  attachment,
  form,
  field,
  index,
}: CustomizationItemProps) => {
  const values = form.values[field]?.[index];
  const errors = form.errors[field]?.[index] as any;
  const [t] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const attachments = form.values[field] || [];

  const updateValue = (
    value: { name?: string; note?: string; file?: File }[]
  ) => form.setFieldValue(field, value);

  const handleRemove = (id: number) => () =>
    updateValue(attachments.filter((attachment) => attachment.id !== id));

  const handleFieldChange =
    (type: "notes" | "name", id: number) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValues = attachments?.map((attachment) =>
        attachment.id === id
          ? { ...attachment, [type]: event.target.value }
          : attachment
      );

      updateValue(newValues);
    };

  const handleFileChange = (file: File) => {
    const newValues = attachments?.map((a) =>
      a.id === attachment.id ? { ...a, file } : a
    );
    updateValue(newValues);
  };

  const handleDelete = () => {
    const newValues = attachments?.map((a) =>
      a.id === attachment.id
        ? {
            ...a,
            fileId: undefined,
            file: undefined,
            original: undefined,
            thumbnail: undefined,
            linkId: null,
          }
        : a
    );
    updateValue(newValues);
  };

  return (
    <section className={styles.row} key={attachment.id}>
      <DragAndDrop
        onDelete={handleDelete}
        onUpload={handleFileChange}
        originalUrl={values?.original}
        thumbnailUrl={values?.thumbnail}
      />
      <section className={styles.photoInfo}>
        <section className={styles.nameSection}>
          <TextField
            className={styles.name}
            margin="normal"
            size="small"
            label={t("Photo Name")}
            placeholder={t("e.g. Hang Tag") as string}
            value={values?.name}
            onChange={handleFieldChange("name", attachment.id)}
            helperText={tValidation(errors?.name || "", {
              field: t("Photo Name"),
            })}
            error={Boolean(errors?.name)}
          />
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={handleRemove(attachment.id)}
            className={styles.deleteBtn}
          >
            <DeleteIcon />
          </Button>
        </section>

        <TextField
          className={styles.notes}
          margin="normal"
          size="small"
          multiline
          rows={3}
          label={t("Notes")}
          value={values?.notes}
          placeholder={
            t("e.g. Front and back of manufacturer’s label") as string
          }
          onChange={handleFieldChange("notes", attachment.id)}
          error={Boolean(errors?.notes)}
          helperText={tValidation(errors?.notes || "", { field: t("Notes") })}
        />
      </section>
    </section>
  );
};
