import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Collapse, InputAdornment, TextField } from "@mui/material";
import {
  ArtificeNumericFormat,
  ArtificeRadio,
} from "../../../../../../common/components";
import { CollapsableButton } from "../collapsable-button";
import { PurchaseFormType } from "../../schemas";
import styles from "./style.module.scss";

interface OrderSubstitutionsProps {
  index: number;
  purchaseForm: PurchaseFormType;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const OrderSubstitutions = ({
  open,
  index,
  purchaseForm,
  setOpen,
}: OrderSubstitutionsProps) => {
  const [t] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const orderSubstitutionValues = purchaseForm.values.productOrders[index];
  const errors = purchaseForm.errors.productOrders?.[index] as any;

  const toggleCollapse = () => setOpen((val) => !val);

  const handleRadioChange = (field: string) => (value: boolean) => {
    updateFieldValue(field, value);
  };

  const handleFieldChange =
    (field: string) => (event: ChangeEvent<{ value: unknown }>) => {
      updateFieldValue(field, event?.target?.value as string);
    };

  const updateFieldValue = (field: string, value: boolean | string) => {
    purchaseForm.setFieldValue(`productOrders[${index}].${field}`, value);
  };

  return (
    <section className={styles.wrapper}>
      <CollapsableButton
        open={open}
        onClick={toggleCollapse}
        label={t("Order Substitutions")}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ArtificeRadio
          label={t("If MOQ required, buy?")}
          checked={Boolean(orderSubstitutionValues.moqRequired)}
          onChange={handleRadioChange("moqRequired")}
        />
        {Boolean(orderSubstitutionValues.moqRequired) && (
          <>
            <p>
              <TextField
                label={t("Max Product Budget")}
                value={orderSubstitutionValues.maxBudget}
                onChange={handleFieldChange("maxBudget")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputComponent: ArtificeNumericFormat as any,
                }}
              />
            </p>
            <TextField
              className={styles.notes}
              label={t("Notes")}
              multiline
              rows={2}
              value={orderSubstitutionValues.moqRequiredNotes}
              onChange={handleFieldChange("moqRequiredNotes")}
              error={Boolean(errors?.moqRequiredNotes)}
              helperText={tValidation(errors?.moqRequiredNotes || "", {
                field: t("Notes"),
              })}
            />
          </>
        )}
        <ArtificeRadio
          label={t("If Item unavailable, substitute?")}
          checked={Boolean(orderSubstitutionValues.substituteUnavailable)}
          onChange={handleRadioChange("substituteUnavailable")}
        />
        {Boolean(orderSubstitutionValues.substituteUnavailable) && (
          <TextField
            className={styles.notes}
            label={t("Notes")}
            multiline
            rows={2}
            value={orderSubstitutionValues.substituteUnavailableNotes}
            onChange={handleFieldChange("substituteUnavailableNotes")}
            error={Boolean(errors?.substituteUnavailableNotes)}
            helperText={tValidation(errors?.substituteUnavailableNotes || "", {
              field: t("Notes"),
            })}
          />
        )}
        <ArtificeRadio
          label={t("If size unavailable, substitute?")}
          checked={Boolean(orderSubstitutionValues.substituteSizeUnavailable)}
          onChange={handleRadioChange("substituteSizeUnavailable")}
        />
        {Boolean(orderSubstitutionValues.substituteSizeUnavailable) && (
          <TextField
            className={styles.notes}
            label={t("Notes")}
            multiline
            rows={2}
            value={orderSubstitutionValues.substituteSizeUnavailableNotes}
            onChange={handleFieldChange("substituteSizeUnavailableNotes")}
            error={Boolean(errors?.substituteSizeUnavailableNotes)}
            helperText={tValidation(
              errors?.substituteSizeUnavailableNotes || "",
              {
                field: t("Notes"),
              }
            )}
          />
        )}
        <ArtificeRadio
          label={t("If the color unavailable, substitute?")}
          checked={Boolean(orderSubstitutionValues.substituteColorUnavailable)}
          onChange={handleRadioChange("substituteColorUnavailable")}
        />
        {Boolean(orderSubstitutionValues.substituteColorUnavailable) && (
          <TextField
            className={styles.notes}
            label={t("Notes")}
            multiline
            rows={2}
            value={orderSubstitutionValues.substituteColorUnavailableNotes}
            onChange={handleFieldChange("substituteColorUnavailableNotes")}
            error={Boolean(errors?.substituteColorUnavailableNotes)}
            helperText={tValidation(
              errors?.substituteColorUnavailableNotes || "",
              {
                field: t("Notes"),
              }
            )}
          />
        )}
        <ArtificeRadio
          label={t("If quantities are not available, buy less?")}
          checked={Boolean(
            orderSubstitutionValues.substituteQuantitiesUnavailable
          )}
          onChange={handleRadioChange("substituteQuantitiesUnavailable")}
        />
        {Boolean(orderSubstitutionValues.substituteQuantitiesUnavailable) && (
          <TextField
            className={styles.notes}
            label={t("Notes")}
            multiline
            rows={2}
            value={orderSubstitutionValues.substituteQuantitiesUnavailableNotes}
            onChange={handleFieldChange("substituteQuantitiesUnavailableNotes")}
            error={Boolean(errors?.substituteQuantitiesUnavailableNotes)}
            helperText={tValidation(
              errors?.substituteQuantitiesUnavailableNotes || "",
              {
                field: t("Notes"),
              }
            )}
          />
        )}
      </Collapse>
    </section>
  );
};
