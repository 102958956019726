import {
  ArtificeAdminRoutesEnum,
  ArtificeAuthRoutesEnum,
  ArtificeModuleRoutesEnum,
  ArtificeProductPhotoProfilesRoutesEnum,
  ArtificeProfileRoutesEnum,
  ArtificePurchaseProfileRoutesEnum,
  ArtificePurchaseRoutesEnum,
} from "../enums";

export const ArtificeRoutes = {
  home: ArtificeModuleRoutesEnum.Home,
  register: `${ArtificeModuleRoutesEnum.Auth}/${ArtificeAuthRoutesEnum.Register}`,
  forgotPassword: `${ArtificeModuleRoutesEnum.Auth}/${ArtificeAuthRoutesEnum.ForgotPassword}`,
  forgotPasswordSuccess: `${ArtificeModuleRoutesEnum.Auth}/${ArtificeAuthRoutesEnum.ForgotPasswordSuccess}`,
  resetPasswordSuccess: `${ArtificeModuleRoutesEnum.Auth}/${ArtificeAuthRoutesEnum.ResetPasswordSuccess}`,
  registerSuccess: `${ArtificeModuleRoutesEnum.Auth}/${ArtificeAuthRoutesEnum.RegisterSuccess}`,
  verifyEmail: `${ArtificeModuleRoutesEnum.Auth}/${ArtificeAuthRoutesEnum.VerifyEmail}`,
  login: `${ArtificeModuleRoutesEnum.Auth}/${ArtificeAuthRoutesEnum.Login}`,
  profile: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.Purchases}`,
  purchases: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.Purchases}`,
  createPurchase: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.Purchases}/${ArtificePurchaseRoutesEnum.Create}`,
  successPurchase: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.Purchases}/${ArtificePurchaseRoutesEnum.Success}`,
  viewPurchase: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.Purchases}/${ArtificePurchaseRoutesEnum.View}`,
  purchaseProfiles: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.PurchaseProfile}`,
  createPurchaseProfile: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.PurchaseProfile}/${ArtificePurchaseProfileRoutesEnum.Create}`,
  productPhotoProfiles: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.ProductPhotoProfiles}`,
  createProductPhotoProfiles: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.ProductPhotoProfiles}/${ArtificeProductPhotoProfilesRoutesEnum.Create}`,
  representedBrands: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.RepresentedBrands}`,
  services: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.Services}`,
  pricing: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.Pricing}`,
  userManagement: `${ArtificeModuleRoutesEnum.Portal}/${ArtificeProfileRoutesEnum.UserManagement}`,

  admin: `${ArtificeModuleRoutesEnum.Admin}/${ArtificeAdminRoutesEnum.Purchases}`,
  adminPurchases: `${ArtificeModuleRoutesEnum.Admin}/${ArtificeAdminRoutesEnum.Purchases}`,
  adminOrganizations: `${ArtificeModuleRoutesEnum.Admin}/${ArtificeAdminRoutesEnum.Organizations}`,
  adminUserManagement: `${ArtificeModuleRoutesEnum.Admin}/${ArtificeAdminRoutesEnum.UserManagement}`,
  adminBrands: `${ArtificeModuleRoutesEnum.Admin}/${ArtificeAdminRoutesEnum.Brands}`,
};
