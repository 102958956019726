import { string } from "yup";
import { maxLength, maxLengthMessage } from "./max-length.const";

export const companyNameValidation = string().max(
  maxLength.companyName,
  maxLengthMessage.max100
);

export const jobTitleValidation = string().max(
  maxLength.jobTitle,
  maxLengthMessage.max100
);
