import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import { IPaymentMethod, IPaymentProfileForm } from "../../interfaces";
import { CreditCardIcon } from "../../../../../../common/components";
import { AddCreditCardModal } from "../add-credit-card-modal";
import styles from "./style.module.scss";

declare global {
  interface Window {
    AuthorizeNetIFrame: any;
  }
}

interface PaymentsProps {
  paymentMethods?: IPaymentMethod[];
  activePayment?: number;
  handlePaymentChange: (paymentId: number) => void;
  addPaymentMethod: () => Promise<IPaymentProfileForm | undefined>;
  fetchPayments: () => void;
}
export const Payments = ({
  paymentMethods,
  activePayment,
  handlePaymentChange,
  addPaymentMethod,
  fetchPayments,
}: PaymentsProps) => {
  const [t] = useTranslation("portal");
  const [addPaymentInProgress, setAddPaymentInProgress] = useState(false);
  const [addPaymentOpened, setAddPaymentOpened] = useState(false);
  const handleClose = () => setAddPaymentOpened(false);

  const form = useRef<HTMLFormElement>(null);
  const token = useRef<HTMLInputElement>(null);

  const handleAddPaymentMethod = async () => {
    setAddPaymentInProgress(true);
    setAddPaymentOpened(true);

    const payment = await addPaymentMethod();
    if (form.current && token.current && payment) {
      form.current.action = payment.authorizeApiUrl;
      token.current.value = payment.formToken;
      form.current.submit();
    }
    setAddPaymentInProgress(false);
  };

  return (
    <>
      <List className={styles.paymentMethods}>
        {paymentMethods?.map((paymentMethod) => (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handlePaymentChange(paymentMethod.paymentProfileId);
              }}
            >
              <Radio
                edge="start"
                onChange={() =>
                  handlePaymentChange(paymentMethod.paymentProfileId)
                }
                checked={activePayment === paymentMethod.paymentProfileId}
              />
              <ListItemIcon>
                <CreditCardIcon type={paymentMethod.cardType} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <section className={styles.pmTextSection}>
                    <span>
                      {t("Card number: {{cardNumber}}", {
                        cardNumber: paymentMethod.cardNumber,
                      })}
                    </span>
                    <span>
                      {t("Valid until: {{cardExpDate}}", {
                        cardExpDate: paymentMethod.cardExpDate,
                      })}
                    </span>
                  </section>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <form
        id="send_token"
        ref={form}
        className={styles.hidden}
        action=""
        method="post"
        target="add_payment"
      >
        <input ref={token} type="hidden" name="token" />
      </form>

      <AddCreditCardModal
        open={addPaymentOpened}
        handleClose={handleClose}
        fetchPayments={fetchPayments}
      />

      <LoadingButton
        loading={addPaymentInProgress}
        onClick={handleAddPaymentMethod}
        variant="contained"
        color="secondary"
        endIcon={<Add />}
      >
        {t("Add Credit Card")}
      </LoadingButton>
    </>
  );
};
