import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  ArtificeAccordionDetails,
  ArtificeAccordionSummary,
} from "../../../../../../common/components";
import { PurchaseFormSectionsEnum } from "../../enums";
import { PurchaseFormType } from "../../schemas";
import { CaseInformation } from "../case-information";
import { ProductOrder } from "../product-order";
import {
  IProductPhotoProfileListItem,
  IPurchaseProfileListItem,
  SectionTitle,
} from "../../../../common";
import { ProductOrderCount } from "../product-order-count";
import { usePurchaseBrands, usePurchaseFormAccordion } from "../../hooks";
import { PRODUCT_ORDERS_MAX_COUNT } from "../../../../../../common";
import { StorageAndShippingSettings } from "../storage-and-shipping-settings";
import styles from "./style.module.scss";

interface PurchaseFormProps {
  purchaseForm: PurchaseFormType;
  productPhotoProfiles?: IProductPhotoProfileListItem[];
  purchaseProfiles?: IPurchaseProfileListItem[];
  handlePreview?(): void;
}

export const PurchaseForm = ({
  purchaseForm,
  productPhotoProfiles,
  purchaseProfiles,
  handlePreview,
}: PurchaseFormProps) => {
  const {
    caseInformationExpanded,
    storageAndShippingExpanded,
    isCaseInformationInvalid,
    isShippingAndStorageInvalid,
    isProductOrderInvalid,
    productOrdersExpanded,
    handleChange,
    handleNext,
    setProductOrderIndexExpanded,
    validateAllSection,
  } = usePurchaseFormAccordion(purchaseForm);

  const { purchaseBrands, refetchBrands } = usePurchaseBrands();

  const [t] = useTranslation("portal");

  return (
    <>
      <form onSubmit={purchaseForm.handleSubmit}>
        {/* Case Information */}
        <Accordion
          expanded={caseInformationExpanded}
          onChange={handleChange(PurchaseFormSectionsEnum.CaseInformation)}
        >
          <ArtificeAccordionSummary
            variant="filled"
            title={t("Case Information")}
            error={Boolean(isCaseInformationInvalid)}
            completed={purchaseForm.values.isCaseInformationCompleted}
          />

          <ArtificeAccordionDetails
            classes={{ footer: styles.footer }}
            currentSection={PurchaseFormSectionsEnum.CaseInformation}
            nextSection={PurchaseFormSectionsEnum.ProductOrder}
            handleNext={handleNext}
          >
            <CaseInformation
              purchaseForm={purchaseForm}
              purchaseBrands={purchaseBrands}
              refetchBrands={refetchBrands}
            />
          </ArtificeAccordionDetails>
        </Accordion>

        {/* Product Order */}
        {purchaseForm.values?.productOrders?.map(
          (productOrder, index, array) => (
            <Accordion
              expanded={productOrdersExpanded(index)}
              key={`product-order-${index}`}
              onChange={handleChange(
                PurchaseFormSectionsEnum.ProductOrder,
                index
              )}
            >
              <ArtificeAccordionSummary
                variant="filled"
                error={Boolean(isProductOrderInvalid(index))}
                right={
                  <ProductOrderCount
                    from={index + 1}
                    count={PRODUCT_ORDERS_MAX_COUNT}
                  />
                }
                title={
                  <SectionTitle
                    name={t("Product Order")}
                    subName={purchaseForm.values.thirdPartySite}
                  />
                }
                completed={productOrder.isProductOrderCompleted}
              />

              <ArtificeAccordionDetails
                classes={{ footer: styles.footer }}
                hideNext={index !== array.length - 1}
                currentSection={PurchaseFormSectionsEnum.ProductOrder}
                nextSection={PurchaseFormSectionsEnum.StorageAndShipping}
                handleNext={handleNext}
              >
                <ProductOrder
                  index={index}
                  count={array.length}
                  purchaseForm={purchaseForm}
                  purchaseBrands={purchaseBrands}
                  refetchBrands={refetchBrands}
                  productPhotoProfiles={productPhotoProfiles}
                  setProductOrderIndexExpanded={setProductOrderIndexExpanded}
                />
              </ArtificeAccordionDetails>
            </Accordion>
          )
        )}

        {/* Storage And Shipping s*/}
        <Accordion
          expanded={storageAndShippingExpanded}
          onChange={handleChange(PurchaseFormSectionsEnum.StorageAndShipping)}
        >
          <ArtificeAccordionSummary
            variant="filled"
            title={t("Storage & Shipping Questions")}
            error={Boolean(isShippingAndStorageInvalid)}
            completed={purchaseForm.values.isStorageAndShippingCompleted}
          />
          <ArtificeAccordionDetails
            classes={{ footer: styles.footer }}
            currentSection={PurchaseFormSectionsEnum.StorageAndShipping}
            handleNext={handleNext}
          >
            <StorageAndShippingSettings
              purchaseForm={purchaseForm}
              purchaseProfiles={purchaseProfiles}
              error={Boolean(isShippingAndStorageInvalid)}
            />
          </ArtificeAccordionDetails>
        </Accordion>

        <section className={styles.formFooter}>
          <section>
            <Button
              className={styles.previewButton}
              variant="outlined"
              onClick={handlePreview}
            >
              {t("Preview")}
            </Button>
            <LoadingButton
              loading={purchaseForm.isSubmitting}
              variant="contained"
              onClick={() => {
                purchaseForm.submitForm();
                validateAllSection();
              }}
            >
              {t("Save & Go to Summary")}
            </LoadingButton>
          </section>
        </section>
      </form>
    </>
  );
};
