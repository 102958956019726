import React from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { IPurchaseProfile, ProfileInfoList } from "../../../../common";
import { usePurchaseProfileInfo } from "../../hooks";
import { ShippingView } from "../../../purchases/components";
import styles from "./style.module.scss";

export const PurchaseProfileInformation = ({
  purchaseProfile,
}: {
  purchaseProfile?: IPurchaseProfile;
}) => {
  const [t] = useTranslation("portal");
  const { companyInformationList, shippingInfo } =
    usePurchaseProfileInfo(purchaseProfile);

  const yes = t("Yes");
  const no = t("No");

  return (
    <Paper classes={{ root: styles.viewWrapper }}>
      <section className={styles.sectionWrapper}>
        <p className={styles.viewSectionTitle}>{t("Company Information")}</p>

        <section className={styles.sectionContent}>
          <ProfileInfoList
            list={companyInformationList}
            type="companyInformationList"
          />
        </section>
      </section>

      <section className={styles.sectionWrapper}>
        <p className={styles.viewSectionTitle}>
          {t("Storage & Shipping Questions")}
        </p>
        <ShippingView shippingInfo={shippingInfo} />
      </section>

      <section className={styles.sectionWrapper}>
        <p className={styles.viewSectionTitle}>{t("Leads")}</p>
        <section>
          <span className={styles.listItemName}>
            {t("Can we notify you of leads related to your Brand?")}
          </span>
          <span className={styles.listItemValue}>
            {" - "}
            {purchaseProfile?.notifyOfLeads ? yes : no}
          </span>
        </section>
      </section>
    </Paper>
  );
};
