import React from "react";
import { ArtificePurchaseProfileRoutesEnum } from "../../../../../common";
import {
  PurchaseProfileList,
  CreatePurchaseProfile,
  ViewPurchaseProfile,
  EditPurchaseProfile,
} from "../pages";

export const PurchaseProfileRoutes = [
  {
    index: true,
    element: <PurchaseProfileList />,
  },
  {
    path: ArtificePurchaseProfileRoutesEnum.Create,
    element: <CreatePurchaseProfile />,
  },
  {
    path: ArtificePurchaseProfileRoutesEnum.View,
    element: <ViewPurchaseProfile />,
  },
  {
    path: ArtificePurchaseProfileRoutesEnum.Edit,
    element: <EditPurchaseProfile />,
  },
];
