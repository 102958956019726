import React from "react";
import { Navigate } from "react-router-dom";
import { ArtificeAdminRoutesEnum } from "../../../common";
import {
  Brands,
  Organizations,
  PurchaseRoutes,
  UserManagement,
} from "../pages";

export const AdminRoutes = [
  {
    path: "",
    children: [
      {
        path: "",
        element: <Navigate to={ArtificeAdminRoutesEnum.Purchases} replace />,
      },
      {
        path: ArtificeAdminRoutesEnum.Purchases,
        children: PurchaseRoutes,
      },
      {
        path: ArtificeAdminRoutesEnum.Organizations,
        element: <Organizations />,
      },
      {
        path: ArtificeAdminRoutesEnum.Brands,
        element: <Brands />,
      },
      {
        path: ArtificeAdminRoutesEnum.UserManagement,
        element: <UserManagement />,
      },
    ],
  },
];
