import React, { useState } from "react";
import classnames from "classnames";
import { Trans } from "react-i18next";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Notes } from "../notes";
import { Currency } from "../../../../../common/components/currency";
import styles from "./style.module.scss";

export interface InfoList {
  name: string;
  value?: string | number;
  secure?: boolean;
  valueType?: "text" | "currency" | "link";
  notes?: string;
  useTrans?: boolean;
  hideColon?: boolean;
}

interface ProfileInfoListItemProps {
  item: InfoList;
  type: string;
  classes?: {
    listItemName?: string;
    listItemValueWrapper?: string;
    listItemValue?: string;
  };
}

export const ProfileInfoListItem = ({
  item,
  type,
  classes,
}: ProfileInfoListItemProps) => {
  const [showSecure, setShowSecure] = useState(false);

  return (
    <>
      <li key={`${type}-${item.name}`}>
        {item.useTrans ? (
          <span
            className={classnames(styles.listItemName, classes?.listItemName)}
          >
            <Trans ns="profile" i18nKey={item.name} />
            {item.hideColon ? null : ":"}
          </span>
        ) : (
          <span
            className={classnames(styles.listItemName, classes?.listItemName)}
          >
            {item.name}
            {item.hideColon ? null : ":"}
          </span>
        )}
      </li>
      {item.valueType === "currency" ? (
        <Currency
          value={item.value}
          classes={{
            currencyText: classnames(
              classes?.listItemValue,
              styles.currencyText
            ),
          }}
        />
      ) : (
        <li
          key={`${type}-${item.name}-${item.value}`}
          className={classes?.listItemValueWrapper}
        >
          <span
            className={classnames(
              styles.listItemValue,
              classes?.listItemValue,
              item.secure ? !showSecure && styles.secure : "",
              item.valueType === "link" ? styles.overflowWrap : ""
            )}
          >
            {item.value}
            {item.secure ? (
              <IconButton
                className={styles.secureToggle}
                onClick={() => setShowSecure((val) => !val)}
              >
                {showSecure ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ) : null}
          </span>
        </li>
      )}

      <li className={styles.notes} key={`${type}-${item.name}-${item.notes}`}>
        <Notes notes={item.notes} noPadding />
      </li>
    </>
  );
};
