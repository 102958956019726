import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridSortModel } from "@mui/x-data-grid";
import { AdminService } from "../services";
import { IBrand } from "../../../portal/pages/represented-brands/interfaces";
import { IBrandListItem } from "../interfaces";
import { useNotifications } from "../../../../common";
import { ApmGridColDef } from "../../../../common/components";
import { BrandTypeEnum } from "../../../portal/common/enums";
import { BrandFilterEnum } from "../enums";
import { useOrganizationsList } from "./use-organizations-list";

const defaultSorting: GridSortModel = [{ field: "brandName", sort: "asc" }];

type IFilterOption = {
  value: string;
  label: string;
} | null;

export interface brandValue {
  orgNames: string[];
  type: BrandTypeEnum;
  systemBrandId?: number;
}

export const useBrandsList = () => {
  const { t } = useTranslation("admin");

  const [loading, setLoading] = useState<boolean>(true);
  const { showAlert } = useNotifications();

  const [brands, setBrands] = useState<IBrand[]>([]);

  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSorting);
  const [filter, setFilter] = useState<string>(BrandFilterEnum.REPRESENTED);
  const [organization, setOrganization] = useState<IFilterOption>();

  const { organizations } = useOrganizationsList();

  const getBrands = async () => {
    setLoading(true);
    const brands = await AdminService.fetchOrgBrands();
    const systemBrands = await AdminService.fetchSystemBrands();
    setBrands([...brands, ...systemBrands]);
    setLoading(false);
  };

  const onChangeBrandType = async (name: string) => {
    try {
      setLoading(true);
      await AdminService.addSystemBrand(name);
      await getBrands();
      showAlert({
        text: t("Brand Type was successfully updated"),
        severity: "success",
      });
    } catch (err) {
      showAlert({
        text: t("There was an error. Please try again"),
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const organizationOptions: any = useMemo(
    () =>
      organizations
        .map((org) => ({ value: org.name, label: org.name }))
        .sort((a, b) => {
          const labelA = a.label.toLowerCase();
          const labelB = b.label.toLowerCase();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }),
    [organizations]
  );

  const brandsFiltered = useMemo(
    () =>
      brands.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.name === item.name)
      ),
    [brands]
  );

  const brandsMap = useMemo(() => {
    const brandsMap = new Map<string, brandValue>();

    brands.forEach((brand) => {
      brand.orgName
        ? brandsMap.set(brand.name, {
            orgNames: [
              ...(brandsMap.get(brand.name)?.orgNames || []),
              brand.orgName,
            ],
            type: BrandTypeEnum.ORGANIZATION,
          })
        : brandsMap.set(brand.name, {
            systemBrandId: brand.id,
            orgNames: [...(brandsMap.get(brand.name)?.orgNames || [])],
            type: BrandTypeEnum.SYSTEM,
          });
    });

    return brandsMap;
  }, [brands]);

  const handleDelete = async (row: IBrandListItem) => {
    try {
      if (!row.systemBrandId) {
        throw new Error("Invalid data");
      }
      await AdminService.deleteSystemBrand(row.systemBrandId);
      showAlert({
        text: t("System Brand was successfully deleted"),
        severity: "success",
      });
    } catch (err) {
      showAlert({
        text: t("There was an error. Please try again"),
        severity: "error",
      });
    }
  };

  const handleEdit = async (row: IBrandListItem, newBrandName: string) => {
    try {
      const brandNameTrimed = newBrandName.trim();
      if (row.brandType === BrandTypeEnum.SYSTEM) {
        await AdminService.updateSystemBrand(brandNameTrimed, row.brandName);
      }

      if (row.orgNames.length > 0) {
        await AdminService.updateBrand(brandNameTrimed, row.brandName);
      }

      showAlert({
        text: t("Brand was successfully updated"),
        severity: "success",
      });
    } catch (err: any) {
      const errCode = err?.response?.data?.code;

      const errMsg =
        errCode === "DUPLICATE"
          ? "Brand with such name already exists"
          : errCode === "NOT_FOUND"
          ? "Brand not found"
          : "There was an error. Please try again";

      showAlert({
        text: t(errMsg),
        severity: "error",
      });
    }
  };

  const columns: ApmGridColDef[] = useMemo(
    () => [
      {
        field: "brandName",
        headerName: t("Brand Name") as string,
        flex: 1,
      },
      {
        field: "orgNames",
        headerName: t("Organization Name(s)") as string,
        flex: 1,
        sortable: false,
      },
      {
        field: "brandType",
        headerName: t("Brand Type") as string,
        flex: 1,
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      brandsFiltered
        .filter((brand) => {
          const brandMap = brandsMap.get(brand.name);

          const matchOrganization =
            !organization?.value ||
            brandMap?.orgNames.includes(organization?.value);

          const mainFilter =
            BrandFilterEnum.REPRESENTED === filter
              ? (brandMap?.orgNames || []).length > 0
              : BrandFilterEnum.SYSTEM === filter
              ? brandMap?.type === BrandTypeEnum.SYSTEM
              : true;

          return matchOrganization && mainFilter;
        })
        .map((brand) => {
          const brandMap = brandsMap.get(brand.name);

          return {
            id: brand.name,
            systemBrandId: brandMap?.systemBrandId,
            brandName: brand.name,
            orgNames: brandMap?.orgNames,
            brandType: brandMap?.type,
            canDelete: brandMap?.type === BrandTypeEnum.SYSTEM,
            customAction: {
              text: "Edit",
              color: "primary",
            },
          };
        }),
    [brandsFiltered, brandsMap, filter, organization?.value]
  );

  useEffect(() => {
    getBrands();
  }, [setBrands]);

  return {
    rows,
    columns,
    brands,
    filter,
    loading,
    organization,
    organizationOptions,
    sortModel,
    setSortModel,
    setOrganization,
    getBrands,
    setFilter,
    handleDelete,
    handleEdit,
    onChangeBrandType,
  };
};
