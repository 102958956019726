export const maxLength = {
  firstName: 100,
  lastName: 100,
  jobTitle: 100,
  email: 255,
  companyName: 100,
  street: 50,
  city: 50,
  country: 255,
  productName: 50,
  productURL: 2048,
  notes: 150,
  productDescription: 100,
  productColor: 20,
  productSize: 10,
  artificeCaseNumber: 30,
  internalCaseNumber: 30,
  photoName: 100,
  photoProfileName: 100,
  shoppingAccountNumber: 20,
  purchaseProfileName: 255,
  thirdPartySite: 255,
};

export const maxLengthMessage = {
  max10: "maxLength_10",
  max20: "maxLength_20",
  max30: "maxLength_30",
  max50: "maxLength_50",
  max100: "maxLength_100",
  max150: "maxLength_150",
  max255: "maxLength_255",
  max2048: "maxLength_2048",
};
