import { useEffect, useState } from "react";
import { IBrand } from "../../represented-brands/interfaces";
import {
  BrandsService,
  SystemBrandsService,
} from "../../represented-brands/services";

export const usePurchaseBrands = () => {
  const [purchaseBrands, setPurchaseBrands] = useState<IBrand[]>([]);

  const getBrands = async () => {
    const [brands, systemBrands] = await Promise.all([
      BrandsService.fetchEntities(),
      SystemBrandsService.fetchEntities(),
    ]);

    const purchaseBrands = [...brands, ...systemBrands].filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    purchaseBrands.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    setPurchaseBrands(purchaseBrands);
  };

  const refetchBrands = async () => {
    await getBrands();
  };

  useEffect(() => {
    getBrands();
  }, []);

  return {
    purchaseBrands,
    refetchBrands,
  };
};
