import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, IconButton, Skeleton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import styles from "./style.module.scss";

interface PageTitleProps {
  subTitle?: string;
  backUrl?: string;
  title?: string | null;
  customAction?: string | null;
  loading?: boolean;
  canUpdate?: boolean;
  onEdit?: () => void;
  onAction?: () => void;
}
export const PageTitle = ({
  subTitle,
  title = "",
  backUrl,
  loading = false,
  customAction,
  canUpdate,
  onEdit,
  onAction,
}: PageTitleProps) => {
  const navigate = useNavigate();
  const [t] = useTranslation("portal");

  const back = () => {
    if (backUrl) {
      navigate(backUrl);
    }
  };

  return (
    <section className={styles.pageTitleWrapper}>
      <section>
        <p className={styles.pageTitle}>
          {backUrl ? (
            <IconButton onClick={back} disableRipple size="small">
              <ArrowBackIosNewIcon />
            </IconButton>
          ) : null}

          {loading ? (
            <Skeleton variant="text" className={styles.loadingProfileName} />
          ) : (
            title
          )}
        </p>
        {subTitle ? <p className={styles.pageSubTitle}>{subTitle}</p> : null}
      </section>
      <section>
        {canUpdate && (
          <Button
            variant="outlined"
            className={styles.editBtn}
            startIcon={<EditIcon />}
            onClick={onEdit}
          >
            {t("Edit")}
          </Button>
        )}
        {customAction && (
          <Button
            variant="contained"
            className={styles.customAction}
            onClick={onAction}
          >
            <span className={styles.customActionText}>{customAction}</span>
          </Button>
        )}
      </section>
    </section>
  );
};
