import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { GridSortModel, GridValidRowModel } from "@mui/x-data-grid";
import { ArtificePurchaseProfileRoutesEnum } from "../../../../../../common";
import { ArtificeGrid } from "../../../../../../common/components";
import {
  ConfirmModal,
  EmptyView,
  PageTitle,
  usePurchaseProfileList,
  EmptyViewType,
} from "../../../../common";
import emptyViewImg from "../../../../../../assets/img/purchase-profiles-empty-view.svg";
import styles from "./style.module.scss";

export const PurchaseProfileList = () => {
  const { t } = useTranslation("portal");
  const [deleteItem, setDeleteItem] = useState<GridValidRowModel | null>();
  const navigate = useNavigate();
  const {
    rows,
    columns,
    loading,
    sortModel,
    purchaseProfiles,
    setSortModel,
    deleteProfile,
  } = usePurchaseProfileList();

  const openDeleteConfirmation = (data: GridValidRowModel) => {
    setDeleteItem(data);
  };

  const handleClose = () => {
    setDeleteItem(null);
  };

  const handleDelete = async () => {
    if (deleteItem?.id) {
      await deleteProfile(deleteItem.id);
    }
    setDeleteItem(null);
  };

  const handleRowClick = (data: GridValidRowModel) => {
    navigate(data.id?.toString());
  };

  const header = useMemo(
    () => (
      <PageTitle
        title={t("Purchase Profile(s)")}
        subTitle={t(
          "Let's start by answering a couple of questions regarding your company, shipping, storage and leads we find."
        )}
        customAction={t("Create a Purchase Profile")}
        onAction={() => navigate(ArtificePurchaseProfileRoutesEnum.Create)}
      />
    ),
    [t, navigate]
  );

  if (loading) {
    return <CircularProgress classes={{ root: styles.loader }} />;
  }

  return !rows.length ? (
    <EmptyView
      type={EmptyViewType.PurchaseProfiles}
      imgSrc={emptyViewImg}
      title={t("Take a couple of minutes to complete the Purchase Profile")}
      purchaseProfiles={purchaseProfiles}
      loading={loading}
    />
  ) : (
    <>
      {header}
      <ArtificeGrid
        canDelete
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
        onDelete={openDeleteConfirmation}
        onCellContentClick={handleRowClick}
      />
      <ConfirmModal
        open={!!deleteItem}
        confirmText={t("Do you want to delete")}
        itemName={deleteItem?.name}
        onClose={handleClose}
        onConfirm={handleDelete}
      />
    </>
  );
};
