import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import logo from "../../../../assets/img/logo.svg";
import { LanguageSwitch } from "../../../../common/components";
import { scrollWithOffset } from "../../../../common/utils";
import { ArtificeRoutes } from "../../../../common";
import { MobileMenu } from "../mobile-menu";
import styles from "./style.module.scss";

export const ArtificeNavigation = () => {
  const [t] = useTranslation("common");

  return (
    <section className={styles.wrapper}>
      <Button
        component={HashLink}
        smooth
        to="/#main"
        scroll={(el) => scrollWithOffset(el)}
      >
        <img src={logo} alt="Artifice" className={styles.logo} />
      </Button>
      <MobileMenu />
      <nav>
        <Button
          component={HashLink}
          smooth
          to="/#about-us"
          color="dark"
          size="large"
          scroll={(el) => scrollWithOffset(el)}
        >
          {t("About Us")}
        </Button>
        <Button
          component={HashLink}
          smooth
          to="/#services"
          color="dark"
          size="large"
          scroll={(el) => scrollWithOffset(el)}
        >
          {t("Our Services")}
        </Button>
        <Button
          component={HashLink}
          smooth
          to="/#contact-us"
          color="dark"
          size="large"
          scroll={(el) => scrollWithOffset(el)}
        >
          {t("Contact Us")}
        </Button>
      </nav>

      <section className={styles.rightBlock}>
        <LanguageSwitch />

        <Button
          component={NavLink}
          to={ArtificeRoutes.register}
          size="large"
          variant="contained"
        >
          {t("Register")}
        </Button>

        <Button size="large" component={NavLink} to={ArtificeRoutes.login}>
          {t("Log in")}
        </Button>
      </section>
    </section>
  );
};
