import React from "react";
import { Navigate } from "react-router-dom";
import { ArtificeProfileRoutesEnum } from "../../../common";
import {
  PurchaseProfileRoutes,
  ProductPhotoProfileRoutes,
  PurchaseRoutes,
  PricingRoutes,
  UserManagementRoutes,
  ServicesRoutes,
} from "../pages";
import { AdminGuard } from "../common";
import { RepresentedBrandsRoutes } from "../pages/represented-brands";

export const PortalRoutes = [
  {
    path: "",
    children: [
      {
        index: true,
        element: <Navigate to={ArtificeProfileRoutesEnum.Purchases} replace />,
      },
    ],
  },
  {
    path: ArtificeProfileRoutesEnum.Purchases,
    children: PurchaseRoutes,
  },
  {
    path: ArtificeProfileRoutesEnum.PurchaseProfile,
    children: PurchaseProfileRoutes,
  },
  {
    path: ArtificeProfileRoutesEnum.ProductPhotoProfiles,
    children: ProductPhotoProfileRoutes,
  },
  {
    path: ArtificeProfileRoutesEnum.RepresentedBrands,
    children: RepresentedBrandsRoutes,
  },
  {
    path: ArtificeProfileRoutesEnum.Services,
    children: ServicesRoutes,
  },
  {
    path: ArtificeProfileRoutesEnum.Pricing,
    children: PricingRoutes,
  },
  {
    path: ArtificeProfileRoutesEnum.UserManagement,
    element: <AdminGuard />,
    children: UserManagementRoutes,
  },
];
