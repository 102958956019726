import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Service, ServiceModal } from "..";
import { services } from "../../constants";

export const ServiceLayout = () => {
  const [t] = useTranslation("common");
  const [servicePreview, setServicePreview] = useState<{
    title: string;
    text: string;
  } | null>();

  return (
    <>
      <Grid
        id="services"
        container
        rowSpacing={0}
        columnSpacing={{ xs: 1, sm: 2, md: 0 }}
      >
        {services.map((service, index) => (
          <Service
            key={index}
            xs={service.xs}
            icon={service.icon}
            title={t(service.title)}
            text={t(service.text)}
            setServicePreview={setServicePreview}
          />
        ))}
      </Grid>
      <ServiceModal
        open={!!servicePreview}
        onClose={() => setServicePreview(null)}
        title={servicePreview?.title || ""}
        text={servicePreview?.text || ""}
      />
    </>
  );
};
