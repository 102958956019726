import React from "react";
import { List, ListItem } from "@mui/material";
import { usePurchaseTransactions } from "../../hooks";
import styles from "./style.module.scss";

export const PurchaseTransactions = () => {
  const { purchaseTransactions } = usePurchaseTransactions();

  return (
    <>
      <b style={{ fontSize: "20px" }}>Transactions:</b>
      <List className={styles.wrapper}>
        {purchaseTransactions?.map((transaction: any) => (
          <>
            <ListItem>
              <b>Card Number:</b> {transaction.cardNumber}
            </ListItem>

            <ListItem>
              <b>Payment Profile Id:</b> {transaction.paymentProfileId}
            </ListItem>

            <ListItem>
              <List component="div" disablePadding>
                {transaction.transactions?.map((t: any) => (
                  <>
                    <ListItem>
                      <b>Amount:</b> {t.amount}
                    </ListItem>
                    <ListItem>
                      <b>Authorize Transaction Id:</b>{" "}
                      {t.authorizeTransactionId}
                    </ListItem>
                    <ListItem>
                      <b>Transaction Request Status:</b>
                      {t.transactionRequestStatus}
                    </ListItem>
                    <ListItem>
                      <b>Transaction Type:</b> {t.transactionType}
                    </ListItem>
                    <ListItem>
                      <section
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <b>Authorize Transaction Response:</b>
                        <textarea
                          readOnly
                          style={{
                            width: "800px",
                            height: "120px",
                            overflow: "auto",
                            padding: "10px 20px",
                          }}
                        >
                          {t.authorizeTransactionResponse}
                        </textarea>
                      </section>
                    </ListItem>
                    <ListItem>
                      <hr />
                    </ListItem>
                  </>
                ))}
              </List>
            </ListItem>
          </>
        ))}
      </List>
    </>
  );
};
