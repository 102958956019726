import React from "react";
import { useTranslation } from "react-i18next";
import { OrganizationsList } from "../../common/components";
import { PageTitle } from "../../../portal";

export const Organizations = () => {
  const { t } = useTranslation("admin");

  return (
    <>
      <PageTitle title={t("Organizations")} />
      <OrganizationsList />
    </>
  );
};
