import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";
import styles from "./style.module.scss";

interface PhotoPreviewProps {
  original: string;
  thumbnail: string;
}

export const PhotoPreview = ({ original, thumbnail }: PhotoPreviewProps) => {
  const [t] = useTranslation("portal");
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const enterFullScreenMode = () => setFullscreen(true);
  const exitFullScreenMode = () => setFullscreen(false);
  const stopPropagation = (evt: any) => evt.stopPropagation();

  const preview = useMemo(
    () =>
      fullscreen
        ? createPortal(
            <section className={styles.backdrop}>
              <section className={styles.imageWrapper}>
                <IconButton
                  className={styles.closeButton}
                  onClick={exitFullScreenMode}
                >
                  <ClearIcon className={styles.closeIcon} />
                </IconButton>
                <img
                  alt="full-screen"
                  className={styles.fullscreen}
                  src={original}
                  onClick={stopPropagation}
                />
              </section>
            </section>,
            document.body
          )
        : null,
    [fullscreen, original]
  );

  return (
    <section>
      {thumbnail ? (
        <img
          alt="thumbnail"
          className={styles.thumbnail}
          src={thumbnail}
          onClick={enterFullScreenMode}
        />
      ) : (
        <div className={styles.noPhotoExample}>{t("No Photo Example")}</div>
      )}
      {preview}
    </section>
  );
};
