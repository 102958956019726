import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Phone } from "../../../../../../common/components";
import { getFormFieldProps } from "../../../../../../common/utils";
import { RegistrationFormType } from "../../schemas";
import TermsAndConditions from "../../../../../../assets/files/Terms_Conditions_Artifice_Global.pdf";
import styles from "../register/style.module.scss";

interface UserInformationProps {
  registerForm: RegistrationFormType;
  fromInvitation: boolean;
}
export const UserInformation = ({
  registerForm,
  fromInvitation,
}: UserInformationProps) => {
  const [t] = useTranslation("common");
  const [tValidation] = useTranslation("validation");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const fieldProps = getFormFieldProps(registerForm, tValidation);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const passwordProps = fieldProps("password");

  return (
    <>
      <TextField
        fullWidth
        required
        margin="normal"
        size="medium"
        InputProps={{ required: false }}
        autoComplete="given-name"
        label={t("First Name")}
        {...fieldProps("firstName", t("First Name"))}
      />
      <TextField
        fullWidth
        required
        margin="normal"
        InputProps={{ required: false }}
        label={t("Last Name")}
        autoComplete="family-name"
        {...fieldProps("lastName", t("Last Name"))}
      />
      <TextField
        fullWidth
        margin="normal"
        label={t("Job Title")}
        autoComplete="job-title"
        {...fieldProps("title", t("Job Title"))}
      />

      <Phone
        phoneLabel={t("Your Direct Phone")}
        getFormProps={fieldProps}
        phoneFormKey="directPhone"
        countryCodeKey="directPhoneCountryCode"
        countryCodeValue={registerForm.values?.directPhoneCountryCode}
        setFieldValue={registerForm.setFieldValue}
      />
      <FormControl className={styles.phoneCheckboxWrapper}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={registerForm.values.isMobileSameAsDirect}
              onChange={() =>
                registerForm.setFieldValue(
                  "isMobileSameAsDirect",
                  !registerForm.values.isMobileSameAsDirect
                )
              }
            />
          }
          label={
            <p className={styles.checkboxLabel}>
              {t("My Mobile Number is the same as Direct Work Number")}
            </p>
          }
        />
      </FormControl>

      {!registerForm.values.isMobileSameAsDirect ? (
        <Phone
          phoneLabel={t("Your Mobile Phone")}
          getFormProps={fieldProps}
          phoneFormKey="mobilePhone"
          countryCodeKey="mobilePhoneCountryCode"
          countryCodeValue={registerForm.values?.mobilePhoneCountryCode}
          setFieldValue={registerForm.setFieldValue}
        />
      ) : null}

      <TextField
        fullWidth
        required
        margin="normal"
        disabled={fromInvitation}
        InputProps={{ required: false }}
        autoComplete="email"
        type="email"
        label={t("Your Work Email Address")}
        {...fieldProps("email", t("Your Work Email Address"))}
      />

      <TextField
        fullWidth
        required
        margin="normal"
        type={showPassword ? "text" : "password"}
        label={t("Password")}
        autoComplete="new-password"
        InputProps={{
          required: false,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...passwordProps}
        onChange={(evt) => {
          registerForm.setFieldTouched("password");
          passwordProps.onChange(evt);
        }}
        helperText={passwordProps.helperText.split("|").map((text: string) => (
          <div>{tValidation(text)}</div>
        ))}
      />
      <TextField
        fullWidth
        required
        margin="normal"
        type={showConfirmPassword ? "text" : "password"}
        label={t("Confirm Password")}
        autoComplete="new-password"
        InputProps={{
          required: false,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...fieldProps("confirmPassword")}
      />

      <FormControl
        error={Boolean(
          registerForm.errors.termsAndConditions &&
            registerForm.touched.termsAndConditions
        )}
      >
        <FormControlLabel
          sx={{ cursor: "default" }}
          control={
            <Checkbox
              className={
                Boolean(
                  registerForm.touched.termsAndConditions &&
                    registerForm.errors.termsAndConditions
                )
                  ? styles.error
                  : ""
              }
              color="primary"
              checked={registerForm.values.termsAndConditions}
              onChange={() =>
                registerForm.setFieldValue(
                  "termsAndConditions",
                  !registerForm.values.termsAndConditions
                )
              }
            />
          }
          label={
            <p className={styles.checkboxLabel}>
              <Trans
                ns="common"
                i18nKey="Terms&Conditions"
                className={styles.checkboxLabel}
              >
                By clicking here, I state that I have read and understood the{" "}
                <Link
                  to={TermsAndConditions}
                  download="Terms & Conditions Artifice Global.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </Link>
              </Trans>
            </p>
          }
        />
        {registerForm.errors.termsAndConditions &&
          registerForm.touched.termsAndConditions && (
            <FormHelperText>
              {tValidation(registerForm.errors.termsAndConditions)}
            </FormHelperText>
          )}
      </FormControl>
    </>
  );
};
