import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Alert, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { HomeContainer } from "../../../../common/components";
import { ArtificeRoutes, UserRoleEnum } from "../../../../common";
import { getFormFieldProps } from "../../../../common/utils";
import { AuthService } from "../../common";
import { loginSchema } from "./schemas";
import styles from "./style.module.scss";

export const LogIn = () => {
  const [t] = useTranslation("login");
  const [tValidation] = useTranslation("validation");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const formik = useFormik({
    validationSchema: loginSchema,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        const response = await AuthService.login(values);
        const route = response.roles.includes(UserRoleEnum.SYSTEM_ADMIN)
          ? ArtificeRoutes.admin
          : ArtificeRoutes.profile;
        navigate(route);
      } catch (err) {
        setError(true);
      }
    },
  });

  useEffect(() => {
    setError(false);
  }, [setError, formik.values]);

  const fieldProps = getFormFieldProps(formik, tValidation);

  return (
    <HomeContainer>
      <section className={styles.loginWrapper}>
        <div className={styles.imgPreview} />

        <section className={styles.formWrapper}>
          <h1 className={styles.title}>{t("Log In")}</h1>
          {error ? (
            <Alert severity="error" className={styles.errorWrapper}>
              {t("Incorrect email or password")}
            </Alert>
          ) : null}

          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              autoFocus
              margin="normal"
              label={t("Email Address")}
              autoComplete="email"
              {...fieldProps("email", t("Email Address"))}
            />
            <TextField
              margin="normal"
              fullWidth
              label={t("Password")}
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...fieldProps("password", t("Password"))}
            />

            <section className={styles.footerLinks}>
              <Link
                className={styles.registerLink}
                component={NavLink}
                to={ArtificeRoutes.register}
                variant="body2"
              >
                <Trans
                  ns="login"
                  i18nKey="dontHaveAccount"
                  className={styles.checkboxLabel}
                >
                  Don't have an account?{" "}
                  <span className={styles.registerHint}>Register</span>
                </Trans>
              </Link>

              <Link
                className={styles.forgotPassword}
                component={NavLink}
                to={ArtificeRoutes.forgotPassword}
                variant="body2"
              >
                {t("Forgot Password?")}
              </Link>
            </section>

            <LoadingButton
              loading={formik.isSubmitting}
              className={styles.loginBtn}
              type="submit"
              variant="contained"
              size="large"
            >
              {t("Log In")}
            </LoadingButton>
          </form>
        </section>
      </section>
    </HomeContainer>
  );
};
