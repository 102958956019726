import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { industryTypes } from "../../../../common";
import { HomeContainer } from "../../../../common/components";
import { getFormFieldProps } from "../../../../common/utils";
import { ContactUsStatusEnum } from "../../common/enums";
import { useContactUs } from "./hooks";
import styles from "./style.module.scss";

export const ContactUs = () => {
  const [t] = useTranslation("common");
  const [tValidation] = useTranslation("validation");
  const { contactUsForm, status } = useContactUs();
  const fieldProps = getFormFieldProps(contactUsForm, t, tValidation);

  if (status === ContactUsStatusEnum.Success) {
    return (
      <section className={styles.statusWrapper}>
        <p className={styles.successTitle}>
          {t(
            "We appreciate you contacting us. One of our colleagues will get back in touch with you soon! Have a great day!"
          )}
        </p>
      </section>
    );
  }

  if (status === ContactUsStatusEnum.Error) {
    return (
      <section className={styles.statusWrapper}>
        <p className={styles.errorTitle}>
          {t("Something went wrong! Please, try again later.")}
        </p>
      </section>
    );
  }

  const showV1 = false;
  return (
    <HomeContainer>
      <section id="contact-us" className={styles.contactUsWrapper}>
        {showV1 ? <div className={styles.imgPlaceholder} /> : null}
        <section className={styles.contactForm}>
          <h2 className={styles.title}>
            {t(
              "Please fill out the form below and we will contact you shortly"
            )}
          </h2>
          <form onSubmit={contactUsForm.handleSubmit}>
            <section className={styles.formRow}>
              <TextField
                fullWidth
                required
                margin="normal"
                InputProps={{ required: false }}
                label={t("Your Name")}
                {...fieldProps("userName", t("Your Name"))}
              />
              <TextField
                fullWidth
                required
                margin="normal"
                InputProps={{ required: false }}
                label={t("Company Name")}
                {...fieldProps("companyName", t("Your Name"))}
              />
            </section>
            <section className={styles.formRow}>
              <TextField
                select
                fullWidth
                required
                margin="normal"
                label={t("Industry Type")}
                {...fieldProps("industryType", t("Industry Type"))}
              >
                {industryTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {t(option.name)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                margin="normal"
                label={t("Title")}
                {...fieldProps("title", t("Title"))}
              />
            </section>
            <section className={styles.formRow}>
              <TextField
                fullWidth
                required
                InputProps={{ required: false }}
                margin="normal"
                label={t("Your Phone")}
                {...fieldProps("phone", t("Your Phone"))}
              />
              <TextField
                fullWidth
                required
                margin="normal"
                InputProps={{ required: false }}
                label={t("Your Email")}
                autoComplete="email"
                {...fieldProps("email", t("Your Email"))}
              />
            </section>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows={3}
              label={t("Message")}
              {...fieldProps("message", t("Message"))}
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={contactUsForm.isSubmitting}
              className={styles.contactBtn}
            >
              {t("Contact Me")}
            </LoadingButton>
          </form>
        </section>
      </section>
    </HomeContainer>
  );
};
