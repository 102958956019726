import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IPurchase } from "../../../portal/pages/purchases/interfaces";
import { AdminService } from "../services";

export const usePurchaseCheckout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseFees, setPurchaseFees] = useState<IPurchase>();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return;
    }

    setIsLoading(true);
    AdminService.fetchPurchaseFeesById(Number(id))
      .then((response) => {
        setPurchaseFees(response);
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  const uploadReports = async (files: any[]) => {
    const reports = files
      .filter(({ file }) => !!file)
      .map(({ file, title, description, type }) =>
        AdminService.uploadReport(file).then((fileId) => ({
          fileId,
          title,
          description,
          type,
        }))
      );

    const uploadedReports = await Promise.all(reports);

    if (uploadedReports.length) {
      await AdminService.attachReportToPurchase(Number(id), uploadedReports);
    }
  };

  const removeFile = async (fileId: number) => {
    await AdminService.removeReportById(fileId);
  };

  const handleCapture = async () => {
    if (id) {
      return await AdminService.handleCapture(Number(id));
    }
  };

  const handleCompleteOrder = async () => {
    if (id) {
      return await AdminService.confirmPurchaseInvoiceStatus(Number(id));
    }
  };

  const updatePurchaseFees = async (values: any) => {
    const formattedValues = {
      sellerTax: Number(values.sellerTax),
      shippingTax: Number(values.shippingTax),
      misc: Number(values.miscellaneous),
      orderedProducts: values.products.map((product: any) => ({
        id: product.id,
        orderedQuantity: Number(product.quantity),
        orderedUnitPrice: Number(product.unitPrice),
      })),
    };

    if (id) {
      return AdminService.updatePurchaseFees(Number(id), formattedValues);
    }
  };

  return {
    id,
    purchaseFees,
    isLoading,
    uploadReports,
    removeFile,
    handleCapture,
    handleCompleteOrder,
    updatePurchaseFees,
  };
};
