export enum ArtificeAuthRoutesEnum {
  Register = "register",
  ForgotPassword = "forgot-password",
  ResetPassword = "reset-password",
  ResetPasswordSuccess = "reset-password-success",
  ForgotPasswordSuccess = "forgot-password-success",
  RegisterSuccess = "register/success",
  VerifyEmail = "register/verify-email",
  Login = "login",
}

export enum ArtificeProfileRoutesEnum {
  Purchases = "purchases",
  PurchaseProfile = "purchase-profiles",
  ProductPhotoProfiles = "product-photo-profiles",
  RepresentedBrands = "represented-brands",
  Services = "services",
  Pricing = "pricing",
  UserManagement = "user-management",
}

export enum ArtificeAdminRoutesEnum {
  Purchases = "purchases",
  Organizations = "organizations",
  UserManagement = "user-management",
  Brands = "brands",
}
export enum ArtificePurchaseRoutesEnum {
  Create = "create",
  Success = "success",
  View = ":id",
  Summary = ":id/summary",
  Edit = ":id/edit",
}

export enum ArtificeAdminPurchaseRoutesEnum {
  View = ":id",
  Checkout = ":id/checkout",
}

export enum ArtificePurchaseProfileRoutesEnum {
  Create = "create",
  View = ":id",
  Edit = ":id/edit",
}

export enum ArtificeProductPhotoProfilesRoutesEnum {
  Create = "create",
  View = ":id",
  Edit = ":id/edit",
}

export enum ArtificeModuleRoutesEnum {
  Home = "/",
  Auth = "/auth",
  Portal = "/portal",
  Admin = "/admin",
}
