import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { states } from "../../../constants";

interface StateProps {
  formControlProps: any;
}

export const State = ({ formControlProps }: StateProps) => {
  const [t] = useTranslation("common");

  return (
    <TextField
      fullWidth
      select
      margin="normal"
      size="medium"
      autoComplete="state"
      label={t("State")}
      {...formControlProps}
    >
      {states.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
