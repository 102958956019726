import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { UserLayout } from "../../layout";
import { ArtificeRoutes } from "../../../../common";
import { User } from "../../models";
import { UserService } from "../../services";

const AuthContext = createContext<{
  user?: User | null;
  logout?: () => Promise<void>;
}>({});

interface AuthProviderProps {
  children?: ReactNode;
  user: User;
}

export const AuthProvider = ({
  children,
  user: userProfile,
}: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(userProfile);
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    await UserService.logout();
    setUser(null);
    navigate(ArtificeRoutes.home, { replace: true });
  }, [setUser, navigate]);

  const value = useMemo(
    () => ({
      user,
      logout,
    }),
    [user, logout]
  );

  return (
    <AuthContext.Provider value={value}>
      <UserLayout>{children}</UserLayout>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
