import { InferType, object, string, array, number } from "yup";
import { productNameValidation } from "../../../../../../common";

const productsValidation = object().shape({
  id: number(),
  name: productNameValidation,
  unitPrice: string(),
  price: string(),
  quantity: string(),
});

export const CheckoutFormSchema = object({
  products: array().of(productsValidation).required(),
  sellerTax: string(),
  shippingTax: string(),
  miscellaneous: string(),
});

export type CheckoutFormValuesType = InferType<typeof CheckoutFormSchema>;
