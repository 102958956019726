import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Alert, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { HomeContainer } from "../../../../../common/components";
import { ArtificeRoutes } from "../../../../../common";
import { useQuery } from "../../../../../common/hooks";
import { AuthService } from "../../../common";
import { getFormFieldProps } from "../../../../../common/utils";
import { resetPasswordSchema } from "../schemas";
import styles from "./style.module.scss";

export const ResetPassword = () => {
  const [t] = useTranslation("login");
  const [tValidation] = useTranslation("validation");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const search = useQuery();
  const token = search.get("token");
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!token) {
      setError(true);
    }
  }, [token]);

  const formik = useFormik({
    validationSchema: resetPasswordSchema,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues: { password: "", confirmPassword: "" },
    onSubmit: async (values) => {
      try {
        if (token) {
          await AuthService.resetPassword(values.password, token);
          navigate(ArtificeRoutes.resetPasswordSuccess);
        }
      } catch (err) {
        setError(true);
      }
    },
  });

  useEffect(() => {
    setError(false);
  }, [setError, formik.values]);

  const fieldProps = getFormFieldProps(formik, tValidation);

  const passwordProps = fieldProps("password");

  return (
    <HomeContainer>
      <section className={styles.loginWrapper}>
        <div className={styles.imgPreview} />

        <section className={styles.formWrapper}>
          <h1 className={styles.title}>{t("Reset Password")}</h1>

          {error ? (
            <Alert severity="error" className={styles.errorWrapper}>
              {t("Something went wrong, please try again")}
            </Alert>
          ) : null}

          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              required
              margin="normal"
              type={showPassword ? "text" : "password"}
              label={t("New Password")}
              autoComplete="new-password"
              InputProps={{
                required: false,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...passwordProps}
              onChange={(evt) => {
                formik.setFieldTouched("password");
                passwordProps.onChange(evt);
              }}
              helperText={passwordProps.helperText
                .split("|")
                .map((text: string) => (
                  <div>{tValidation(text)}</div>
                ))}
            />
            <TextField
              fullWidth
              required
              margin="normal"
              type={showConfirmPassword ? "text" : "password"}
              label={t("Confirm New Password")}
              autoComplete="new-password"
              InputProps={{
                required: false,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...fieldProps("confirmPassword")}
            />
            <section className={styles.footerLinks}>
              <Link
                className={styles.registerLink}
                component={NavLink}
                to={ArtificeRoutes.register}
                variant="body2"
              >
                <Trans
                  ns="login"
                  i18nKey="dontHaveAccount"
                  className={styles.checkboxLabel}
                >
                  Don't have an account?{" "}
                  <span className={styles.registerHint}>Register</span>
                </Trans>
              </Link>

              <Link
                className={styles.forgotPassword}
                component={NavLink}
                to={ArtificeRoutes.login}
                variant="body2"
              >
                {t("Back to Log In")}
              </Link>
            </section>

            <LoadingButton
              loading={formik.isSubmitting}
              className={styles.loginBtn}
              type="submit"
              variant="contained"
              size="large"
            >
              {t("Reset Password")}
            </LoadingButton>
          </form>
        </section>
      </section>
    </HomeContainer>
  );
};
