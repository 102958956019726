import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { ArtificeModal } from "../../../../../../common/components";
import { useNotifications } from "../../../../../../common";
import logo from "../../../../../../assets/img/logo.svg";
import styles from "./style.module.scss";

declare global {
  interface Window {
    AuthorizeNetIFrame: any;
  }
}

interface AddCreditCardModalProps {
  open: boolean;
  handleClose: () => void;
  fetchPayments: () => void;
}
export const AddCreditCardModal = ({
  open,
  handleClose,
  fetchPayments,
}: AddCreditCardModalProps) => {
  const [t] = useTranslation("portal");
  const [loading, setLoading] = useState(true);
  const { showAlert } = useNotifications();

  useEffect(() => {
    window.AuthorizeNetIFrame = window.AuthorizeNetIFrame || {};

    function parseQueryString(str: string): { [key: string]: string } {
      const vars: { [key: string]: string } = {};
      let arr = str.split("&");

      for (let i = 0; i < arr.length; i++) {
        const pair = arr[i].split("=");
        vars[pair[0]] = unescape(pair[1]);
      }
      return vars;
    }

    window.AuthorizeNetIFrame.onReceiveCommunication = function (qstr: string) {
      let params: { [key: string]: string } = parseQueryString(qstr);

      let ifrm = document.getElementById("add_payment");

      switch (params["action"]) {
        case "successfulSave":
          setLoading(true);
          handleClose();
          fetchPayments();
          break;
        case "cancel":
          setLoading(true);
          handleClose();
          break;
        case "resizeWindow":
          let w = parseInt(params["width"]);
          let h = parseInt(params["height"]);
          if (ifrm) {
            ifrm.style.width = w.toString() + "px";
            ifrm.style.height = h.toString() + "px";
          }

          break;
        case "transactResponse":
          if (ifrm) {
            ifrm.style.display = "none";
          }
          break;
        default:
          console.log("no action matches");
          break;
      }
    };
  });

  return (
    <>
      <ArtificeModal
        title={
          <div className={styles.logoWrapper}>
            <img src={logo} alt="logo" className={styles.logo} />
          </div>
        }
        open={open}
        classes={{ wrapper: styles.addPaymentModal }}
        onClose={() => {
          setLoading(true);
          handleClose();
        }}
      >
        {loading && (
          <div className={styles.loadingWrapper}>
            <CircularProgress />
          </div>
        )}
        <div
          id="iframe_holder"
          className={loading ? styles.loading : styles.iframeWrapper}
        >
          <iframe
            onLoad={() => {
              setLoading(false);
            }}
            onError={() => {
              handleClose();
              showAlert({
                severity: "error",
                text: t("Something went wrong. Please try again."),
              });
            }}
            className={loading ? styles.loading : ""}
            title="add_payment"
            id="add_payment"
            name="add_payment"
            width="100%"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      </ArtificeModal>
    </>
  );
};
