import React from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Button, TextField } from "@mui/material";
import { useAddBrand } from "../../hooks";
import { ISystemBrand } from "../../interfaces";
import { ConfirmModal } from "../../../../common";
import styles from "./style.module.scss";

interface AddBrandProps {
  systemBrands: ISystemBrand[];
  reloadList: () => Promise<void>;
}

export const AddBrand = ({ systemBrands, reloadList }: AddBrandProps) => {
  const [t] = useTranslation("portal");
  const {
    brand,
    loading,
    modalOpen,
    handleModalOpen,
    handleModalClose,
    handleOnInputChange,
    handleAddBrand,
  } = useAddBrand();

  return (
    <>
      <section className={styles.wrapper}>
        <Autocomplete
          classes={{
            root: styles.inputRoot,
          }}
          autoHighlight
          freeSolo
          disableClearable
          className={styles.input}
          onInputChange={handleOnInputChange}
          value={brand}
          options={systemBrands.map((option) => option.name) || []}
          renderInput={(params) => <TextField {...params} label="Brand" />}
        />
        <Button
          disabled={!brand}
          className={styles.button}
          variant="contained"
          size="medium-m"
          onClick={handleModalOpen}
        >
          {t("Add Brand")}
        </Button>
      </section>
      <ConfirmModal
        open={modalOpen}
        actionInProgress={loading}
        confirmText={t("Do you want to add brand")}
        title={t("Add Brand")}
        confirmActionText={t("Add")}
        itemName={brand}
        onClose={handleModalClose}
        onConfirm={async () => {
          await handleAddBrand();
          await reloadList();
        }}
      />
    </>
  );
};
