import i18n from "i18next";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/assets/locales/{{lng}}/{{ns}}.json",
    },
    lng: "en-US",
    ns: ["common"],
    defaultNS: "common",
    supportedLngs: ["en-US", "ua"],
    nonExplicitSupportedLngs: false,
    fallbackLng: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
