import axios from "../../../../common/api";
import { authEndpoints } from "../constants";
import { IRegister } from "../interfaces";

export class AuthService {
  static register(token: string, registrationValues: IRegister) {
    return axios
      .post(authEndpoints.registration, registrationValues, {
        headers: { recaptcha: token },
      })
      .then((response) => response.data)
      .catch((err) => {
        throw err.response.data;
      });
  }
  static registerByInvite(
    token: string,
    registrationValues: IRegister,
    invitationToken: string
  ) {
    return axios.post(
      authEndpoints.registerByEmail,
      { ...registrationValues, invitationToken },
      { headers: { recaptcha: token } }
    );
  }

  static login(loginValues: { email: string; password: string }) {
    return axios
      .post(authEndpoints.login, loginValues)
      .then((response) => response.data);
  }

  static forgotPassword(email: string) {
    return axios
      .post(authEndpoints.resetPassword, { email })
      .then((response) => response.data);
  }

  static resetPassword(password: string, token: string) {
    return axios
      .patch(authEndpoints.resetPassword, { password, token })
      .then((response) => response.data);
  }

  static verifyEmail(token: string) {
    return axios.post(authEndpoints.verifyEmail, { token });
  }
}
