import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";
import { ArtificeModal } from "../../../../../common/components";
import { IBrandListItem } from "../../interfaces";
import styles from "./style.module.scss";

export interface EditBrandModalProps {
  row: IBrandListItem | null;
  open: boolean;
  onClose: () => void;
  onConfirm: (newBrandName: string) => Promise<void>;
}

export const EditBrandModal = ({
  row,
  open,
  onClose,
  onConfirm,
}: EditBrandModalProps) => {
  const { t } = useTranslation("admin");
  const [newBrandName, setNewBrandName] = useState<string>("");

  useEffect(() => {
    setNewBrandName(row?.brandName || "");
  }, [row]);

  return (
    <ArtificeModal
      title={t("Update Brand")}
      open={open}
      onClose={() => {
        setNewBrandName("");
        onClose();
      }}
    >
      <section>
        <TextField
          fullWidth
          margin="normal"
          label={t("New Brand Name")}
          value={newBrandName}
          onChange={(evt) => {
            if (evt.target.value.trim().length === 0) {
              setNewBrandName(evt.target.value.trim());
            } else {
              setNewBrandName(evt.target.value);
            }
          }}
        />
        <section className={styles.modalFooterActions}>
          <Button
            variant="outlined"
            onClick={() => {
              setNewBrandName("");
              onClose();
            }}
          >
            {t("Close")}
          </Button>
          <Button
            disabled={!newBrandName || newBrandName === row?.brandName}
            variant="contained"
            onClick={() => onConfirm(newBrandName)}
          >
            {t("Update")}
          </Button>
        </section>
      </section>
    </ArtificeModal>
  );
};
