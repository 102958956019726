import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LoadingButton from "@mui/lab/LoadingButton";
import { FileUpload } from "../file-upload";
import {
  IPurchase,
  IPurchaseReport,
} from "../../../../portal/pages/purchases/interfaces";
import { TabPanel } from "../../../../../common/components";
import { ConfirmModal } from "../../../../portal";
import {
  PurchaseReportTypeEnum,
  useNotifications,
} from "../../../../../common";
import styles from "./style.module.scss";

const reports = [
  {
    type: PurchaseReportTypeEnum.PURCHASE_REPORT,
    title: "Purchase Report",
  },
  {
    type: PurchaseReportTypeEnum.RESEARCH_REPORT,
    title: "Research Report",
  },
  {
    type: PurchaseReportTypeEnum.LEAD_REPORT,
    title: "Lead Report",
  },
  {
    type: PurchaseReportTypeEnum.SUPPLEMENTAL_REPORT,
    title: "Supplemental Report",
  },
];

interface AttachReportsProps {
  purchase?: IPurchase;
  uploadReports: (files: any[]) => Promise<void>;
  removeFile: (fileId: number) => void;
}

const formatName = (type: string) => {
  return (
    reports.find((report) => report.type === type)?.title +
    " " +
    format(Date.now(), "MM/dd")
  );
};

export const AttachReports = ({
  purchase,
  uploadReports,
  removeFile,
}: AttachReportsProps) => {
  const { showAlert } = useNotifications();
  const [isSubmitDirty, setIsSubmitDirty] = useState<boolean>(false);
  const [uploadedReports, setUploadedReports] = useState<IPurchaseReport[]>();
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<{
    id: number;
    title?: string;
    type?: string;
  } | null>();
  const [files, setFiles] = useState<IPurchaseReport[]>([
    {
      id: Math.random(),
    },
  ]);

  useEffect(() => {
    if (purchase?.reports) {
      setUploadedReports(purchase.reports);
    }
  }, [purchase]);

  const addFile = () => {
    setFiles((f: any) => [
      ...f,
      { id: Math.random(), type: PurchaseReportTypeEnum.PURCHASE_REPORT },
    ]);
  };

  const handleUpload = (id: number, file: any) => {
    setFiles((stateFiles: any) =>
      stateFiles.map((stateFile: any) =>
        stateFile.id === id ? { ...stateFile, file } : stateFile
      )
    );
  };

  const handleFileChange = (id: number, type: string, value: string) => {
    setFiles((stateFiles: any) =>
      stateFiles.map((stateFile: any) =>
        stateFile.id === id ? { ...stateFile, [type]: value } : stateFile
      )
    );
  };

  const handleDelete = (id: number) => {
    setFiles((files) =>
      files.map((file) =>
        file.id !== id
          ? file
          : {
              ...file,
              fileId: null,
              file: null,
            }
      )
    );
  };

  const saveFiles = async () => {
    const hasError = files.some(
      ({ title, file, type }: any) => file && (!title || !type)
    );
    const filesToUpload = files.filter(({ file }: any) => file);

    if (hasError || !filesToUpload.length) {
      setIsSubmitDirty(hasError);
      return;
    }

    try {
      setUploadInProgress(true);

      await uploadReports(files);
      setFiles([
        {
          id: Math.random(),
        },
      ]);
      showAlert({
        text: "Successfully uploaded.",
        severity: "success",
      });
      setValue(0);
      setIsSubmitDirty(false);
    } catch (err) {
      showAlert({
        text: "Something went wrong, please try again.",
        severity: "error",
      });
    } finally {
      setUploadInProgress(false);
    }
  };

  const handleFileRemove = async (file: {
    id: number;
    title?: string;
    type?: string;
  }) => {
    setFileToDelete(file);
  };

  const handleClose = () => {
    setFileToDelete(null);
  };

  const confirmFileDelete = async () => {
    handleClose();

    if (!fileToDelete?.id) {
      return;
    }

    setDeleteInProgress(true);

    try {
      await removeFile(fileToDelete.id);
      setUploadedReports((files) =>
        files?.filter((file) => file.id !== fileToDelete.id)
      );
      showAlert({
        text: "Successfully deleted.",
        severity: "success",
      });
    } catch (err) {
      showAlert({
        text: "Something went wrong, please try again.",
        severity: "error",
      });
    } finally {
      setDeleteInProgress(false);
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Attached Reports" />
        <Tab label="Upload" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <List sx={{ width: "300px" }}>
          {uploadedReports?.map((report) => (
            <ListItem
              secondaryAction={
                <IconButton edge="end" onClick={() => handleFileRemove(report)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemButton component={Link} href={report.url}>
                <ListItemIcon>
                  <PictureAsPdfIcon />
                </ListItemIcon>
                <ListItemText primary={report.title} secondary={report.type} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <section style={{ paddingTop: "20px" }}>
          {files.map(({ id, title, type, file }) => (
            <section className={styles.uploadItem} key={id}>
              <FileUpload
                fileName={title}
                onDelete={() => handleDelete(id)}
                onUpload={(file) => handleUpload(id, file)}
              />
              <section className={styles.uploadItemRight}>
                <FormControl
                  className={styles.reportType}
                  required
                  error={Boolean(file) && isSubmitDirty && !type}
                >
                  <InputLabel>Report Type</InputLabel>
                  <Select
                    label="Report Type"
                    value={type || ""}
                    required
                    onChange={(evt: any) => {
                      handleFileChange(id, "type", evt.target.value);
                      handleFileChange(
                        id,
                        "title",
                        formatName(evt.target.value)
                      );
                    }}
                  >
                    {reports.map((report) => (
                      <MenuItem value={report.type}>{report.title}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {Boolean(file) && isSubmitDirty && !type
                      ? "Field is required"
                      : ""}
                  </FormHelperText>
                </FormControl>
                <TextField
                  className={styles.reportName}
                  label="File Name"
                  required
                  helperText={
                    file && isSubmitDirty && !title ? "Field is required" : ""
                  }
                  error={Boolean(file) && isSubmitDirty && !title}
                  value={title || ""}
                  onChange={(evt: any) =>
                    handleFileChange(id, "title", evt.target.value)
                  }
                />
              </section>
            </section>
          ))}
          <Button onClick={addFile}>Add report</Button>

          <section className={styles.footer}>
            <LoadingButton
              onClick={saveFiles}
              variant="contained"
              loading={uploadInProgress}
            >
              Upload Report(s)
            </LoadingButton>
          </section>
        </section>
      </TabPanel>

      <ConfirmModal
        open={!!fileToDelete}
        actionInProgress={deleteInProgress}
        confirmText={"Do you want to remove"}
        title="Remove"
        confirmActionText="Remove"
        itemName={fileToDelete?.title || fileToDelete?.type}
        onClose={handleClose}
        onConfirm={confirmFileDelete}
      />
    </>
  );
};
