import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import TextField from "@mui/material/TextField";
import { BrandField } from "../brand-field";
import { getFormFieldProps } from "../../../../../../common/utils";
import { PurchaseFormType } from "../../schemas";
import { IBrand } from "../../../represented-brands/interfaces";
import styles from "./style.module.scss";

interface CaseInformationProps {
  purchaseForm: PurchaseFormType;
  purchaseBrands: IBrand[];
  refetchBrands: () => Promise<void>;
}

export const CaseInformation = ({
  purchaseForm,
  purchaseBrands,
  refetchBrands,
}: CaseInformationProps) => {
  const [t] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const fieldProps = getFormFieldProps(purchaseForm, t, tValidation);

  return (
    <>
      <section>
        <section className={classnames(styles.twoColumns, styles.tinyMargin)}>
          <TextField
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true, disabled: true }}
            label={t("Artifice Case Number")}
            value={purchaseForm.values.artificeCaseNumber}
            defaultValue={" "}
          />
          <TextField
            fullWidth
            margin="normal"
            label={t("Internal Case Number")}
            {...fieldProps("internalCaseNumber", t("Internal Case Number"))}
          />
        </section>
      </section>
      <section className={styles.twoColumns}>
        <TextField
          fullWidth
          margin="normal"
          required
          label={t("Seller/Third Party Site")}
          {...fieldProps("thirdPartySite", t("Seller/Third Party Site"))}
        />
        <section className={styles.brandsWrapper}>
          <BrandField
            label="Purchase on Behalf of"
            fieldName="purchaseBrand"
            purchaseForm={purchaseForm}
            value={purchaseForm.values.purchaseBrand || null}
            purchaseBrands={purchaseBrands}
            refetchBrands={refetchBrands}
          />
        </section>
      </section>
    </>
  );
};
