import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

import { usePurchaseProfile } from "../../hooks";
import { PurchaseProfileInformation } from "../../components";
import { PageTitle } from "../../../../common";
import { ArtificeRoutes } from "../../../../../../common";
import styles from "./style.module.scss";

export const ViewPurchaseProfile = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("portal");
  const { purchaseProfile, loading } = usePurchaseProfile();

  const handleEditNavigation = () => navigate("edit");

  return (
    <section className={styles.purchaseProfileWrapper}>
      <PageTitle
        backUrl={ArtificeRoutes.purchaseProfiles}
        loading={loading}
        title={purchaseProfile?.name}
        canUpdate={purchaseProfile?.canUpdate}
        onEdit={handleEditNavigation}
        subTitle={t("Each team member can create their own profile.")}
      />
      {loading ? (
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <PurchaseProfileInformation purchaseProfile={purchaseProfile} />
      )}
    </section>
  );
};
