import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress, Paper } from "@mui/material";

import { PageTitle } from "../../../../common";
import { ProductPhotoProfileInformation } from "../../components";
import { useProductPhotoProfile } from "../../hooks";
import { ArtificeRoutes } from "../../../../../../common";
import styles from "./style.module.scss";

export const ViewProductPhotoProfile = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("portal");
  const { profile, loading } = useProductPhotoProfile();

  const handleEditNavigation = () => navigate("edit");

  return (
    <section className={styles.purchaseProfileWrapper}>
      <PageTitle
        backUrl={ArtificeRoutes.productPhotoProfiles}
        loading={loading}
        title={profile?.name}
        canUpdate={profile?.canUpdate}
        onEdit={handleEditNavigation}
        subTitle={t(
          "This is where you tell us exactly what screenshots, packaging and products photos you need."
        )}
      />

      {loading ? (
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <Paper classes={{ root: styles.viewWrapper }}>
          <ProductPhotoProfileInformation profile={profile} />
        </Paper>
      )}
    </section>
  );
};
