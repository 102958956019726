import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface GuardedRouteProps {
  isRouteAccessible?: boolean;
  redirectRoute?: string;
}

export const GuardedRoute = ({
  isRouteAccessible = false,
  redirectRoute = "/",
}: GuardedRouteProps): JSX.Element =>
  isRouteAccessible ? <Outlet /> : <Navigate to={redirectRoute} replace />;
