import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArtificeRoutes, useNotifications } from "../../../../../common";
import {
  ProductPhotoProfileService,
  IProductPhotoProfile,
} from "../../../common";

export const useProductPhotoProfile = () => {
  const { t } = useTranslation("portal");
  const { showAlert } = useNotifications();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [profile, setProfile] = useState<IProductPhotoProfile>();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return;
    }

    const getProductPhotoProfile = async () => {
      try {
        setLoading(true);
        const data = await ProductPhotoProfileService.fetchEntityById(
          Number(id)
        );
        if (!data?.id) {
          throw new Error("no data");
        }
        setProfile(data);
        setLoading(false);
      } catch (err) {
        showAlert({
          text: t("There was an error. Please try again"),
          severity: "error",
        });
        navigate(ArtificeRoutes.productPhotoProfiles);
      }
    };

    getProductPhotoProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate]);

  return { profile, loading, id };
};
