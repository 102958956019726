import React, { ReactNode } from "react";
import classNames from "classnames";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorIcon from "@mui/icons-material/Error";
import { AccordionSummary } from "@mui/material";
import styles from "./style.module.scss";

interface ArtificeAccordionSummaryProps {
  title: string | JSX.Element;
  variant?: "filled";
  right?: ReactNode;
  error?: boolean;
  completed?: boolean;
  hideExpand?: boolean;
  hideCheckIcon?: boolean;
}

export const ArtificeAccordionSummary = ({
  title,
  completed = false,
  variant,
  right,
  error,
  hideExpand = false,
  hideCheckIcon = false,
}: ArtificeAccordionSummaryProps) => (
  <AccordionSummary
    classes={{
      root: variant === "filled" ? styles.filled : styles.root,
      content: styles.content,
    }}
    expandIcon={hideExpand ? null : <ExpandMoreIcon />}
  >
    <section
      className={classNames({
        [styles.title]: true,
        [styles.completed]: completed,
        [styles.error]: error,
      })}
    >
      {hideCheckIcon ? null : (
        <div className={styles.checkIcon}>
          {error ? <ErrorIcon /> : <CheckCircleRoundedIcon />}
        </div>
      )}
      <span>{title}</span>
    </section>
    {right}
  </AccordionSummary>
);
