import React from "react";
import { HomeContainer } from "../../../common/components";
import { AboutUs, ContactUs, OurServices, MainSection } from "../components";

export const Home = () => {
  return (
    <>
      <MainSection />
      <>
        <AboutUs />
        <HomeContainer>
          <OurServices />
        </HomeContainer>
      </>
      <ContactUs />
    </>
  );
};
