import React from "react";
import { useTranslation } from "react-i18next";
import { ProductPhotoProfileForm } from "../../components";
import { useEditProductPhotoProfile } from "../../hooks";
import { PageTitle } from "../../../../common";
import { ArtificeRoutes } from "../../../../../../common";

export const EditProductPhotoProfile = () => {
  const { t } = useTranslation("portal");
  const { profileForm, profileName, id, loading } =
    useEditProductPhotoProfile();

  return (
    <>
      <PageTitle
        loading={loading}
        title={profileName}
        backUrl={`${ArtificeRoutes.productPhotoProfiles}/${id}`}
        subTitle={t(
          "This is where you tell us exactly what screenshots, packaging and products photos you need."
        )}
      />
      <ProductPhotoProfileForm profileForm={profileForm} />
    </>
  );
};
