import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AdminService } from "../services";
import { useNotifications } from "../../../../common";

export const useAddSystemBrand = () => {
  const { t } = useTranslation("admin");
  const [loading, setLoading] = useState<boolean>(false);
  const { showAlert } = useNotifications();

  const [name, setName] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setName("");
  };

  const addSystemBrand = async () => {
    try {
      setLoading(true);
      const brandNameTrimed = name.trim();
      await AdminService.addSystemBrand(brandNameTrimed);
      showAlert({
        text: t("System Brand added successfully."),
        severity: "success",
      });
    } catch (err: any) {
      const errorText =
        err?.response?.data?.statusCode === 409
          ? t("System Brand is already added.")
          : t("Something went wrong, please try again.");

      showAlert({
        text: errorText,
        severity: "error",
      });
    } finally {
      setName("");
      setLoading(false);
      setModalOpen(false);
    }
  };

  const handleNameChange = (evt: any) => {
    if (evt.target.value.trim().length === 0) {
      setName(evt.target.value.trim());
    } else {
      setName(evt.target.value);
    }
  };

  const handleAddSystemBrand = async () => {
    await addSystemBrand();
    handleModalClose();
    setName("");
  };

  return {
    name,
    loading,
    modalOpen,
    handleModalOpen,
    handleModalClose,
    handleNameChange,
    handleAddSystemBrand,
  };
};
