import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion } from "@mui/material";
import {
  ArtificeAccordionDetails,
  ArtificeAccordionSummary,
} from "../../../../../../common/components";
import { IPurchase } from "../../interfaces";
import { ProfileInfoList } from "../../../../common";
import { usePurchaseInfo } from "../../hooks";
import { ProductList } from "../product-list";
import { ShippingView } from "../shipping-view";
import { LeadsView } from "../leads-view";
import styles from "./style.module.scss";

interface PurchaseInformationProps {
  purchase?: IPurchase;
  showLeads?: boolean;
}

export const PurchaseInformation = ({
  purchase,
  showLeads,
}: PurchaseInformationProps) => {
  const [t] = useTranslation("portal");

  const { caseInfoList, sellerInfo, shippingInfo } = usePurchaseInfo(purchase);

  return (
    <section className={styles.viewWrapper}>
      <Accordion expanded={true} classes={{ root: styles.accordionWrapper }}>
        <ArtificeAccordionSummary
          variant="filled"
          title={t("Case Information")}
          hideCheckIcon={true}
          hideExpand={true}
        />
        <ArtificeAccordionDetails>
          <>
            <section className={styles.caseInfoContent}>
              <ProfileInfoList
                list={caseInfoList}
                type="caseInformation"
                classes={{
                  listItemName: styles.listItemName,
                  listItemValue: styles.listItemValue,
                }}
              />
            </section>
            <section className={styles.sellerInfo}>
              <ProfileInfoList
                list={sellerInfo}
                type="caseInformation"
                classes={{
                  listItemName: styles.listItemName,
                  listItemValue: styles.listItemValue,
                }}
              />
            </section>
          </>
        </ArtificeAccordionDetails>
      </Accordion>
      <ProductList
        products={purchase?.purchaseProducts || []}
        sellerName={purchase?.sellerRef}
      />
      <Accordion expanded={true} classes={{ root: styles.accordionWrapper }}>
        <ArtificeAccordionSummary
          variant="filled"
          title={t("Storage & Shipping Questions")}
          hideCheckIcon={true}
          hideExpand={true}
        />
        <ArtificeAccordionDetails>
          <section>
            <ShippingView
              shippingInfo={shippingInfo}
              isPurchaseSection={true}
            />
          </section>
        </ArtificeAccordionDetails>
      </Accordion>

      {showLeads ? (
        <Accordion expanded={true} classes={{ root: styles.accordionWrapper }}>
          <ArtificeAccordionSummary
            variant="filled"
            title={t("Leads")}
            hideCheckIcon={true}
            hideExpand={true}
          />
          <ArtificeAccordionDetails>
            <section>
              <LeadsView
                notifyOfLeads={purchase?.notifyOfLeads}
                isPurchaseSection={true}
              />
            </section>
          </ArtificeAccordionDetails>
        </Accordion>
      ) : null}
    </section>
  );
};
