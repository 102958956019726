import { string } from "yup";
import isEmail from "validator/es/lib/isEmail";
import { maxLength, maxLengthMessage } from "./max-length.const";
import { validationMessage } from "./messages";

const nameRegex = /^[- \w'.]+$/i;
const numberRegex = /^[+\d]+$/i;
export const uppercaseRegExp = /(?=.*?[A-Z])/;
export const lowercaseRegExp = /(?=.*?[a-z])/;
export const digitsRegExp = /(?=.*?[0-9])/;
export const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
export const minLengthRegExp = /.{12,}/;

export const firstNameValidation = string()
  .max(maxLength.firstName, maxLengthMessage.max100)
  .matches(nameRegex, "Enter a valid characters: A-Z, 0-9, ', -, “ “");

export const lastNameValidation = string()
  .max(maxLength.lastName, maxLengthMessage.max100)
  .matches(nameRegex, "Enter a valid characters: A-Z, 0-9, ', -, “ “");

export const emailValidation = string()
  .max(maxLength.email, maxLengthMessage.max255)
  .test({
    name: "email",
    test: function (item = "") {
      return !item || isEmail(item);
    },
    message: "Email must be a valid email",
  });

export const phoneValidation = string()
  .matches(numberRegex, "Only digits are allowed")
  .min(6, "Please, enter minimum 6 digits")
  .max(14, "Maximum digits - 14");

export const passwordValidation = string()
  .required(validationMessage.required)
  .test({
    name: "password",
    test: function (item = "") {
      const validations = [
        {
          message: "Password must contain at least 1 upper case letter",
          regex: uppercaseRegExp,
        },
        {
          message: "Password must contain at least 1 lower case letter",
          regex: lowercaseRegExp,
        },
        {
          message: "Password must contain at least 1 number",
          regex: digitsRegExp,
        },
        {
          message: "Password must contain at least 1 special character",
          regex: specialCharRegExp,
        },
        {
          message: "Password must have at least 12 characters",
          regex: minLengthRegExp,
        },
      ];
      const messages = validations
        .filter((validation) => !validation.regex.test(item))
        .map((validation) => validation.message);

      return messages.length
        ? this.createError({
            message: messages.join("|"),
          })
        : true;
    },
  });
