import React from "react";
import { useTranslation } from "react-i18next";
import { GridSortModel } from "@mui/x-data-grid";
import { PageTitle } from "../../../../common";
import { ArtificeGrid } from "../../../../../../common/components";
import { useRepresentedBrandsList } from "../../hooks";
import { AddBrand } from "../../components/add-brand";

export const RepresentedBrandsList = () => {
  const [t] = useTranslation("portal");
  const {
    systemBrands,
    rows,
    columns,
    sortModel,
    loading,
    getBrands,
    setSortModel,
  } = useRepresentedBrandsList();

  return (
    <>
      <PageTitle title={t("Represented Brands")} />
      <AddBrand systemBrands={systemBrands} reloadList={getBrands} />
      <ArtificeGrid
        loading={loading}
        customAction
        skipActions
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
      />
    </>
  );
};
