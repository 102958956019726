import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useSectionNames = () => {
  const [t] = useTranslation("portal");

  return useMemo(
    () => ({
      name: t("Product Photo Profile Name"),
      screenShoots: t("Screen Shots"),
      documents: t("Documents, Receipts, Written Materials"),
      outerPackaging: t("Outer Packaging"),
      productPackaging: t("Product Packaging - Customize Photo(s)"),
      purchasedItem: t("Purchased Item - Customize Photo(s)"),
    }),
    [t]
  );
};
