import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth } from "../../../../../core";
import { useUserInvitation } from "../../hooks";
import styles from "./style.module.scss";

interface InviteProps {
  reloadList: () => void;
}
export const Invite = ({ reloadList }: InviteProps) => {
  const [t] = useTranslation("portal");
  const { user } = useAuth();
  const { loading, inviteUser } = useUserInvitation();
  const [email, setEmail] = useState("");

  const handleEmailChange = (evt: any) => {
    setEmail(evt.target.value);
  };

  const handleUserInvite = async () => {
    await inviteUser(`${email}@${user?.orgDomain}`);
    setEmail("");
    reloadList();
  };

  return (
    <section className={styles.wrapper}>
      <TextField
        classes={{
          root: styles.inputRoot,
        }}
        size="medium-m"
        margin="normal"
        label={t("Email address")}
        InputProps={{
          endAdornment: <span>@{user?.orgDomain}</span>,
        }}
        value={email}
        onChange={handleEmailChange}
      />

      <LoadingButton
        disabled={!email}
        loading={loading}
        className={styles.button}
        variant="contained"
        size="medium-m"
        onClick={handleUserInvite}
      >
        {t("Send Invite")}
      </LoadingButton>
    </section>
  );
};
