import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Paper, Tab, Tabs } from "@mui/material";
import { TabPanel } from "../../../../../../common/components";
import { ProfileInfoList, SectionTitle } from "../../../../common";
import { ProductPhotoProfileInformation } from "../../../product-photo-profile";
import { IPurchasePurchaseProducts } from "../../interfaces";
import { useProduct } from "../../hooks";
import {
  ArtificeAccordionDetails,
  ArtificeAccordionSummary,
} from "../../../../../../common/components";
import { ProductOrderCount } from "../product-order-count";
import styles from "./style.module.scss";

interface ProductProps {
  product: IPurchasePurchaseProducts;
  productExpanded: (index: number) => boolean;
  handleProductOrderChange: (index: number) => any;
  index: number;
  count: number;
  sellerName?: string;
}

export const ProductView = ({
  product,
  index,
  count,
  sellerName,
  productExpanded,
  handleProductOrderChange,
}: ProductProps) => {
  const {
    productMainList,
    productGeneralList,
    productPhotoProfileInfo,
    productSudstituionsList,
    tabValue,
    handleTabChange,
  } = useProduct(product);
  const [t] = useTranslation("portal");

  return (
    <Accordion
      classes={{ root: styles.accordionWrapper }}
      key={index}
      expanded={productExpanded(index)}
      onChange={handleProductOrderChange(index)}
    >
      <ArtificeAccordionSummary
        variant="filled"
        right={<ProductOrderCount from={index + 1} count={count} />}
        title={
          <SectionTitle
            name={t("Product Details")}
            subName={sellerName || ""}
          />
        }
        hideCheckIcon={true}
      />
      <ArtificeAccordionDetails>
        <section>
          <section className={styles.sectionContent}>
            <ProfileInfoList
              list={productMainList}
              type="productOrder"
              classes={{
                listItemName: styles.listItemName,
                listItemValue: styles.listItemValue,
              }}
            />
          </section>

          <section className={styles.tabsWrapper}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab className={styles.tabItem} label={t("General")} />
              <Tab className={styles.tabItem} label={t("Photo Profile")} />
              <Tab
                className={styles.tabItem}
                label={t("Order Substitutions")}
              />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
              <Paper className={styles.tabContent}>
                <ProfileInfoList
                  list={productGeneralList}
                  type="productOrder"
                  classes={{
                    listItemName: styles.listItemName,
                    listItemValue: styles.listItemValue,
                  }}
                />
              </Paper>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <section className={styles.tabPhotoProfileContent}>
                <ProductPhotoProfileInformation
                  profile={productPhotoProfileInfo}
                  cardsLayout={true}
                />
              </section>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <Paper className={styles.tabContent}>
                <ProfileInfoList
                  list={productSudstituionsList}
                  type="productSudstituions"
                  classes={{
                    listItemName: styles.listItemName,
                    listItemValue: styles.listItemValue,
                  }}
                />
              </Paper>
            </TabPanel>
          </section>
        </section>
      </ArtificeAccordionDetails>
    </Accordion>
  );
};
