import { useEffect, useState } from "react";
import { AuthService } from "../../../common";
import { useQuery } from "../../../../../common/hooks";

export const useEmailVerification = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const search = useQuery();
  const token = search.get("token");

  useEffect(() => {
    const verifyEmail = async (token: string) => {
      try {
        await AuthService.verifyEmail(token);
        setIsVerified(true);
      } catch (error: any) {
        setErrorMessage(
          error?.response?.data?.code === "ALREADY_CONFIRMED"
            ? "Email verification link expired."
            : "Notice that unfortunately we couldn’t verify your email."
        );
      }
    };

    if (token) {
      verifyEmail(token);
    }
  }, [token]);

  return { errorMessage, isVerified };
};
