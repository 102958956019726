import { InferType, object, string, boolean } from "yup";
import {
  cityValidation,
  companyNameValidation,
  countryValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  phoneValidation,
  purchaseProfileNameValidation,
  shoppingAccountNumberValidation,
  streetValidation,
  validationMessage,
  zipValidation,
} from "../../../../../common";

const useCorporateShippingAccount = (
  parent: {
    useCorporateShippingAccount: boolean;
    isStorageAndShippingRequired: boolean;
    skipValidation: boolean;
  },
  item?: string
) => !parent.useCorporateShippingAccount || Boolean(item);

const storageAndShippingRequired = (
  parent: { isStorageAndShippingRequired: boolean },
  item?: string
) => !parent.isStorageAndShippingRequired || Boolean(item);

export const StorageAndShippingSchema = {
  isStorageAndShippingRequired: boolean().required(),
  skipValidation: boolean(),
  useCorporateShippingAccount: boolean(),
  deliveryService: string().when("skipValidation", {
    is: false,
    then: () =>
      string().test("name", validationMessage.required, function (item) {
        return useCorporateShippingAccount(this.parent, item);
      }),
  }),
  corporateShippingAccount: string().when("skipValidation", {
    is: false,
    then: () =>
      shoppingAccountNumberValidation.test(
        "name",
        validationMessage.required,
        function (item) {
          return useCorporateShippingAccount(this.parent, item);
        }
      ),
  }),
  shipToCompanyName: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      companyNameValidation.test(
        "name",
        validationMessage.required,
        function (item) {
          return storageAndShippingRequired(this.parent, item);
        }
      ),
  }),
  shipToFirstName: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      firstNameValidation.test(
        "name",
        validationMessage.required,
        function (item) {
          return storageAndShippingRequired(this.parent, item);
        }
      ),
  }),
  shipToLastName: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      lastNameValidation.test(
        "name",
        validationMessage.required,
        function (item) {
          return storageAndShippingRequired(this.parent, item);
        }
      ),
  }),
  shipToEmail: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      emailValidation.test("name", validationMessage.required, function (item) {
        return storageAndShippingRequired(this.parent, item);
      }),
  }),
  shipToMobileNumber: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      phoneValidation.test("name", validationMessage.required, function (item) {
        return storageAndShippingRequired(this.parent, item);
      }),
  }),
  shipToMobileNumberCountryCode: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      string().test("name", validationMessage.required, function (item) {
        return storageAndShippingRequired(this.parent, item);
      }),
  }),

  shipToStreet1: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      streetValidation.test(
        "name",
        validationMessage.required,
        function (item) {
          return storageAndShippingRequired(this.parent, item);
        }
      ),
  }),
  shipToStreet2: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () => streetValidation,
  }),
  shipToCity: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      cityValidation.test("name", validationMessage.required, function (item) {
        return storageAndShippingRequired(this.parent, item);
      }),
  }),
  shipToState: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      string().test("name", validationMessage.required, function (item) {
        return storageAndShippingRequired(this.parent, item);
      }),
  }),
  shipToZip: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      zipValidation.test("name", validationMessage.required, function (item) {
        return storageAndShippingRequired(this.parent, item);
      }),
  }),
  shipToCountry: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      countryValidation.test(
        "name",
        validationMessage.required,
        function (item) {
          return storageAndShippingRequired(this.parent, item);
        }
      ),
  }),
  shippingPreferences: string().when("isStorageAndShippingRequired", {
    is: true,
    then: () =>
      string().test("name", validationMessage.required, function (item) {
        return storageAndShippingRequired(this.parent, item);
      }),
  }),
};

export const PurchaseProfileSchema = {
  // Company Information
  companyName: companyNameValidation,
  industryType: string(),
  department: string(),
  companyStreet: streetValidation,
  companyCity: cityValidation,
  companyState: string(),
  companyZip: zipValidation,
  email: emailValidation,
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  directWorkNumber: phoneValidation,
  directWorkNumberCountryCode: string(),
  mobilePhoneNumber: phoneValidation,
  mobilePhoneNumberCountryCode: string(),
  notifyOfLeads: boolean(),

  ...StorageAndShippingSchema,
};

export const PurchaseProfilePageSchema = object({
  companyInformationCompleted: boolean().required(),
  isStorageCompleted: boolean().required(),
  isLeadsCompleted: boolean().required(),
  profileName: purchaseProfileNameValidation.required(
    validationMessage.required
  ),

  ...PurchaseProfileSchema,
});

export type PurchaseProfileFormValuesType = InferType<
  typeof PurchaseProfilePageSchema
>;
