import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Divider } from "@mui/material";
import Link from "@mui/material/Link";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { PurchaseReportTypeEnum } from "../../enums";

export const FileDownload = (props: GridRenderCellParams) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!props.value?.length) {
    return null;
  }

  const purchaseReports = props.value.filter(
    ({ type }: any) => type === PurchaseReportTypeEnum.PURCHASE_REPORT
  );

  const researchReports = props.value.filter(
    ({ type }: any) => type === PurchaseReportTypeEnum.RESEARCH_REPORT
  );

  const leadReports = props.value.filter(
    ({ type }: any) => type === PurchaseReportTypeEnum.LEAD_REPORT
  );

  const supplementalReports = props.value.filter(
    ({ type }: any) => type === PurchaseReportTypeEnum.SUPPLEMENTAL_REPORT
  );

  const receipts = props.value.filter(
    ({ type }: any) => type === PurchaseReportTypeEnum.RECEIPT
  );

  const reports = [
    purchaseReports,
    supplementalReports,
    receipts,
    researchReports,
    leadReports,
  ].filter((r) => r.length);

  return (
    <div>
      <Button onClick={handleClick}>
        <MoreHorizIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {reports.map((r, index) => (
          <section key={r.id}>
            {r.map((report: any) => (
              <MenuItem
                onClick={handleClose}
                href={report.url}
                target="_blank"
                component={Link}
              >
                <FileDownloadIcon
                  sx={{ marginRight: "12px", color: "#0058dd" }}
                />
                {report.title}
              </MenuItem>
            ))}
            {index === reports.length - 1 ? null : <Divider />}
          </section>
        ))}
      </Menu>
    </div>
  );
};
