import React, { useState } from "react";
import { CreatePurchaseProfileSections } from "../enums";
import { PurchaseProfileFormType } from "../interfaces";

export const usePurchaseProfileAccordion = (
  profileForm: PurchaseProfileFormType
) => {
  const [expanded, setExpanded] = useState<CreatePurchaseProfileSections[]>([
    CreatePurchaseProfileSections.ContactInformation,
  ]);

  const handleChange =
    (panel: CreatePurchaseProfileSections) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? [panel] : []);
    };

  const completeSectionMap = {
    [CreatePurchaseProfileSections.ContactInformation]:
      "companyInformationCompleted",
    [CreatePurchaseProfileSections.StorageAndShipping]: "isStorageCompleted",
    [CreatePurchaseProfileSections.Leads]: "isLeadsCompleted",
  };

  const companyInformationKeys = [
    "companyName",
    "firstName",
    "lastName",
    "companyStreet",
    "companyCity",
    "companyState",
    "companyZip",
    "email",
    "directWorkNumberCountryCode",
    "directWorkNumber",
    "mobilePhoneNumberCountryCode",
    "mobilePhoneNumber",
  ];

  const storageAndShippingKeys = [
    "deliveryService",
    "corporateShippingAccount",
    "shipToCompanyName",
    "shipToFirstName",
    "shipToLastName",
    "shipToEmail",
    "shipToMobileNumberCountryCode",
    "shipToMobileNumber",
    "shipToStreet1",
    "shipToStreet2",
    "shipToCity",
    "shipToCountry",
    "shipToState",
    "shipToZip",
    "shippingPreferences",
  ];

  const isCompanyInformationInvalid = companyInformationKeys.some(
    (key) =>
      (profileForm.touched as Record<string, boolean>)?.[key] &&
      (profileForm.errors as Record<string, string>)?.[key]
  );

  const isStorageAnsShippingInvalid = storageAndShippingKeys.some(
    (key) =>
      (profileForm.touched as Record<string, boolean>)?.[key] &&
      (profileForm.errors as Record<string, string>)?.[key]
  );

  const handleNext = async (
    currentSection: CreatePurchaseProfileSections,
    nextSection?: CreatePurchaseProfileSections
  ) => {
    if (await shouldPreventFromNext(currentSection)) {
      return;
    }
    setExpanded(nextSection ? [nextSection] : []);
    profileForm.setFieldValue(completeSectionMap[currentSection], true);
  };

  const validateContactInformation = async () => {
    companyInformationKeys.forEach((key) => {
      profileForm.setFieldTouched(key, true, true);
    });

    const errors = await profileForm.validateForm();

    return companyInformationKeys.some(
      (key) => (errors as Record<string, string>)?.[key]
    );
  };

  const validateStorageAndShipping = async () => {
    storageAndShippingKeys.forEach((key) => {
      profileForm.setFieldTouched(key, true, true);
    });

    const errors = await profileForm.validateForm();

    return storageAndShippingKeys.some(
      (key) => (errors as Record<string, string>)?.[key]
    );
  };

  const shouldPreventFromNext = async (
    currentSection: CreatePurchaseProfileSections
  ) => {
    if (currentSection === CreatePurchaseProfileSections.ContactInformation) {
      if (await validateContactInformation()) {
        return true;
      }
    }

    if (currentSection === CreatePurchaseProfileSections.StorageAndShipping) {
      if (await validateStorageAndShipping()) {
        return true;
      }
    }
  };

  const {
    ContactInformation: eContactInformation,
    StorageAndShipping: eStorageAndShipping,
    Leads: eLeads,
  } = CreatePurchaseProfileSections;

  return {
    isContactInformationExpanded: expanded.includes(eContactInformation),
    isStorageAndShippingExpanded: expanded.includes(eStorageAndShipping),
    isLeadsExpanded: expanded.includes(eLeads),

    contactInformation: eContactInformation,
    storageAndShipping: eStorageAndShipping,
    leads: eLeads,

    isCompanyInformationInvalid,
    isStorageAnsShippingInvalid,

    handleChange,
    handleNext,
  };
};
