import React from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../../portal";
import { BrandsList } from "../../common/components";

export const Brands = () => {
  const { t } = useTranslation("admin");

  return (
    <>
      <PageTitle title={t("Brands")} />
      <BrandsList />
    </>
  );
};
