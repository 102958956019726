import React, { ReactNode } from "react";
import { LanguageSwitch } from "../../../common/components";
import {
  AdminNavigation,
  PortalNavigation,
  UserMenu,
  MakePurchase,
} from "../components";
import { useAuth } from "../providers";
import styles from "./style.module.scss";

interface PortalLayoutProps {
  children?: ReactNode;
}
export const UserLayout = ({ children }: PortalLayoutProps) => {
  const { user } = useAuth();

  const navigation = user?.hasPortalAccess ? (
    <PortalNavigation />
  ) : (
    <AdminNavigation />
  );

  return (
    <section className={styles.portalLayout}>
      {navigation}
      <section className={styles.portalBody}>
        <section className={styles.topNavigation}>
          <MakePurchase />
          <section className={styles.rightBlock}>
            <LanguageSwitch />
            <UserMenu />
          </section>
        </section>
        <section className={styles.portalMainSection}>{children}</section>
      </section>
    </section>
  );
};
