import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { industryTypes } from "../../../constants";

interface IndustryTypeProps {
  formControlProps: any;
}
export const IndustryType = ({ formControlProps }: IndustryTypeProps) => {
  const [t] = useTranslation("common");

  return (
    <TextField
      select
      fullWidth
      margin="normal"
      label={t("Industry Type")}
      {...formControlProps}
    >
      {industryTypes.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {t(option.name)}
        </MenuItem>
      ))}
    </TextField>
  );
};
