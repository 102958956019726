import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridValueFormatterParams, GridSortModel } from "@mui/x-data-grid";
import { IOrganizationListItem } from "../interfaces";
import { AdminService } from "../services";
import { ApmGridColDef } from "../../../../common/components";
import { formatDisplayDate } from "../../../../common/utils";
import { departmentTypes, industryTypes } from "../../../../common";

const defaultSorting: GridSortModel = [{ field: "dateUpdated", sort: "desc" }];

export const useOrganizationsList = () => {
  const { t } = useTranslation("admin");
  const [loading, setLoading] = useState<boolean>(true);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSorting);
  const [organizations, setOrganizations] = useState<IOrganizationListItem[]>(
    []
  );

  const getOrganizations = async () => {
    setLoading(true);
    const response = await AdminService.fetchAllOrganizations();
    setOrganizations(response);
    setLoading(false);
  };

  const refetchOrganizations = async () => {
    await getOrganizations();
  };

  useEffect(() => {
    getOrganizations();
  }, [setOrganizations]);

  const onChangePaymentType = async (id: string, paymentType: string) => {
    setLoading(true);
    await AdminService.updateOrganization(id, paymentType);
    await refetchOrganizations();
    setLoading(false);
  };

  const statusMap = useMemo(
    () =>
      new Map([
        ["pending", t("Pending")],
        ["approved", t("Active")],
        ["rejected", t("Rejected")],
      ]),
    [t]
  );

  const columns: ApmGridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t("Name") as string,
        flex: 1,
        handleContentClick: true,
      },
      {
        field: "industryType",
        headerName: t("Industry Type") as string,
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) => {
          const name = industryTypes.find(
            (type) => type.id === params.value
          )?.name;

          return t(name || "");
        },
      },
      {
        field: "subType",
        headerName: t("Department") as string,
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) => {
          const name = departmentTypes.find(
            (type) => type.id === params.value
          )?.name;

          return t(name || "");
        },
      },
      {
        field: "dateUpdated",
        headerName: t("Date Updated") as string,
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) =>
          formatDisplayDate(params.value),
      },
      {
        field: "status",
        headerName: t("Status") as string,
        flex: 1,
      },
      {
        field: "paymentType",
        headerName: t("Payment Type") as string,
        flex: 1,
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      organizations.map((organization) => ({
        id: organization.id,
        name: organization.name,
        industryType: organization.industryType,
        subType: organization.subType,
        status: statusMap.get(organization.status),
        dateUpdated: new Date(organization.updatedAt),
        paymentType: organization.paymentType,
      })),
    [organizations, statusMap]
  );

  return {
    organizations,
    rows,
    columns,
    loading,
    sortModel,
    setSortModel,
    onChangePaymentType,
  };
};
