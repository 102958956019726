import React from "react";
import { Accordion } from "@mui/material";
import {
  ArtificeAccordionDetails,
  ArtificeAccordionSummary,
} from "../../../../../../common/components";
import {
  Documents,
  OuterPackaging,
  ProductPackaging,
  PurchasedItem,
  ScreenShots,
} from "../form-sections";
import { SectionTitle } from "../../../../common";
import { ProductPhotoProfileFormType } from "../../interfaces";
import { useSectionNames } from "../../hooks";
import { useProductPhotoProfileAccordion } from "../../hooks/use-product-photo-profile-accordion";
import { ProfileName } from "../form-sections/profile-name";

interface ProductPhotoProfileFormInnerProps {
  profileForm: ProductPhotoProfileFormType;
  showNameSection?: boolean;
}

export const ProductPhotoProfileFormInner = ({
  profileForm,
  showNameSection,
}: ProductPhotoProfileFormInnerProps) => {
  const sectionsNames = useSectionNames();
  const {
    isNameExpanded,
    isScreenShootsExpanded,
    isDocumentsReceiptsWrittenMaterialsExpanded,
    isOuterPackagingExpanded,
    isCustomizeProductPackagingExpanded,
    isCustomizePhotoItemOrPurchasedItemExpanded,

    isNameInvalid,
    isScreenshotsInvalid,
    isDocumentsReceiptsWrittenMaterialsInvalid,
    isOuterPackagingInvalid,
    isCustomizeProductPackagingInvalid,
    isPurchasedItemInvalid,

    nameSection,
    screenShoots,
    documentsReceiptsWrittenMaterials,
    outerPackaging,
    customizeProductPackaging,
    customizePhotoItemOrPurchasedItem,

    handleChange,
    handleNext,
  } = useProductPhotoProfileAccordion(profileForm, showNameSection);

  return (
    <>
      {/* Name */}
      {showNameSection && (
        <Accordion
          expanded={isNameExpanded}
          onChange={handleChange(nameSection)}
        >
          <ArtificeAccordionSummary
            variant="filled"
            title={sectionsNames.name}
            error={Boolean(isNameInvalid)}
            completed={profileForm.values.isNameCompleted}
          />

          <ArtificeAccordionDetails
            currentSection={nameSection}
            nextSection={screenShoots}
            handleNext={handleNext}
          >
            <ProfileName formik={profileForm} />
          </ArtificeAccordionDetails>
        </Accordion>
      )}

      {/* Screen Shots */}
      <Accordion
        expanded={isScreenShootsExpanded}
        onChange={handleChange(screenShoots)}
      >
        <ArtificeAccordionSummary
          variant="filled"
          title={
            <SectionTitle
              name={sectionsNames.screenShoots}
              subName={showNameSection ? profileForm.values.profileName : ""}
            />
          }
          error={Boolean(isScreenshotsInvalid)}
          completed={profileForm.values.isScreenshotsCompleted}
        />

        <ArtificeAccordionDetails
          currentSection={screenShoots}
          nextSection={documentsReceiptsWrittenMaterials}
          handleNext={handleNext}
        >
          <ScreenShots formik={profileForm} />
        </ArtificeAccordionDetails>
      </Accordion>

      {/* Documents, Receipts, Written Materials */}
      <Accordion
        expanded={isDocumentsReceiptsWrittenMaterialsExpanded}
        onChange={handleChange(documentsReceiptsWrittenMaterials)}
      >
        <ArtificeAccordionSummary
          variant="filled"
          title={
            <SectionTitle
              name={sectionsNames.documents}
              subName={showNameSection ? profileForm.values.profileName : ""}
            />
          }
          error={Boolean(isDocumentsReceiptsWrittenMaterialsInvalid)}
          completed={
            profileForm.values.isDocumentsReceiptsWrittenMaterialsCompleted
          }
        />

        <ArtificeAccordionDetails
          currentSection={documentsReceiptsWrittenMaterials}
          nextSection={outerPackaging}
          handleNext={handleNext}
        >
          <Documents formik={profileForm} />
        </ArtificeAccordionDetails>
      </Accordion>

      {/* Outer Packaging */}
      <Accordion
        expanded={isOuterPackagingExpanded}
        onChange={handleChange(outerPackaging)}
      >
        <ArtificeAccordionSummary
          variant="filled"
          title={
            <SectionTitle
              name={sectionsNames.outerPackaging}
              subName={showNameSection ? profileForm.values.profileName : ""}
            />
          }
          error={Boolean(isOuterPackagingInvalid)}
          completed={profileForm.values.isOuterPackagingCompleted}
        />
        <ArtificeAccordionDetails
          currentSection={outerPackaging}
          nextSection={customizeProductPackaging}
          handleNext={handleNext}
        >
          <OuterPackaging formik={profileForm} />
        </ArtificeAccordionDetails>
      </Accordion>

      {/* Product Packaging - Customize Photo(s) */}
      <Accordion
        expanded={isCustomizeProductPackagingExpanded}
        onChange={handleChange(customizeProductPackaging)}
      >
        <ArtificeAccordionSummary
          variant="filled"
          title={
            <SectionTitle
              name={sectionsNames.productPackaging}
              subName={showNameSection ? profileForm.values.profileName : ""}
            />
          }
          error={Boolean(isCustomizeProductPackagingInvalid)}
          completed={profileForm.values.isCustomizeProductPackagingCompleted}
        />
        <ArtificeAccordionDetails
          currentSection={customizeProductPackaging}
          nextSection={customizePhotoItemOrPurchasedItem}
          handleNext={handleNext}
        >
          <ProductPackaging formik={profileForm} />
        </ArtificeAccordionDetails>
      </Accordion>

      {/* Customize Photo(s) - Item or Purchased Item */}
      <Accordion
        expanded={isCustomizePhotoItemOrPurchasedItemExpanded}
        onChange={handleChange(customizePhotoItemOrPurchasedItem)}
      >
        <ArtificeAccordionSummary
          variant="filled"
          title={
            <SectionTitle
              name={sectionsNames.purchasedItem}
              subName={showNameSection ? profileForm.values.profileName : ""}
            />
          }
          error={Boolean(isPurchasedItemInvalid)}
          completed={profileForm.values.isPurchasedItemCompleted}
        />
        <ArtificeAccordionDetails
          currentSection={customizePhotoItemOrPurchasedItem}
          handleNext={handleNext}
        >
          <PurchasedItem formik={profileForm} />
        </ArtificeAccordionDetails>
      </Accordion>
    </>
  );
};
