import { InferType, object, boolean, string, number, array } from "yup";
import { FormikProps } from "formik";
import { ProductPhotoProfileFormSchema } from "../../product-photo-profile/schemas";
import { StorageAndShippingSchema } from "../../purchase-profile/schemas";
import {
  validationMessage,
  productNameValidation,
  productURLValidation,
  productNotesValidation,
  productDescriptionValidation,
  productColorValidation,
  productSizeValidation,
  artificeCaseNumberValidation,
  internalCaseNumberValidation,
  thirdPartySiteValidation,
  productUnitPriceValidation,
} from "../../../../../common";

export const BrandSchema = {
  id: number(),
  name: string(),
  updatedAt: string(),
  createdBy: object({
    id: number(),
    firstName: string(),
    lastName: string(),
    email: string(),
  }).optional(),
  type: string(),
};

export const ProductOrderSchema = object({
  isProductOrderCompleted: boolean(),
  productName: productNameValidation.required(validationMessage.required),
  productUrl: productURLValidation.required(validationMessage.required),
  quantity: string().required(validationMessage.required),
  unitPrice: productUnitPriceValidation.required(validationMessage.required),
  productPhotoProfile: number(),
  description: productDescriptionValidation,
  color: productColorValidation,
  size: productSizeValidation,
  notes: productNotesValidation,
  brand: object({ ...BrandSchema })
    .optional()
    .nullable(),

  // Photo Profile Settings
  photoProfileSettings: ProductPhotoProfileFormSchema,

  // Order substitution
  moqRequired: boolean(),
  maxBudget: string(),
  moqRequiredNotes: productNotesValidation,
  substituteUnavailable: boolean(),
  substituteUnavailableNotes: productNotesValidation,
  substituteSizeUnavailable: boolean(),
  substituteSizeUnavailableNotes: productNotesValidation,
  substituteColorUnavailable: boolean(),
  substituteColorUnavailableNotes: productNotesValidation,
  substituteQuantitiesUnavailable: boolean(),
  substituteQuantitiesUnavailableNotes: productNotesValidation,
});

export const PurchaseSchema = object({
  // Case Information
  isCaseInformationCompleted: boolean(),
  artificeCaseNumber: artificeCaseNumberValidation,
  thirdPartySite: thirdPartySiteValidation.required(validationMessage.required),
  internalCaseNumber: internalCaseNumberValidation,
  purchaseBrand: object({ ...BrandSchema })
    .optional()
    .nullable(),
  status: string(),

  // Product Order
  productOrders: array().of(ProductOrderSchema).required(),

  // Storage and Shipping
  isStorageAndShippingCompleted: boolean(),
  shippingAndStorage: object({ ...StorageAndShippingSchema }),
  storeAtArtifice: boolean(),
  notifyOfLeads: boolean().required(),
});

export type PurchaseFormValuesType = InferType<typeof PurchaseSchema>;
export type ProductOrderValidationFormValuesType = InferType<
  typeof ProductOrderSchema
>;

export type PurchaseFormType = FormikProps<PurchaseFormValuesType>;
