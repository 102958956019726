import { FormikProps } from "formik";
import { object, string, boolean, ref, InferType } from "yup";
import {
  cityValidation,
  companyNameValidation,
  emailValidation,
  firstNameValidation,
  jobTitleValidation,
  lastNameValidation,
  passwordValidation,
  phoneValidation,
  streetValidation,
  zipValidation,
  validationMessage,
} from "../../../../../common";

export const registrationSchema = object({
  industryType: string().required(validationMessage.required),
  department: string().when("industryType", {
    is: "brand",
    then: (schema) => schema.required(validationMessage.required),
  }),
  companyName: companyNameValidation.required(validationMessage.required),
  companyStreet: streetValidation.required(validationMessage.required),
  companyCity: cityValidation.required(validationMessage.required),
  companyState: string().required(validationMessage.required),
  companyZip: zipValidation.required(validationMessage.required),

  isWorkSameAsHQ: boolean(),
  workStreet: streetValidation.when("isWorkSameAsHQ", {
    is: false,
    then: (schema) => schema.required(validationMessage.required),
  }),
  workCity: cityValidation.when("isWorkSameAsHQ", {
    is: false,
    then: (schema) => schema.required(validationMessage.required),
  }),
  workState: string().when("isWorkSameAsHQ", {
    is: false,
    then: (schema) => schema.required(validationMessage.required),
  }),
  workZip: zipValidation.when("isWorkSameAsHQ", {
    is: false,
    then: (schema) => schema.required(validationMessage.required),
  }),

  firstName: firstNameValidation.required(validationMessage.required),
  lastName: lastNameValidation.required(validationMessage.required),
  title: jobTitleValidation.optional(),

  directPhone: phoneValidation,
  directPhoneCountryCode: string(),

  isMobileSameAsDirect: boolean(),
  mobilePhone: phoneValidation,
  mobilePhoneCountryCode: string(),

  email: emailValidation.required(validationMessage.required),
  password: passwordValidation,
  confirmPassword: string()
    .oneOf([ref("password")], "Passwords must match")
    .required(validationMessage.required),
  termsAndConditions: boolean().oneOf(
    [true],
    "Please, accept terms and conditions"
  ),
});

export type RegistrationFormValuesType = InferType<typeof registrationSchema>;

export type RegistrationFormType = FormikProps<RegistrationFormValuesType>;
