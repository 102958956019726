import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import "../../../translation";
import styles from "./style.module.scss";

export const LanguageSwitch = () => {
  const [t, i18n] = useTranslation("common");
  const [lng, setLng] = useState(i18n.resolvedLanguage);

  const handleChange = (event: SelectChangeEvent) => {
    const lng = event.target.value;
    setLng(lng);
    i18n.changeLanguage(lng);
  };

  return process.env.REACT_APP_SUPPORT_I18N ? (
    <Select
      size="medium"
      value={lng}
      variant="standard"
      disableUnderline
      onChange={handleChange}
      className={styles.languageSelect}
    >
      <MenuItem value="en-US">{t("Eng")}</MenuItem>
      <MenuItem value="ua">{t("Ukr")}</MenuItem>
    </Select>
  ) : null;
};
