import { get } from "lodash";

export const getFormFieldProps =
  (form: any, t: any = (text: string) => text, tValidation?: any) =>
  (field: string, label = "Field") => {
    const { touched, errors, values } = form;
    const fieldError = get(errors, field);
    const fieldTouched = get(touched, field);
    const fieldValue = get(values, field);

    return {
      ...form.getFieldProps(field),
      value: fieldValue,
      touched: fieldTouched,
      helperText: fieldTouched
        ? getErrorMessage(fieldError, label, t, tValidation)
        : "",
      error: Boolean(fieldTouched && fieldError),
    };
  };

const getErrorMessage = (
  fieldError: string,
  field: string,
  t: any = (text: string) => text,
  tValidation?: any
) => {
  if (tValidation?.(fieldError || "", { field })) {
    return tValidation(fieldError || "", { field });
  }

  return t(fieldError || "", { field });
};
