export enum ProductPhotoProfileFormSectionsEnum {
  Name = "name",
  ScreenShoots = "screen-shoots",
  DocumentsReceiptsWrittenMaterials = "documents-receipts-written-materials",
  OuterPackaging = "outer-packaging",
  CustomizeProductPackaging = "customize-inner-packaging",
  CustomizePhotoItemOrPurchasedItem = "customize-photo-item-or-purchased-item",
}

export enum ProductPhotoProfileSectionEnum {
  ProductPackaging = "INNER_PACKAGING",
  PurchasedItem = "PURCHASE_ITEM",
}
