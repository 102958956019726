import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { TextField } from "@mui/material";

import { ArtificeCheckbox } from "../../../../../../../common/components";
import { ProductPhotoProfileFormType } from "../../../interfaces";
import { getFormFieldProps } from "../../../../../../../common/utils";
import styles from "./style.module.scss";

interface DocumentsProps {
  formik: ProductPhotoProfileFormType;
}
export const Documents = ({ formik }: DocumentsProps) => {
  const [t] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const { allDocuments } = formik.values;
  const handleAllDocuments = (val: boolean) =>
    formik.setFieldValue("allDocuments", val);
  const formProps = getFormFieldProps(formik, t, tValidation);

  return (
    <section className={styles.verticalView}>
      <ArtificeCheckbox
        label={
          <Trans
            ns="profile"
            i18nKey="All Documents included on or in package"
          />
        }
        checked={Boolean(allDocuments)}
        onChange={handleAllDocuments}
      />
      <TextField
        className={styles.notes}
        label={t("Notes")}
        multiline
        rows={2}
        {...formProps("documentsNotes", t("Notes"))}
      />
    </section>
  );
};
