import { useTranslation } from "react-i18next";

export const useShippingInfo = (shippingInfo?: any) => {
  const [t] = useTranslation("portal");

  const shippingDetailsList = [
    [
      {
        name: t("Delivery Service"),
        value: shippingInfo.deliveryService,
      },
      {
        name: t("Shipping Account Number"),
        value: shippingInfo.shippingAccountNumber,
        secure: true,
      },
    ],
  ];

  const shipToList = [
    [
      {
        name: t("Company Name"),
        value: shippingInfo.companyName,
      },
      {
        name: t("First Name"),
        value: shippingInfo.firstName,
      },
      {
        name: t("Last Name"),
        value: shippingInfo.lastName,
      },
      {
        name: t("Email Address"),
        value: shippingInfo.email,
      },
      {
        name: t("Phone Number"),
        value: shippingInfo.phoneNumber,
      },
    ],
    [
      {
        name: t("Street 1"),
        value: shippingInfo.street1,
      },
      {
        name: t("Street 2"),
        value: shippingInfo.street2,
      },
      {
        name: t("City"),
        value: shippingInfo.city,
      },
      {
        name: t("State"),
        value: shippingInfo.state,
      },
      {
        name: t("Zip Code"),
        value: shippingInfo.zip,
      },
      {
        name: t("Country"),
        value: shippingInfo.country,
      },
    ],
  ];

  return { shippingDetailsList, shipToList };
};
