import React from "react";
import { useTranslation } from "react-i18next";
import { UsersList } from "../../common/components";
import { PageTitle } from "../../../portal";

export const UserManagement = () => {
  const { t } = useTranslation("admin");

  return (
    <>
      <PageTitle title={t("Users")} />
      <UsersList />
    </>
  );
};
