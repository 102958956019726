import React, { useState } from "react";
import { ProductPhotoProfileFormSectionsEnum } from "../../../common/enums";
import { ProductPhotoProfileFormType } from "../interfaces";

export const useProductPhotoProfileAccordion = (
  profileForm: ProductPhotoProfileFormType,
  showNameSection?: boolean
) => {
  const [expanded, setExpanded] = useState<
    ProductPhotoProfileFormSectionsEnum[]
  >([
    showNameSection
      ? ProductPhotoProfileFormSectionsEnum.Name
      : ProductPhotoProfileFormSectionsEnum.ScreenShoots,
  ]);

  const productPackagingKeys = [
    "productPackagingTopNotes",
    "productPackagingBottomNotes",
    "productPackagingFrontNotes",
    "productPackagingBackNotes",
  ];

  const handleChange =
    (panel: ProductPhotoProfileFormSectionsEnum) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? [panel] : []);
    };

  const completeSectionMap = {
    [ProductPhotoProfileFormSectionsEnum.Name]: "isNameCompleted",
    [ProductPhotoProfileFormSectionsEnum.ScreenShoots]:
      "isScreenshotsCompleted",
    [ProductPhotoProfileFormSectionsEnum.DocumentsReceiptsWrittenMaterials]:
      "isDocumentsReceiptsWrittenMaterialsCompleted",
    [ProductPhotoProfileFormSectionsEnum.OuterPackaging]:
      "isOuterPackagingCompleted",
    [ProductPhotoProfileFormSectionsEnum.CustomizeProductPackaging]:
      "isCustomizeProductPackagingCompleted",
    [ProductPhotoProfileFormSectionsEnum.CustomizePhotoItemOrPurchasedItem]:
      "isPurchasedItemCompleted",
  };

  const isNameInvalid =
    profileForm.errors.profileName && profileForm.touched.profileName;

  const isScreenshotsInvalid =
    profileForm.errors.screenShotsNotes && profileForm.touched.screenShotsNotes;

  const isDocumentsReceiptsWrittenMaterialsInvalid =
    profileForm.errors.documentsNotes && profileForm.touched.documentsNotes;

  const isOuterPackagingInvalid =
    profileForm.errors.outerPackagingNotes &&
    profileForm.touched.outerPackagingNotes;

  const isCustomizeProductPackagingInvalid =
    productPackagingKeys.some(
      (key) =>
        (profileForm.touched as Record<string, boolean>)?.[key] &&
        (profileForm.errors as Record<string, string>)?.[key]
    ) || profileForm.errors.productPackagingPhotoExamples;

  const isPurchasedItemInvalid = profileForm.errors.purchasedPhotoExamples;

  const validateName = () => {
    profileForm.setFieldTouched("profileName", true, true);
    return profileForm.errors.profileName;
  };

  const validateScreenShots = () => {
    profileForm.setFieldTouched("screenShotsNotes", true, true);
    return profileForm.errors.screenShotsNotes;
  };

  const validateDocumentsReceiptsWrittenMaterials = () => {
    profileForm.setFieldTouched("documentsNotes", true, true);
    return profileForm.errors.documentsNotes;
  };

  const validateOuterPackaging = () => {
    profileForm.setFieldTouched("outerPackagingNotes", true, true);
    return profileForm.errors.outerPackagingNotes;
  };

  const validateCustomizeProductPackaging = async () => {
    productPackagingKeys.forEach((key) => {
      profileForm.setFieldTouched(key, true, true);
    });

    profileForm.values.purchasedPhotoExamples?.forEach((item) => {
      Object.keys(item).forEach((key) => {
        profileForm.setFieldTouched(key, true, true);
      });
    });

    const errors = await profileForm.validateForm();

    return (
      productPackagingKeys.some(
        (key) => (errors as Record<string, string>)?.[key]
      ) || errors.productPackagingPhotoExamples
    );
  };

  const validatePurchasedItem = async () => {
    profileForm.values.purchasedPhotoExamples?.forEach((item) => {
      Object.keys(item).forEach((key) => {
        profileForm.setFieldTouched(key, true, true);
      });
    });

    const errors = await profileForm.validateForm();

    return errors.purchasedPhotoExamples;
  };

  const shouldPreventFromNext = async (
    currentSection: ProductPhotoProfileFormSectionsEnum
  ) => {
    if (currentSection === ProductPhotoProfileFormSectionsEnum.Name) {
      if (validateName()) {
        return true;
      }
    }

    if (currentSection === ProductPhotoProfileFormSectionsEnum.ScreenShoots) {
      if (validateScreenShots()) {
        return true;
      }
    }

    if (
      currentSection ===
      ProductPhotoProfileFormSectionsEnum.DocumentsReceiptsWrittenMaterials
    ) {
      if (validateDocumentsReceiptsWrittenMaterials()) {
        return true;
      }
    }

    if (currentSection === ProductPhotoProfileFormSectionsEnum.OuterPackaging) {
      if (validateOuterPackaging()) {
        return true;
      }
    }

    if (
      currentSection ===
      ProductPhotoProfileFormSectionsEnum.CustomizeProductPackaging
    ) {
      if (await validateCustomizeProductPackaging()) {
        return true;
      }
    }

    if (
      currentSection ===
      ProductPhotoProfileFormSectionsEnum.CustomizePhotoItemOrPurchasedItem
    ) {
      if (await validatePurchasedItem()) {
        return true;
      }
    }
  };

  const handleNext = async (
    currentSection: ProductPhotoProfileFormSectionsEnum,
    nextSection: ProductPhotoProfileFormSectionsEnum
  ) => {
    if (await shouldPreventFromNext(currentSection)) {
      return;
    }
    setExpanded(nextSection ? [nextSection] : [nextSection]);
    profileForm.setFieldValue(completeSectionMap[currentSection], true);
  };

  const {
    Name: eName,
    ScreenShoots: eScreenShoots,
    DocumentsReceiptsWrittenMaterials: eDocumentsReceiptsWrittenMaterials,
    OuterPackaging: eOuterPackaging,
    CustomizeProductPackaging: eCustomizeProductPackaging,
    CustomizePhotoItemOrPurchasedItem: eCustomizePhotoItemOrPurchasedItem,
  } = ProductPhotoProfileFormSectionsEnum;

  return {
    isNameExpanded: expanded.includes(eName),
    isScreenShootsExpanded: expanded.includes(eScreenShoots),
    isDocumentsReceiptsWrittenMaterialsExpanded: expanded.includes(
      eDocumentsReceiptsWrittenMaterials
    ),
    isOuterPackagingExpanded: expanded.includes(eOuterPackaging),
    isCustomizeProductPackagingExpanded: expanded.includes(
      eCustomizeProductPackaging
    ),
    isCustomizePhotoItemOrPurchasedItemExpanded: expanded.includes(
      eCustomizePhotoItemOrPurchasedItem
    ),

    isNameInvalid,
    isScreenshotsInvalid,
    isDocumentsReceiptsWrittenMaterialsInvalid,
    isOuterPackagingInvalid,
    isCustomizeProductPackagingInvalid,
    isPurchasedItemInvalid,

    nameSection: eName,
    screenShoots: eScreenShoots,
    documentsReceiptsWrittenMaterials: eDocumentsReceiptsWrittenMaterials,
    outerPackaging: eOuterPackaging,
    customizeProductPackaging: eCustomizeProductPackaging,
    customizePhotoItemOrPurchasedItem: eCustomizePhotoItemOrPurchasedItem,

    handleChange,
    handleNext,
  };
};
