export const brandTypes = [
  {
    id: "system",
    value: "SYSTEM",
    name: "System",
  },
  {
    id: "org",
    value: "ORGANIZATION",
    name: "Custom",
  },
];
