import React from "react";
import classnames from "classnames";

import styles from "./style.module.scss";

interface NotesProps {
  notes?: string;
  noPadding?: boolean;
}

export const Notes = ({ notes, noPadding }: NotesProps) => {
  return notes ? (
    <p className={classnames(styles.notes, noPadding && styles.noPadding)}>
      {notes}
    </p>
  ) : null;
};
