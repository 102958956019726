import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { IconButton, InputAdornment, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  IShippingPreferences,
  SHIPPING_PREFERENCES,
  states,
  deliveryService,
} from "../../../../../../../common";
import { getFormFieldProps } from "../../../../../../../common/utils";
import { PurchaseProfileFormType } from "../../../interfaces";
import {
  ArtificeRadio,
  Phone,
  ZipCode,
} from "../../../../../../../common/components";
import styles from "./style.module.scss";

interface StorageAndShippingProps {
  formik: PurchaseProfileFormType;
  required?: boolean;
}

export const StorageAndShipping = ({
  formik,
  required,
}: StorageAndShippingProps) => {
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [t] = useTranslation("portal");
  const [tCommon] = useTranslation("common");
  const [tValidation] = useTranslation("validation");
  const formProps = getFormFieldProps(formik, t, tValidation);

  const handleCheckboxChange = (field: string) => (checked: boolean) => {
    formik.setFieldValue(field, checked);
  };

  const handleClickShowAccountNumber = () =>
    setShowAccountNumber((show) => !show);

  const handleMouseDownAccountNumber = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const useCorporateShippingAccount = formik.values.useCorporateShippingAccount;
  const shippingPreferencesOptions = useMemo(
    () =>
      SHIPPING_PREFERENCES.map((preferences) => ({
        ...preferences,
        label: t(preferences.label),
      })),
    [t]
  );

  const shippingPreferencesLabel = shippingPreferencesOptions.find(
    ({ value }) => value === formik.values.shippingPreferences
  ) || { label: "", value: "" };

  return (
    <section>
      <section className={styles.shippingDetails}>
        <section className={styles.shoppingAddressSection}>
          <p className={styles.shippingDetailsLabel}>
            {t("Shipping Details")}:
          </p>

          <p className={styles.shippingDetailsSubtitile}>
            {t("Save money by using your companies shipping account.")}
          </p>

          <ArtificeRadio
            checked={Boolean(useCorporateShippingAccount)}
            label={t("Use Corporate Shipping Account?")}
            onChange={handleCheckboxChange("useCorporateShippingAccount")}
          />

          {useCorporateShippingAccount ? (
            <section
              className={classNames(
                styles.twoMainColumns,
                styles.shippingAccountWrapper
              )}
            >
              <section className={styles.formRow}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  className={styles.twoColumns}
                  options={deliveryService}
                  value={formik.values.deliveryService}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("deliveryService", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...formProps("deliveryService")}
                      {...params}
                      required={required}
                      label={t("Delivery Service")}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                ></Autocomplete>
                <TextField
                  margin="normal"
                  required={required}
                  label={t("Shipping Account Number")}
                  className={classNames({
                    [styles.twoColumns]: true,
                    [styles.password]: !showAccountNumber,
                  })}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowAccountNumber}
                          onMouseDown={handleMouseDownAccountNumber}
                          edge="end"
                        >
                          {showAccountNumber ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...formProps(
                    "corporateShippingAccount",
                    t("Shipping Account Number")
                  )}
                />
              </section>
            </section>
          ) : null}
        </section>

        <p className={styles.shippingDetailsLabel}>{t("Ship To")}:</p>

        <section
          className={classNames(styles.formRow, styles.myCompanyWrapper)}
        >
          <section className={styles.twoMainColumns}>
            <TextField
              fullWidth
              margin="normal"
              autoComplete="companyName"
              required={required}
              label={tCommon("Company Name")}
              {...formProps("shipToCompanyName", tCommon("Company Name"))}
            />
            <section className={styles.formRow}>
              <TextField
                margin="normal"
                autoComplete="first-name"
                className={styles.twoColumns}
                required={required}
                label={tCommon("First Name")}
                {...formProps("shipToFirstName", tCommon("First Name"))}
              />
              <TextField
                margin="normal"
                autoComplete="last-name"
                className={styles.twoColumns}
                required={required}
                label={tCommon("Last Name")}
                {...formProps("shipToLastName", tCommon("Last Name"))}
              />
            </section>

            <TextField
              margin="normal"
              autoComplete="email"
              fullWidth
              required={required}
              className={styles.twoColumns}
              label={tCommon("Email Address")}
              {...formProps("shipToEmail", tCommon("Email Address"))}
            />

            <Phone
              required={required}
              phoneLabel={t("Mobile Number")}
              getFormProps={formProps}
              phoneFormKey="shipToMobileNumber"
              countryCodeKey="shipToMobileNumberCountryCode"
              countryCodeValue={formik.values?.shipToMobileNumberCountryCode}
              setFieldValue={formik.setFieldValue}
            />
          </section>

          <section className={styles.twoMainColumns}>
            <section className={styles.formRow}>
              <TextField
                margin="normal"
                autoComplete="street-1"
                className={styles.twoColumns}
                required={required}
                label={tCommon("Street 1")}
                {...formProps("shipToStreet1", tCommon("Street 1"))}
              />
              <TextField
                margin="normal"
                autoComplete="street-2"
                className={styles.twoColumns}
                label={tCommon("Street 2")}
                {...formProps("shipToStreet2", tCommon("Street 2"))}
              />
            </section>
            <section className={styles.formRow}>
              <TextField
                margin="normal"
                autoComplete="city"
                className={styles.twoColumns}
                required={required}
                label={tCommon("City")}
                {...formProps("shipToCity", tCommon("City"))}
              />
              <TextField
                margin="normal"
                autoComplete="country"
                className={styles.twoColumns}
                required={required}
                label={tCommon("Country")}
                {...formProps("shipToCountry", tCommon("Country"))}
              />
            </section>
            <section className={styles.formRow}>
              <TextField
                select
                margin="normal"
                autoComplete="state"
                required={required}
                className={styles.twoColumns}
                label={tCommon("State")}
                {...formProps("shipToState")}
              >
                {states.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <section className={styles.twoColumns}>
                <ZipCode
                  required={required}
                  label={t("Zip Code")}
                  formKey="shipToZip"
                  getFormProps={formProps}
                />
              </section>
            </section>
          </section>
        </section>

        <p className={styles.shippingDetailsLabel}>
          {t("Shipping Preferences")}:
        </p>

        <section className={styles.shippingPreferences}>
          <Autocomplete
            disablePortal
            disableClearable
            options={shippingPreferencesOptions}
            value={shippingPreferencesLabel}
            onChange={(event, newValue: IShippingPreferences) => {
              formik.setFieldValue("shippingPreferences", newValue.value);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...formProps("shippingPreferences")}
                  {...params}
                  required={required}
                  label={t("Shipping Preferences")}
                  margin="normal"
                  variant="outlined"
                />
              );
            }}
          ></Autocomplete>
        </section>

        {!useCorporateShippingAccount ? (
          <p className={styles.warningHint}>
            {t(
              "* Artifice uses commercial rates to ship product(s) based on ground shipping. Should you not provide a shipping account number, Artifice will use their account and bill you."
            )}
          </p>
        ) : null}
      </section>
    </section>
  );
};
