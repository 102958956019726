import { IOrganizationListItem } from "../interfaces";

export class Organization {
  static fromDTO(dto: IOrganizationListItem): Organization {
    const organization = new Organization();

    organization.id = dto.id;
    organization.type = dto.type;
    organization.companyName = dto.name;
    organization.industryType = dto.industryType;
    organization.department = dto.department;
    organization.street = dto.contactInformation?.street || "";
    organization.city = dto.contactInformation?.city || "";
    organization.zip = dto.contactInformation?.zip || "";
    organization.state = dto.contactInformation?.state || "";
    organization.email = dto.contactInformation?.email || "";
    organization.country = dto.contactInformation?.country || "";

    return organization;
  }

  id?: number;
  companyName = "";
  type: "department" | "organization" | null = null;
  industryType = "";
  department = "";
  street = "";
  city = "";
  zip = "";
  state = "";
  email = "";
  country = "";
}
