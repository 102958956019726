import { useEffect, useState } from "react";
import { PurchaseProfileService } from "../services";
import { Organization } from "../models";

export const useOrganizations = () => {
  const [userOrganization, setUserOrganization] = useState<Organization>(
    new Organization()
  );

  useEffect(() => {
    const getOrganizations = async () => {
      const organizations = await PurchaseProfileService.fetchOrganizations();
      const organization = organizations.find(
        ({ type }) => type === "organization"
      );
      const department = organizations.find(
        ({ type }) => type === "department"
      );

      if (department) {
        setUserOrganization(department);
      } else if (organization) {
        setUserOrganization(organization);
      }
    };

    getOrganizations();
  }, [setUserOrganization]);

  return { userOrganization };
};
