import { ContactUsFormValuesType } from "../schemas";
import { IContactUs } from "../../../common/interfaces";

export class ContactUsModel {
  static getInitialValues(): ContactUsFormValuesType {
    return {
      userName: "",
      companyName: "",
      industryType: "",
      title: "",
      phone: "",
      email: "",
      message: "",
    };
  }

  static fromForm(form: ContactUsFormValuesType): IContactUs {
    return {
      userName: form.userName,
      companyName: form.companyName,
      industryType: form.industryType,
      title: form.title,
      phone: form.phone,
      email: form.email,
      message: form.message,
    };
  }
}
