import React from "react";
import { useTranslation } from "react-i18next";
import { GridSortModel } from "@mui/x-data-grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ArtificeGrid } from "../../../../../common/components";
import { useUsersList } from "../../hooks";
import styles from "./style.module.scss";

export const UsersList = () => {
  const { t } = useTranslation("admin");

  const {
    rows,
    organization,
    organizationOptions,
    loading,
    columns,
    filters,
    sortModel,
    setOrganization,
    setSortModel,
  } = useUsersList();

  return (
    <>
      <section>
        <Autocomplete
          className={styles.filter}
          options={organizationOptions}
          autoHighlight
          value={organization}
          onChange={(event, newValue) => {
            setOrganization(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label={t("Organization")} />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
        />
      </section>

      <ArtificeGrid
        loading={loading}
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        filterModel={{ items: filters } as any}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
      />
    </>
  );
};
