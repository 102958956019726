import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { countries, ICountryType } from "../../../constants";
import { ArtificeMask } from "../../artifice-mask";
import styles from "./style.module.scss";

interface PhoneProps {
  phoneLabel: string;
  phoneFormKey: string;
  countryCodeKey: string;
  countryCodeValue: any;
  getFormProps: any;
  setFieldValue: (field: string, value: string) => void;
  required?: boolean;
  disabled?: boolean;
}

export const Phone = (props: PhoneProps) => {
  const { t } = useTranslation("common");
  const phoneProps = props.getFormProps(props.phoneFormKey);

  return (
    <FormControl classes={{ root: styles.phoneWrapper }}>
      <TextField
        label={t("Code")}
        select
        className={styles.countryRoot}
        disabled={props.disabled}
        required={props.required}
        value={props?.countryCodeValue || ""}
        onChange={(event) =>
          props.setFieldValue(props.countryCodeKey, event.target.value)
        }
      >
        {countries.map((option: ICountryType) => (
          <MenuItem key={option.code} value={option.phone}>
            <img
              className={styles.countryImage}
              loading="lazy"
              width="15"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            +{option.phone}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        margin="normal"
        label={props.phoneLabel}
        disabled={props.disabled}
        autoComplete="phone"
        {...phoneProps}
        helperText={t(phoneProps.helperText)}
        required={props.required}
        placeholder="xxx xxx xxxxx"
        InputProps={{
          inputProps: { mask: "000 000 00000000" },
          inputComponent: ArtificeMask,
        }}
      />
    </FormControl>
  );
};
