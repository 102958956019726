import {
  ProductOrderValidationFormValuesType,
  PurchaseFormValuesType,
} from "../schemas";
import {
  IDraftPurchase,
  IPurchase,
  IPurchasePurchaseProducts,
  IShippingInfo,
} from "../interfaces";
import {
  IProductItem,
  ProductPhotoProfileFormService,
  ProductPhotoProfileService,
  PurchaseProfileFormService,
} from "../../../common";
import {
  SHIP_BY_GROUND,
  SHIP_OVERNIGHT,
  TWO_DAYS_SHIPPING,
} from "../../../../../common";

export class PurchaseFormService {
  static initialProductOrder(): ProductOrderValidationFormValuesType {
    return {
      isProductOrderCompleted: false,
      productName: "",
      productUrl: "",
      quantity: "",
      unitPrice: "",
      productPhotoProfile: 0,
      description: "",
      color: "",
      size: "",
      notes: "",
      brand: undefined,

      // photo profile settings
      photoProfileSettings: {
        ...ProductPhotoProfileFormService.formInitialValues(),
        profileName: "INITIAL",
      },

      // order substitution
      moqRequired: false,
      maxBudget: "",
      moqRequiredNotes: "",
      substituteUnavailable: false,
      substituteUnavailableNotes: "",
      substituteSizeUnavailable: false,
      substituteSizeUnavailableNotes: "",
      substituteColorUnavailable: false,
      substituteColorUnavailableNotes: "",
      substituteQuantitiesUnavailable: false,
      substituteQuantitiesUnavailableNotes: "",
    };
  }

  static initialForm(purchase?: IDraftPurchase): PurchaseFormValuesType {
    return {
      isCaseInformationCompleted: false,
      artificeCaseNumber: purchase?.artificeCaseNumber,
      purchaseBrand: null,
      thirdPartySite: "",
      internalCaseNumber: "",
      status: purchase?.status,

      productOrders: [PurchaseFormService.initialProductOrder()],

      // Storage and Shipping
      isStorageAndShippingCompleted: false,
      shippingAndStorage: PurchaseProfileFormService.formInitialValues({
        isStorageAndShippingRequired: false,
        skipValidation: true,
      }),
      storeAtArtifice: true,
      notifyOfLeads: false,
    };
  }

  static async fromForm(
    purchaseForm: PurchaseFormValuesType
  ): Promise<IPurchase> {
    const purchaseProducts =
      await PurchaseFormService.toPurchasePurchaseProducts(
        purchaseForm.productOrders
      );

    const storeAtArtifice = Boolean(purchaseForm.storeAtArtifice);

    return {
      internalCaseNumber: purchaseForm.internalCaseNumber,
      purchaseBrand: purchaseForm.purchaseBrand || null,
      sellerRef: purchaseForm.thirdPartySite,
      notifyOfLeads: purchaseForm.notifyOfLeads,
      storeAtArtifice,
      shippingInfo: storeAtArtifice
        ? undefined
        : PurchaseFormService.toShippingInfo(purchaseForm),
      purchaseProducts,
    };
  }

  static toShippingInfo(
    purchaseForm: PurchaseFormValuesType
  ): Partial<IShippingInfo> {
    return {
      useShippingAccount: Boolean(
        purchaseForm.shippingAndStorage.useCorporateShippingAccount
      ),
      deliveryService: purchaseForm.shippingAndStorage.deliveryService,
      shippingAccountNumber:
        purchaseForm.shippingAndStorage.corporateShippingAccount,
      companyName: purchaseForm.shippingAndStorage.shipToCompanyName,
      firstName: purchaseForm.shippingAndStorage.shipToFirstName,
      lastName: purchaseForm.shippingAndStorage.shipToLastName,
      email: purchaseForm.shippingAndStorage.shipToEmail,
      phoneCountryCode:
        purchaseForm.shippingAndStorage.shipToMobileNumberCountryCode,
      phone: purchaseForm.shippingAndStorage.shipToMobileNumber,
      street1: purchaseForm.shippingAndStorage.shipToStreet1,
      street2: purchaseForm.shippingAndStorage.shipToStreet2,
      city: purchaseForm.shippingAndStorage.shipToCity,
      state: purchaseForm.shippingAndStorage.shipToState,
      zip: purchaseForm.shippingAndStorage.shipToZip,
      country: purchaseForm.shippingAndStorage.shipToCountry,
      shipOvernight:
        purchaseForm.shippingAndStorage.shippingPreferences === SHIP_OVERNIGHT,
      shipGround:
        purchaseForm.shippingAndStorage.shippingPreferences === SHIP_BY_GROUND,
      ship2Days:
        purchaseForm.shippingAndStorage.shippingPreferences ===
        TWO_DAYS_SHIPPING,
    };
  }

  static async toPurchasePurchaseProducts(
    productOrders: ProductOrderValidationFormValuesType[]
  ): Promise<IPurchasePurchaseProducts[]> {
    const productOrdersPromises = productOrders.map((productOrder) =>
      ProductPhotoProfileService.uploadAllPhotoExamples(
        productOrder.photoProfileSettings.productPackagingPhotoExamples,
        productOrder.photoProfileSettings.purchasedPhotoExamples
      ).then((packageItems) => ({
        ...productOrder,
        photoProfileSettings: {
          ...productOrder.photoProfileSettings,
          packageItems: packageItems.map((item) => ({
            ...item,
            id: undefined,
          })),
        },
      }))
    );
    const productOrdersWitPackageItems = await Promise.all(
      productOrdersPromises
    );

    return productOrdersWitPackageItems.map((productOrder, index) => ({
      orderInList: index,
      brand: productOrder?.brand,
      url: productOrder.productUrl,
      name: productOrder.productName,
      quantity: Number(productOrder.quantity),
      unitPrice: Number(productOrder.unitPrice),
      description: productOrder.description,
      color: productOrder.color,
      size: productOrder.size,
      notes: productOrder.notes,

      // order substitution
      moqRequired: Boolean(productOrder.moqRequired),
      moqNotes: productOrder.moqRequiredNotes,
      moqMaxBudget: productOrder.maxBudget
        ? Number(productOrder.maxBudget)
        : undefined,
      substituteUnavailable: Boolean(productOrder.substituteUnavailable),
      substituteUnavailableNotes: productOrder.substituteUnavailableNotes,
      substituteSizeUnavailable: Boolean(
        productOrder.substituteSizeUnavailable
      ),
      substituteSizeUnavailableNotes:
        productOrder.substituteSizeUnavailableNotes,
      substituteColorUnavailable: Boolean(
        productOrder.substituteColorUnavailable
      ),
      substituteColorUnavailableNotes:
        productOrder.substituteColorUnavailableNotes,
      substituteQuantitiesUnavailable: Boolean(
        productOrder.substituteQuantitiesUnavailable
      ),
      substituteQuantitiesUnavailableNotes:
        productOrder.substituteQuantitiesUnavailableNotes,

      profileName: productOrder.photoProfileSettings.profileName,
      listingPage: productOrder.photoProfileSettings.listingPage,
      orderConfirmation: productOrder.photoProfileSettings.orderConfirmation,
      checkoutReceipt: productOrder.photoProfileSettings.checkoutReceipt,
      screenShotNotes: productOrder.photoProfileSettings.screenShotsNotes || "",
      allDocsIncluded: productOrder.photoProfileSettings.allDocuments,
      docsReceiptMaterialNotes:
        productOrder.photoProfileSettings.documentsNotes || "",
      shippingPackage: productOrder.photoProfileSettings.shippingPackage,
      shippingLabels: productOrder.photoProfileSettings.shippingLabelsOnly,
      disposeOuterPackage:
        productOrder.photoProfileSettings.disposeOuterPackaging,
      outerPackageNotes:
        productOrder.photoProfileSettings.outerPackagingNotes || "",
      productPackageTop: productOrder.photoProfileSettings.productPackagingTop,
      productPackageTopNotes:
        productOrder.photoProfileSettings.productPackagingTopNotes || "",
      productPackageBottom:
        productOrder.photoProfileSettings.productPackagingBottom,
      productPackageBottomNotes:
        productOrder.photoProfileSettings.productPackagingBottomNotes || "",
      productPackageFront:
        productOrder.photoProfileSettings.productPackagingFront,
      productPackageFrontNotes:
        productOrder.photoProfileSettings.productPackagingFrontNotes || "",
      productPackageBack:
        productOrder.photoProfileSettings.productPackagingBack,
      productPackageBackNotes:
        productOrder.photoProfileSettings.productPackagingBackNotes || "",
      packageItems: productOrder.photoProfileSettings.packageItems,
    }));
  }

  static toForm(purchase?: IPurchase): PurchaseFormValuesType {
    return {
      isCaseInformationCompleted: true,
      artificeCaseNumber: purchase?.artificeCaseNumber || "",
      internalCaseNumber: purchase?.internalCaseNumber || "",
      purchaseBrand: purchase?.purchaseBrand || null,
      thirdPartySite: purchase?.sellerRef || "",
      status: purchase?.status || "",

      productOrders: !Array.isArray(purchase?.purchaseProducts)
        ? [PurchaseFormService.initialProductOrder()]
        : purchase!.purchaseProducts.map((purchaseProduct) => ({
            isProductOrderCompleted: true,
            productName: purchaseProduct.name || "",
            productUrl: purchaseProduct.url || "",
            quantity: purchaseProduct.quantity?.toString() || "",
            unitPrice: purchaseProduct.unitPrice?.toString() || "",
            productPhotoProfile: 0,
            description: purchaseProduct.description || "",
            color: purchaseProduct.color || "",
            size: purchaseProduct.size || "",
            notes: purchaseProduct.notes || "",
            brand: purchaseProduct.brand,

            // photo profile settings
            photoProfileSettings: {
              ...ProductPhotoProfileFormService.toForm({
                ...purchaseProduct,
                name: "",
                productItems:
                  (purchaseProduct.packageItems as IProductItem[]) || [],
              }),
              profileName: "INITIAL",
            },

            // order substitution
            moqRequired: purchaseProduct.moqRequired || false,
            maxBudget: purchaseProduct.moqMaxBudget?.toString() || "",
            moqRequiredNotes: purchaseProduct.moqNotes || "",
            substituteUnavailable:
              purchaseProduct.substituteUnavailable || false,
            substituteUnavailableNotes:
              purchaseProduct.substituteUnavailableNotes || "",
            substituteSizeUnavailable:
              purchaseProduct.substituteSizeUnavailable || false,
            substituteSizeUnavailableNotes:
              purchaseProduct.substituteSizeUnavailableNotes || "",
            substituteColorUnavailable:
              purchaseProduct.substituteColorUnavailable || false,
            substituteColorUnavailableNotes:
              purchaseProduct.substituteColorUnavailableNotes || "",
            substituteQuantitiesUnavailable:
              purchaseProduct.substituteQuantitiesUnavailable || false,
            substituteQuantitiesUnavailableNotes:
              purchaseProduct.substituteQuantitiesUnavailableNotes || "",
          })),

      isStorageAndShippingCompleted: true,
      notifyOfLeads: Boolean(purchase?.notifyOfLeads),
      storeAtArtifice: purchase?.storeAtArtifice ?? true,
      shippingAndStorage: {
        isStorageAndShippingRequired: false,
        useCorporateShippingAccount:
          purchase?.shippingInfo?.useShippingAccount ?? true,
        deliveryService: purchase?.shippingInfo?.deliveryService || "",
        corporateShippingAccount:
          purchase?.shippingInfo?.shippingAccountNumber || "",
        shipToCompanyName: purchase?.shippingInfo?.companyName || "",
        shipToFirstName: purchase?.shippingInfo?.firstName || "",
        shipToLastName: purchase?.shippingInfo?.lastName || "",
        shipToEmail: purchase?.shippingInfo?.email || "",
        shipToMobileNumber: purchase?.shippingInfo?.phone || "",
        shipToMobileNumberCountryCode:
          purchase?.shippingInfo?.phoneCountryCode || "1",
        shipToStreet1: purchase?.shippingInfo?.street1 || "",
        shipToStreet2: purchase?.shippingInfo?.street2 || "",
        shipToCity: purchase?.shippingInfo?.city || "",
        shipToState: purchase?.shippingInfo?.state || "",
        shipToZip: purchase?.shippingInfo?.zip || "",
        shipToCountry: purchase?.shippingInfo?.country || "",
        shippingPreferences: PurchaseFormService.toShippingPreferences(
          purchase?.shippingInfo
        ),
      },
    };
  }

  static toShippingPreferences(
    shippingInfo?: Partial<IShippingInfo>
  ): string | undefined {
    if (shippingInfo?.shipGround) {
      return SHIP_BY_GROUND;
    }

    if (shippingInfo?.shipOvernight) {
      return SHIP_OVERNIGHT;
    }

    if (shippingInfo?.ship2Days) {
      return TWO_DAYS_SHIPPING;
    }
  }
}
