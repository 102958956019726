import React from "react";
import { defer, createBrowserRouter, Outlet, Navigate } from "react-router-dom";
import { ArtificeModuleRoutesEnum } from "../common";
import { HomeLayout } from "../modules/landing";
import { AuthLayout, AuthRoutes } from "../modules/auth";
import { PortalRoutes } from "../modules/portal";
import Landing from "../modules/landing";
import { AdminRoutes } from "../modules/admin";
import { AuthRouteLoader, UserService } from "../modules/core";

export const Routes = createBrowserRouter([
  {
    path: ArtificeModuleRoutesEnum.Home,
    element: <HomeLayout />,
    children: [
      {
        element: <Outlet />,
        children: Landing,
      },
      {
        path: ArtificeModuleRoutesEnum.Auth,
        element: <AuthLayout />,
        children: AuthRoutes,
      },
    ],
  },
  {
    path: ArtificeModuleRoutesEnum.Portal,
    element: <AuthRouteLoader permission="hasPortalAccess" />,
    loader: () => defer({ userPromise: UserService.fetchUserProfile() }),
    children: PortalRoutes,
  },
  {
    path: ArtificeModuleRoutesEnum.Admin,
    element: <AuthRouteLoader permission="hasAdminAccess" />,
    loader: () => defer({ userPromise: UserService.fetchUserProfile() }),
    children: AdminRoutes,
  },
  {
    path: "*",
    element: <Navigate to={ArtificeModuleRoutesEnum.Home} replace />,
  },
]);
