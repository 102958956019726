import { useEffect, useState } from "react";
import { createFilterOptions } from "@mui/material";
import { IBrand, IBrandField } from "../../represented-brands/interfaces";
import { BrandsService } from "../../represented-brands/services";
import { useNotifications } from "../../../../../common";
import { BrandTypeEnum, PurchaseStatusEnum } from "../../../common/enums";
import { PurchaseFormType } from "../schemas";

export interface IBrandAdd extends IBrand {
  inputValue?: string;
}
export const useBrandField = (
  purchaseForm: PurchaseFormType,
  purchaseBrands: IBrand[],
  value: IBrandField | null,
  fieldName: string,
  index?: number
) => {
  const filter = createFilterOptions<IBrandAdd>();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { showAlert } = useNotifications();

  const onBrandChange = (_: any, newValue: any) => {
    if (typeof newValue === "string") {
      setModalOpen(true);
      purchaseForm.setFieldValue(fieldName + ".name", newValue);
    } else if (newValue && newValue.inputValue) {
      setModalOpen(true);
      purchaseForm.setFieldValue(fieldName + ".name", newValue.inputValue);
    } else {
      purchaseForm.setFieldValue(fieldName, newValue);
    }
  };

  const handleClose = () => {
    purchaseForm.setFieldValue(fieldName, null);
    setModalOpen(false);
  };

  const handleAdd = async () => {
    setLoading(true);
    try {
      if (value?.name) {
        const brandNameTrimed = value?.name.trim();
        const brand = await BrandsService.createEntity(brandNameTrimed);

        showAlert({
          text: "Successfully added a brand.",
          severity: "success",
        });
        return brand;
      }
    } catch (err) {
      showAlert({
        text: "Something went wrong, please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setModalOpen(false);
    }
  };

  useEffect(() => {
    const orgBrands = purchaseBrands.filter(
      (brand) => brand.type === BrandTypeEnum.ORGANIZATION
    );
    if (
      orgBrands.length === 1 &&
      purchaseForm.values.status === PurchaseStatusEnum.DRAFT &&
      !index
    ) {
      purchaseForm.setFieldValue(fieldName, orgBrands[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseBrands, index, purchaseForm.values.status]);

  useEffect(() => {
    if (
      index &&
      index > 0 &&
      !purchaseForm.values.productOrders[index].productName
    ) {
      purchaseForm.setFieldValue(
        fieldName,
        purchaseForm.values.productOrders[index - 1].brand
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, purchaseForm.values.status]);

  return {
    fieldName,
    modalOpen,
    loading,
    filter,
    handleAdd,
    handleClose,
    onBrandChange,
  };
};
