import { PurchaseFormValuesType } from "../schemas";
import { BrandTypeEnum } from "../../../common/enums";
import { BrandsService } from "../../represented-brands/services";
import { IBrandField } from "../../represented-brands/interfaces";

interface IValue {
  purchaseBrand?: boolean;
  productOrders: number[];
}

export const isNewSystemBrand = (brand?: IBrandField | null) => {
  return brand?.type === BrandTypeEnum.SYSTEM && brand?.name;
};

export const useCreateBrands = () => {
  const assignBrand = async (values: PurchaseFormValuesType) => {
    const newBrands = new Map<string, IValue>();

    if (isNewSystemBrand(values?.purchaseBrand)) {
      newBrands.set(values.purchaseBrand!.name!, {
        purchaseBrand: true,
        productOrders: [],
      });
    }

    values.productOrders.forEach((product, index) => {
      if (isNewSystemBrand(product?.brand)) {
        if (newBrands.has(product.brand!.name!)) {
          const existingBrand = newBrands.get(product.brand!.name!);
          existingBrand?.productOrders.push(index);
        } else {
          newBrands.set(product.brand!.name!, {
            productOrders: [index],
          });
        }
      }
    });

    const newBrandNames = Array.from(newBrands.keys());

    await Promise.all(
      newBrandNames.map(async (brandName) => {
        const purchaseAndProducts = newBrands.get(brandName);

        return BrandsService.createEntity(brandName).then((newBrand) => {
          if (purchaseAndProducts?.purchaseBrand) {
            values.purchaseBrand = newBrand;
          }

          purchaseAndProducts?.productOrders.forEach((index: number) => {
            values.productOrders[index].brand = newBrand;
          });
        });
      })
    );
  };

  return {
    assignBrand,
  };
};
