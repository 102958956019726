import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { ArtificeModal } from "../../../../../../common/components";
import { getFormFieldProps } from "../../../../../../common/utils";
import { ProductPhotoProfileFormType } from "../../interfaces";
import styles from "./style.module.scss";

interface PurchaseProfileSaveModalProps {
  open: boolean;
  handleClose: () => void;
  productPhotoProfileForm: ProductPhotoProfileFormType;
}

export const ProductPhotoProfileSaveModal = ({
  handleClose,
  open,
  productPhotoProfileForm,
}: PurchaseProfileSaveModalProps) => {
  const [t] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const formProps = getFormFieldProps(productPhotoProfileForm, t, tValidation);

  const handleSubmit = async () => {
    try {
      await productPhotoProfileForm.submitForm();
    } finally {
      handleClose();
    }
  };

  return (
    <ArtificeModal
      title={t("Name your Product Photo Profile")}
      open={open}
      onClose={handleClose}
      classes={{
        wrapper: styles.modalWrapper,
      }}
    >
      <section>
        <TextField
          fullWidth
          margin="normal"
          label={t("Product Name")}
          {...formProps("profileName", t("Product Name"))}
        />
        <section className={styles.modalFooterActions}>
          <Button variant="outlined" onClick={handleClose}>
            {t("Back")}
          </Button>
          <LoadingButton
            loading={productPhotoProfileForm.isSubmitting}
            disabled={!!productPhotoProfileForm.errors.profileName}
            variant="contained"
            onClick={handleSubmit}
          >
            {t("Save")}
          </LoadingButton>
        </section>
      </section>
    </ArtificeModal>
  );
};
