import React from "react";
import { useTranslation } from "react-i18next";
import SwipeableViews from "react-swipeable-views";
import {
  Alert,
  CircularProgress,
  Step,
  StepButton,
  Stepper,
} from "@mui/material";
import { HomeContainer, TabPanel } from "../../../../../../common/components";
import { useRegistration, useStepsSwitcher } from "../../hooks";
import { CompanyInformation } from "../company-information";
import { UserInformation } from "../user-information";
import { FormFooter } from "../form-footer";
import styles from "./style.module.scss";

export const Register = () => {
  const [t] = useTranslation("common");
  const {
    registerForm,
    error,
    loading,
    fromInvitation,
    invitationErrorMessage,
  } = useRegistration();
  const {
    steps,
    completed,
    activeStep,
    handleNext,
    handleBack,
    handleStep,
    isLastStep,
  } = useStepsSwitcher(registerForm);

  if (loading) {
    return <CircularProgress classes={{ root: styles.loader }} />;
  }

  if (invitationErrorMessage) {
    return (
      <p className={styles.invitationError}>{t(invitationErrorMessage)}</p>
    );
  }

  const errorMessages: { [key: string]: string } = {
    USER_EXISTS: "User with this email already exists.",
    EMAIL_NOT_ACCEPTABLE:
      "You cannot register with a private email address. Please enter your corporate email.",
  };

  const errorMessage =
    (error?.code && errorMessages[error?.code]) ||
    "Something went wrong. Please try again.";

  return (
    <HomeContainer>
      <section className={styles.registerWrapper}>
        <Stepper nonLinear activeStep={activeStep} className={styles.stepper}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {t(label)}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <section className={styles.registerContent}>
          <div className={styles.imgPreview} />

          <section className={styles.formWrapper}>
            <h1 className={styles.title}>{t("Register")}</h1>
            {error ? (
              <Alert severity="error" className={styles.errorWrapper}>
                {t(errorMessage)}
              </Alert>
            ) : null}

            <form onSubmit={registerForm.handleSubmit}>
              <SwipeableViews index={activeStep}>
                <TabPanel value={activeStep} index={0}>
                  <CompanyInformation
                    fromInvitation={fromInvitation}
                    registerForm={registerForm}
                  />
                </TabPanel>
                <TabPanel value={activeStep} index={1}>
                  <UserInformation
                    fromInvitation={fromInvitation}
                    registerForm={registerForm}
                  />
                </TabPanel>
              </SwipeableViews>

              <FormFooter
                handleBack={handleBack}
                handleNext={handleNext}
                activeStep={activeStep}
                registerForm={registerForm}
                isLastStep={isLastStep}
              />
            </form>
          </section>
        </section>
      </section>
    </HomeContainer>
  );
};
