import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GridValueFormatterParams,
  GridValueGetterParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { formatDisplayDate } from "../../../../../common/utils";
import { useNotifications } from "../../../../../common/";
import { PurchaseProfileService } from "../../services";
import { IPurchaseProfileListItem } from "../../interfaces";
import { ApmGridColDef } from "../../../../../common/components";

const defaultSorting: GridSortModel = [{ field: "dateUpdated", sort: "desc" }];

export const usePurchaseProfileList = () => {
  const { showAlert } = useNotifications();
  const { t } = useTranslation("portal");
  const [loading, setLoading] = useState<boolean>(true);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSorting);
  const [purchaseProfiles, setPurchaseProfiles] = useState<
    IPurchaseProfileListItem[]
  >([]);

  useEffect(() => {
    const getUserProfiles = async () => {
      setLoading(true);
      const profiles = await PurchaseProfileService.fetchPurchaseProfiles();
      setPurchaseProfiles(profiles);
      setLoading(false);
    };

    getUserProfiles();
  }, [setPurchaseProfiles]);

  const columns: ApmGridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t("Purchase Profile Name") as string,
        flex: 1,
        handleContentClick: true,
      },
      {
        field: "createdBy",
        headerName: t("Created By") as string,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.firstName} ${params.row.lastName}`,
      },
      {
        field: "dateUpdated",
        headerName: t("Date Updated") as string,
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) =>
          formatDisplayDate(params.value),
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      purchaseProfiles.map((profile) => ({
        id: profile.id,
        name: profile.name,
        lastName: profile.createdBy?.lastName || "",
        firstName: profile.createdBy.firstName || "",
        dateUpdated: new Date(profile.updatedAt),
        canDelete: profile.canDelete,
        canUpdate: profile.canUpdate,
      })),
    [purchaseProfiles]
  );

  const deleteProfile = async (id: number) => {
    try {
      await PurchaseProfileService.deleteEntity(id);
      setPurchaseProfiles((profiles) =>
        profiles.filter((profile) => profile.id !== id)
      );
      showAlert({
        text: t("Successfully deleted"),
        severity: "success",
      });
    } catch (err) {
      showAlert({
        text: t("There was an error. Please try again"),
        severity: "error",
      });
    }
  };

  return {
    rows,
    columns,
    loading,
    sortModel,
    purchaseProfiles,
    setSortModel,
    deleteProfile,
  };
};
