import axios from "../../../../common/api";
import { organizationsEndpoints, purchaseProfileEndpoints } from "../constants";
import { formatUrlWithPathParams } from "../../../../common/utils";
import {
  ICreatePurchaseProfile,
  IOrganizationListItem,
  IPurchaseProfile,
  IPurchaseProfileListItem,
} from "../interfaces";
import { Organization } from "../models";

export class PurchaseProfileService {
  static fetchPurchaseProfiles(): Promise<IPurchaseProfileListItem[]> {
    return axios
      .get(purchaseProfileEndpoints.entity)
      .then((response) => response.data);
  }

  static fetchEntityById(id: number): Promise<IPurchaseProfile> {
    return axios
      .get(formatUrlWithPathParams(purchaseProfileEndpoints.entityById, { id }))
      .then((response) => response.data);
  }

  static createPurchaseProfile(
    purchaseProfile: ICreatePurchaseProfile
  ): Promise<ICreatePurchaseProfile> {
    return axios
      .post(purchaseProfileEndpoints.entity, purchaseProfile)
      .then((response) => response.data);
  }

  static editPurchaseProfile(
    purchaseProfileId: number,
    purchaseProfile: ICreatePurchaseProfile
  ): Promise<ICreatePurchaseProfile> {
    return axios
      .put(
        formatUrlWithPathParams(purchaseProfileEndpoints.entityById, {
          id: purchaseProfileId,
        }),
        purchaseProfile
      )
      .then((response) => response.data);
  }

  static fetchOrganizations(): Promise<Organization[]> {
    return axios
      .get<IOrganizationListItem[]>(organizationsEndpoints.entity)
      .then((response) => response.data)
      .then((data) => data.map(Organization.fromDTO));
  }

  static deleteEntity(id: number) {
    return axios
      .delete(
        formatUrlWithPathParams(purchaseProfileEndpoints.entityById, { id })
      )
      .then((response) => response.data);
  }
}
