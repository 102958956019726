import { IDraftPurchase, IPurchase, IPurchaseListItem } from "../interfaces";
import { purchaseEndpoints } from "../../../common";
import axios from "../../../../../common/api";
import { formatUrlWithPathParams } from "../../../../../common/utils";

export class PurchaseService {
  static createEntity(): Promise<IDraftPurchase> {
    return axios
      .post(purchaseEndpoints.entity)
      .then((response) => response.data);
  }

  static updateEntity(id: number, purchase: IPurchase): Promise<IPurchase> {
    return axios
      .put(
        formatUrlWithPathParams(purchaseEndpoints.entityById, { id }),
        purchase
      )
      .then((response) => response.data);
  }

  static fetchEntities(): Promise<IPurchaseListItem[]> {
    return axios
      .get(purchaseEndpoints.entity)
      .then((response) => response.data);
  }

  static fetchEntityById(id: number): Promise<IPurchase> {
    return axios
      .get(formatUrlWithPathParams(purchaseEndpoints.entityById, { id }))
      .then((response) => response.data);
  }
}
