import React from "react";
import { useTranslation } from "react-i18next";
import { ServiceLayout } from "../../../../common/components";
import styles from "./style.module.scss";

export const OurServices = () => {
  const [t] = useTranslation("common");

  return (
    <section id="services" className={styles.servicesWrapper}>
      <section className={styles.headerWrapper}>
        <h2 className={styles.title}>{t("Our Services")}</h2>
      </section>
      <ServiceLayout />
    </section>
  );
};
