import React from "react";
import styles from "./style.module.scss";

interface SectionTitleProps {
  name: string;
  subName: string;
}

export const SectionTitle = ({ name, subName }: SectionTitleProps) => {
  return (
    <>
      {name} {subName ? " / " : ""}
      <span className={styles.subName}>{subName}</span>
    </>
  );
};
