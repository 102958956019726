import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Register } from "../register";

export const RegisterRoute = () => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
  >
    <Register />
  </GoogleReCaptchaProvider>
);
