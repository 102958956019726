import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import {
  Departments,
  IndustryType,
  Phone,
  State,
  ZipCode,
} from "../../../../../../../common/components";
import { getFormFieldProps } from "../../../../../../../common/utils";
import { PurchaseProfileFormType } from "../../../interfaces";
import styles from "./style.module.scss";

interface CompanyInformationProps {
  formik: PurchaseProfileFormType;
}

export const CompanyInformation = ({ formik }: CompanyInformationProps) => {
  const [t] = useTranslation("common");
  const [tPortal] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const formProps = getFormFieldProps(formik, tPortal, tValidation);

  const isBrand = formik.values.industryType === "brand";

  return (
    <section className={styles.formRow}>
      <div className={styles.twoMainColumns}>
        <TextField
          fullWidth
          margin="normal"
          autoComplete="company-name"
          label={t("Company Name")}
          {...formProps("companyName", t("Company Name"))}
        />

        <div className={styles.formRow}>
          <div className={styles.twoColumns}>
            <IndustryType formControlProps={formProps("industryType")} />
          </div>
          <div className={styles.twoColumns}>
            {isBrand ? (
              <Departments formControlProps={formProps("department")} />
            ) : null}
          </div>
        </div>

        <p className={styles.label}>{t("Company Address (HQ)")}</p>

        <section className={styles.formRow}>
          <TextField
            margin="normal"
            size="medium"
            autoComplete="street"
            className={styles.twoColumns}
            label={t("Street")}
            {...formProps("companyStreet", t("Street"))}
          />
          <TextField
            margin="normal"
            size="medium"
            autoComplete="city"
            className={styles.twoColumns}
            label={t("City")}
            {...formProps("companyCity", t("City"))}
          />
        </section>

        <section className={styles.formRow}>
          <div className={styles.twoColumns}>
            <State formControlProps={formProps("companyState")} />
          </div>

          <div className={styles.twoColumns}>
            <ZipCode
              label={t("Zip Code")}
              formKey="companyZip"
              getFormProps={formProps}
            />
          </div>
        </section>
      </div>

      <div className={styles.twoMainColumns}>
        <div className={styles.formRow}>
          <TextField
            margin="normal"
            autoComplete="first-name"
            className={styles.twoColumns}
            label={t("First Name")}
            {...formProps("firstName", t("First Name"))}
          />

          <TextField
            margin="normal"
            autoComplete="last-name"
            className={styles.twoColumns}
            label={t("Last Name")}
            {...formProps("lastName", t("Last Name"))}
          />
        </div>

        <TextField
          fullWidth
          margin="normal"
          autoComplete="email"
          label={t("Email Address")}
          {...formProps("email", t("Email Address"))}
        />

        <Phone
          phoneLabel={t("Direct Work Number")}
          getFormProps={formProps}
          phoneFormKey="directWorkNumber"
          countryCodeKey="directWorkNumberCountryCode"
          countryCodeValue={formik.values?.directWorkNumberCountryCode}
          setFieldValue={formik.setFieldValue}
        />
        <Phone
          phoneLabel={t("Mobile Number")}
          getFormProps={formProps}
          phoneFormKey="mobilePhoneNumber"
          countryCodeKey="mobilePhoneNumberCountryCode"
          countryCodeValue={formik.values?.mobilePhoneNumberCountryCode}
          setFieldValue={formik.setFieldValue}
        />
      </div>
    </section>
  );
};
