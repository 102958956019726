import { paymentsEndpoint } from "../../../common";
import axios from "../../../../../common/api";
import { formatUrlWithPathParams } from "../../../../../common/utils";
import { IPaymentMethod, IPaymentProfileForm } from "../interfaces";

export class PaymentsService {
  static getProfileForm(purchaseId: number): Promise<IPaymentProfileForm> {
    return axios
      .get(
        formatUrlWithPathParams(paymentsEndpoint.profileForm, { purchaseId })
      )
      .then((response) => response.data);
  }

  static getPaymentMethods(): Promise<IPaymentMethod[]> {
    return axios
      .get(paymentsEndpoint.profiles)
      .then((response) => response.data);
  }

  static authorizePurchase(
    purchaseId: number,
    paymentProfileId: number
  ): Promise<unknown> {
    return axios
      .post(
        formatUrlWithPathParams(paymentsEndpoint.authorizePurchase, {
          purchaseId,
          paymentProfileId,
        })
      )
      .then((response) => response.data);
  }

  static async requestPurchaseInvoice(purchaseId: number): Promise<unknown> {
    return axios
      .post(
        formatUrlWithPathParams(paymentsEndpoint.requestPurchaseInvoice, {
          purchaseId,
        })
      )
      .then((response) => response.data);
  }
}
