export const organizationsEndpoints = {
  entity: "/api/v1/users/organizations",
};

export const purchaseEndpoints = {
  entity: "/api/v1/purchases",
  entityById: "/api/v1/purchases/:id",
};

export const paymentsEndpoint = {
  profileForm: "/api/v1/payments/purchases/:purchaseId/profile-form",
  profiles: "/api/v1/payments/profiles",
  authorizePurchase:
    "/api/v1/payments/profiles/:paymentProfileId/purchases/:purchaseId/authorize",
  requestPurchaseInvoice:
    "/api/v1/payments/purchases/:purchaseId/invoices/request",
};

export const purchaseProfileEndpoints = {
  entity: "/api/v1/purchase-profiles",
  entityById: "/api/v1/purchase-profiles/:id",
};

export const productPhotoEndpoints = {
  entity: "/api/v1/product-photo-profiles",
  entityById: "/api/v1/product-photo-profiles/:id",
  fileLinkMetadata: "/api/v1/files/links/:linkId/metadata",
  fileLink: "/api/v1/files/links/:linkId",
};

export const brandEndpoints = {
  entity: "/api/v1/brands",
};

export const systemBrandEndpoints = {
  entity: "/api/v1/system-brands",
};

export const fileEndpoints = {
  uploadProductImage: "/api/v1/files/product-image",
};

export const umEndpoints = {
  entity: "/api/v1/um/organizations/users",
  invitation: "/api/v1/um/invitations",
  declineInvitation: "/api/v1/um/invitations/:invitationId/decline",
  suspend: "/api/v1/um/users/:userId/suspend",
  activate: "/api/v1/um/users/:userId/activate",
};
