import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { PurchaseSchema, PurchaseFormValuesType } from "../schemas";
import { PurchaseFormService, PurchaseService } from "../services";
import { IDraftPurchase } from "../interfaces";
import {
  useProductPhotoProfileList,
  usePurchaseProfileList,
} from "../../../common";
import { ArtificeRoutes, useNotifications } from "../../../../../common";
import { useCreateBrands } from "./use-create-brands";

export const useCreatePurchase = () => {
  const [t] = useTranslation("portal");
  const [isLoading, setIsLoading] = useState(true);
  const [purchase, setPurchase] = useState<IDraftPurchase>();
  const { productPhotoProfiles } = useProductPhotoProfileList();
  const { purchaseProfiles } = usePurchaseProfileList();
  const { assignBrand } = useCreateBrands();
  const { showAlert } = useNotifications();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    PurchaseService.createEntity().then((response) => {
      setPurchase(response);
      setIsLoading(false);
    });
  }, []);

  const initialValues = useMemo(
    () => PurchaseFormService.initialForm(purchase),
    [purchase]
  );

  const handleCheckout = async (values: PurchaseFormValuesType) => {
    if (!purchase) {
      return;
    }
    try {
      await assignBrand(values);

      const purchaseToUpdate = await PurchaseFormService.fromForm(values);

      await PurchaseService.updateEntity(purchase.id, purchaseToUpdate);

      showAlert({
        text: t("Successfully created"),
        severity: "success",
      });
      navigate(`${ArtificeRoutes.purchases}/${purchase.id}/summary`);
    } catch (err) {
      showAlert({
        text: t("There was an error. Please try again"),
        severity: "error",
      });
    }
  };

  const purchaseForm = useFormik({
    validationSchema: PurchaseSchema,
    validateOnMount: true,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: handleCheckout,
  });

  return {
    purchaseForm,
    isLoading,
    productPhotoProfiles,
    purchaseProfiles,
  };
};
