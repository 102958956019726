import { string } from "yup";
import { maxLength, maxLengthMessage } from "./max-length.const";

const numberRegex = /^[+\d]+$/i;

export const streetValidation = string().max(
  maxLength.street,
  maxLengthMessage.max50
);

export const cityValidation = string().max(
  maxLength.city,
  maxLengthMessage.max50
);

export const countryValidation = string().max(
  maxLength.country,
  maxLengthMessage.max255
);

export const shoppingAccountNumberValidation = string().max(
  maxLength.shoppingAccountNumber,
  maxLengthMessage.max20
);

export const purchaseProfileNameValidation = string().max(
  maxLength.purchaseProfileName,
  maxLengthMessage.max255
);

export const zipValidation = string()
  .matches(numberRegex, "Only digits are allowed")
  .min(5, "Please, enter minimum 5 digits")
  .max(9, "Maximum digits - 9");
