import React, { useMemo } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Button, Paper } from "@mui/material";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { CustomPagination } from "../grid-pagination";
import { ArtificeGridProps } from "./artifice-grid-interfaces";
import styles from "./style.module.scss";

export const ArtificeGrid = (props: ArtificeGridProps) => {
  const { t } = useTranslation("common");

  const columns = useMemo(() => {
    if (!(props.canDelete || props.customAction)) {
      return props.columns;
    }

    const [last, ...restColumns] = [...props.columns].reverse();
    const firstColumns = [...restColumns].reverse();

    const columnsWithAction = firstColumns.map((col) =>
      col.handleContentClick
        ? {
            ...col,
            renderCell({ value, row }: GridRenderCellParams) {
              return (
                <div
                  className={styles.cellWithAction}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    props.onCellContentClick?.(row);
                  }}
                >
                  {value}
                </div>
              );
            },
          }
        : col
    );

    const lastColumn = props.skipActions
      ? last
      : {
          ...last,
          renderCell(gridParams: GridRenderCellParams) {
            return (
              <>
                <section>
                  {last?.renderCell ? (
                    last.renderCell(gridParams)
                  ) : (
                    <span>{gridParams.formattedValue}</span>
                  )}
                </section>
                <section className={styles.btnsWrapper}>
                  {gridParams.row.customAction ? (
                    <Button
                      size="small"
                      variant="outlined"
                      color={gridParams.row.customAction.color}
                      className={classnames(styles.deleteBtn, styles.customBtn)}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        props.onAction?.(gridParams.row);
                      }}
                    >
                      {gridParams.row.customAction.text}
                    </Button>
                  ) : null}
                  {gridParams.row.canDelete ? (
                    <Button
                      size="small"
                      color="error"
                      variant="outlined"
                      className={styles.deleteBtn}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        props.onDelete?.(gridParams.row);
                      }}
                    >
                      {t("Delete")}
                    </Button>
                  ) : null}
                </section>
              </>
            );
          },
        };

    return [...columnsWithAction, lastColumn];
  }, [t, props]);

  return (
    <Paper>
      <DataGrid
        classes={{
          main: styles.main,
          cell: styles.gridCell,
          row: styles.gridRow,
        }}
        loading={props.loading}
        filterModel={props.filterModel}
        sortingOrder={["asc", "desc"]}
        autoHeight
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        disableColumnFilter
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        slots={{ pagination: CustomPagination }}
        pageSizeOptions={[10, 20, 40]}
        {...props}
        columns={columns}
      />
    </Paper>
  );
};
