import React from "react";
import { Paper } from "@mui/material";
import {
  ContactUsModal,
  ServiceLayout,
} from "../../../../../../common/components";
import styles from "./style.module.scss";

export const Services = () => {
  return (
    <Paper className={styles.viewWrapper}>
      <ServiceLayout />
      <ContactUsModal />
    </Paper>
  );
};
