import { useEffect, useMemo, useState } from "react";
import { AdminService } from "../services";
import { statusesMap } from "../constants";
import { useNotifications } from "../../../../common";

export const usePurchaseStatus = () => {
  const { showAlert } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseStatuses, setPurchaseStatuses] = useState<string[]>();

  useEffect(() => {
    setIsLoading(true);
    AdminService.fetchPurchaseStatuses()
      .then((response) => {
        setPurchaseStatuses(response);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const updateStatus = async (
    id: number,
    status: string,
    sendEmail: boolean
  ) => {
    try {
      await AdminService.updatePurchaseStatus(Number(id), status, sendEmail);
      showAlert({
        text: "Successfully updated.",
        severity: "success",
      });
    } catch (err) {
      showAlert({
        text: "Something went wrong, please try again.",
        severity: "error",
      });
    }
  };

  const mappedPurchaseStatuses = useMemo(
    () =>
      purchaseStatuses?.map((status) => ({
        value: status,
        title: statusesMap.get(status) || "",
      })),
    [purchaseStatuses]
  );

  return {
    isLoading,
    mappedPurchaseStatuses,
    updateStatus,
  };
};
