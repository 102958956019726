import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Button } from "@mui/material";
import {
  ArtificeAccordionDetails,
  ArtificeAccordionSummary,
} from "../../../../../../common/components";
import { PurchaseProfileFormType } from "../../interfaces";
import { CompanyInformation, StorageAndShipping, Leads } from "../sections";
import { PurchaseProfileSaveModal } from "../purchase-profile-save-modal";
import { usePurchaseProfileAccordion } from "../../hooks/use-purchase-profile-accordion";
import styles from "./style.module.scss";

interface PurchaseProfileFormProps {
  profileForm: PurchaseProfileFormType;
  editMode?: boolean;
}

export const PurchaseProfileForm = ({
  profileForm,
  editMode = false,
}: PurchaseProfileFormProps) => {
  const {
    contactInformation,
    leads,
    storageAndShipping,
    isCompanyInformationInvalid,
    isStorageAnsShippingInvalid,
    isContactInformationExpanded,
    isStorageAndShippingExpanded,
    isLeadsExpanded,
    handleChange,
    handleNext,
  } = usePurchaseProfileAccordion(profileForm);
  const [open, setOpen] = useState(false);
  const [t] = useTranslation("portal");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <form onSubmit={profileForm.handleSubmit}>
        {/* Company Information */}
        <Accordion
          expanded={isContactInformationExpanded}
          onChange={handleChange(contactInformation)}
        >
          <ArtificeAccordionSummary
            variant="filled"
            title={t("Company Information")}
            error={Boolean(isCompanyInformationInvalid)}
            completed={profileForm.values.companyInformationCompleted}
          />

          <ArtificeAccordionDetails
            currentSection={contactInformation}
            nextSection={storageAndShipping}
            handleNext={handleNext}
          >
            <CompanyInformation formik={profileForm} />
          </ArtificeAccordionDetails>
        </Accordion>

        {/* Storage And Shipping */}
        <Accordion
          expanded={isStorageAndShippingExpanded}
          onChange={handleChange(storageAndShipping)}
        >
          <ArtificeAccordionSummary
            variant="filled"
            title={t("Storage & Shipping Questions")}
            error={Boolean(isStorageAnsShippingInvalid)}
            completed={profileForm.values.isStorageCompleted}
          />

          <ArtificeAccordionDetails
            currentSection={storageAndShipping}
            nextSection={leads}
            handleNext={handleNext}
          >
            <StorageAndShipping formik={profileForm} />
          </ArtificeAccordionDetails>
        </Accordion>

        {/* Lead s*/}
        <Accordion expanded={isLeadsExpanded} onChange={handleChange(leads)}>
          <ArtificeAccordionSummary
            variant="filled"
            title={t("Leads")}
            completed={profileForm.values.isLeadsCompleted}
          />
          <ArtificeAccordionDetails
            currentSection={leads}
            handleNext={handleNext}
          >
            <Leads formik={profileForm} />
          </ArtificeAccordionDetails>
        </Accordion>
      </form>

      <section className={styles.footer}>
        <section>
          <Button variant="contained" onClick={handleOpen}>
            {t("Save & Complete")}
          </Button>
        </section>
      </section>

      <PurchaseProfileSaveModal
        open={open}
        purchaseProfileForm={profileForm}
        handleClose={handleClose}
      />
    </>
  );
};
