import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridSortModel, GridValueGetterParams } from "@mui/x-data-grid";
import { IUserListItem } from "../interfaces";
import { AdminService } from "../services";
import { ApmGridColDef } from "../../../../common/components";
import { useOrganizationsList } from "./use-organizations-list";

const defaultSorting: GridSortModel = [{ field: "name", sort: "asc" }];

const classMap = new Map([
  ["PENDING", "cell-blue-text"],
  ["APPROVED", "cell-green-text"],
  ["SUSPENDED", "cell-light-grey"],
  ["Invitation Sent", "cell-blue-background"],
  ["Registration In Progress", "cell-blue-background"],
]);

type IFilterOption = {
  value: string;
  label: string;
} | null;

export const useUsersList = () => {
  const { t } = useTranslation("admin");
  const [organization, setOrganization] = useState<IFilterOption>();
  const [loading, setLoading] = useState<boolean>(true);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSorting);
  const [users, setUsers] = useState<IUserListItem[]>([]);
  const { organizations } = useOrganizationsList();

  const organizationOptions: any = useMemo(
    () =>
      organizations
        .map((org) => ({ value: org.name, label: org.name }))
        .sort((a, b) => {
          const labelA = a.label.toLowerCase();
          const labelB = b.label.toLowerCase();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }),
    [organizations]
  );

  useEffect(() => {
    const getAllUsers = async () => {
      setLoading(true);
      const response = await AdminService.fetchAllUsers();
      setUsers(response);
      setLoading(false);
    };

    getAllUsers();
  }, [setUsers]);

  const columns: ApmGridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t("Name") as string,
        flex: 1,
        handleContentClick: true,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.firstName} ${params.row.lastName}`,
      },
      {
        field: "email",
        headerName: t("Email") as string,
        flex: 1,
      },
      {
        field: "orgName",
        headerName: t("Organization") as string,
        flex: 1,
      },
      {
        field: "role",
        headerName: t("Role") as string,
        flex: 1,
      },
      {
        field: "status",
        headerName: t("Status") as string,
        cellClassName: (cell) => cell.row.class,
        flex: 1,
      },
    ],
    [t]
  );

  const statusMap = useMemo(
    () =>
      new Map([
        ["PENDING", t("Pending")],
        ["APPROVED", t("Active")],
        ["SUSPENDED", t("Suspended")],
        ["Invitation Sent", t("Invited")],
        ["Registration In Progress", t("Invited")],
      ]),
    [t]
  );

  const rows = useMemo(
    () =>
      users.map((user) => ({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        orgName: user.orgName,
        role: t(user.role),
        status: statusMap.get(user.status),
        class: classMap.get(user.status),
      })),
    [users, t, statusMap]
  );

  const filters = useMemo(
    () =>
      [
        organization
          ? { field: "orgName", operator: "equals", value: organization.value }
          : null,
      ].filter(Boolean),
    [organization]
  );

  return {
    rows,
    columns,
    loading,
    sortModel,
    filters,
    organization,
    organizationOptions,
    setOrganization,
    setSortModel,
  };
};
