import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import styles from "./style.module.scss";

interface ShippingProps {
  notifyOfLeads?: boolean;
  isPurchaseSection?: boolean;
}

export const LeadsView = ({
  notifyOfLeads,
  isPurchaseSection,
}: ShippingProps) => {
  const [t] = useTranslation("portal");

  const yes = t("Yes");
  const no = t("No");

  return (
    <>
      <section className={styles.sectionContent}>
        <section>
          <span
            className={classnames(
              styles.listItemName,
              isPurchaseSection ? styles.listItemNameBold : null
            )}
          >
            {t("Can we notify you of leads related to your Brand?")}
          </span>
          <span
            className={classnames(
              styles.listItemValue,
              isPurchaseSection ? styles.listItemValueNormal : null
            )}
          >
            {" - "}
            {notifyOfLeads ? yes : no}
          </span>
        </section>
      </section>
    </>
  );
};
