import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import styles from "../register/style.module.scss";
import { RegistrationFormType } from "../../schemas";

interface FormFooterProps {
  handleBack: () => void;
  handleNext: () => void;
  activeStep: number;
  registerForm: RegistrationFormType;
  isLastStep: () => boolean;
}

export const FormFooter = ({
  handleBack,
  handleNext,
  activeStep,
  registerForm,
  isLastStep,
}: FormFooterProps) => {
  const [t] = useTranslation("common");

  return (
    <section className={styles.footerWrapper}>
      <section className={styles.actionWrapper}>
        {activeStep ? (
          <Button
            type="button"
            size="large"
            variant="outlined"
            onClick={handleBack}
            startIcon={<WestIcon />}
          >
            {t("Back")}
          </Button>
        ) : null}
        {isLastStep() ? (
          <LoadingButton
            loading={registerForm.isSubmitting}
            size="large"
            variant="contained"
            type="button"
            onClick={() => {
              handleNext();
              registerForm.submitForm();
            }}
          >
            {t("Register")}
          </LoadingButton>
        ) : (
          <Button
            type="button"
            size="large"
            variant="outlined"
            onClick={handleNext}
            className={styles.nextBtn}
            startIcon={<EastIcon />}
          >
            {t("Next")}
          </Button>
        )}
      </section>
    </section>
  );
};
