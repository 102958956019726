import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentsService } from "../services";
import { IPaymentMethod, IPaymentProfileForm } from "../interfaces";
import { ArtificeRoutes, useNotifications } from "../../../../../common";

export const usePayments = () => {
  const [t] = useTranslation("portal");
  const { id } = useParams();
  const navigate = useNavigate();
  const [checkoutInProgress, setCheckoutInProgress] = useState(false);
  const [payByInvoiceInProgress, setPayByInvoiceInProgress] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [activePayment, setActivePayment] = useState<number>();
  const [profileForm, setProfileForm] = useState<IPaymentProfileForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const { showAlert } = useNotifications();

  const handlePaymentChange = (paymentId: number) => {
    setActivePayment(paymentId);
  };

  const addPaymentMethod = async () => {
    if (id) {
      const response = await PaymentsService.getProfileForm(Number(id));
      setProfileForm(response);

      return response;
    }
  };

  const handleCheckout = async () => {
    if (id && activePayment) {
      try {
        setCheckoutInProgress(true);
        await PaymentsService.authorizePurchase(Number(id), activePayment);
        navigate(ArtificeRoutes.successPurchase);
      } catch (err: any) {
        showAlert({
          text:
            err?.response?.data?.message ||
            t("There was an error. Please try again"),
          severity: "error",
        });
      } finally {
        setCheckoutInProgress(false);
      }
    } else if (paymentMethods?.length < 1) {
      showAlert({
        text: "Please add credit card",
        severity: "error",
      });
    } else if (!activePayment) {
      showAlert({
        text: "Please select credit card",
        severity: "error",
      });
    }
  };

  const handlePayByInvoice = async () => {
    if (id) {
      try {
        setPayByInvoiceInProgress(true);
        await PaymentsService.requestPurchaseInvoice(Number(id));
        navigate(ArtificeRoutes.successPurchase);
      } catch (err) {
        showAlert({
          text: "There was an error. Please try again",
          severity: "error",
        });
      } finally {
        setPayByInvoiceInProgress(false);
      }
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = () => {
    setLoading(true);
    PaymentsService.getPaymentMethods().then((response) => {
      setPaymentMethods(response);
      setLoading(false);
    });
  };

  return {
    paymentMethods,
    activePayment,
    profileForm,
    checkoutInProgress,
    payByInvoiceInProgress,
    loading,
    fetchPayments,
    handlePaymentChange,
    handlePayByInvoice,
    addPaymentMethod,
    handleCheckout,
  };
};
