import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GridRowHeightParams,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import {
  Autocomplete,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ArtificeGrid } from "../../../../../common/components";
import { useBrandsList } from "../../hooks";
import {
  AddSystemBrand,
  BrandTypeSelect,
  BrandTypeSelectProps,
  OrganizationNameChip,
  EditBrandModal,
} from "..";
import { BrandFilterEnum } from "../../enums";
import { ConfirmModal } from "../../../../portal";
import { IBrandListItem } from "../../interfaces";
import styles from "./style.module.scss";

export const brandsFilter = [
  {
    value: BrandFilterEnum.REPRESENTED,
    label: "Represented by Organization",
  },
  { value: BrandFilterEnum.SYSTEM, label: "System Brands" },
  { value: "ALL", label: "All Brands" },
];

export const BrandsList = () => {
  const { t } = useTranslation("admin");
  const [deleteItem, setDeleteItem] = useState<IBrandListItem | null>(null);
  const [editItem, setEditItem] = useState<IBrandListItem | null>(null);

  const {
    rows,
    columns,
    filter,
    loading,
    organization,
    organizationOptions,
    sortModel,
    setSortModel,
    setOrganization,
    getBrands,
    setFilter,
    handleDelete,
    handleEdit,
    onChangeBrandType,
  } = useBrandsList();

  const onDelete = (row: GridValidRowModel) => {
    setDeleteItem(row as IBrandListItem);
  };

  const onConfirmDelete = async () => {
    if (deleteItem) {
      await handleDelete(deleteItem);
      await getBrands();
    }
    setDeleteItem(null);
  };

  const onCloseDelete = () => {
    setDeleteItem(null);
  };

  const onAction = (row: GridValidRowModel) => {
    setEditItem(row as IBrandListItem);
  };

  const onConfirmEdit = async (newBrandName: string) => {
    if (editItem) {
      await handleEdit(editItem, newBrandName);
      await getBrands();
    }
    setEditItem(null);
  };

  const onCloseEdit = () => {
    setEditItem(null);
  };

  const columnsWithChipAndDropDown = useMemo(
    () =>
      columns.map((column) => {
        return column.field === "orgNames"
          ? {
              ...column,
              renderCell: OrganizationNameChip,
            }
          : column.field === "brandType"
          ? {
              ...column,
              renderCell: (cellParams: BrandTypeSelectProps) => (
                <BrandTypeSelect {...cellParams} onChange={onChangeBrandType} />
              ),
            }
          : column;
      }),
    [columns, onChangeBrandType]
  );

  return (
    <>
      <AddSystemBrand reloadList={getBrands} />
      <section className={styles.row}>
        <Select
          className={styles.filter}
          value={filter}
          onChange={(event: SelectChangeEvent) => {
            setFilter(event.target.value);
          }}
        >
          {brandsFilter.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {t(option.label)}
            </MenuItem>
          ))}
        </Select>
        <Autocomplete
          autoHighlight
          className={styles.orgFilter}
          options={organizationOptions}
          value={organization}
          onChange={(event, newValue) => {
            setOrganization(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label={t("Organization")} />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.value.toString()}>
              {option.label}
            </li>
          )}
        />
      </section>
      <ArtificeGrid
        canDelete
        customAction
        onAction={onAction}
        loading={loading}
        rows={rows}
        columns={columnsWithChipAndDropDown}
        sortModel={sortModel}
        onDelete={onDelete}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
        getRowHeight={(params: GridRowHeightParams) => {
          return params.model?.orgNames && params.model?.orgNames.length > 0
            ? "auto"
            : 52;
        }}
      />
      <ConfirmModal
        open={Boolean(deleteItem)}
        title={t("Delete System Brand")}
        confirmText={t("Delete System Brand")}
        confirmActionText={"Delete"}
        itemName={deleteItem?.brandName}
        onClose={onCloseDelete}
        onConfirm={onConfirmDelete}
      />
      <EditBrandModal
        open={Boolean(editItem)}
        row={editItem}
        onClose={onCloseEdit}
        onConfirm={onConfirmEdit}
      />
    </>
  );
};
