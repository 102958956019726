import axios from "../../../../common/api";
import { fileEndpoints } from "../constants";
import { FileError } from "../../../../common/utils";

export class FileService {
  static async uploadProductImage(file: File): Promise<number> {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        fileEndpoints.uploadProductImage,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      return response.data.id;
    } catch (err) {
      throw new FileError(file.name);
    }
  }
}
