import { useTranslation } from "react-i18next";
import { IPurchase, IShippingViewInfo } from "../interfaces";
import { formatPhoneNumber } from "../../../../../common/utils";
import {
  SHIP_BY_GROUND,
  SHIP_OVERNIGHT,
  TWO_DAYS_SHIPPING,
  states,
} from "../../../../../common";

const defaultValue = "-";

export const usePurchaseInfo = (purchase?: IPurchase) => {
  const [t] = useTranslation("portal");

  const state = states.find(
    ({ id }) => id === purchase?.shippingInfo?.state
  )?.name;

  const shippingPreference = purchase?.shippingInfo?.ship2Days
    ? TWO_DAYS_SHIPPING
    : purchase?.shippingInfo?.shipGround
    ? SHIP_BY_GROUND
    : purchase?.shippingInfo?.shipOvernight
    ? SHIP_OVERNIGHT
    : defaultValue;

  const caseInfoList = [
    [
      {
        name: t("Artifice Case Number"),
        value: purchase?.artificeCaseNumber || defaultValue,
      },
    ],
    [
      {
        name: t("Purchase on Behalf of"),
        value: purchase?.purchaseBrand?.name || defaultValue,
      },
    ],
    [
      {
        name: t("Internal Case Number"),
        value: purchase?.internalCaseNumber || defaultValue,
      },
    ],
  ];

  const sellerInfo = [
    [
      {
        name: t("Seller/Third Party Site"),
        value: purchase?.sellerRef || defaultValue,
      },
    ],
  ];

  const shippingInfo: IShippingViewInfo = {
    storeAtArtifice: purchase?.storeAtArtifice || false,
    useShippingAccount: purchase?.shippingInfo?.useShippingAccount || false,
    deliveryService: purchase?.shippingInfo?.deliveryService || defaultValue,
    shippingAccountNumber:
      purchase?.shippingInfo?.shippingAccountNumber || defaultValue,
    companyName: purchase?.shippingInfo?.companyName || defaultValue,
    firstName: purchase?.shippingInfo?.firstName || defaultValue,
    lastName: purchase?.shippingInfo?.lastName || defaultValue,
    email: purchase?.shippingInfo?.email || defaultValue,
    phoneNumber:
      formatPhoneNumber(
        purchase?.shippingInfo?.phoneCountryCode,
        purchase?.shippingInfo?.phone
      ) || defaultValue,
    street1: purchase?.shippingInfo?.street1 || defaultValue,
    street2: purchase?.shippingInfo?.street2 || defaultValue,
    city: purchase?.shippingInfo?.city || defaultValue,
    state: state || defaultValue,
    zip: purchase?.shippingInfo?.zip || defaultValue,
    country: purchase?.shippingInfo?.country || defaultValue,
    shippingPreference: shippingPreference,
  };

  return { caseInfoList, sellerInfo, shippingInfo };
};
