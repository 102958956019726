import { useMemo, useState } from "react";
import { useFormik } from "formik";
import useDebounce from "../../../../../common/hooks/use-debounce";
import {
  IPurchaseProfile,
  PurchaseProfileFormService,
  PurchaseProfileService,
} from "../../../common";
import { PurchaseProfilePageSchema } from "../../purchase-profile/schemas";
import { PurchaseFormType } from "../schemas";

export const useStorageAndShippingSettings = (
  purchaseForm?: PurchaseFormType
) => {
  const [profile, setProfile] = useState<IPurchaseProfile>();

  const initialValues = useMemo(() => {
    if (profile) {
      return PurchaseProfileFormService.toForm(
        profile,
        !profile.storeAtArtifice
      );
    }

    if (purchaseForm?.initialValues.shippingAndStorage) {
      return {
        ...PurchaseProfileFormService.formInitialValues(),
        ...purchaseForm.initialValues.shippingAndStorage,
        skipValidation: purchaseForm?.initialValues.storeAtArtifice,
      };
    }

    return PurchaseProfileFormService.formInitialValues({
      isStorageAndShippingRequired: purchaseForm?.initialValues.storeAtArtifice,
      skipValidation: purchaseForm?.initialValues.storeAtArtifice,
    });
  }, [
    profile,
    purchaseForm?.initialValues.shippingAndStorage,
    purchaseForm?.initialValues.storeAtArtifice,
  ]);

  const profileForm = useFormik({
    validationSchema: PurchaseProfilePageSchema,
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: () => {},
  });

  const profileFormValues = useDebounce(profileForm.values, 300);

  const fetchProfile = async (id: number) => {
    const data = await PurchaseProfileService.fetchEntityById(Number(id));
    profileForm.resetForm();
    setProfile(data);
  };

  return {
    profileForm,
    profileFormValues,
    fetchProfile,
  };
};
