import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";

import { ArtificeRoutes } from "../../../../../../common";
import { useEmailVerification } from "../../hooks";
import { HomeContainer } from "../../../../../../common/components";
import registrationImage from "../../../../../../assets/img/register.png";
import styles from "./style.module.scss";

export const VerifyEmail = () => {
  const [t] = useTranslation("common");
  const { isVerified, errorMessage } = useEmailVerification();

  return (
    <HomeContainer>
      <section className={styles.registerSuccessWrapper}>
        <img
          src={registrationImage}
          alt="registration-success"
          className={styles.registerSuccessImg}
        />
        <section className={styles.description}>
          {!isVerified && !errorMessage ? (
            <p className={styles.title}>
              {t("Please hold a moment, we are verifying your email...")}
            </p>
          ) : null}

          {errorMessage ? <p className={styles.error}>{errorMessage}</p> : null}

          {isVerified ? (
            <section className={styles.successWrapper}>
              <p className={styles.successTitle}>
                {t("Your email address is verified")}
              </p>
              <p className={styles.successSubTitle}>
                {t(
                  "Thank you for joining the Artifice Global Community. You now have access to the Artifice Global System."
                )}
              </p>
              <Button
                component={NavLink}
                to={ArtificeRoutes.login}
                size="large"
                variant="contained"
              >
                {t("Log In")}
              </Button>
            </section>
          ) : null}
        </section>
      </section>
    </HomeContainer>
  );
};
