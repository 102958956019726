import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { ArtificeModal } from "../../../../../../common/components";
import { PurchaseFormType } from "../../schemas";
import { usePurchasePreview } from "../../hooks";
import { PurchaseInformation } from "../purchase-information";
import styles from "./style.module.scss";

interface PreviewPurchaseModalProps {
  open: boolean;
  handleClose: () => void;
  purchaseForm: PurchaseFormType;
}

export const PreviewPurchaseModal = ({
  open,
  purchaseForm,
  handleClose,
}: PreviewPurchaseModalProps) => {
  const [t] = useTranslation("portal");

  const { purchase } = usePurchasePreview(purchaseForm);

  return (
    <ArtificeModal
      title={t("Purchase Preview")}
      open={open}
      onClose={handleClose}
      classes={{
        wrapper: styles.modalWrapper,
      }}
      footer={
        <section className={styles.previewFooter}>
          <Button
            className={styles.closeButton}
            variant="outlined"
            onClick={handleClose}
          >
            {t("Close")}
          </Button>
        </section>
      }
    >
      <section className={styles.previewWrapper}>
        <PurchaseInformation purchase={purchase} />
      </section>
    </ArtificeModal>
  );
};
