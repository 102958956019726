import React from "react";
import { useTranslation } from "react-i18next";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { CreditCardIcon } from "../credit-card-icon";
import { CreditCardTypeEnum } from "../../enums";

interface PaymentItemProps {
  cardType?: CreditCardTypeEnum;
  cardNumber?: string;
}
export const PaymentItem = ({ cardType, cardNumber }: PaymentItemProps) => {
  const { t } = useTranslation("common");

  return (
    <>
      <p>
        <b>{t("Payment Method")}</b>
      </p>
      <List>
        <ListItem disablePadding>
          <ListItemIcon>
            {cardType ? <CreditCardIcon type={cardType} /> : null}
          </ListItemIcon>
          <ListItemText
            primary={
              <span>{t("Card number: {{cardNumber}}", { cardNumber })}</span>
            }
          />
        </ListItem>
      </List>
    </>
  );
};
