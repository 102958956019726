import React from "react";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";
import { useAddSystemBrand } from "../../hooks";
import { ConfirmModal } from "../../../../portal";
import styles from "./style.module.scss";

interface AddSystemBrandProps {
  reloadList: () => Promise<void>;
}

export const AddSystemBrand = ({ reloadList }: AddSystemBrandProps) => {
  const [t] = useTranslation("admin");

  const {
    name,
    loading,
    modalOpen,
    handleModalOpen,
    handleModalClose,
    handleNameChange,
    handleAddSystemBrand,
  } = useAddSystemBrand();

  return (
    <>
      <section className={styles.addBrandWrapper}>
        <TextField
          classes={{
            root: styles.inputRoot,
          }}
          label={t("System Brand")}
          value={name}
          onChange={handleNameChange}
        />
        <Button
          disabled={!name}
          className={styles.button}
          variant="contained"
          size="medium-m"
          onClick={handleModalOpen}
        >
          {t("Add System Brand")}
        </Button>
      </section>
      <ConfirmModal
        open={modalOpen}
        actionInProgress={loading}
        confirmText={t("Do you want to add System Brand")}
        title={t("Add System Brand")}
        confirmActionText={t("Add")}
        itemName={name}
        onClose={handleModalClose}
        onConfirm={async () => {
          await handleAddSystemBrand();
          await reloadList();
        }}
      />
    </>
  );
};
