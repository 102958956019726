import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { GridSortModel, GridValidRowModel } from "@mui/x-data-grid";
import { ArtificeGrid } from "../../../../../../common/components";
import { useOrganizationUsersList } from "../../hooks";
import { Invite } from "../../components";
import { ConfirmModal, PageTitle } from "../../../../common";
import { IUseGridItem } from "../../interfaces";
import { UserActionEnum } from "../../enums";

export const UserManagementList = () => {
  const [confirmItem, setConfirmItem] = useState<IUseGridItem | null>();
  const [t] = useTranslation("portal");
  const {
    loading,
    rows,
    columns,
    sortModel,
    fetchUsers,
    setSortModel,
    handleAction,
  } = useOrganizationUsersList();

  const onAction = (row: GridValidRowModel) => {
    setConfirmItem(row as IUseGridItem);
  };
  const handleClose = () => {
    setConfirmItem(null);
  };

  const handleConfirm = () => {
    if (confirmItem) {
      handleAction(confirmItem);
    }
    setConfirmItem(null);
  };

  const confirmText = useMemo(() => {
    if (!confirmItem?.customAction?.type) {
      return "";
    }

    switch (confirmItem.customAction.type) {
      case UserActionEnum.Suspend:
        return (
          <Trans
            ns="portal"
            i18nKey="userSuspend"
            values={{ email: confirmItem.email }}
          />
        );
      case UserActionEnum.Activate:
        return (
          <Trans
            ns="portal"
            i18nKey="userActivate"
            values={{ email: confirmItem.email }}
          />
        );
      case UserActionEnum.DeclineInvitation:
        return (
          <Trans
            ns="portal"
            i18nKey="userDeclineInvitation"
            values={{ email: confirmItem.email }}
          />
        );
      default:
        return "";
    }
  }, [confirmItem]);

  return (
    <>
      <PageTitle title={t("User Management")} />
      <Invite reloadList={fetchUsers} />
      <ArtificeGrid
        customAction
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        loading={loading}
        onAction={onAction}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
      />
      <ConfirmModal
        open={Boolean(confirmItem)}
        title={confirmItem?.customAction?.confirmTitle}
        confirmText={confirmText}
        confirmActionText={confirmItem?.customAction?.confirmAction}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};
