import React from "react";
import { Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PricingItem } from "../../components";
import { pricing, pricingHeaders } from "../../constants";
import { ContactUsModal } from "../../../../../../common/components";
import styles from "./style.module.scss";

export const Pricing = () => {
  const [t] = useTranslation("portal");

  return (
    <Paper className={styles.viewWrapper}>
      <Grid
        id="services"
        container
        rowSpacing={0}
        columnSpacing={{ xs: 1, sm: 2, md: 0 }}
      >
        {pricingHeaders.map((header) => {
          return (
            <Grid xs={4}>
              <section className={styles.headerWrapper}>
                <p className={styles.header}>{t(header)}</p>
              </section>
            </Grid>
          );
        })}

        {pricing.map((pricingRow) => {
          return (
            <>
              {pricingRow.map((pricingItem, index) => {
                return (
                  <Grid xs={4}>
                    {pricingItem?.title && pricingItem?.price ? (
                      <PricingItem
                        key={index}
                        title={t(pricingItem.title)}
                        description={pricingItem?.description}
                        price={pricingItem.price}
                      />
                    ) : null}
                  </Grid>
                );
              })}
            </>
          );
        })}
      </Grid>
      <ContactUsModal />
    </Paper>
  );
};
