import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GridCellParams,
  GridComparatorFn,
  GridSortModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useNotifications } from "../../../../../common";
import { ApmGridColDef } from "../../../../../common/components";
import { UserManagementService } from "../services";
import { IUseGridItem, IUserListItem } from "../interfaces";
import { UserActionEnum } from "../enums";

const defaultSorting: GridSortModel = [{ field: "firstName", sort: "asc" }];

const classMap = new Map([
  ["PENDING", "cell-blue-text"],
  ["APPROVED", "cell-green-text"],
  ["SUSPENDED", "cell-light-grey"],
  ["Invitation Sent", "cell-blue-background"],
  ["Registration In Progress", "cell-blue-background"],
]);

export const useOrganizationUsersList = () => {
  const { t } = useTranslation("portal");
  const [loading, setLoading] = useState<boolean>(true);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSorting);
  const [users, setUsers] = useState<IUserListItem[]>([]);
  const { showAlert } = useNotifications();

  const fetchUsers = async () => {
    setLoading(true);
    const profiles = await UserManagementService.fetchEntities();
    setUsers(profiles);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [setUsers]);

  const getCellClass = (cell: GridCellParams): string => {
    return cell.row._status === "SUSPENDED" ? "cell-light-grey" : "";
  };

  const sortStringsComparator: GridComparatorFn = useCallback(
    (a, b) => {
      const order = sortModel[0].sort;

      if (a === "" && b === "") {
        return 0;
      }

      if (a === "") {
        return order === "asc" ? 1 : -1;
      }

      if (b === "") {
        return order === "asc" ? -1 : 1;
      }

      return a.localeCompare(b);
    },
    [sortModel]
  );

  const columns: ApmGridColDef[] = useMemo(
    () => [
      {
        field: "firstName",
        headerName: t("User Name") as string,
        flex: 1,
        handleContentClick: true,
        cellClassName: getCellClass,
        sortComparator: sortStringsComparator,
        valueGetter: (params: GridValueGetterParams) =>
          [params.row.firstName, params.row.lastName].filter(Boolean).join(" "),
      },
      {
        field: "email",
        headerName: t("Email") as string,
        cellClassName: getCellClass,
        flex: 1,
      },
      {
        field: "role",
        headerName: t("Role") as string,
        cellClassName: getCellClass,
        flex: 1,
      },
      {
        field: "status",
        headerName: t("Status") as string,
        cellClassName: (cell) => cell.row.class,
        flex: 1,
      },
    ],
    [t, sortStringsComparator]
  );

  const getAction = useCallback(
    (user: IUserListItem) => {
      if (user.canDecline) {
        return {
          type: UserActionEnum.DeclineInvitation,
          text: t("Cancel Invite"),
          confirmTitle: t("Cancel Invite"),
          confirmAction: t("Confirm"),
          color: "error",
        };
      }

      if (user.canActivate) {
        return {
          type: UserActionEnum.Activate,
          confirmTitle: t("Activate"),
          confirmAction: t("Activate"),
          text: t("Activate"),
        };
      }

      if (user.canSuspend) {
        return {
          type: UserActionEnum.Suspend,
          text: t("Suspend"),
          confirmTitle: t("Suspend"),
          confirmAction: t("Suspend"),
          color: "error",
        };
      }
    },
    [t]
  );

  const statusMap = useMemo(
    () =>
      new Map([
        ["PENDING", t("Pending")],
        ["APPROVED", t("Active")],
        ["SUSPENDED", t("Suspended")],
        ["Invitation Sent", t("Invited")],
        ["Registration In Progress", t("Invited")],
      ]),
    [t]
  );

  const rows = useMemo(
    () =>
      users.map((user) => ({
        id: `${user.id}-${user.email}`,
        entityId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: t(user.role),
        _status: user.status,
        status: statusMap.get(user.status),
        class: classMap.get(user.status),
        canDelete: false,
        canUpdate: false,
        customAction: getAction(user),
      })),
    [users, t, statusMap, getAction]
  );

  const proceedActionWithUser = async (row: IUseGridItem) => {
    switch (row.customAction?.type) {
      case UserActionEnum.Suspend:
        return await UserManagementService.deactivateUser(row.entityId);
      case UserActionEnum.Activate:
        return await UserManagementService.activateUser(row.entityId);
      case UserActionEnum.DeclineInvitation:
        return await UserManagementService.declineInvitation(row.entityId);
    }
  };
  const handleAction = async (row: IUseGridItem) => {
    try {
      await proceedActionWithUser(row);
      await fetchUsers();
      showAlert({
        text: "Successfully updated",
        severity: "success",
      });
    } catch (err) {
      showAlert({
        text: t("There was an error. Please try again"),
        severity: "error",
      });
    }
  };

  return {
    rows,
    columns,
    loading,
    sortModel,
    fetchUsers,
    setSortModel,
    handleAction,
  };
};
