import { useState } from "react";
import { RegistrationFormType } from "../schemas";

const steps = ["Company Information", "User Information"];

export const useStepsSwitcher = (registerForm: RegistrationFormType) => {
  const [activeStep, setActiveStep] = useState(0);

  const [completed] = useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = async () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? await findStepWithError()
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const findStepWithError = async () => {
    const errors = await registerForm.validateForm();

    if (!Object.keys(errors).length) {
      return activeStep;
    }

    const firstSectionRequiredFields = [
      "industryType",
      "department",
      "companyName",
      "companyStreet",
      "companyCity",
      "companyState",
      "companyZip",
      "workStreet",
      "workCity",
      "workState",
      "workZip",
    ];

    if (
      firstSectionRequiredFields.some(
        (field) => (errors as Record<string, object>)?.[field]
      )
    ) {
      return 0;
    }

    return 1;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return {
    completed,
    handleStep,
    isLastStep,
    steps,
    activeStep,
    handleNext,
    handleBack,
  };
};
