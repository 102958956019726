import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  ArtificeCheckbox,
  ArtificeModal,
} from "../../../../../common/components";
import { usePurchaseStatus, usePurchaseStatusHistory } from "../../hooks";
import styles from "./style.module.scss";

interface PurchaseStatus {
  value: string;
  title: string;
}

interface ConfirmPurchaseStatusModalProps {
  id?: string;
  open: boolean;
  toPurchaseStatus?: PurchaseStatus;
  refetchPurchase: () => void;
  setToPurchaseStatus: (status?: PurchaseStatus) => void;
}
export const ConfirmPurchaseStatusModal = ({
  id,
  open,
  toPurchaseStatus,
  refetchPurchase,
  setToPurchaseStatus,
}: ConfirmPurchaseStatusModalProps) => {
  const { t } = useTranslation("admin");
  const [loading, setLoading] = useState<boolean>(false);
  const [sendEmail, setSendEmail] = useState<boolean>(false);

  const { updateStatus } = usePurchaseStatus();
  const { purchaseStatusHistoryMap, getPurchaseStatusHistory } =
    usePurchaseStatusHistory(id);

  const handleCheckboxChange = () => {
    setSendEmail(!sendEmail);
  };

  const handleStatusUpdateConfirm = async () => {
    if (!id || !toPurchaseStatus?.value) {
      return;
    }
    setLoading(true);
    await updateStatus(Number(id), toPurchaseStatus.value, sendEmail);
    await Promise.all([refetchPurchase(), getPurchaseStatusHistory()]);
    setLoading(false);
    setToPurchaseStatus(undefined);
  };

  const handleStatusUpdateClose = () => setToPurchaseStatus(undefined);

  useEffect(() => {
    const defaultValue =
      toPurchaseStatus?.value &&
      purchaseStatusHistoryMap.get(toPurchaseStatus?.value)?.emailSent;
    setSendEmail(!defaultValue);
  }, [purchaseStatusHistoryMap, toPurchaseStatus?.value]);

  return (
    <ArtificeModal
      title={t("Confirm")}
      open={open}
      onClose={handleStatusUpdateClose}
    >
      <section>
        <p className={styles.content}>
          {t("Do you want to change purchase status to")}
          <span className={styles.itemTitle}> {toPurchaseStatus?.title}</span>?
        </p>
        <section className={styles.checkboxWrapper}>
          <ArtificeCheckbox
            label={t("Send email notification")}
            checked={sendEmail}
            onChange={handleCheckboxChange}
          />
        </section>

        <section className={styles.modalFooterActions}>
          <Button
            variant="outlined"
            size="medium"
            onClick={handleStatusUpdateClose}
          >
            {t("Cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={handleStatusUpdateConfirm}
            loading={loading}
          >
            {t("Confirm")}
          </LoadingButton>
        </section>
      </section>
    </ArtificeModal>
  );
};
