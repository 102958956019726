import {
  Gavel,
  GroupsOutlined,
  ManageSearchOutlined,
  ShoppingBagOutlined,
  Source,
  Storage,
  SupportAgentOutlined,
} from "@mui/icons-material";

export const services = [
  {
    xs: 6,
    title: "Tech Platform for Online Test Purchases",
    icon: <ShoppingBagOutlined fontSize="large" />,
    text: "Complete a test purchase in less than 3 minutes, from almost any website or e-comm site in the world, using our technology platform. Don’t waste time with follow up emails. Test purchases includes: The Purchase: Listing Page, Order Summary, Checkout Page and Receipt screenshots. Delivery: Product pick up from any venue. Photos of packaging & product. Full Report: A detailed purchase report with recommended next steps post open-source info evaluation.",
  },
  {
    xs: 6,
    title: "Storage / Retrieval / Destruction",
    icon: <Storage fontSize="large" />,
    text: "Using electronic evidence tracking system, store purchased product with full transparency of what is being stored, where it is being stored, how long it’s been in storage. With the click of a button order the products delivered to the location of your choosing or request the product be destroyed. Never wonder again what your exposure is, related to stored products.",
  },
  {
    xs: 4,
    title: "Consulting Services",
    icon: <SupportAgentOutlined fontSize="large" />,
    text: "Consulting services can be an invaluable part of any program. Whether assisting in program or case strategy development, program development, ROI, gap assessments, or building cases for criminal and civil prosecution let Artifice help to develop your world-class approach to challenges.",
  },
  {
    xs: 4,
    title: "Managed Investigation",
    icon: <ManageSearchOutlined fontSize="large" />,
    text: "Enforcement moves at lightning pace. There are so many moving parts to successfully develop investigations into successes. Take the guess work out of investigations, use our managed investigations services by people who have a proven record for success in bringing the results you need. This service looks at the entire body of all of your investigations, sifts through the noise to find the most meaningful cases to move forward. The service can be something as small as a bi-weekly review of investigation reports and recommendations for next steps or management of the investigative function of your program.",
  },
  {
    xs: 4,
    title: "Secondments",
    icon: <GroupsOutlined fontSize="large" />,
    text: "Need an established Brand Protection Professional to fill a vacancy or a temporary leave? Artifice can provide proven professionals at all levels. Don’t get behind, bring in someone that can hit the ground running. Program management, Online, investigations, evidence processing. You name it, we have someone that can support your needs.",
  },
  {
    xs: 6,
    title: "Depositions & Expert Witness Testimony",
    icon: <Gavel fontSize="large" />,
    text: "Court testimony or a resulting deposition is a frequent result of a test purchase. Having an internal team member serve as a deponent of trial witness can be time consuming, expensive, stressful, and costly for organizations. Artifice’s team has served as deponents and expert witnesses in a variety of criminal and civil matters throughout the United States. Let Artifice handle everything up to and including expert testimony and deposition without your program skipping a beat.",
  },
  {
    xs: 6,
    title: "Intelligence Development",
    icon: <Source fontSize="large" />,
    text: "Intelligence is a critical part of any enforcement program. Without a clear method for intelligence collection and review it is unlikely that any program will have a meaningful method of targeting the most egregious offenders. Without that targeting method, it is likely that programs remain strictly reactionary rather than proactively addressing the problem.  Let Artifice, with its years of experience in the public and private sector, help develop meaningful intelligence that ties into your global strategy.",
  },
];
