import { useState } from "react";
import { useFormik } from "formik";
import { contactUsSchema } from "../schemas";
import { ContactUsModel } from "../services";
import { LandingService } from "../../../common/services";
import { ContactUsStatusEnum } from "../../../common/enums";

export const useContactUs = () => {
  const [status, setStatus] = useState(ContactUsStatusEnum.None);

  const contactUsForm = useFormik({
    validationSchema: contactUsSchema,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues: ContactUsModel.getInitialValues(),

    onSubmit: async (values) => {
      try {
        await LandingService.contactUs(ContactUsModel.fromForm(values));
        setStatus(ContactUsStatusEnum.Success);
      } catch (err) {
        setStatus(ContactUsStatusEnum.Error);
      }
    },
  });

  return { contactUsForm, status };
};
