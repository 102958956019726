import React, { createContext, ReactNode, useContext, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

interface INotification {
  text: string;
  severity: "error" | "success";
}

interface NotificationsProviderProps {
  children: ReactNode;
}
interface IUseNotifications {
  showAlert: (alert: INotification) => void;
}

const NotificationsContext = createContext({} as IUseNotifications);

export const NotificationsProvider = ({
  children,
}: NotificationsProviderProps) => {
  const [notification, setNotification] = useState<INotification>();

  const clearNotifications = () => setNotification(undefined);

  const showAlert = (alert: INotification) => {
    setNotification(alert);
  };

  return (
    <NotificationsContext.Provider value={{ showAlert }}>
      {children}
      <Snackbar
        open={Boolean(notification)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={clearNotifications}
      >
        <Alert
          onClose={clearNotifications}
          severity={notification?.severity}
          sx={{ width: "100%" }}
        >
          {notification?.text}
        </Alert>
      </Snackbar>
    </NotificationsContext.Provider>
  );
};

export const useNotifications = (): IUseNotifications =>
  useContext(NotificationsContext);
