import React from "react";
import { Customization } from "../../customization";
import { ProductPhotoProfileFormType } from "../../../interfaces";
import { MAX_PURCHASED_PRODUCT_PHOTO_EXAMPLE } from "../../../constants";

interface PurchasedItemProps {
  formik: ProductPhotoProfileFormType;
}

export const PurchasedItem = ({ formik }: PurchasedItemProps) => (
  <Customization
    form={formik}
    field="purchasedPhotoExamples"
    max={MAX_PURCHASED_PRODUCT_PHOTO_EXAMPLE}
  />
);
