import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

interface ITextMaskCustom {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const ArtificeMask = forwardRef<HTMLInputElement, ITextMaskCustom>(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        inputRef={ref}
        onAccept={(_, mask) =>
          onChange({
            target: { name: props.name, value: mask.unmaskedValue },
          })
        }
        overwrite
      />
    );
  }
);
