import React from "react";
import { Button, Paper } from "@mui/material";
import { NavLink } from "react-router-dom";
import styles from "./style.module.scss";

interface SuccessViewProps {
  title: string;
  subtitle: string;
  secondSubtitle?: string;
  buttonText?: string;
  buttonTo?: string;
}

export const SuccessView = ({
  title,
  subtitle,
  secondSubtitle,
  buttonText,
  buttonTo,
}: SuccessViewProps) => (
  <section className={styles.successWrapper}>
    <Paper classes={{ root: styles.wrapper }}>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
      {secondSubtitle && (
        <section className={styles.secondSubtitleWrapper}>
          <p className={styles.subtitle}>{secondSubtitle}</p>
        </section>
      )}
      {buttonTo && buttonText && (
        <Button
          size="medium"
          variant="contained"
          to={buttonTo}
          component={NavLink}
        >
          {buttonText}
        </Button>
      )}
    </Paper>
  </section>
);
