import { useTranslation } from "react-i18next";
import { ProductPhotoProfile } from "../../../common";
import { useProductPhotoAttachments } from "./use-product-photo-attachments";

export const useProductPhotoProfileInfo = (profile?: ProductPhotoProfile) => {
  const [t] = useTranslation("portal");
  const { productPhotos, productPackagingPhotos } =
    useProductPhotoAttachments(profile);

  const yes = t("Yes");
  const no = t("No");

  const screenShoots = [
    [
      {
        name: t("Listing Page"),
        value: profile?.listingPage ? yes : no,
      },
      {
        name: t("Order Confirmation"),
        value: profile?.orderConfirmation ? yes : no,
      },
      {
        name: t("Checkout Receipt"),
        value: profile?.checkoutReceipt ? yes : no,
      },
    ],
  ];

  const documents = [
    [
      {
        name: "All Documents included on or in package",
        value: profile?.allDocsIncluded ? yes : no,
        useTrans: true,
      },
    ],
  ];

  const outerPackaging = [
    [
      {
        name: t("Photo of whole package"),
        value: profile?.shippingPackage ? yes : no,
      },
      {
        name: t("Shipping Labels Only"),
        value: profile?.shippingLabels ? yes : no,
      },
      {
        name: t("Can we dispose of the outer packaging once photographed?"),
        value: profile?.disposeOuterPackage ? yes : no,
      },
    ],
  ];

  const productPackaging = [
    [
      {
        name: t("Top"),
        value: profile?.productPackageTop ? yes : no,
        notes: profile?.productPackageTopNotes,
      },
      {
        name: t("Bottom"),
        value: profile?.productPackageBottom ? yes : no,
        notes: profile?.productPackageBottomNotes,
      },
      {
        name: t("Front"),
        value: profile?.productPackageFront ? yes : no,
        notes: profile?.productPackageFrontNotes,
      },
      {
        name: t("Back"),
        value: profile?.productPackageBack ? yes : no,
        notes: profile?.productPackageBackNotes,
      },
    ],
  ];

  return {
    productPhotos,
    productPackagingPhotos,
    screenShoots,
    documents,
    outerPackaging,
    productPackaging,
  };
};
