import axios from "../../../common/api";
import { authEndpoints } from "../../auth/common";
import { profileEndpoints } from "../constants";
import { User } from "../models";
import { IUser } from "../interfaces";

export class UserService {
  static fetchUserProfile(): Promise<User> {
    return axios
      .get<IUser>(profileEndpoints.profile)
      .then((response) => User.fromDTO(response.data));
  }

  static logout() {
    return axios.post(authEndpoints.logout);
  }
}
