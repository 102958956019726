import axios from "../../../../../common/api";
import { umEndpoints } from "../../../common";
import { IUserListItem } from "../interfaces";
import { formatUrlWithPathParams } from "../../../../../common/utils";

export class UserManagementService {
  static fetchEntities(): Promise<IUserListItem[]> {
    return axios.get(umEndpoints.entity).then((response) => response.data);
  }

  static inviteUser(email: string) {
    return axios.post(umEndpoints.invitation, { email }).catch((err) => {
      throw err.response.data;
    });
  }

  static declineInvitation(invitationId: number) {
    return axios.post(
      formatUrlWithPathParams(umEndpoints.declineInvitation, { invitationId })
    );
  }

  static deactivateUser(userId: number) {
    return axios.post(formatUrlWithPathParams(umEndpoints.suspend, { userId }));
  }

  static activateUser(userId: number) {
    return axios.post(
      formatUrlWithPathParams(umEndpoints.activate, { userId })
    );
  }
}
