import axios from "../../../../../common/api";
import { brandEndpoints } from "../../../common";
import { BrandTypeEnum } from "../../../common/enums";
import { IBrand } from "../interfaces";

export class BrandsService {
  static async fetchEntities(): Promise<IBrand[]> {
    const data = await axios
      .get(brandEndpoints.entity)
      .then((response) => response.data);

    return data.map((brand: IBrand) => {
      return { ...brand, type: BrandTypeEnum.ORGANIZATION };
    });
  }

  static async createEntity(name: string): Promise<IBrand> {
    return axios
      .post(brandEndpoints.entity, { name })
      .then((response) => response.data);
  }
}
