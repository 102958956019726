import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GridSortModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { formatDisplayDate } from "../../../../../common/utils";
import { useNotifications } from "../../../../../common";
import { ProductPhotoProfileService } from "../../services";
import { ApmGridColDef } from "../../../../../common/components";
import { IProductPhotoProfileListItem } from "../../interfaces";

const defaultSorting: GridSortModel = [{ field: "dateUpdated", sort: "desc" }];

export const useProductPhotoProfileList = () => {
  const { showAlert } = useNotifications();
  const { t } = useTranslation("portal");
  const [loading, setLoading] = useState<boolean>(true);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSorting);
  const [productPhotoProfiles, setProductPhotoProfiles] = useState<
    IProductPhotoProfileListItem[]
  >([]);

  const columns: ApmGridColDef[] = [
    {
      field: "name",
      headerName: t("Product Photo Profile Name") as string,
      flex: 1,
      handleContentClick: true,
    },
    {
      field: "createdBy",
      headerName: t("Created By") as string,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.firstName} ${params.row.lastName}`,
    },
    {
      field: "dateUpdated",
      headerName: t("Date Updated") as string,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatDisplayDate(params.value),
    },
  ];

  useEffect(() => {
    const getUserProfiles = async () => {
      setLoading(true);
      const productPhotos = await ProductPhotoProfileService.fetchEntities();
      setProductPhotoProfiles(productPhotos);
      setLoading(false);
    };

    getUserProfiles();
  }, [setProductPhotoProfiles]);

  const rows = useMemo(
    () =>
      productPhotoProfiles.map((profile) => ({
        id: profile.id,
        name: profile.name,
        lastName: profile.createdBy?.lastName || "",
        firstName: profile.createdBy.firstName || "",
        dateUpdated: new Date(profile.updatedAt),
        canDelete: profile.canDelete,
        canUpdate: profile.canUpdate,
      })),
    [productPhotoProfiles]
  );

  const deleteProfile = async (id: number) => {
    try {
      await ProductPhotoProfileService.deleteEntity(id);
      setProductPhotoProfiles((profiles) =>
        profiles.filter((profile) => profile.id !== id)
      );
      showAlert({
        text: t("Successfully deleted"),
        severity: "success",
      });
    } catch (err) {
      showAlert({
        text: t("There was an error. Please try again"),
        severity: "error",
      });
    }
  };

  return {
    rows,
    columns,
    loading,
    sortModel,
    setSortModel,
    deleteProfile,
    productPhotoProfiles,
  };
};
