import React, { useMemo } from "react";
import { GridSortModel } from "@mui/x-data-grid";
import { ArtificeGrid } from "../../../../../common/components";
import { useOrganizationsList } from "../../hooks";
import { PaymentTypeSelect, PaymentTypeSelectProps } from "../";

export const OrganizationsList = () => {
  const {
    rows,
    loading,
    columns,
    sortModel,
    setSortModel,
    onChangePaymentType,
  } = useOrganizationsList();

  const columnsWithPaymentType = useMemo(
    () =>
      columns.map((column) => {
        return column.field === "paymentType"
          ? {
              ...column,
              renderCell: (cellParams: PaymentTypeSelectProps) => (
                <PaymentTypeSelect
                  {...cellParams}
                  onChange={onChangePaymentType}
                />
              ),
            }
          : column;
      }),
    [columns, onChangePaymentType]
  );
  return (
    <>
      <ArtificeGrid
        loading={loading}
        rows={rows}
        columns={columnsWithPaymentType}
        sortModel={sortModel}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
      />
    </>
  );
};
