import React from "react";
import classnames from "classnames";
import { Paper } from "@mui/material";
import {
  ProfileInfoList,
  Notes,
  ProductPhotoProfile,
} from "../../../../common";
import { useProductPhotoProfileInfo } from "../../hooks/use-product-photo-profile-info";
import { PhotoExamplesList } from "../photo-examples-list";
import { useSectionNames } from "../../hooks";
import styles from "./style.module.scss";

interface ProductProfileProps {
  profile?: ProductPhotoProfile;
  cardsLayout?: boolean;
}
export const ProductPhotoProfileInformation = ({
  profile,
  cardsLayout,
}: ProductProfileProps) => {
  const {
    screenShoots,
    documents,
    outerPackaging,
    productPackaging,
    productPackagingPhotos,
    productPhotos,
  } = useProductPhotoProfileInfo(profile);
  const sectionNames = useSectionNames();

  return (
    <>
      <Paper
        elevation={cardsLayout ? 1 : 0}
        square={!cardsLayout}
        className={styles.sectionWrapper}
      >
        <p
          className={classnames(
            styles.viewSectionTitle,
            cardsLayout ? styles.tabViewSectionTitle : null
          )}
        >
          {sectionNames.screenShoots}
        </p>

        <section className={styles.sectionContent}>
          <ProfileInfoList
            list={screenShoots}
            type="screen-shoots"
            classes={
              cardsLayout
                ? {
                    listItemName: styles.listItemName,
                    listItemValue: styles.listItemValue,
                  }
                : {}
            }
          />
          <Notes notes={profile?.screenShotNotes} />
        </section>
      </Paper>

      <Paper
        elevation={cardsLayout ? 1 : 0}
        square={!cardsLayout}
        className={styles.sectionWrapper}
      >
        <p
          className={classnames(
            styles.viewSectionTitle,
            cardsLayout ? styles.tabViewSectionTitle : null
          )}
        >
          {sectionNames.documents}
        </p>

        <section className={styles.sectionContent}>
          <ProfileInfoList
            list={documents}
            type="documents"
            classes={
              cardsLayout
                ? {
                    listItemName: styles.listItemName,
                    listItemValue: styles.listItemValue,
                  }
                : {}
            }
          />
          <Notes notes={profile?.docsReceiptMaterialNotes} />
        </section>
      </Paper>

      <Paper
        elevation={cardsLayout ? 1 : 0}
        square={!cardsLayout}
        className={styles.sectionWrapper}
      >
        <p
          className={classnames(
            styles.viewSectionTitle,
            cardsLayout ? styles.tabViewSectionTitle : null
          )}
        >
          {sectionNames.outerPackaging}
        </p>

        <section className={styles.sectionContent}>
          <ProfileInfoList
            list={outerPackaging}
            type="outer-packaging"
            classes={
              cardsLayout
                ? {
                    listItemName: styles.listItemName,
                    listItemValue: styles.listItemValue,
                  }
                : {}
            }
          />
          <Notes notes={profile?.outerPackageNotes} />
        </section>
      </Paper>
      <Paper
        elevation={cardsLayout ? 1 : 0}
        square={!cardsLayout}
        className={styles.sectionWrapper}
      >
        <p
          className={classnames(
            styles.viewSectionTitle,
            cardsLayout ? styles.tabViewSectionTitle : null
          )}
        >
          {sectionNames.productPackaging}
        </p>

        <section className={styles.sectionContent}>
          <ProfileInfoList
            list={productPackaging}
            type="product-packaging"
            classes={
              cardsLayout
                ? {
                    listItemValueWrapper: styles.listItemValueWrapper,
                    listItemName: styles.listItemName,
                    listItemValue: styles.listItemValue,
                  }
                : {}
            }
          />
          <PhotoExamplesList photoExamples={productPackagingPhotos} />
        </section>
      </Paper>

      {productPhotos?.length ? (
        <Paper
          elevation={cardsLayout ? 1 : 0}
          square={!cardsLayout}
          className={styles.sectionWrapper}
        >
          <p
            className={classnames(
              styles.viewSectionTitle,
              cardsLayout ? styles.tabViewSectionTitle : null
            )}
          >
            {sectionNames.purchasedItem}
          </p>
          <PhotoExamplesList photoExamples={productPhotos} />
        </Paper>
      ) : null}
    </>
  );
};
