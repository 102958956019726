import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GridSortModel, GridValidRowModel } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import { PageTitle, PurchasesEmptyView } from "../../../../common";
import { ArtificePurchaseRoutesEnum } from "../../../../../../common";
import {
  ArtificeGrid,
  FileDownload,
} from "../../../../../../common/components";
import { usePurchaseList } from "../../hooks";
import styles from "./style.module.scss";

export const PurchaseList = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("portal");
  const { loading, rows, columns, sortModel, setSortModel } = usePurchaseList();

  const handleCellContentClick = (row: GridValidRowModel) => {
    navigate(row.id.toString());
  };

  const columnsWithReports = useMemo(
    () =>
      columns.map((column) => {
        return column.field === "reports"
          ? {
              ...column,
              renderCell: FileDownload,
            }
          : column;
      }),
    [columns]
  );

  if (loading) {
    return <CircularProgress classes={{ root: styles.loader }} />;
  }

  return (
    <>
      {!rows.length ? (
        <PurchasesEmptyView />
      ) : (
        <>
          <PageTitle
            title={t("Purchases")}
            subTitle={t(
              "At this page you can view existing Purchases or Create a new one."
            )}
            customAction={t("Make a Purchase")}
            onAction={() => navigate(ArtificePurchaseRoutesEnum.Create)}
          />

          <ArtificeGrid
            customAction
            skipActions
            rows={rows}
            columns={columnsWithReports}
            sortModel={sortModel}
            onCellContentClick={handleCellContentClick}
            onSortModelChange={(model: GridSortModel) => setSortModel(model)}
          />
        </>
      )}
    </>
  );
};
