import React, { useState } from "react";
import { PurchaseFormSectionsEnum } from "../enums";
import { PurchaseFormType } from "../schemas";

export const usePurchaseFormAccordion = (profileForm: PurchaseFormType) => {
  const [expanded, setExpanded] = useState<PurchaseFormSectionsEnum[]>([
    PurchaseFormSectionsEnum.CaseInformation,
  ]);
  const [productOrderIndexExpanded, setProductOrderIndexExpanded] =
    useState<number>(0);

  const handleChange =
    (panel: PurchaseFormSectionsEnum, index = 0) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      setProductOrderIndexExpanded(index);
      setExpanded(newExpanded ? [panel] : []);
    };

  const completeSectionMap = {
    [PurchaseFormSectionsEnum.CaseInformation]: "isCaseInformationCompleted",
    [PurchaseFormSectionsEnum.ProductOrder]: "isProductOrderCompleted",
    [PurchaseFormSectionsEnum.StorageAndShipping]:
      "isStorageAndShippingCompleted",
  };

  const isCaseInformationInvalid =
    profileForm.errors.thirdPartySite && profileForm.touched.thirdPartySite;

  const isShippingAndStorageInvalid =
    profileForm.errors.shippingAndStorage &&
    Object.keys(profileForm.errors.shippingAndStorage).some(
      (key) =>
        (profileForm.touched.shippingAndStorage as Record<string, boolean>)?.[
          key
        ]
    );

  const handleNext = async (
    currentSection: PurchaseFormSectionsEnum,
    nextSection: PurchaseFormSectionsEnum,
    index = 0
  ) => {
    if (await shouldPreventFromNext(currentSection)) {
      return;
    }
    setExpanded(nextSection ? [nextSection] : []);
    setProductOrderIndexExpanded(index);
    profileForm.setFieldValue(completeSectionMap[currentSection], true);

    if (currentSection === PurchaseFormSectionsEnum.ProductOrder) {
      profileForm.values.productOrders.forEach((_, i) => {
        profileForm.setFieldValue(
          `productOrders.[${i}].isProductOrderCompleted`,
          true
        );
      });
    }
  };

  const shouldPreventFromNext = async (
    currentSection: PurchaseFormSectionsEnum
  ) => {
    if (currentSection === PurchaseFormSectionsEnum.CaseInformation) {
      if (validateCaseInformation()) {
        return true;
      }
    }

    if (currentSection === PurchaseFormSectionsEnum.ProductOrder) {
      if (validateProductOrder()) {
        return true;
      }
    }

    if (currentSection === PurchaseFormSectionsEnum.StorageAndShipping) {
      if (await validateShippingAndStorage()) {
        return true;
      }
    }
  };

  const validateCaseInformation = () => {
    profileForm.setFieldTouched("thirdPartySite", true, true);
    return profileForm.errors.thirdPartySite;
  };

  const isProductOrderInvalid = (index: number) => {
    return (
      profileForm.errors.productOrders?.[index] &&
      Object.keys(profileForm.errors.productOrders[index]).some(
        (key) =>
          (profileForm.touched?.productOrders as Record<string, boolean>[])?.[
            index
          ]?.[key]
      )
    );
  };

  const validateProductOrder = () => {
    profileForm.values.productOrders.forEach((_, index) => {
      profileForm.setFieldTouched(
        `productOrders[${index}].productName`,
        true,
        true
      );
      profileForm.setFieldTouched(
        `productOrders[${index}].productUrl`,
        true,
        true
      );
      profileForm.setFieldTouched(
        `productOrders[${index}].quantity`,
        true,
        true
      );
      profileForm.setFieldTouched(
        `productOrders[${index}].unitPrice`,
        true,
        true
      );
    });

    return profileForm.errors.productOrders;
  };

  const validateShippingAndStorage = async () => {
    Object.keys(profileForm.values.shippingAndStorage).forEach((key) => {
      profileForm.setFieldTouched(`shippingAndStorage.${key}`, true, true);
    });

    const errors = await profileForm.validateForm();

    return errors.shippingAndStorage;
  };

  const validateAllSection = () => {
    if (profileForm.errors.thirdPartySite) {
      setExpanded([PurchaseFormSectionsEnum.CaseInformation]);
      return;
    }

    if (Array.isArray(profileForm.errors.productOrders)) {
      setExpanded([PurchaseFormSectionsEnum.ProductOrder]);
      const index = profileForm.errors.productOrders.findIndex(Boolean);
      setProductOrderIndexExpanded(index);

      return;
    }

    if (profileForm.errors.shippingAndStorage) {
      setExpanded([PurchaseFormSectionsEnum.StorageAndShipping]);
    }
  };

  return {
    caseInformationExpanded: expanded.includes(
      PurchaseFormSectionsEnum.CaseInformation
    ),
    storageAndShippingExpanded: expanded.includes(
      PurchaseFormSectionsEnum.StorageAndShipping
    ),
    isCaseInformationInvalid,
    isShippingAndStorageInvalid,
    isProductOrderInvalid,
    productOrdersExpanded: (index: number) =>
      expanded.includes(PurchaseFormSectionsEnum.ProductOrder) &&
      index === productOrderIndexExpanded,
    validateAllSection,
    handleChange,
    handleNext,
    setProductOrderIndexExpanded,
  };
};
