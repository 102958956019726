import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdminService } from "../services";
import { IPurchaseStatusHistory } from "../interfaces";
import { useNotifications } from "../../../../common";

export interface purchaseStatusHistoryValue {
  history: IPurchaseStatusHistory[];
  emailSent: boolean;
}

export const usePurchaseStatusHistory = (purchaseId?: string) => {
  const [loading, setLoading] = useState(false);
  const [purchaseStatusHistory, setPurchaseStatusHistory] = useState<
    IPurchaseStatusHistory[] | null
  >(null);

  const { showAlert } = useNotifications();
  const { t } = useTranslation("admin");

  const getPurchaseStatusHistory = async () => {
    setLoading(true);
    try {
      const data = purchaseId
        ? await AdminService.fetchPurchaseStatusHistory(purchaseId)
        : null;
      setPurchaseStatusHistory(data);
    } catch (err) {
      showAlert({
        text: t("Something went wrong, please try again."),
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const purchaseStatusHistoryMap = useMemo(() => {
    const purchaseStatusHistoryMap = new Map<
      string,
      purchaseStatusHistoryValue
    >();

    purchaseStatusHistory?.forEach((item) => {
      const existing = purchaseStatusHistoryMap.get(item.status);
      purchaseStatusHistoryMap.set(item.status, {
        emailSent: existing?.emailSent || item.emailSent,
        history: [...(existing?.history || []), item],
      });
    });

    return purchaseStatusHistoryMap;
  }, [purchaseStatusHistory]);

  useEffect(() => {
    getPurchaseStatusHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    purchaseStatusHistoryMap,
    getPurchaseStatusHistory,
  };
};
