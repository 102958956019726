import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { PageTitle } from "../../../../common";
import { ArtificeRoutes } from "../../../../../../common";
import { PurchaseForm, PreviewPurchaseModal } from "../../components";
import { useEditPurchase } from "../../hooks";
import styles from "./style.module.scss";

export const EditPurchase = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    purchaseForm,
    purchaseProfiles,
    productPhotoProfiles,
    isLoading,
    id,
  } = useEditPurchase();

  return (
    <>
      <PageTitle
        loading={isLoading}
        backUrl={`${ArtificeRoutes.purchases}/${id}`}
        title={purchaseForm.values.artificeCaseNumber}
      />
      {isLoading ? (
        <CircularProgress classes={{ root: styles.loader }} />
      ) : (
        <PurchaseForm
          handlePreview={handleOpen}
          purchaseForm={purchaseForm}
          purchaseProfiles={purchaseProfiles}
          productPhotoProfiles={productPhotoProfiles}
        />
      )}
      <PreviewPurchaseModal
        open={open}
        handleClose={handleClose}
        purchaseForm={purchaseForm}
      />
    </>
  );
};
