import React, { ReactNode } from "react";
import styles from "./style.module.scss";

interface TabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} className={styles.wrapper}>
      {value === index && children}
    </div>
  );
};
