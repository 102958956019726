import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { ArtificeMask } from "../../artifice-mask";

interface ZipCodeProps {
  label: string;
  formKey: string;
  getFormProps: any;
  required?: boolean;
  disabled?: boolean;
}

export const ZipCode = (props: ZipCodeProps) => {
  const { t } = useTranslation("common");
  const fieldProps = props.getFormProps(props.formKey);

  return (
    <TextField
      fullWidth
      margin="normal"
      label={props.label}
      autoComplete="postal-code"
      required={props.required}
      disabled={Boolean(props.disabled)}
      placeholder="xxxxx"
      {...fieldProps}
      helperText={t(fieldProps.helperText)}
      InputProps={{
        inputProps: { mask: "00000-0000" },
        inputComponent: ArtificeMask,
      }}
    />
  );
};
