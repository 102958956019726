import { CheckoutFormValuesType } from "./checkout-form.schema";

interface IToFormPayload {
  purchaseProducts?: {
    id?: number;
    name: string;
    quantity: number;
    unitPrice: number;
    orderedQuantity?: number;
    orderedUnitPrice?: number;
  }[];
  sellerTax: number;
  shippingTax: number;
  miscellaneous: number;
}

export class CheckoutFormService {
  static fromForm(values: CheckoutFormValuesType) {}

  static toForm(payload?: IToFormPayload): CheckoutFormValuesType {
    return {
      products:
        payload?.purchaseProducts?.map((product) => ({
          id: product.id,
          name: product.name,
          quantity: product.orderedQuantity?.toString(),
          unitPrice: product.orderedUnitPrice?.toString(),
        })) || [],
      sellerTax: payload?.sellerTax?.toString() || "",
      shippingTax: payload?.shippingTax?.toString() || "",
      miscellaneous: payload?.miscellaneous?.toString() || "",
    };
  }
}
