import React from "react";
import { useTranslation } from "react-i18next";
import { Logout } from "@mui/icons-material";
import { Avatar, Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useAuth } from "../../providers";
import styles from "./style.module.scss";

export const UserMenu = () => {
  const [t] = useTranslation("common");
  const { logout, user } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick}>
        <Avatar>{user?.initials}</Avatar>
        <section className={styles.userNameSection}>
          <p className={styles.userName}>
            {user?.firstName} {user?.lastName}
          </p>
          <p className={styles.userEmail}>{user?.email}</p>
        </section>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("Logout")}
        </MenuItem>
      </Menu>
    </>
  );
};
