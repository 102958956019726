import React from "react";
import { useTranslation } from "react-i18next";
import EastIcon from "@mui/icons-material/East";
import { AccordionDetails, Button } from "@mui/material";
import classnames from "classnames";
import styles from "./style.module.scss";

interface ArtificeAccordionSummaryProps {
  children: JSX.Element;
  currentSection?: string;
  nextSection?: string;
  hideNext?: boolean;
  classes?: {
    footer?: string;
  };
  handleNext?: (currentSection: any, nextSection: any) => void;
}

export const ArtificeAccordionDetails = ({
  currentSection,
  nextSection,
  handleNext,
  hideNext,
  children,
  classes,
}: ArtificeAccordionSummaryProps) => {
  const [t] = useTranslation("portal");

  return (
    <AccordionDetails>
      {children}
      {hideNext || !handleNext ? null : (
        <Button
          variant="text"
          className={classnames(styles.nextBtn, classes?.footer)}
          endIcon={<EastIcon />}
          onClick={() => handleNext(currentSection, nextSection)}
        >
          {t("Next")}
        </Button>
      )}
    </AccordionDetails>
  );
};
