import React from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import styles from "./style.module.scss";

interface PricingItemProps {
  title: string;
  description?: string;
  price: string;
}

export const PricingItem = ({
  title,
  description,
  price,
}: PricingItemProps) => {
  const [t] = useTranslation("portal");

  return (
    <Paper sx={{ borderRadius: 0 }} classes={{ root: styles.itemWrapper }}>
      <p className={styles.title}>{t(title)}</p>
      <p className={styles.price}>{t(price)}</p>
      {description && <p className={styles.description}>{t(description)}</p>}
    </Paper>
  );
};
