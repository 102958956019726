import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BrandsService } from "../services";
import { useNotifications } from "../../../../../common";

export const useAddBrand = () => {
  const [t] = useTranslation("portal");
  const { showAlert } = useNotifications();
  const [loading, setLoading] = useState<boolean>(false);
  const [brand, setBrand] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const handleOnInputChange = (_: any, newValue: string) => {
    if (newValue.trim().length === 0) {
      setBrand(newValue.trim());
    } else {
      setBrand(newValue);
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setBrand("");
  };

  const handleAddBrand = async () => {
    setLoading(true);
    try {
      const brandNameTrimed = brand.trim();
      await BrandsService.createEntity(brandNameTrimed);

      showAlert({
        text: t("Successfully added a brand."),
        severity: "success",
      });
    } catch (err: any) {
      const errorText =
        err?.response?.data?.statusCode === 409
          ? t("Brand is already added.")
          : t("Something went wrong, please try again.");

      showAlert({
        text: errorText,
        severity: "error",
      });
    } finally {
      setBrand("");
      setLoading(false);
      setModalOpen(false);
    }
  };

  return {
    brand,
    loading,
    modalOpen,
    handleModalOpen,
    handleModalClose,
    handleAddBrand,
    handleOnInputChange,
  };
};
