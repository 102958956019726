import React from "react";
import { Button } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styles from "./style.module.scss";

interface CollapsableButtonProps {
  open: boolean;
  label: string;
  onClick: () => void;
}
export const CollapsableButton = ({
  open,
  label,
  onClick,
}: CollapsableButtonProps) => (
  <Button
    variant="text"
    disableRipple
    classes={{ root: styles.collapseButton }}
    onClick={onClick}
  >
    {label}
    {open ? <ExpandLess /> : <ExpandMore />}
  </Button>
);
