import React from "react";
import { useParams } from "react-router-dom";
import { PurchaseCheckout } from "../../../../common/components";
import { PageTitle } from "../../../../../portal";
import { ArtificeRoutes } from "../../../../../../common";

export const PurchaseCheckoutPage = () => {
  const { id } = useParams();

  return (
    <>
      <PageTitle
        title="Order Summary"
        backUrl={`${ArtificeRoutes.adminPurchases}/${id}`}
      />
      <PurchaseCheckout />
    </>
  );
};
