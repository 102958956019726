import React from "react";
import { ArtificePurchaseRoutesEnum } from "../../../../../common";
import {
  CreatePurchase,
  PurchaseList,
  Summary,
  ViewPurchase,
  EditPurchase,
  CheckoutSuccess,
} from "../pages";

export const PurchaseRoutes = [
  {
    index: true,
    element: <PurchaseList />,
  },
  {
    path: ArtificePurchaseRoutesEnum.Create,
    element: <CreatePurchase />,
  },
  {
    path: ArtificePurchaseRoutesEnum.Success,
    element: <CheckoutSuccess />,
  },
  {
    path: ArtificePurchaseRoutesEnum.View,
    element: <ViewPurchase />,
  },
  {
    path: ArtificePurchaseRoutesEnum.Edit,
    element: <EditPurchase />,
  },
  {
    path: ArtificePurchaseRoutesEnum.Summary,
    element: <Summary />,
  },
];
