import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { GridSortModel, GridValidRowModel } from "@mui/x-data-grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { usePurchaseList } from "../../../../common/hooks";
import {
  ArtificeGrid,
  FileDownload,
  PurchaseStatus,
} from "../../../../../../common/components";
import {
  purchasePaymentStatus,
  purchaseStatus,
} from "../../../../../../common";
import styles from "./style.module.scss";

export const PurchaseList = () => {
  const navigate = useNavigate();
  const {
    rows,
    loading,
    columns,
    sortModel,
    organizationOptions,
    organization,
    paymentStatus,
    status,
    setSortModel,
    setOrganization,
    setStatus,
    setPaymentStatus,
  } = usePurchaseList();

  const handleCellContentClick = (row: GridValidRowModel) => {
    navigate(row.id.toString());
  };

  const columnsWithReports = useMemo(
    () =>
      columns.map((column) => {
        return column.field === "reports"
          ? {
              ...column,
              renderCell: FileDownload,
            }
          : column.field === "status"
          ? {
              ...column,
              renderCell: PurchaseStatus,
            }
          : column;
      }),
    [columns]
  );

  return (
    <>
      <section className={styles.row}>
        <Autocomplete
          className={styles.filter}
          options={organizationOptions}
          autoHighlight
          value={organization}
          onChange={(event, newValue) => {
            setOrganization(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label={"Organization"} />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.value.toString()}>
              {option.label}
            </li>
          )}
        />

        <Autocomplete
          className={styles.filter}
          options={purchaseStatus}
          autoHighlight
          value={status}
          onChange={(event, newValue) => {
            setStatus(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label={"Purchase Status"} />
          )}
        />

        <Autocomplete
          className={styles.filter}
          options={purchasePaymentStatus}
          autoHighlight
          value={paymentStatus}
          onChange={(event, newValue) => {
            setPaymentStatus(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label={"Purchase Payment Status"} />
          )}
        />
      </section>
      <ArtificeGrid
        skipActions
        customAction
        loading={loading}
        rows={rows}
        columns={columnsWithReports}
        sortModel={sortModel}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
        onCellContentClick={handleCellContentClick}
      />
    </>
  );
};
