import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";

interface ProductOrderCountProps {
  from: number;
  count: number;
}

export const ProductOrderCount = ({ from, count }: ProductOrderCountProps) => {
  const [t] = useTranslation("portal");

  return (
    <span className={styles.countWrapper}>
      {t("{{from}} of {{count}}", { from, count })}
    </span>
  );
};
