import React from "react";
import { useTranslation } from "react-i18next";
import { useCreatePurchaseProfile } from "../../hooks";
import { PurchaseProfileForm } from "../../components";
import { PageTitle } from "../../../../common";
import { ArtificeRoutes } from "../../../../../../common";
import styles from "./style.module.scss";

export const CreatePurchaseProfile = () => {
  const [t] = useTranslation("portal");
  const { profileForm } = useCreatePurchaseProfile();

  return (
    <section className={styles.purchaseProfileWrapper}>
      <PageTitle
        backUrl={ArtificeRoutes.purchaseProfiles}
        title={t("Create a Purchase Profile")}
        subTitle={t("Each team member can create their own profile.")}
      />

      <PurchaseProfileForm profileForm={profileForm} />
    </section>
  );
};
