import { useMemo } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ProductPhotoProfileService,
  ProductPhotoProfileFormService,
} from "../../../common";
import {
  ProductPhotoProfileFormSchema,
  ProductPhotoProfileFormValuesType,
} from "../schemas";
import { ArtificeRoutes, useNotifications } from "../../../../../common";
import { FileError } from "../../../../../common/utils";
import { useProductPhotoProfile } from "./use-product-photo-profile";

export const useEditProductPhotoProfile = () => {
  const { t } = useTranslation("portal");
  const { showAlert } = useNotifications();
  const navigate = useNavigate();
  const { profile, id, loading } = useProductPhotoProfile();

  const initialValues = useMemo(
    () => ProductPhotoProfileFormService.toForm(profile),
    [profile]
  );

  const edit = async (formValues: ProductPhotoProfileFormValuesType) => {
    try {
      await ProductPhotoProfileService.updateEntity({
        id: Number(id),
        profile: ProductPhotoProfileFormService.fromForm(formValues),
        formValues: {
          productPackagingPhotoExamples:
            formValues.productPackagingPhotoExamples,
          purchasedPhotoExamples: formValues.purchasedPhotoExamples,
        },
      });
      showAlert({
        text: t("Successfully edited"),
        severity: "success",
      });
      navigate(`${ArtificeRoutes.productPhotoProfiles}/${id}`);
    } catch (err) {
      showAlert({
        text:
          err instanceof FileError
            ? t(
                "There was an error with {{name}}. Please try again or change it",
                { name: err }
              )
            : t("There was an error. Please try again"),
        severity: "error",
      });
    }
  };

  const profileForm = useFormik({
    validationSchema: ProductPhotoProfileFormSchema,
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: edit,
  });

  return {
    loading,
    profileForm,
    profileName: profile?.name,
    id,
  };
};
