import React from "react";
import { Paper } from "@mui/material";
import { IPurchase } from "../../interfaces";
import { SummaryProducts } from "../summary-products";
import { PurchaseCheckoutFooter } from "../purchase-checkout-footer";
import { PaymentItem } from "../../../../../../common/components";
import styles from "./style.module.scss";

interface SummaryViewProps {
  purchase?: IPurchase;
}
export const SummaryView = ({ purchase }: SummaryViewProps) => {
  return (
    <>
      <Paper classes={{ root: styles.wrapper }}>
        <SummaryProducts purchase={purchase} />
      </Paper>

      {purchase?.paymentProfile ? (
        <Paper classes={{ root: styles.wrapper }}>
          <PaymentItem
            cardNumber={purchase.paymentProfile.cardNumber}
            cardType={purchase.paymentProfile.cardType}
          />
        </Paper>
      ) : null}

      {purchase?.canAuthorize ? (
        <PurchaseCheckoutFooter
          canRequestInvoice={purchase.canRequestInvoice}
        />
      ) : null}
    </>
  );
};
