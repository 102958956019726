import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress, Paper } from "@mui/material";
import { Payments } from "../payments";
import ArtificeGlobalsOrderAndProcessingFees from "../../../../../../assets/files/Artifice_Globals_Order_Processing_Fees.pdf";
import { usePayments } from "../../hooks";
import styles from "./style.module.scss";

interface PurchaseCheckoutFooterProps {
  canRequestInvoice?: boolean;
}

export const PurchaseCheckoutFooter = ({
  canRequestInvoice,
}: PurchaseCheckoutFooterProps) => {
  const [t] = useTranslation("portal");
  const {
    paymentMethods,
    activePayment,
    checkoutInProgress,
    payByInvoiceInProgress,
    loading,
    handlePaymentChange,
    handlePayByInvoice,
    addPaymentMethod,
    handleCheckout,
    fetchPayments,
  } = usePayments();
  return (
    <>
      <Paper classes={{ root: styles.wrapper }}>
        <p className={styles.paymentsTitle}>
          {t("Select from saved card(s):")}
        </p>

        {loading ? (
          <CircularProgress classes={{ root: styles.loader }} />
        ) : (
          <Payments
            paymentMethods={paymentMethods}
            activePayment={activePayment}
            handlePaymentChange={handlePaymentChange}
            addPaymentMethod={addPaymentMethod}
            fetchPayments={fetchPayments}
          />
        )}
      </Paper>
      <div className={styles.feesText}>
        <Trans ns="portal" i18nKey="ArtificeGlobalsOrder&ProcessingFees">
          By selecting to check out you agree to{" "}
          <Link
            to={ArtificeGlobalsOrderAndProcessingFees}
            target="_blank"
            rel="noreferrer"
          >
            Artifice Global's Order & Processing Fees
          </Link>
        </Trans>
      </div>

      <LoadingButton
        variant="contained"
        color={canRequestInvoice ? "secondary" : "primary"}
        loading={checkoutInProgress}
        onClick={handleCheckout}
      >
        {t("Checkout")}
      </LoadingButton>
      {canRequestInvoice && (
        <div className={styles.rightBtn}>
          <LoadingButton
            variant="contained"
            loading={payByInvoiceInProgress}
            onClick={handlePayByInvoice}
          >
            {t("Pay By Invoice")}
          </LoadingButton>
        </div>
      )}
    </>
  );
};
