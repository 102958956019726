import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { ArtificeModal } from "..";
import styles from "./style.module.scss";

export const ContactUsModal = () => {
  const { t } = useTranslation("common");
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <section className={styles.formFooter}>
        <Button
          color="primary"
          size="medium"
          variant="contained"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          {t("Contact Us")}
        </Button>
      </section>
      <ArtificeModal
        title={t("Contact Us")}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        classes={{ wrapper: styles.wrapper }}
      >
        <section>
          <ul className={styles.contactInfo}>
            <li>
              <PhoneAndroidIcon />
              <a href="tel:+17608454526">(760) 845-4526</a>
            </li>
            <li>
              <LocationOnIcon />
              <p>250 Red Cliffs Blvd. Suite 4B-118 St. George UT 84790</p>
            </li>
            <li>
              <EmailIcon />
              <a href="mailto:ContactUs@artificeglobal.com">
                ContactUs@artificeglobal.com
              </a>
            </li>
          </ul>
          <section className={styles.modalFooterActions}>
            <Button
              variant="outlined"
              size="medium"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {t("Close")}
            </Button>
          </section>
        </section>
      </ArtificeModal>
    </>
  );
};
