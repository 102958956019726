import axios from "../../../../../common/api";
import { systemBrandEndpoints } from "../../../common";
import { BrandTypeEnum } from "../../../common/enums";
import { ISystemBrand } from "../interfaces";

export class SystemBrandsService {
  static async fetchEntities(): Promise<ISystemBrand[]> {
    const data = await axios
      .get(systemBrandEndpoints.entity)
      .then((response) => response.data);

    return data.map((brand: ISystemBrand) => {
      return { ...brand, type: BrandTypeEnum.SYSTEM };
    });
  }
}
