import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { OrderSubstitutions } from "../order-substitutioins";
import { PurchaseFormType } from "../../schemas";
import { IProductPhotoProfileListItem } from "../../../../common";
import { PurchaseFormService } from "../../services";
import { ProductOrderDetails } from "../product-order-details";
import { PhotoProfileSettings } from "../photo-profile-settings";
import { PRODUCT_ORDERS_MAX_COUNT } from "../../../../../../common";
import { IBrand } from "../../../represented-brands/interfaces";

interface ProductOrderProps {
  index: number;
  count: number;
  purchaseForm: PurchaseFormType;
  purchaseBrands: IBrand[];
  refetchBrands: () => Promise<void>;
  productPhotoProfiles?: IProductPhotoProfileListItem[];
  setProductOrderIndexExpanded: Dispatch<SetStateAction<number>>;
}

export const ProductOrder = ({
  index,
  count,
  purchaseForm,
  purchaseBrands,
  productPhotoProfiles,
  refetchBrands,
  setProductOrderIndexExpanded,
}: ProductOrderProps) => {
  const [t] = useTranslation("portal");
  const [photoProfileSettingsOpen, setProfileSettingsOpen] = useState(false);
  const [orderSubstitutionOpen, setOrderSubstitutionOpen] = useState(false);
  const handleProductAdd = () => {
    setProfileSettingsOpen(false);
    setOrderSubstitutionOpen(false);
    const productOrders = purchaseForm.values.productOrders;
    purchaseForm.setFieldValue("productOrders", [
      ...productOrders,
      PurchaseFormService.initialProductOrder(),
    ]);
    setProductOrderIndexExpanded(productOrders.length);
  };

  const handleProductRemove = () => {
    const productOrders = purchaseForm.values.productOrders;
    purchaseForm.setFieldValue(
      "productOrders",
      productOrders.filter((_, i: number) => i !== index)
    );
  };

  const canAddMore = count === index + 1 && count < PRODUCT_ORDERS_MAX_COUNT;

  return (
    <section>
      <ProductOrderDetails
        index={index}
        purchaseForm={purchaseForm}
        onProductRemove={handleProductRemove}
        purchaseBrands={purchaseBrands}
        refetchBrands={refetchBrands}
      />
      <PhotoProfileSettings
        index={index}
        open={photoProfileSettingsOpen}
        setOpen={setProfileSettingsOpen}
        form={purchaseForm}
        productPhotoProfiles={productPhotoProfiles}
      />
      <OrderSubstitutions
        open={orderSubstitutionOpen}
        setOpen={setOrderSubstitutionOpen}
        index={index}
        purchaseForm={purchaseForm}
      />

      {canAddMore ? (
        <Button variant="contained" onClick={handleProductAdd}>
          {t("Add More Items")}
        </Button>
      ) : null}
    </section>
  );
};
