import { UserRoleEnum } from "../../../common";
import { IUser } from "../interfaces";

export class User {
  static fromDTO(dto: IUser): User {
    const user = new User();
    const contact = dto?.contacts[0];

    user.id = dto.id;
    user.isOrgAdmin = dto.roles.includes(UserRoleEnum.ORG_ADMIN);
    user.firstName = dto.firstName;
    user.lastName = dto.lastName;
    user.email = dto.email;
    user.orgDomain = dto.orgDomain;
    user.roles = dto.roles;
    user.initials = user.formatInitials();
    user.contact = {
      directPhoneCountryCode: contact?.directPhoneCountryCode,
      directPhone: contact?.directPhone,
      mobilePhoneCountryCode: contact?.mobilePhoneCountryCode,
      mobilePhone: contact?.mobilePhone,
      city: contact?.city,
      state: contact?.state,
      street: contact?.street,
      zip: contact?.zip,
    };

    return user;
  }

  get hasPortalAccess(): boolean {
    return [UserRoleEnum.ORG_ADMIN, UserRoleEnum.ORG_MEMBER].some((role) =>
      this.roles?.includes(role)
    );
  }

  get hasAdminAccess(): boolean {
    return [UserRoleEnum.SYSTEM_ADMIN].some((role) =>
      this.roles?.includes(role)
    );
  }

  id?: number;
  isOrgAdmin?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  initials?: string;
  orgDomain?: string;
  roles?: UserRoleEnum[];
  contact?: {
    city: string;
    state: string;
    street: string;
    zip: string;
    directPhoneCountryCode?: string;
    directPhone?: string;
    mobilePhoneCountryCode?: string;
    mobilePhone?: string;
  };

  private formatInitials(): string {
    return [this?.firstName || "", this?.lastName || ""]
      .filter(Boolean)
      .map((n) => n[0])
      .join("");
  }
}
