import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, Radio, RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import classnames from "classnames";
import styles from "./style.module.scss";

interface FormRadioProps {
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
  classes?: {
    wrapper?: string;
  };
}

export const ArtificeRadio = ({
  checked,
  label,
  onChange,
  classes,
}: FormRadioProps) => {
  const [t] = useTranslation("common");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value === "yes");
  };

  return (
    <section className={classnames(styles.wrapper, classes?.wrapper)}>
      <FormControl
        classes={{ root: styles.horizontalLabel }}
        onChange={handleRadioChange}
      >
        <span className={styles.horizontalLabel}>{label}</span>
        <RadioGroup row>
          <FormControlLabel
            value="yes"
            checked={checked}
            control={<Radio />}
            label={t("Yes")}
          />
          <FormControlLabel
            value="no"
            checked={!checked}
            control={<Radio />}
            label={t("No")}
          />
        </RadioGroup>
      </FormControl>
    </section>
  );
};
