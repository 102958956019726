import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import {
  ArtificeCheckbox,
  ArtificeRadio,
} from "../../../../../../../common/components";
import { ProductPhotoProfileFormType } from "../../../interfaces";
import { getFormFieldProps } from "../../../../../../../common/utils";
import styles from "./style.module.scss";

interface OuterPackagingProps {
  formik: ProductPhotoProfileFormType;
}

export const OuterPackaging = ({ formik }: OuterPackagingProps) => {
  const [t] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const handleDisposeChange = (checked: boolean) => {
    formik.setFieldValue("disposeOuterPackaging", checked);
  };
  const { disposeOuterPackaging, shippingPackage, shippingLabelsOnly } =
    formik.values;

  const handleShippingPackageChange = (val: boolean) => {
    formik.setFieldValue("shippingPackage", val);
  };
  const handleShippingLabelsOnlyChange = (val: boolean) => {
    formik.setFieldValue("shippingLabelsOnly", val);
  };
  const formProps = getFormFieldProps(formik, t, tValidation);

  return (
    <section className={styles.verticalView}>
      <ArtificeCheckbox
        label={t("Photo of whole package")}
        checked={Boolean(shippingPackage)}
        onChange={handleShippingPackageChange}
      />
      <ArtificeCheckbox
        label={t("Shipping Labels Only")}
        checked={Boolean(shippingLabelsOnly)}
        onChange={handleShippingLabelsOnlyChange}
      />
      <ArtificeRadio
        classes={{
          wrapper: styles.dispose,
        }}
        checked={disposeOuterPackaging}
        label={t("Can we dispose of the outer packaging once photographed?")}
        onChange={handleDisposeChange}
      />
      <TextField
        className={styles.notes}
        label={t("Notes")}
        multiline
        rows={2}
        {...formProps("outerPackagingNotes", t("Notes"))}
      />
    </section>
  );
};
