import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

import { ProductPhotoProfileFormType } from "../../interfaces";
import { CustomizationItem } from "./CustomizationItem";

interface CustomizationProps {
  form: ProductPhotoProfileFormType;
  field: "purchasedPhotoExamples" | "productPackagingPhotoExamples";
  max: number;
}

export const Customization = ({ form, field, max }: CustomizationProps) => {
  const [t] = useTranslation("portal");
  const attachments = form.values[field] || [];
  const updateValue = (
    value: { name?: string; note?: string; file?: File }[]
  ) => form.setFieldValue(field, value);

  const handleCustomize = () => {
    updateValue([...attachments, { name: "", notes: "", id: Math.random() }]);
  };

  return (
    <section>
      {attachments?.map((attachment, index) => (
        <CustomizationItem
          key={attachment.id}
          attachment={attachment}
          form={form}
          field={field}
          index={index}
        />
      ))}
      <Button
        variant="outlined"
        onClick={handleCustomize}
        disabled={attachments.length >= max}
      >
        {t("Customize")}
      </Button>
    </section>
  );
};
