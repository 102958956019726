import React, { useState } from "react";
import { Alert, Button, ButtonGroup, Paper } from "@mui/material";
import {
  useAdminPurchase,
  usePurchaseCheckout,
  usePurchaseStatus,
} from "../../hooks";
import { SummaryProducts } from "../../../../portal/pages/purchases/components/summary-products";
import { AttachReports } from "../attach-reports";
import { PurchaseTransactions } from "../purchase-transactions";
import { statusesMap } from "../../constants";
import { PaymentItem } from "../../../../../common/components";
import { ConfirmPurchaseStatusModal } from "../";
import {
  InvoiceEnum,
  PurchasePaymentStatusEnum,
} from "../../../../../common/enums";
import styles from "./style.module.scss";

export const PurchaseCheckout = () => {
  const [toPurchaseStatus, setToPurchaseStatus] = useState<{
    value: string;
    title: string;
  }>();
  const { purchase, id, fetchPurchase } = useAdminPurchase();
  const {
    uploadReports,
    removeFile,
    handleCapture,
    handleCompleteOrder,
    updatePurchaseFees,
  } = usePurchaseCheckout();
  const { mappedPurchaseStatuses } = usePurchaseStatus();

  const refetchPurchase = () => {
    fetchPurchase(id);
  };

  const handleUploadReports = async (files: any) => {
    await uploadReports(files);
    refetchPurchase();
  };

  const handleUpdateCheckout = (values: any) => {
    return updatePurchaseFees(values);
  };

  const handleCaptureClick = async () => {
    await handleCapture();
    refetchPurchase();
  };

  const handleConfirmOrderClick = async () => {
    await handleCompleteOrder();
    refetchPurchase();
  };

  const handleStatusUpdateClick = (status: {
    value: string;
    title: string;
  }) => {
    if (!id) {
      return;
    }
    setToPurchaseStatus(status);
  };

  const canUpdatePurchaseStatus =
    purchase?.paymentStatus &&
    [
      PurchasePaymentStatusEnum.INVOICE_APPROVED,
      PurchasePaymentStatusEnum.CAPTURED,
    ].includes(purchase?.paymentStatus);

  return (
    <>
      <Paper classes={{ root: styles.wrapper }}>
        <section className={styles.purchaseStatus}>
          <section>
            <p>
              Purchase Status: <b>{statusesMap.get(purchase?.status!)}</b>
            </p>
            <p>
              Purchase Payment Status:{" "}
              <b>{statusesMap.get(purchase?.paymentStatus!) || "-"}</b>
            </p>
          </section>
          <section>
            <ButtonGroup variant="outlined" sx={{ marginLeft: "16px" }}>
              {mappedPurchaseStatuses?.map((purchaseStatus) => (
                <Button
                  size="small"
                  variant={
                    purchaseStatus.value === purchase?.status
                      ? "contained"
                      : "outlined"
                  }
                  disabled={
                    !canUpdatePurchaseStatus ||
                    purchaseStatus.value === purchase?.status
                  }
                  onClick={() => handleStatusUpdateClick(purchaseStatus)}
                >
                  {purchaseStatus.title}
                </Button>
              ))}
            </ButtonGroup>
          </section>
        </section>

        {purchase && !canUpdatePurchaseStatus ? (
          <Alert severity="warning">
            Updating a purchase status is only possible when it has been{" "}
            <b>captured</b>.
          </Alert>
        ) : null}
      </Paper>

      <Paper classes={{ root: styles.wrapper }}>
        <SummaryProducts
          showTotalHold
          purchase={purchase}
          isEditable={Boolean(
            purchase?.canCapture || purchase?.canApproveInvoice
          )}
          canCapture={purchase?.canCapture}
          canApproveInvoice={purchase?.canApproveInvoice}
          onUpdate={handleUpdateCheckout}
          onCapture={handleCaptureClick}
          onCompleteOrder={handleConfirmOrderClick}
        />
      </Paper>

      {purchase?.paymentProfile ? (
        <Paper classes={{ root: styles.wrapper }}>
          <PaymentItem
            cardNumber={purchase.paymentProfile.cardNumber}
            cardType={purchase.paymentProfile.cardType}
          />
        </Paper>
      ) : null}

      <Paper classes={{ root: styles.reportWrapper }}>
        <AttachReports
          purchase={purchase}
          uploadReports={handleUploadReports}
          removeFile={removeFile}
        />
      </Paper>

      {!purchase?.paymentStatus?.includes(InvoiceEnum.INVOICE) && (
        <Paper classes={{ root: styles.reportWrapper }}>
          <PurchaseTransactions />
        </Paper>
      )}

      <ConfirmPurchaseStatusModal
        id={id}
        open={!!toPurchaseStatus}
        refetchPurchase={refetchPurchase}
        toPurchaseStatus={toPurchaseStatus}
        setToPurchaseStatus={setToPurchaseStatus}
      />
    </>
  );
};
