import React from "react";
import { useTranslation } from "react-i18next";
import { ArtificeRadio } from "../../../../../../../common/components";
import { PurchaseProfileFormType } from "../../../interfaces";
import styles from "./style.module.scss";

interface LeadsProps {
  formik: PurchaseProfileFormType;
}

export const Leads = ({ formik }: LeadsProps) => {
  const [t] = useTranslation("portal");

  const handleCheckboxChange = (checked: boolean) =>
    formik.setFieldValue("notifyOfLeads", checked);

  const notifyOfLeads = formik.values.notifyOfLeads;

  return (
    <section className={styles.wrapper}>
      <ArtificeRadio
        checked={Boolean(notifyOfLeads)}
        label={t("Can we notify you of leads related to your Brand?")}
        onChange={handleCheckboxChange}
      />
    </section>
  );
};
