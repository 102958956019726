import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { ArtificeModal } from "..";
import styles from "./style.module.scss";

interface ServiceModalProps {
  open: boolean;
  text: string;
  title: string;
  onClose: () => void;
}
export const ServiceModal = ({
  open,
  onClose,
  title,
  text,
}: ServiceModalProps) => {
  const { t } = useTranslation("common");

  return (
    <ArtificeModal
      title={t(title)}
      open={open}
      onClose={onClose}
      classes={{ wrapper: styles.wrapper }}
    >
      <section>
        <p className={styles.text}>{t(text)}</p>
        <section className={styles.modalFooterActions}>
          <Button variant="outlined" size="medium" onClick={onClose}>
            {t("Close")}
          </Button>
        </section>
      </section>
    </ArtificeModal>
  );
};
