import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { ArtificeRoutes } from "../../../../../common";
import { HomeContainer } from "../../../../../common/components";
import registrationImage from "../../../../../assets/img/register.png";
import styles from "./style.module.scss";

export const ResetPasswordSuccess = () => {
  const [t] = useTranslation("common");

  return (
    <HomeContainer>
      <section className={styles.registerSuccessWrapper}>
        <img
          src={registrationImage}
          alt="reset-success"
          className={styles.registerSuccessImg}
        />
        <section className={styles.description}>
          <p className={styles.title}>{t("Reset Password")}</p>
          <p className={styles.subtitle}>
            {t(
              "Your password has been changed successfully. You can log in to the application with your updated credentials."
            )}
          </p>
          <section className={styles.footerActions}>
            <Button
              variant="contained"
              component={NavLink}
              size="large"
              to={ArtificeRoutes.login}
            >
              {t("Go To Login")}
            </Button>
          </section>
        </section>
      </section>
    </HomeContainer>
  );
};
