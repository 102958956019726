import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminService } from "../services";

export const usePurchaseTransactions = () => {
  const [purchaseTransactions, setPurchaseTransactions] = useState<any>();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return;
    }

    AdminService.fetchPurchaseTransactions(Number(id)).then(
      setPurchaseTransactions
    );
  }, [id]);

  return {
    purchaseTransactions,
  };
};
