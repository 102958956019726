import React from "react";
import { PhotoPreview } from "../photo-preview";
import { Notes } from "../../../../common";
import styles from "./style.module.scss";

interface PhotoExamplesListProps {
  photoExamples?: {
    original: string;
    thumbnail: string;
    notes: string;
    name?: string;
  }[];
}

export const PhotoExamplesList = ({
  photoExamples,
}: PhotoExamplesListProps) => {
  return (
    <section className={styles.sectionContent}>
      {photoExamples?.map((photo) => (
        <section className={styles.photoSection}>
          <section key={photo.original} className={styles.photoPreview}>
            <PhotoPreview
              original={photo.original}
              thumbnail={photo.thumbnail}
            />
          </section>
          <section>
            <span>{photo.name}</span>
            <Notes notes={photo.notes} />
          </section>
        </section>
      ))}
    </section>
  );
};
