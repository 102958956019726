import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// todo: check if we can reuse it
import { IPurchase } from "../../../portal/pages/purchases/interfaces";
import { AdminService } from "../services";

export const useAdminPurchase = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [purchase, setPurchase] = useState<IPurchase>();
  const { id } = useParams();

  const fetchPurchase = (purchaseId?: string) => {
    if (!purchaseId) {
      return;
    }

    setIsLoading(true);
    AdminService.fetchPurchaseById(Number(purchaseId))
      .then(setPurchase)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchPurchase(id);
  }, [id]);

  return {
    id,
    purchase,
    isLoading,
    fetchPurchase,
  };
};
