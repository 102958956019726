import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axiosInstance from "../api";
import { ArtificeRoutes } from "../constants";
import { useNotifications } from "../providers";

export const useInterceptors = () => {
  const navigate = useNavigate();
  const { showAlert } = useNotifications();
  const { t } = useTranslation("common");

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          showAlert({
            severity: "error",
            text: t(
              "Your session has expired please log in again to continue using the portal."
            ),
          });
          navigate(ArtificeRoutes.login);
        }
        return Promise.reject(error);
      }
    );
  }, [navigate, showAlert, t]);
};
