import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";

import { ProductPhotoProfileFormType } from "../../../interfaces";
import { getFormFieldProps } from "../../../../../../../common/utils";
import styles from "./style.module.scss";

interface ProfileNameProps {
  formik: ProductPhotoProfileFormType;
}
export const ProfileName = ({ formik }: ProfileNameProps) => {
  const [t] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const formProps = getFormFieldProps(formik, t, tValidation);

  return (
    <TextField
      className={styles.name}
      label={t("Product Name")}
      {...formProps("profileName", t("Product Name"))}
    />
  );
};
