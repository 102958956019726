import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridSortModel, GridValueFormatterParams } from "@mui/x-data-grid";
import { ApmGridColDef } from "../../../../../common/components";
import { BrandsService, SystemBrandsService } from "../services";
import { formatDisplayDate } from "../../../../../common/utils";
import { IBrand, ISystemBrand } from "../interfaces";

const defaultSorting: GridSortModel = [{ field: "name", sort: "asc" }];

export const useRepresentedBrandsList = () => {
  const { t } = useTranslation("portal");
  const [loading, setLoading] = useState<boolean>(false);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSorting);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [systemBrands, setSystemBrands] = useState<ISystemBrand[]>([]);

  const getBrands = async () => {
    setLoading(true);
    const brands = await BrandsService.fetchEntities();
    setBrands(brands);
    setLoading(false);
  };

  const getSystemBrands = async () => {
    const systemBrands = await SystemBrandsService.fetchEntities();
    systemBrands.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    setSystemBrands(systemBrands);
  };

  useEffect(() => {
    getBrands();
  }, []);

  useEffect(() => {
    getSystemBrands();
  }, []);

  const columns: ApmGridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t("Brand Name") as string,
        flex: 1,
      },
      {
        field: "updatedAt",
        headerName: t("Added On") as string,
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) =>
          formatDisplayDate(params.value),
      },
      {
        field: "createdBy",
        headerName: t("Added By") as string,
        flex: 1,
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      brands.map((brand) => ({
        id: brand.name,
        name: brand.name,
        updatedAt: new Date(brand?.updatedAt || ""),
        createdBy: brand?.createdBy?.email,
      })),
    [brands]
  );

  return {
    brands,
    systemBrands,
    rows,
    columns,
    loading,
    sortModel,
    getBrands,
    setSortModel,
  };
};
