import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { FormControl } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import {
  Departments,
  IndustryType,
  State,
  ZipCode,
} from "../../../../../../common/components";
import { getFormFieldProps } from "../../../../../../common/utils";
import { RegistrationFormType } from "../../schemas";
import styles from "../register/style.module.scss";

interface CompanyInformationProps {
  registerForm: RegistrationFormType;
  fromInvitation: boolean;
}

export const CompanyInformation = ({
  registerForm,
  fromInvitation,
}: CompanyInformationProps) => {
  const [t] = useTranslation("common");
  const [tValidation] = useTranslation("validation");
  const fieldProps = getFormFieldProps(registerForm, tValidation);
  const isBrand = registerForm.values.industryType === "brand";
  const isWorkSameAsHQ = registerForm.values.isWorkSameAsHQ;

  return (
    <>
      <IndustryType
        formControlProps={{
          disabled: fromInvitation,
          required: true,
          InputProps: { required: false },
          ...fieldProps("industryType"),
        }}
      />

      {isBrand ? (
        <Departments
          formControlProps={{
            disabled: fromInvitation,
            required: true,
            InputProps: { required: false },
            ...fieldProps("department"),
          }}
        />
      ) : null}
      <TextField
        fullWidth
        required
        margin="normal"
        disabled={fromInvitation}
        InputProps={{ required: false }}
        autoComplete="company-name"
        label={t("Company Name")}
        {...fieldProps("companyName", t("Company Name"))}
      />

      <p className={classnames(styles.label, styles.mt24)}>
        {t("Company Address (HQ)")}
      </p>

      <section className={styles.formRow}>
        <TextField
          fullWidth
          disabled={fromInvitation}
          margin="normal"
          size="medium"
          autoComplete="street"
          required
          InputProps={{ required: false }}
          label={t("Street")}
          {...fieldProps("companyStreet", t("Street"))}
        />
        <TextField
          fullWidth
          disabled={fromInvitation}
          margin="normal"
          size="medium"
          autoComplete="city"
          required
          InputProps={{ required: false }}
          label={t("City")}
          {...fieldProps("companyCity", t("City"))}
        />
      </section>

      <section className={styles.formRow}>
        <State
          formControlProps={{
            disabled: fromInvitation,
            required: true,
            InputProps: { required: false },
            ...fieldProps("companyState"),
          }}
        />
        <ZipCode
          disabled={fromInvitation}
          required={true}
          label={t("Zip Code")}
          formKey="companyZip"
          getFormProps={fieldProps}
        />
      </section>

      <FormControl className={styles.checkboxWrapper}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={registerForm.values.isWorkSameAsHQ}
              onChange={() =>
                registerForm.setFieldValue(
                  "isWorkSameAsHQ",
                  !registerForm.values.isWorkSameAsHQ
                )
              }
            />
          }
          label={
            <p className={styles.checkboxLabel}>
              {t("My work address is same as HQ address")}
            </p>
          }
        />
      </FormControl>

      {!isWorkSameAsHQ ? (
        <>
          <p className={styles.label}>{t("Work Address")}</p>

          <section className={styles.formRow}>
            <TextField
              fullWidth
              margin="normal"
              size="medium"
              autoComplete="street"
              required
              InputProps={{ required: false }}
              label={t("Street")}
              {...fieldProps("workStreet", t("Street"))}
            />
            <TextField
              fullWidth
              margin="normal"
              size="medium"
              autoComplete="city"
              required
              InputProps={{ required: false }}
              label={t("City")}
              {...fieldProps("workCity", t("City"))}
            />
          </section>

          <section className={styles.formRow}>
            <State
              formControlProps={{
                required: true,
                InputProps: { required: false },
                ...fieldProps("workState"),
              }}
            />

            <ZipCode
              label={t("Zip Code")}
              formKey="workZip"
              required={true}
              getFormProps={fieldProps}
            />
          </section>
        </>
      ) : null}
    </>
  );
};
