import { PurchaseFeeItemTypeEnum } from "../../../../common/enums";
import { IPurchaseFeeItem } from "../../interfaces";

export const getPurchaseFee = (
  type: PurchaseFeeItemTypeEnum,
  purchaseFeeItems?: IPurchaseFeeItem[]
) =>
  Number(purchaseFeeItems?.find((feeItem) => feeItem.type === type)?.amount) ||
  0;

export const getTotalProducts = (
  products?: { quantity?: string | number; unitPrice?: string | number }[]
) =>
  products?.reduce(
    (acc, product) =>
      Number(product?.quantity) * Number(product?.unitPrice) + acc,
    0
  ) || 0;
