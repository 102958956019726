import React from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { paymentTypes } from "../../../../../common";
import styles from "./style.module.scss";

export interface PaymentTypeSelectProps extends GridRenderCellParams {
  onChange?: (id: string, paymentType: string) => void;
}

export const PaymentTypeSelect = (props: PaymentTypeSelectProps) => {
  const [t] = useTranslation("admin");

  const onChange = async (evt: any) => {
    props?.onChange && props.onChange(props?.row?.id, evt?.target?.value);
  };

  return (
    <Select
      fullWidth
      className={styles.select}
      value={props.value}
      onChange={onChange}
    >
      {paymentTypes.map((option) => (
        <MenuItem key={option.id} value={option.value}>
          {t(option.name)}
        </MenuItem>
      ))}
    </Select>
  );
};
