import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import styles from "./style.module.scss";

interface ServiceProps {
  xs: number;
  key: number;
  icon: ReactNode;
  title: string;
  text: string;
  setServicePreview: (service: { title: string; text: string }) => void;
}

export const Service = (item: ServiceProps) => {
  const [t] = useTranslation("common");
  const text = item.text.substring(0, 300).split(" ").slice(0, -1).join(" ");

  return (
    <Grid
      key={item.key}
      direction={"column"}
      className={styles.serviceWrapper}
      xs={item.xs}
    >
      <section className={styles.iconWrapper}>{item.icon}</section>
      <p className={styles.title}>{item.title}</p>
      <p className={styles.text}>
        {item.text.length > 300 ? text : item.text}
        {item.text.length > 300 ? (
          <>
            ...
            <p
              className={styles.readMore}
              onClick={() =>
                item.setServicePreview({ title: item.title, text: item.text })
              }
            >
              {t("Read more")}
            </p>
          </>
        ) : null}
      </p>
    </Grid>
  );
};
