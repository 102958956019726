export const statusesMap = new Map([
  ["DRAFT", "Draft"],
  ["CREATED", "Created"],
  ["REQUESTED", "Requested"],
  ["ORDER_COMPLETED", "Order Completed"],
  ["SELLER_SHIPPED", "Seller Shipped"],
  ["ARTIFICE_RECEIVED", "Artifice Received"],
  ["AUTHORIZED", "Authorized"],
  ["AUTHORIZED_FAILED", "Authorize Failed"],
  ["INVOICE_REQUESTED", "Authorized"],
  ["INVOICE_APPROVED", "Captured"],
  ["INVOICE_REJECTED", "Rejected"],
  ["CAPTURED", "Captured"],
  ["CAPTURED_FAILED", "Captured Failed"],
]);
