import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { ArtificeModal } from "../../../../../../common/components";
import { getFormFieldProps } from "../../../../../../common/utils";
import { PurchaseProfileFormType } from "../../interfaces";
import styles from "../purchase-profile-form/style.module.scss";

interface PurchaseProfileSaveModalProps {
  open: boolean;
  handleClose: () => void;
  purchaseProfileForm: PurchaseProfileFormType;
}

export const PurchaseProfileSaveModal = ({
  handleClose,
  open,
  purchaseProfileForm,
}: PurchaseProfileSaveModalProps) => {
  const [t] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const formProps = getFormFieldProps(purchaseProfileForm, t, tValidation);

  return (
    <ArtificeModal
      title={t("Name Your Purchase Profile")}
      open={open}
      onClose={handleClose}
    >
      <section>
        <TextField
          fullWidth
          margin="normal"
          label={t("Purchase Profile Name")}
          {...formProps("profileName", t("Purchase Profile Name"))}
        />
        <section className={styles.modalFooterActions}>
          <Button variant="outlined" onClick={handleClose}>
            {t("Back")}
          </Button>
          <Button
            disabled={!!purchaseProfileForm.errors.profileName}
            variant="contained"
            onClick={() => {
              purchaseProfileForm.submitForm();
              handleClose();
            }}
          >
            {t("Save")}
          </Button>
        </section>
      </section>
    </ArtificeModal>
  );
};
