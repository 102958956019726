export const formatUrlWithPathParams = (
  url: string,
  params: Record<string, number | string>
) => {
  for (const [key, value] of Object.entries(params)) {
    url = url.replace(`:${key}`, value as string);
  }

  return url;
};
