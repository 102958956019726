import React, { useState } from "react";
import classnames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { Alert, Button } from "@mui/material";
import { IPurchase } from "../../interfaces";
import { SummaryGridRow } from "../../../../common/components/summary-grid-row";
import { useCheckoutInfo } from "../../hooks";
import { getFormFieldProps } from "../../../../../../common/utils";
import { ConfirmModal } from "../../../../common";
import { Currency } from "../../../../../../common/components/currency";
import styles from "./style.module.scss";

interface SummaryViewProps {
  purchase?: IPurchase;
  isEditable?: boolean;
  showTotalHold?: boolean;
  canCapture?: boolean;
  canApproveInvoice?: boolean;
  onUpdate?: (values: any) => Promise<void>;
  onCapture?: () => void;
  onCompleteOrder?: () => void;
}

export const SummaryProducts = ({
  purchase,
  isEditable,
  showTotalHold,
  canCapture,
  canApproveInvoice,
  onUpdate,
  onCapture,
  onCompleteOrder,
}: SummaryViewProps) => {
  const [confirmCapture, setConfirmCapture] = useState(false);
  const [confirmCompleteOrder, setConfirmCompleteOrder] = useState(false);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const { t } = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const {
    productTitle,
    productInfo,
    estimatedIncidentalHolds,
    servicesHeader,
    staticService,
    sellerTax,
    shippingTax,
    miscellaneous,
    sellerTaxCharge,
    shippingTaxCharge,
    misc,
    totalRow,
    estimatedHolds,
    checkoutForm,
    totalCapture,
    totalHoldRow,
  } = useCheckoutInfo(purchase, isEditable, showTotalHold);

  const fieldProps = getFormFieldProps(checkoutForm, t, tValidation);

  const handleUpdate = async () => {
    await onUpdate?.(checkoutForm.values);
    checkoutForm.resetForm({ values: checkoutForm.values });
  };

  const updatedEstimatedIncidentalHolds = estimatedIncidentalHolds.map(
    (item) => {
      return {
        ...item,
        subtitle: item.useTrans ? (
          <Trans ns="portal" i18nKey="Incidental Holds Description" />
        ) : (
          item.subtitle
        ),
      };
    }
  );

  const handleConfirmCaptureOpen = () => setConfirmCapture(true);

  const handleConfirmCaptureClose = () => setConfirmCapture(false);

  const handleConfirmCompleteOrderOpen = () => setConfirmCompleteOrder(true);

  const handleConfirmCompleteOrderClose = () => setConfirmCompleteOrder(false);

  const handleConfirmUpdateOpen = () => setConfirmUpdate(true);

  const handleConfirmUpdateClose = () => setConfirmUpdate(false);

  const handleConfirmCapture = () => {
    setConfirmCapture(false);
    onCapture?.();
  };

  const handleConfirmCompleteOrder = () => {
    handleConfirmCompleteOrderClose();
    onCompleteOrder?.();
  };

  const handleConfirmUpdate = () => {
    setConfirmUpdate(false);
    handleUpdate();
  };

  return (
    <>
      <section className={styles.summaryWrapper}>
        <section className={styles.caseInfoWrapper}>
          <div className={styles.caseInfoColumn}>
            <div>
              <p className={styles.caseInfoRow}>{t("Artifice Case Number")}</p>
              <p className={styles.caseInfoRow}>{t("Purchase on Behalf of")}</p>
              <p className={styles.caseInfoRow}>
                {t("Seller Name / Seller URL")}
              </p>
            </div>
            <div>
              <p
                className={classnames(styles.caseInfoRow, styles.caseInfoValue)}
              >
                {purchase?.artificeCaseNumber}
              </p>
              <p
                className={classnames(styles.caseInfoRow, styles.caseInfoValue)}
              >
                {purchase?.purchaseBrand?.name || "-"}
              </p>
              <p
                className={classnames(styles.caseInfoRow, styles.caseInfoValue)}
              >
                {purchase?.sellerRef}
              </p>
            </div>
          </div>
          <div className={styles.caseInfoColumn}>
            <div>
              <p className={styles.caseInfoRow}>{t("Internal Case Number")}</p>
            </div>
            <div>
              <p
                className={classnames(styles.caseInfoRow, styles.caseInfoValue)}
              >
                {purchase?.internalCaseNumber || "-"}
              </p>
            </div>
          </div>
        </section>
        <SummaryGridRow
          gridItems={productTitle}
          classes={{ gridWrapper: styles.highlightedRow }}
        />
        <SummaryGridRow
          gridItems={productInfo}
          classes={{ gridWrapper: styles.borderTop }}
          fieldProps={fieldProps}
        />

        {estimatedHolds ? (
          <SummaryGridRow
            gridItems={updatedEstimatedIncidentalHolds}
            classes={{ gridWrapper: styles.borderTop, title: styles.bold }}
          />
        ) : null}

        <SummaryGridRow
          gridItems={servicesHeader}
          classes={{
            gridWrapper: styles.borderTop,
            title: styles.highlightedBold,
          }}
        />

        <SummaryGridRow
          gridItems={staticService}
          classes={{ gridWrapper: styles.borderVertical }}
        />

        <section className={styles.taxesSection}>
          <section className={styles.taxesWrapper}>
            {sellerTax || isEditable ? (
              <SummaryGridRow
                gridItems={sellerTaxCharge}
                fieldProps={fieldProps}
              />
            ) : null}
            {shippingTax || isEditable ? (
              <SummaryGridRow
                gridItems={shippingTaxCharge}
                fieldProps={fieldProps}
              />
            ) : null}
            {miscellaneous || isEditable ? (
              <SummaryGridRow gridItems={misc} fieldProps={fieldProps} />
            ) : null}

            {showTotalHold &&
            purchase?.paymentStatus !== "CAPTURED" &&
            purchase?.paymentStatus !== "INVOICE_APPROVED" ? (
              <SummaryGridRow
                gridItems={totalHoldRow}
                classes={{
                  gridWrapper: styles.borderTop,
                  title: styles.bold,
                }}
              />
            ) : null}
          </section>
        </section>

        <SummaryGridRow
          gridItems={totalRow}
          classes={{ gridWrapper: styles.highlightedRow }}
        />

        <section className={styles.footer}>
          {isEditable ? (
            <Button
              variant="contained"
              onClick={handleConfirmUpdateOpen}
              disabled={!checkoutForm.dirty}
            >
              Save Purchase Changes
            </Button>
          ) : null}

          {canCapture ? (
            <Button
              disabled={checkoutForm.dirty}
              variant="contained"
              onClick={handleConfirmCaptureOpen}
            >
              Capture
            </Button>
          ) : null}

          {canApproveInvoice ? (
            <Button
              disabled={checkoutForm.dirty}
              variant="contained"
              onClick={handleConfirmCompleteOrderOpen}
            >
              {t("Complete the Order")}
            </Button>
          ) : null}
        </section>
        {isEditable && !checkoutForm.dirty ? (
          <Alert severity="warning" sx={{ marginTop: "16px" }}>
            Updating a purchase is only possible when it has changes.
          </Alert>
        ) : null}

        {canCapture && checkoutForm.dirty ? (
          <Alert severity="warning" sx={{ marginTop: "16px" }}>
            Capturing a purchase is only possible when it has no unsaved
            changes.
          </Alert>
        ) : null}
      </section>
      <ConfirmModal
        title={t("Confirm")}
        confirmText={t("Do you want to complete the order")}
        confirmActionText={t("Complete the Order")}
        open={confirmCompleteOrder}
        onClose={handleConfirmCompleteOrderClose}
        onConfirm={handleConfirmCompleteOrder}
      />

      <ConfirmModal
        title={t("Confirm")}
        confirmText={t("Do you want to capture")}
        confirmActionText={t("Capture")}
        open={confirmCapture}
        itemName={
          <Currency
            value={totalCapture}
            classes={{ currencyWrapper: styles.currencyWrapper }}
          />
        }
        onClose={handleConfirmCaptureClose}
        onConfirm={handleConfirmCapture}
      />

      <ConfirmModal
        title={t("Confirm")}
        confirmText={t("Do you want to save purchase changes")}
        confirmActionText={t("Save")}
        open={confirmUpdate}
        onClose={handleConfirmUpdateClose}
        onConfirm={handleConfirmUpdate}
      />
    </>
  );
};
