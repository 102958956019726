import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { PurchaseFormType } from "../../schemas";
import { useBrandField } from "../../hooks";
import { ConfirmModal } from "../../../../common";
import { IBrand, IBrandField } from "../../../represented-brands/interfaces";
import styles from "./style.module.scss";

interface BrandFieldProps {
  value: IBrandField | null;
  label: string;
  fieldName: string;
  purchaseForm: PurchaseFormType;
  purchaseBrands: IBrand[];
  refetchBrands: () => Promise<void>;
  index?: number;
}

export const BrandField = ({
  value,
  label,
  index,
  fieldName,
  purchaseForm,
  purchaseBrands,
  refetchBrands,
}: BrandFieldProps) => {
  const [t] = useTranslation("portal");
  const { modalOpen, loading, filter, handleAdd, onBrandChange, handleClose } =
    useBrandField(purchaseForm, purchaseBrands, value, fieldName, index);

  return (
    <>
      <Autocomplete
        classes={{ root: styles.inputRoot }}
        autoHighlight
        freeSolo
        selectOnFocus
        clearOnBlur
        value={value?.name || null}
        onChange={onBrandChange}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const existingBrand = filtered.find(
            (brand) => brand.name === params.inputValue
          );

          if (params.inputValue.trim() !== "" && !existingBrand) {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        options={purchaseBrands}
        getOptionLabel={(option: any) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.name}>
            {option.name}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label={t(label)} />}
      />
      <ConfirmModal
        open={modalOpen}
        actionInProgress={loading}
        confirmText={
          <Trans
            ns="portal"
            i18nKey="Add brand on purchase text"
            values={{
              brandName: value?.name,
            }}
          />
        }
        title={t("Add Brand")}
        confirmActionText={t("Add")}
        onClose={handleClose}
        onConfirm={async () => {
          const brand = await handleAdd();
          await refetchBrands();
          purchaseForm.setFieldValue(fieldName, brand);
        }}
      />
    </>
  );
};
