import { useMemo } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  PurchaseProfilePageSchema,
  PurchaseProfileFormValuesType,
} from "../schemas";
import {
  useOrganizations,
  PurchaseProfileService,
  PurchaseProfileFormService,
} from "../../../common";
import { ArtificeRoutes, useNotifications } from "../../../../../common";
import { useAuth } from "../../../../core";

export const useCreatePurchaseProfile = () => {
  const { t } = useTranslation("portal");
  const { showAlert } = useNotifications();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { userOrganization } = useOrganizations();

  const initialValues = useMemo(
    () =>
      PurchaseProfileFormService.formInitialValues({
        organization: userOrganization,
        user,
      }),
    [userOrganization, user]
  );

  const createPurchaseProfile = async (
    formValues: PurchaseProfileFormValuesType
  ) => {
    try {
      await PurchaseProfileService.createPurchaseProfile(
        PurchaseProfileFormService.fromForm(formValues)
      );
      showAlert({
        text: t("Successfully created"),
        severity: "success",
      });
      navigate(ArtificeRoutes.purchaseProfiles);
    } catch (err) {
      showAlert({
        text: t("There was an error. Please try again"),
        severity: "error",
      });
    }
  };

  const profileForm = useFormik({
    validationSchema: PurchaseProfilePageSchema,
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: createPurchaseProfile,
  });

  return { profileForm };
};
