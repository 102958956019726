import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { usePurchaseList } from "../../../portal/pages/purchases/hooks";
import {
  useProductPhotoProfileList,
  usePurchaseProfileList,
} from "../../../portal";
import { ArtificeRoutes } from "../../../../common";

export const MakePurchase = () => {
  const [t] = useTranslation("portal");
  const location = useLocation();

  const { purchases, loading: PurchasesLoading } = usePurchaseList();
  const { productPhotoProfiles, loading: photoProfilesLoading } =
    useProductPhotoProfileList();
  const { purchaseProfiles, loading: purchaseProfilesLoading } =
    usePurchaseProfileList();

  return location.pathname === ArtificeRoutes.purchases &&
    !purchases.length &&
    (!productPhotoProfiles.length || !purchaseProfiles.length) &&
    !PurchasesLoading &&
    !photoProfilesLoading &&
    !purchaseProfilesLoading ? (
    <Button
      variant="outlined"
      component={NavLink}
      size="medium"
      to={ArtificeRoutes.createPurchase}
      endIcon={<KeyboardArrowRight />}
    >
      {t("Make a Purchase")}
    </Button>
  ) : null;
};
