import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { brandTypes } from "../../../../../common";
import { ConfirmModal } from "../../../../portal";
import { BrandTypeEnum } from "../../../../portal/common/enums";
import styles from "./style.module.scss";

export interface BrandTypeSelectProps extends GridRenderCellParams {
  onChange?: (name: string) => Promise<void>;
}

export const BrandTypeSelect = (props: BrandTypeSelectProps) => {
  const [t] = useTranslation("admin");
  const [updateTypeModalOpen, setUpdateTypeModalOpen] =
    useState<boolean>(false);

  const handleModalOpen = () => {
    setUpdateTypeModalOpen(true);
  };

  const handleModalClose = () => {
    setUpdateTypeModalOpen(false);
  };

  return (
    <>
      <Select
        fullWidth
        disabled={props.value === BrandTypeEnum.SYSTEM}
        className={styles.select}
        value={props.value}
        onChange={handleModalOpen}
      >
        {brandTypes.map((option) => (
          <MenuItem key={option.id} value={option.value}>
            {t(option.name)}
          </MenuItem>
        ))}
      </Select>
      <ConfirmModal
        open={updateTypeModalOpen}
        title={t("Update Brand Type")}
        confirmText={t("Update brand type to System for Brand")}
        confirmActionText={t("Update")}
        itemName={props?.row?.id}
        onClose={handleModalClose}
        onConfirm={async () => {
          props?.onChange && (await props?.onChange(props?.row?.id));
          handleModalClose();
        }}
      />
    </>
  );
};
