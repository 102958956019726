import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GridSortModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { IPurchaseListItem } from "../interfaces";
import { AdminService } from "../services";
import { InvoiceEnum } from "../../../../common/enums";
import { ApmGridColDef } from "../../../../common/components";
import { formatDisplayDate } from "../../../../common/utils";
import { useOrganizationsList } from "./use-organizations-list";

const defaultSorting: GridSortModel = [{ field: "requestedAt", sort: "desc" }];

const purchaseStatus: { [key: string]: string } = {
  CREATED: "Created",
  REQUESTED: "Requested",
  ORDER_COMPLETED: "Order Completed",
  CAPTURED: "Captured",
  PROCESSING: "Processing",
  SELLER_SHIPPED: "Seller Shipped",
  ARTIFICE_RECEIVED: "Artifice Received",
};

const purchasePaymentStatus: { [key: string]: string } = {
  AUTHORIZED: "Authorized",
  AUTHORIZED_FAILED: "Card Failed",
  CAPTURED: "Cost Captured",
  CAPTURED_FAILED: "Capture Failed",
  INVOICE_REQUESTED: "Authorized",
  INVOICE_APPROVED: "Cost Captured",
  INVOICE_REJECTED: "Capture Failed",
};

type IFilterOption = {
  value: string | string[];
  label: string;
} | null;

export const usePurchaseList = () => {
  const { t } = useTranslation("admin");
  const [loading, setLoading] = useState<boolean>(true);
  const [organization, setOrganization] = useState<IFilterOption>();
  const [status, setStatus] = useState<IFilterOption>({
    value: "REQUESTED",
    label: "Requested",
  });
  const [paymentStatus, setPaymentStatus] = useState<IFilterOption>();
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSorting);
  const [purchases, setPurchases] = useState<IPurchaseListItem[]>([]);
  const { organizations } = useOrganizationsList();

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        setLoading(true);
        const response = await AdminService.fetchAllPurchases();
        setPurchases(response);
      } finally {
        setLoading(false);
      }
    };

    getAllUsers();
  }, [setPurchases]);

  const columns: ApmGridColDef[] = useMemo(
    () => [
      {
        field: "orgName",
        headerName: t("Organization") as string,
        flex: 1,
        handleContentClick: true,
      },
      {
        field: "artificeCaseNumber",
        headerName: t("Artifice Case Number") as string,
        flex: 1,
      },
      {
        field: "createdBy",
        headerName: t("Created By") as string,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.firstName} ${params.row.lastName}`,
      },
      {
        field: "sellerRef",
        headerName: t("Seller") as string,
        flex: 1,
      },
      {
        field: "status",
        headerName: t("Purchase Status") as string,
        flex: 1,
      },
      {
        field: "paymentStatus",
        headerName: t("Purchase Payment Status") as string,
        flex: 1,
      },
      {
        field: "requestedAt",
        headerName: t("Requested At") as string,
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) =>
          formatDisplayDate(params.value),
      },
      {
        field: "reports",
        headerName: t("Reports") as string,
        flex: 1,
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      purchases
        .filter((purchase) => {
          const matchOrganization =
            !organization?.value || purchase.orgName === organization?.value;
          const matchStatus =
            !status?.value || purchase.status === status?.value;

          const matchPaymentStatus =
            !paymentStatus?.value ||
            paymentStatus?.value.includes(purchase.paymentStatus);

          return matchOrganization && matchStatus && matchPaymentStatus;
        })
        .map((purchase) => ({
          id: purchase.id,
          artificeCaseNumber: purchase.artificeCaseNumber,
          lastName: purchase.createdBy?.lastName || "",
          firstName: purchase.createdBy.firstName || "",
          sellerRef: purchase.sellerRef,
          orgId: purchase.orgId,
          orgName: purchase.orgName,
          requestedAt: new Date(purchase.requestedAt),
          status: purchaseStatus[purchase.status],
          paymentStatus: purchasePaymentStatus[purchase.paymentStatus],
          byInvoice: purchase.paymentStatus?.includes(InvoiceEnum.INVOICE),
          reports: purchase.reports,
          canDelete: false,
          canUpdate: false,
        })),
    [purchases, organization, status, paymentStatus]
  );

  const organizationOptions: any = useMemo(
    () =>
      organizations
        .map((org) => ({ value: org.name, label: org.name }))
        .sort((a, b) => {
          const labelA = a.label.toLowerCase();
          const labelB = b.label.toLowerCase();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }),
    [organizations]
  );

  return {
    rows,
    columns,
    loading,
    sortModel,
    organization,
    organizationOptions,
    status,
    paymentStatus,
    setOrganization,
    setStatus,
    setSortModel,
    setPaymentStatus,
  };
};
