import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import { mbToBytes } from "../../../../../../common/utils";
import { UPLOAD_PHOTO_MAX_SIZE_MB } from "../../../../../../common";
import { ConfirmModal } from "../../../../common";
import { PhotoPreview } from "../photo-preview";
import styles from "./style.module.scss";

interface DragAndDropProps {
  onDelete: () => void;
  onUpload: (file: File) => void;
  originalUrl?: string;
  thumbnailUrl?: string;
}

export const DragAndDrop = ({
  originalUrl = "",
  thumbnailUrl = "",
  onUpload,
  onDelete,
}: DragAndDropProps) => {
  const [t] = useTranslation("portal");
  const [file, setFile] = useState<string>(originalUrl);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const handleOpen = () => setDeleteOpen(true);

  const handleClose = () => setDeleteOpen(false);

  const handleDelete = () => {
    setFile("");
    setDeleteOpen(false);
    onDelete();
  };

  const sizeValidator = useCallback((file: any) => {
    if (file.size > mbToBytes(UPLOAD_PHOTO_MAX_SIZE_MB)) {
      return {
        code: "file-too-large",
        message: "The maximum file size is {{maxMb}}MB",
      };
    }

    return null;
  }, []);

  const onDrop = useCallback(
    ([file]: File[]) => {
      onUpload(file);
      setFile(URL.createObjectURL(file));
    },
    [onUpload, setFile]
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    validator: sizeValidator,
    maxFiles: 1,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
  });

  const fileError = fileRejections[0]?.errors?.[0]?.message;

  return (
    <>
      <section className={styles.dragWrapper}>
        {file ? (
          <>
            <IconButton
              size="small"
              onClick={handleOpen}
              classes={{
                root: styles.deleteBtn,
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
            <PhotoPreview original={file} thumbnail={thumbnailUrl || file} />
          </>
        ) : (
          <>
            <div className={styles.dropzone} {...getRootProps()}>
              <input {...getInputProps()} />
              {fileError ? (
                <span className={styles.fileError}>
                  {t(fileError, {
                    maxMb: UPLOAD_PHOTO_MAX_SIZE_MB,
                  })}
                </span>
              ) : (
                <section>
                  <DownloadIcon className={styles.dropIcon} />
                  <p className={styles.dropHint}>
                    {t("Drag and drop photo here, or")}
                  </p>
                  <p className={styles.dropLink}>{t("Choose a Photo.")}</p>
                </section>
              )}
            </div>
          </>
        )}
      </section>

      <ConfirmModal
        open={deleteOpen}
        confirmText={t("Do you want to delete photo?")}
        onClose={handleClose}
        onConfirm={handleDelete}
      />
    </>
  );
};
