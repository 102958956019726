import axios from "../../../../common/api";
import {
  IOrganizationListItem,
  IPurchaseListItem,
  IPurchaseStatusHistory,
  IUserListItem,
} from "../interfaces";
import { IPurchase } from "../../../portal/pages/purchases/interfaces";
import { FileError } from "../../../../common/utils";
import {
  IBrand,
  ISystemBrand,
} from "../../../portal/pages/represented-brands/interfaces";
import { BrandTypeEnum } from "../../../portal/common/enums";

export class AdminService {
  static async fetchAllOrganizations(): Promise<IOrganizationListItem[]> {
    return axios
      .get("/api/v1/um/system/organizations")
      .then((response) => response.data);
  }

  static async fetchAllUsers(): Promise<IUserListItem[]> {
    return axios
      .get("/api/v1/um/system/users")
      .then((response) => response.data);
  }

  static async fetchAllPurchases(): Promise<IPurchaseListItem[]> {
    return axios
      .get("/api/v1/system/purchases")
      .then((response) => response.data);
  }

  static async fetchOrgBrands(): Promise<IBrand[]> {
    const data = await axios
      .get("/api/v1/system/brands")
      .then((response) => response.data);

    return data.map((brand: IBrand) => {
      return { ...brand, type: BrandTypeEnum.ORGANIZATION };
    });
  }

  static async fetchSystemBrands(): Promise<ISystemBrand[]> {
    const data = await axios
      .get("/api/v1/system-brands")
      .then((response) => response.data);

    return data.map((brand: IBrand) => {
      return { ...brand, type: BrandTypeEnum.SYSTEM };
    });
  }

  static async fetchPurchaseById(id: number): Promise<IPurchase> {
    return axios
      .get(`/api/v1/system/purchases/${id}`)
      .then((response) => response.data);
  }

  static async fetchPurchaseFeesById(id: number): Promise<IPurchase> {
    return axios
      .get(`/api/v1/system/purchases/${id}/fees`)
      .then((response) => response.data);
  }

  static fetchPurchaseTransactions(id: number): Promise<unknown> {
    return axios
      .get(`/api/v1/payments/purchases/${id}/transactions`)
      .then((response) => response.data);
  }

  static updateOrganization(orgId: string, paymentType: string) {
    return axios.patch(`/api/v1/um/organizations/${orgId}`, {
      paymentType,
    });
  }

  static attachReportToPurchase(
    purchaseId: number,
    purchaseReports: {
      fileId: number;
      type: string;
      title: string;
      description: string;
    }[]
  ): Promise<IPurchase> {
    return axios
      .post(`/api/v1/purchase-reports/purchases/${purchaseId}`, {
        purchaseReports,
      })
      .then((response) => response.data);
  }

  static async uploadReport(file: File): Promise<number> {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "/api/v1/files/purchase-report",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      return response.data.id;
    } catch (err) {
      throw new FileError(file.name);
    }
  }

  static removeReportById(fileId: number) {
    return axios.delete(`/api/v1/purchase-reports/${fileId}`);
  }

  static handleCapture(purchaseId: number) {
    return axios.post(`/api/v1/payments/purchases/${purchaseId}/capture`);
  }

  static confirmPurchaseInvoiceStatus(purchaseId: number) {
    return axios.patch(
      `/api/v1/payments/purchases/${purchaseId}/invoices/status`,
      { invoiceStatus: "INVOICE_APPROVED" }
    );
  }

  static updatePurchaseFees(purchaseId: number, values: any) {
    return axios
      .put(`/api/v1/system/purchases/${purchaseId}/fees`, values)
      .then((response) => response.data);
  }

  static fetchPurchaseStatuses() {
    return axios
      .get("/api/v1/system/statuses/purchase")
      .then((response) => response.data);
  }

  static async fetchPurchaseStatusHistory(
    purchaseId: string
  ): Promise<IPurchaseStatusHistory[]> {
    return axios
      .get(`/api/v1/system/purchase-status-history/${purchaseId}`)
      .then((response) => response.data);
  }

  static async updatePurchaseStatus(
    purchaseId: number,
    status: string,
    sendEmail: boolean
  ) {
    return axios
      .patch(`/api/v1/system/purchases/${purchaseId}/status`, {
        status,
        sendEmail,
      })
      .then((response) => response.data);
  }

  static async addSystemBrand(name: string): Promise<ISystemBrand> {
    return axios
      .post("/api/v1/system/system-brands", { name })
      .then((response) => response.data);
  }

  static async deleteSystemBrand(id: number): Promise<void> {
    return axios
      .delete(`/api/v1/system/system-brands/${id}`)
      .then((response) => response.data);
  }

  static async updateSystemBrand(
    name: string,
    oldName: string
  ): Promise<ISystemBrand> {
    return axios
      .put("/api/v1/system/system-brands", { name, oldName })
      .then((response) => response.data);
  }

  static async updateBrand(name: string, oldName: string): Promise<IBrand> {
    return axios
      .put("/api/v1/system/brands", { name, oldName })
      .then((response) => response.data);
  }
}
