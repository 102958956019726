import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ArtificeModal } from "../../../../../common/components";
import styles from "./style.module.scss";

interface DeleteModalProps {
  open: boolean;
  confirmText?: string | JSX.Element;
  title?: string;
  itemName?: string | ReactNode;
  actionInProgress?: boolean;
  confirmActionText?: string;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
}
export const ConfirmModal = ({
  open,
  onClose,
  title,
  onConfirm,
  confirmText,
  confirmActionText,
  actionInProgress,
  itemName,
}: DeleteModalProps) => {
  const { t } = useTranslation("portal");
  const actionText = confirmActionText || t("Delete");
  const modalTitle = title || t("Delete");

  return (
    <ArtificeModal title={modalTitle} open={open} onClose={onClose}>
      <section>
        <p className={styles.deleteContent}>
          {confirmText}
          {itemName && (
            <>
              <span className={styles.deleteItemTitle}> {itemName}</span>?
            </>
          )}
        </p>
        <section className={styles.modalFooterActions}>
          <Button variant="outlined" size="medium" onClick={onClose}>
            {t("Cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={onConfirm}
            loading={actionInProgress}
          >
            {actionText}
          </LoadingButton>
        </section>
      </section>
    </ArtificeModal>
  );
};
