import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { AuthService, IAcceptedInvitation } from "../../../common";
import { registrationSchema, RegistrationFormValuesType } from "../schemas";
import { ArtificeRoutes } from "../../../../../common";
import { RegisterFormService, RegisterService } from "../services";
import { useQuery } from "../../../../../common/hooks";

const codeResponse: { [key: string]: string } = {
  LINK_EXPIRED: "Email verification link expired.",
  ACCEPTED: "Email is already verified, please proceed to Log In page.",
  DECLINED:
    "Email verification link is invalid, please contact your administrator.",
};

export const useRegistration = () => {
  const [invitationErrorMessage, setInvitationErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [invitationData, setInvitationData] = useState<IAcceptedInvitation>();
  const navigate = useNavigate();
  const [error, setError] = useState<Record<string, string>>();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const search = useQuery();
  const invitationToken = search.get("token");

  const acceptInvitation = async (token: string) => {
    try {
      const data = await RegisterService.acceptInvitation(token);
      setInvitationData(data);
    } catch (error: any) {
      setInvitationErrorMessage(
        codeResponse[error?.response?.data?.code] ||
          "There was an error with your invitation, please try again later or ask for a new invitation."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(!!invitationToken);
    setInvitationData(undefined);

    if (invitationToken) {
      acceptInvitation(invitationToken);
    }
  }, [invitationToken]);

  const getToken = async () => {
    if (!process.env.REACT_APP_RECAPTCHA_KEY) {
      return "";
    }

    if (!executeRecaptcha) {
      throw new Error("Execute recaptcha not yet available");
    }

    setError(undefined);

    return executeRecaptcha("register");
  };

  const initialValues = useMemo(
    () =>
      invitationData
        ? RegisterFormService.toForm(invitationData)
        : RegisterFormService.getInitialValues(),
    [invitationData]
  );

  const handleRegistration = async (values: RegistrationFormValuesType) => {
    try {
      const tokenForCaptcha = await getToken();
      if (invitationToken) {
        await AuthService.registerByInvite(
          tokenForCaptcha,
          RegisterFormService.fromForm(values),
          invitationToken
        );
        navigate(ArtificeRoutes.login);
      } else {
        await AuthService.register(
          tokenForCaptcha,
          RegisterFormService.fromForm(values)
        );
        navigate(ArtificeRoutes.registerSuccess);
      }
    } catch (err) {
      setError(err as Record<string, string>);
    }
  };

  const registerForm = useFormik({
    validationSchema: registrationSchema,
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: handleRegistration,
  });

  useEffect(() => {
    setError(undefined);
  }, [setError, registerForm.values]);

  return {
    registerForm,
    error,
    loading,
    invitationErrorMessage,
    fromInvitation: Boolean(invitationToken),
  };
};
