export interface IShippingPreferences {
  label: string;
  value: string;
}

export const SHIP_BY_GROUND = "SHIP_BY_GROUND";
export const TWO_DAYS_SHIPPING = "TWO_DAYS_SHIPPING";
export const SHIP_OVERNIGHT = "SHIP_OVERNIGHT";

export const SHIPPING_PREFERENCES: readonly IShippingPreferences[] = [
  { label: "Ship by ground", value: SHIP_BY_GROUND },
  { label: "2 days shipping", value: TWO_DAYS_SHIPPING },
  { label: "Ship overnight", value: SHIP_OVERNIGHT },
];
