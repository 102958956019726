interface IPricing {
  title?: string;
  description?: string;
  price?: string;
}

export const pricing: IPricing[][] = [
  [
    {
      title: "Test Purchase (Artifice Platform)",
      description:
        "Up to 5 unique items per seller. Unlimited number of each of the items selected. Includes purchase, screenshots, product retrieval, photographing, processing as evidence, and full report including recommended next steps.",
      price: "$285.00",
    },
    {
      title: "Public Records Checks",
      description: "(Single Level - US)",
      price: "$50.00",
    },
    {
      title: "Product Storage",
      description: "Banker Box to full",
      price: "$5.00 Mo.",
    },
  ],
  [
    {
      title: "Consulting Services",
      description: "With platform services",
      price: "$250 Per Hour",
    },
    {
      title: "Public Records Checks",
      description: "(Deep Dive - US)",
      price: "$75.00 Per Hour",
    },
    {
      title: "Product Storage",
      description: "Retrieval & Restock",
      price: "$25 ea.",
    },
  ],
  [
    {
      title: "Consulting Services",
      description: "Without platform services",
      price: "$300 Per Hour",
    },
    {
      title: "Public Records Checks",
      description: "(After 5 or more Test Purchases per Mo - US)",
      price: "Complimentary",
    },
    {
      title: "Destruction",
      description: "Due to government regs determined on case by case",
      price: "Custom",
    },
  ],
  [{ title: "Managed Investigation Services", price: "$145 Per Hour" }, {}, {}],
  [
    {
      title: "Secondments",
      description: "Case by Case dependent on experience and work type",
      price: "Custom",
    },
    {},
    {},
  ],
  [
    {
      title: "Deposition and Expert Testimony",
      description: "Half Day/Full Day",
      price: "$500 / $1,000 + costs",
    },
    {},
    {},
  ],
  [
    {
      title: "Intelligence Development",
      price: "$145 Per Hour",
    },
    {},
    {},
  ],
];
