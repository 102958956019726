import { InferType, object, boolean, string, array, mixed, number } from "yup";
import {
  productPhotoNameValidation,
  productPhotoNotesValidation,
  productPhotoProfileNameValidation,
  validationMessage,
} from "../../../../../common";

const AttachmentsValidation = array().of(
  object()
    .shape({
      id: number(),
      name: productPhotoNameValidation,
      notes: productPhotoNotesValidation,
      file: mixed(),

      // used in edit mode, so we should have it here for strict types
      fileId: number(),
      thumbnail: string(),
      original: string(),
    })
    .required()
);

export const ProductPhotoProfileSchema = {
  // ScreenShoots
  listingPage: boolean().required(),
  orderConfirmation: boolean().required(),
  checkoutReceipt: boolean().required(),
  screenShotsNotes: productPhotoNotesValidation,

  // Documents Receipts Written Materials
  allDocuments: boolean().required(),
  documentsNotes: productPhotoNotesValidation,

  // OuterPackaging
  shippingPackage: boolean().required(),
  shippingLabelsOnly: boolean().required(),
  disposeOuterPackaging: boolean().required(),
  outerPackagingNotes: productPhotoNotesValidation,

  // CustomizeProductPackaging
  productPackagingTop: boolean().required(),
  productPackagingTopNotes: productPhotoNotesValidation,
  productPackagingBottom: boolean().required(),
  productPackagingBottomNotes: productPhotoNotesValidation,
  productPackagingFront: boolean().required(),
  productPackagingFrontNotes: productPhotoNotesValidation,
  productPackagingBack: boolean().required(),
  productPackagingBackNotes: productPhotoNotesValidation,
  productPackagingPhotoExamples: AttachmentsValidation,

  // CustomizePhotoItemOrPurchasedItem
  purchasedPhotoExamples: AttachmentsValidation,
};

export const ProductPhotoProfileFormSchema = object({
  profileName: productPhotoProfileNameValidation.required(
    validationMessage.required
  ),

  //Name
  isNameCompleted: boolean(),

  // ScreenShoots
  isScreenshotsCompleted: boolean(),

  // Documents Receipts Written Materials
  isDocumentsReceiptsWrittenMaterialsCompleted: boolean(),

  // OuterPackaging
  isOuterPackagingCompleted: boolean(),

  // CustomizeProductPackaging
  isCustomizeProductPackagingCompleted: boolean(),

  // CustomizePhotoItemOrPurchasedItem
  isPurchasedItemCompleted: boolean(),

  ...ProductPhotoProfileSchema,
});

export type ProductPhotoProfileFormValuesType = InferType<
  typeof ProductPhotoProfileFormSchema
>;
