import React from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { PageTitle } from "../../../../common";
import { ArtificeRoutes } from "../../../../../../common";
import { usePurchase } from "../../hooks";
import { SummaryView } from "../../components";
import styles from "./style.module.scss";

export const Summary = () => {
  const [t] = useTranslation("portal");
  const { purchase, isLoading, id } = usePurchase();

  return (
    <section>
      <PageTitle
        backUrl={`${ArtificeRoutes.purchases}/${id}`}
        title={t("Order Summary")}
      />
      {isLoading ? (
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <SummaryView purchase={purchase} />
      )}
    </section>
  );
};
