import { createTheme, PaletteColorOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface CustomPalette {
    light: PaletteColorOptions;
    dark: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    light: true;
    dark: true;
  }

  interface ButtonPropsSizeOverrides {
    "medium-m": true;
  }
}
declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    "medium-m": true;
  }
}

declare module "@mui/material/InputBase" {
  interface InputBasePropsSizeOverrides {
    "medium-m": true;
  }
}

export const theme = createTheme({
  typography: { fontFamily: "Open Sans, sans-serif" },
  palette: {
    primary: {
      main: "#0058DD",
    },
    secondary: {
      main: "#dbedff",
      contrastText: "#0058DD",
      dark: "#b0d8ff",
    },
    light: {
      main: "#FFFFFF",
      contrastText: "#263238",
    },
    dark: {
      main: "#263238",
      contrastText: "#263238",
    },
  },
  components: {
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fontSize: 14,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: 16,
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { size: "medium" },
          style: {
            fontSize: 16,
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { size: "medium-m" },
          style: {
            height: "48px",
            "> label": {
              top: "-3px",
            },
          },
        },
      ],
    },
    MuiInputBase: {
      variants: [
        {
          props: { size: "medium-m" },
          style: {
            height: "48px",
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { size: "large" },
          style: {
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: 0,
            padding: "16px 32px",
          },
        },
        {
          props: { size: "medium" },
          style: {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            borderRadius: 0,
            padding: "12px 32px",
          },
        },
        {
          props: { size: "small" },
          style: {
            height: "40px",
          },
        },
        {
          props: { size: "medium-m" },
          style: {
            height: "48px",
          },
        },
      ],
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            borderBottom: "1px solid #C4C4C4",
          },
          justifyContent: "flex-start",
        },
        content: {
          flexGrow: "initial",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "24px 27px",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          zIndex: 2,
          overflowAnchor: "auto",
          "&.Mui-expanded": {
            // marginBottom: 0,
            // marginTop: 0,
          },
        },
      },
    },
  },
});
