import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../../../../common";
import { UserManagementService } from "../services";

export const useUserInvitation = () => {
  const { t } = useTranslation("portal");
  const { t: tCommon } = useTranslation("common");
  const [loading, setLoading] = useState<boolean>(false);
  const { showAlert } = useNotifications();

  const inviteUser = async (email: string) => {
    try {
      setLoading(true);
      await UserManagementService.inviteUser(email);
      showAlert({
        text: t("Invitation is sent."),
        severity: "success",
      });
    } catch (error) {
      const errorMessage =
        (error as Record<string, string>)?.code === "INVITATION_EXISTS"
          ? "User with this email already exists."
          : "Something went wrong. Please try again.";

      showAlert({
        text: tCommon(errorMessage),
        severity: "error",
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    inviteUser,
  };
};
