import { CALENDLY_URL } from "../constants";

declare global {
  interface Window {
    Calendly: {
      initPopupWidget: (options: { url: string }) => void;
    };
  }
}

export const scheduleMeeting = () => {
  window.Calendly?.initPopupWidget({
    url: CALENDLY_URL,
  });
};
