import React from "react";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { ArtificeRoutes } from "../../../../../../common";
import { HomeContainer } from "../../../../../../common/components";
import registrationImage from "../../../../../../assets/img/register.png";
import styles from "./style.module.scss";

export const RegisterSuccess = () => {
  const [t] = useTranslation("common");

  return (
    <HomeContainer>
      <section className={styles.registerSuccessWrapper}>
        <img
          src={registrationImage}
          alt="registration-success"
          className={styles.registerSuccessImg}
        />
        <section className={styles.description}>
          <p className={styles.title}>
            {t("Your email address needs to be verified")}
          </p>
          <p className={styles.subtitle}>
            <Trans ns="common" i18nKey="Email sent">
              We sent you a confirmation email. Check your email and click on
              the confirmation button to continue.
              <br /> Be patient, it can take few minutes, please do not register
              again.
            </Trans>
          </p>
          <section className={styles.footerActions}>
            <Button
              variant="contained"
              component={NavLink}
              size="large"
              to={ArtificeRoutes.home}
            >
              {t("Back to Home Page")}
            </Button>
          </section>
        </section>
      </section>
    </HomeContainer>
  );
};
