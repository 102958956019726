import { object, string } from "yup";
import { InferType } from "yup";
import {
  emailValidation,
  phoneValidation,
  validationMessage,
} from "../../../../../common";

export const contactUsSchema = object({
  userName: string().required(validationMessage.required),
  companyName: string().required(validationMessage.required),
  industryType: string().required(validationMessage.required),
  title: string(),
  phone: phoneValidation.required(validationMessage.required),
  email: emailValidation.required(validationMessage.required),
  message: string(),
});

export type ContactUsFormValuesType = InferType<typeof contactUsSchema>;
