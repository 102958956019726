import * as React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import styles from "./style.module.scss";

export const OrganizationNameChip = (props: GridRenderCellParams) => {
  return (
    <div className={styles.orgNamesWrapper}>
      {props?.value.map((val: string) => (
        <Chip className={styles.orgName} key={val} label={val} />
      ))}
    </div>
  );
};
