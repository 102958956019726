export const paymentTypes = [
  {
    id: "all",
    value: "ALL",
    name: "All",
  },
  {
    id: "credit-card",
    value: "CREDIT_CARD",
    name: "Credit Card",
  },
];
