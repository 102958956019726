export const departmentTypes = [
  {
    id: "brand-protection",
    name: "Brand Protection",
  },
  {
    id: "legal",
    name: "Legal",
  },
  {
    id: "security",
    name: "Security",
  },
  {
    id: "other",
    name: "Other",
  },
];
