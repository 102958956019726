import { IAcceptedInvitation, IRegister } from "../../../common";
import { RegistrationFormValuesType } from "../schemas";

export class RegisterFormService {
  static getInitialValues(): RegistrationFormValuesType {
    return {
      industryType: "",
      department: "",
      companyName: "",

      companyStreet: "",
      companyCity: "",
      companyState: "",
      companyZip: "",

      isWorkSameAsHQ: false,
      workStreet: "",
      workCity: "",
      workState: "",
      workZip: "",

      firstName: "",
      lastName: "",
      title: "",
      directPhone: "",
      directPhoneCountryCode: "1",
      mobilePhone: "",
      mobilePhoneCountryCode: "1",
      isMobileSameAsDirect: true,

      email: "",
      password: "",
      confirmPassword: "",
      termsAndConditions: false,
    };
  }

  static toForm(invitation: IAcceptedInvitation): RegistrationFormValuesType {
    return {
      industryType: invitation.organization.industryType,
      department: invitation.organization.department,
      companyName: invitation.organization.name,

      companyStreet: invitation.organization.contactInformation.street,
      companyCity: invitation.organization.contactInformation.city,
      companyState: invitation.organization.contactInformation.state,
      companyZip: invitation.organization.contactInformation.zip,

      isWorkSameAsHQ: false,
      workStreet: "",
      workCity: "",
      workState: "",
      workZip: "",

      firstName: "",
      lastName: "",
      title: "",

      directPhone: "",
      directPhoneCountryCode: "1",

      mobilePhone: "",
      mobilePhoneCountryCode: "1",
      isMobileSameAsDirect: true,

      email: invitation.invitationEmail,
      password: "",
      confirmPassword: "",
      termsAndConditions: false,
    };
  }

  static fromForm(form: RegistrationFormValuesType): IRegister {
    const mobileNumber =
      form.isMobileSameAsDirect || !form.mobilePhone
        ? undefined
        : form.mobilePhone;

    const mobileNumberCountryCode =
      form.isMobileSameAsDirect || !form.mobilePhoneCountryCode
        ? undefined
        : form.mobilePhoneCountryCode;

    return {
      industryType: form.industryType,
      department:
        form.industryType !== "brand" || !form.department
          ? undefined
          : form.department,
      companyName: form.companyName,
      companyAddress: {
        street: form.companyStreet,
        city: form.companyCity,
        state: form.companyState,
        zip: form.companyZip,
      },
      isWorkSameAsHQ: Boolean(form.isWorkSameAsHQ),
      workAddress: form.isWorkSameAsHQ
        ? undefined
        : {
            street: form.workStreet,
            city: form.workCity,
            state: form.workState,
            zip: form.workZip,
          },
      firstName: form.firstName,
      lastName: form.lastName,
      title: form.title || undefined,
      directPhone: form.directPhone || undefined,
      directPhoneCountryCode: form.directPhoneCountryCode || undefined,
      isMobileSameAsDirect: Boolean(form.isMobileSameAsDirect),
      mobilePhone: mobileNumber,
      mobilePhoneCountryCode: mobileNumberCountryCode,
      email: form.email,
      password: form.password,
      termsAndConditions: Boolean(form.termsAndConditions),
    };
  }
}
