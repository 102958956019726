import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { GridSortModel, GridValidRowModel } from "@mui/x-data-grid";
import { ArtificeProductPhotoProfilesRoutesEnum } from "../../../../../../common";
import { ArtificeGrid } from "../../../../../../common/components";
import {
  useProductPhotoProfileList,
  EmptyView,
  ConfirmModal,
  PageTitle,
  EmptyViewType,
} from "../../../../common";
import emptyViewImg from "../../../../../../assets/img/photo-profiles-empty-view.svg";
import styles from "./style.module.scss";

export const ProductPhotoProfileList = () => {
  const navigate = useNavigate();
  const [deleteItem, setDeleteItem] = useState<GridValidRowModel | null>();
  const {
    loading,
    rows,
    columns,
    sortModel,
    productPhotoProfiles,
    setSortModel,
    deleteProfile,
  } = useProductPhotoProfileList();
  const { t } = useTranslation("portal");

  const showDeleteConfirmation = (data: GridValidRowModel) => {
    setDeleteItem(data);
  };

  const handleClose = () => {
    setDeleteItem(null);
  };

  const handleDelete = async () => {
    if (deleteItem?.id) {
      await deleteProfile(deleteItem.id);
    }
    setDeleteItem(null);
  };

  const handleRowClick = (data: GridValidRowModel) => {
    navigate(data.id?.toString());
  };

  if (loading) {
    return <CircularProgress classes={{ root: styles.loader }} />;
  }

  return rows.length ? (
    <>
      <PageTitle
        title={t("Product Photo Profiles")}
        subTitle={t(
          "Now let's detail and customize what screenshots and pictures you want taken."
        )}
        customAction={t("Create Product Photo Profile")}
        onAction={() => navigate(ArtificeProductPhotoProfilesRoutesEnum.Create)}
      />
      <ArtificeGrid
        canDelete
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
        onDelete={showDeleteConfirmation}
        onCellContentClick={handleRowClick}
      />

      <ConfirmModal
        open={!!deleteItem}
        confirmText={t("Do you want to delete")}
        itemName={deleteItem?.name}
        onClose={handleClose}
        onConfirm={handleDelete}
      />
    </>
  ) : (
    <EmptyView
      type={EmptyViewType.ProductPhotoProfiles}
      imgSrc={emptyViewImg}
      title={t(
        "Take a couple of minutes to complete the Product Photo Profile"
      )}
      productPhotoProfiles={productPhotoProfiles}
      loading={loading}
    />
  );
};
