import { ProductPhotoProfileSectionEnum } from "../../../common/enums";
import { IProductItem, ProductPhotoProfile } from "../../../common";

const formatPhotoExample = (photos?: IProductItem[]) =>
  photos
    ?.sort((fileA, fileB) => fileA.orderInList - fileB.orderInList)
    .map((file) => ({
      id: file.id,
      fileId: file.fileId,
      original: file.url,
      thumbnail: file.thumbnailUrl,
      name: file.name,
      notes: file.notes,
    }));

export const useProductPhotoAttachments = (profile?: ProductPhotoProfile) => {
  const productPackagingPhotos = profile?.productItems?.filter(
    (file) => file.type === ProductPhotoProfileSectionEnum.ProductPackaging
  );

  const productPhotos = profile?.productItems?.filter(
    (file) => file.type === ProductPhotoProfileSectionEnum.PurchasedItem
  );

  return {
    productPackagingPhotos: formatPhotoExample(productPackagingPhotos),
    productPhotos: formatPhotoExample(productPhotos),
  };
};
