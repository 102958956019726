import { PurchaseProfileFormValuesType } from "../../pages/purchase-profile/schemas";
import { ICreatePurchaseProfile, IPurchaseProfile } from "../interfaces";
import { Organization } from "../models";
import { SHIP_BY_GROUND } from "../../../../common";
import { User } from "../../../core";

export class PurchaseProfileFormService {
  static toForm(
    dto?: IPurchaseProfile,
    isStorageAndShippingRequired = false,
    skipValidation = false
  ): PurchaseProfileFormValuesType {
    return {
      companyInformationCompleted: false,
      isStorageCompleted: false,
      isLeadsCompleted: false,

      companyName: dto?.companyName || "",
      industryType: dto?.industryType || "",
      department: dto?.department || "",
      companyStreet: dto?.companyHQAddress.street || "",
      companyCity: dto?.companyHQAddress.city || "",
      companyState: dto?.companyHQAddress.state || "",
      companyZip: dto?.companyHQAddress.zip || "",
      mobilePhoneNumber: dto?.clientMobileNumber || "",
      mobilePhoneNumberCountryCode: dto?.clientMobileNumberCountryCode || "1",
      directWorkNumber: dto?.clientDirectWorkNumber || "",
      directWorkNumberCountryCode:
        dto?.clientDirectWorkNumberCountryCode || "1",
      email: dto?.clientEmail || "",
      firstName: dto?.clientFirstName || "",
      lastName: dto?.clientLastName || "",

      isStorageAndShippingRequired,
      skipValidation,
      useCorporateShippingAccount: Boolean(dto?.useCorporateShippingAccount),
      deliveryService: dto?.deliveryService || "",
      corporateShippingAccount: dto?.corporateShippingAccount || "",
      shipToCompanyName: dto?.shippingInfo.companyName || "",
      shipToFirstName: dto?.shippingInfo.firstName || "",
      shipToLastName: dto?.shippingInfo.lastName || "",
      shipToEmail: dto?.shippingInfo.email || "",
      shipToMobileNumber: dto?.shippingInfo.mobileNumber || "",
      shipToMobileNumberCountryCode:
        dto?.shippingInfo.mobileNumberCountryCode || "1",
      shipToStreet1: dto?.shippingInfo.street1 || "",
      shipToStreet2: dto?.shippingInfo.street2 || "",
      shipToCity: dto?.shippingInfo.city || "",
      shipToState: dto?.shippingInfo.state || "",
      shipToZip: dto?.shippingInfo.zip || "",
      shipToCountry: dto?.shippingInfo.country || "",

      shippingPreferences: dto?.shippingInfo.shippingPreferences || "",
      notifyOfLeads: dto?.notifyOfLeads || false,
      profileName: dto?.name || "",
    };
  }

  static fromForm(
    formValues: PurchaseProfileFormValuesType
  ): ICreatePurchaseProfile {
    const deliveryService = formValues.useCorporateShippingAccount
      ? formValues.deliveryService
      : null;

    const corporateShippingAccount =
      formValues.useCorporateShippingAccount &&
      formValues.corporateShippingAccount
        ? formValues.corporateShippingAccount
        : null;

    const shippingTo = {
      useCorporateShippingAccount: formValues.useCorporateShippingAccount,
      deliveryService,
      corporateShippingAccount,
      shippingInfo: {
        companyName: formValues.shipToCompanyName,
        firstName: formValues.shipToFirstName,
        lastName: formValues.shipToLastName,
        email: formValues.shipToEmail || null,
        mobileNumber: formValues.shipToMobileNumber || null,
        mobileNumberCountryCode:
          formValues.shipToMobileNumberCountryCode || undefined,
        street1: formValues.shipToStreet1,
        street2: formValues.shipToStreet2,
        city: formValues.shipToCity,
        state: formValues.shipToState,
        zip: formValues.shipToZip || null,
        country: formValues.shipToCountry,
        shippingPreferences: formValues.shippingPreferences || null,
      },
    };

    return {
      name: formValues.profileName,
      companyName: formValues.companyName,
      industryType: formValues.industryType,
      department:
        formValues.industryType === "brand" ? formValues.department : undefined,
      companyHQAddress: {
        street: formValues.companyStreet,
        city: formValues.companyCity,
        state: formValues.companyState,
        zip: formValues.companyZip || null,
      },
      clientDirectWorkNumber: formValues.directWorkNumber?.toString() || null,
      clientDirectWorkNumberCountryCode:
        formValues.directWorkNumberCountryCode?.toString() || undefined,
      clientMobileNumber: formValues.mobilePhoneNumber?.toString() || null,
      clientMobileNumberCountryCode:
        formValues.mobilePhoneNumberCountryCode?.toString() || undefined,
      clientEmail: formValues.email || null,
      clientFirstName: formValues.firstName,
      clientLastName: formValues.lastName,
      notifyOfLeads: formValues.notifyOfLeads,
      ...shippingTo,
    };
  }

  static formInitialValues({
    user,
    organization,
    isStorageAndShippingRequired = false,
    skipValidation = false,
  }: {
    user?: User | null;
    organization?: Organization;
    isStorageAndShippingRequired?: boolean;
    skipValidation?: boolean;
    storeAtArtifice?: boolean;
  } = {}): PurchaseProfileFormValuesType {
    return {
      // Company Information
      companyInformationCompleted: false,
      companyName: organization?.companyName,
      industryType: organization?.industryType,
      department: organization?.department,
      companyStreet: organization?.street,
      companyCity: organization?.city,
      companyZip: organization?.zip,
      companyState: organization?.state,
      directWorkNumber: user?.contact?.directPhone || "",
      directWorkNumberCountryCode: user?.contact?.directPhoneCountryCode || "1",
      email: user?.email,
      mobilePhoneNumber: user?.contact?.mobilePhone || "",
      mobilePhoneNumberCountryCode:
        user?.contact?.mobilePhoneCountryCode || "1",
      firstName: user?.firstName,
      lastName: user?.lastName,

      // Storage And Shipping
      skipValidation,
      isStorageAndShippingRequired,
      isStorageCompleted: false,
      useCorporateShippingAccount: true,
      deliveryService: "",
      corporateShippingAccount: "",
      shipToCompanyName: organization?.companyName || "",
      shipToFirstName: user?.firstName || "",
      shipToLastName: user?.lastName || "",
      shipToEmail: user?.email || "",
      shipToMobileNumber: user?.contact?.mobilePhone || "",
      shipToMobileNumberCountryCode:
        user?.contact?.mobilePhoneCountryCode || "1",
      shipToStreet1: user?.contact?.street || "",
      shipToStreet2: "",
      shipToCity: user?.contact?.city || "",
      shipToState: user?.contact?.state || "",
      shipToZip: user?.contact?.zip || "",
      shipToCountry: "",

      shippingPreferences: SHIP_BY_GROUND,

      isLeadsCompleted: false,
      notifyOfLeads: true,
      profileName: "",
    };
  }
}
