import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CollapsableButton } from "../collapsable-button";
import { ConfirmModal, IProductPhotoProfileListItem } from "../../../../common";
import { ProductPhotoProfileFormInner } from "../../../product-photo-profile";
import { useProductPhotoProfileSettings } from "../../hooks";
import { PurchaseFormType } from "../../schemas";
import styles from "./style.module.scss";

interface PhotoProfileSettingsProps {
  form: PurchaseFormType;
  index: number;
  open: boolean;
  productPhotoProfiles?: IProductPhotoProfileListItem[];
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface IStateProductPhotoProfileListItem
  extends IProductPhotoProfileListItem {
  custom?: boolean;
}

const EMPTY_PROFILE = {} as IStateProductPhotoProfileListItem;
const CUSTOM_PROFILE = { custom: true } as IStateProductPhotoProfileListItem;

export const PhotoProfileSettings = ({
  form,
  open,
  index,
  productPhotoProfiles,
  setOpen,
}: PhotoProfileSettingsProps) => {
  const [t] = useTranslation("portal");
  const [profileToConfirm, setProfileToConfirm] =
    useState<IStateProductPhotoProfileListItem>();
  const [value, setValue] =
    useState<IStateProductPhotoProfileListItem>(EMPTY_PROFILE);
  const { profileForm, fetchProfile } = useProductPhotoProfileSettings(
    form,
    index
  );

  const handleConfirm = () => {
    if (profileToConfirm) {
      setValue(profileToConfirm);
      fetchProfile(profileToConfirm.id);
    }

    setProfileToConfirm(undefined);
  };

  const handleClose = () => {
    setProfileToConfirm(undefined);
  };

  const handleProductPhotoProfileChange = (_: any, profile: any) => {
    if (value.custom) {
      return setProfileToConfirm(profile);
    }

    if (profile.id) {
      setValue(profile);
      fetchProfile(profile.id);
    }
  };

  useEffect(() => {
    if (profileForm.dirty) {
      setValue(CUSTOM_PROFILE);
    }
    form.setFieldValue(`productOrders[${index}].photoProfileSettings`, {
      ...profileForm.values,
      profileName: profileForm.values.profileName || "CUSTOM",
    });
    // eslint-disable-next-line
  }, [profileForm.values, index]);

  return (
    <section>
      <section className={styles.productPhotoProfilesSection}>
        <FormControl
          fullWidth
          classes={{ root: styles.productPhotoProfilesWrapper }}
        >
          <Autocomplete
            options={productPhotoProfiles || []}
            autoHighlight
            disableClearable
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label={t("Product Photo Profile")} />
            )}
            onChange={handleProductPhotoProfileChange}
            value={value}
          />
        </FormControl>
        <section className={styles.productPhotoProfilesSettings}>
          <CollapsableButton
            open={open}
            onClick={() => setOpen((val) => !val)}
            label={t("Create/View Photo Profile")}
          />
        </section>
      </section>

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={styles.productPhotoWrapper}
      >
        <ProductPhotoProfileFormInner profileForm={profileForm} />
      </Collapse>

      <ConfirmModal
        title={t("Confirm")}
        open={Boolean(profileToConfirm)}
        confirmText={t("Do you want to change Product Photo Profile?")}
        confirmActionText={t("Confirm")}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </section>
  );
};
