import { string } from "yup";
import { maxLength, maxLengthMessage } from "./max-length.const";

export const productPhotoNotesValidation = string().max(
  maxLength.notes,
  maxLengthMessage.max150
);

export const productPhotoNameValidation = string().max(
  maxLength.photoName,
  maxLengthMessage.max100
);

export const productPhotoProfileNameValidation = string().max(
  maxLength.photoProfileName,
  maxLengthMessage.max100
);
