import { useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PurchaseSchema, PurchaseFormValuesType } from "../schemas";
import { PurchaseFormService, PurchaseService } from "../services";
import {
  useProductPhotoProfileList,
  usePurchaseProfileList,
} from "../../../common";
import { ArtificeRoutes, useNotifications } from "../../../../../common";
import { useCreateBrands } from "./use-create-brands";
import { usePurchase } from "./use-purchase";

export const useEditPurchase = () => {
  const { purchase, id, isLoading } = usePurchase();
  const { productPhotoProfiles } = useProductPhotoProfileList();
  const { purchaseProfiles } = usePurchaseProfileList();
  const { showAlert } = useNotifications();
  const [t] = useTranslation("portal");
  const navigate = useNavigate();

  const { assignBrand } = useCreateBrands();

  useEffect(() => {
    if (purchase && !purchase.canEdit) {
      navigate(ArtificeRoutes.purchases);
    }
  }, [navigate, purchase]);

  const initialValues = useMemo(
    () => PurchaseFormService.toForm(purchase),
    [purchase]
  );

  const handleUpdate = async (values: PurchaseFormValuesType) => {
    if (!purchase || !id) {
      return;
    }

    try {
      await assignBrand(values);

      const purchaseToUpdate = await PurchaseFormService.fromForm(values);

      await PurchaseService.updateEntity(Number(id), purchaseToUpdate);

      showAlert({
        text: t("Successfully updated"),
        severity: "success",
      });
      navigate(`${ArtificeRoutes.purchases}/${id}/summary`);
    } catch (err) {
      showAlert({
        text: t("There was an error. Please try again"),
        severity: "error",
      });
    }
  };

  const purchaseForm = useFormik({
    validationSchema: PurchaseSchema,
    validateOnMount: true,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: handleUpdate,
  });

  return {
    id,
    purchase,
    purchaseForm,
    isLoading,
    productPhotoProfiles,
    purchaseProfiles,
  };
};
