import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import Add from "@mui/icons-material/Add";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { ArtificeRoutes } from "../../../../../common";
import {
  IProductPhotoProfileListItem,
  IPurchaseProfileListItem,
} from "../../interfaces";
import styles from "./style.module.scss";

export enum EmptyViewType {
  Purchases = "purchases",
  PurchaseProfiles = "purchase-profiles",
  ProductPhotoProfiles = "product-photo-profiles",
}

interface EmptyViewProps {
  type: EmptyViewType;
  imgSrc: string;
  title: string;
  subtitle?: string;
  loading: boolean;
  productPhotoProfiles?: IProductPhotoProfileListItem[];
  purchaseProfiles?: IPurchaseProfileListItem[];
}

export const EmptyView = ({
  type,
  imgSrc,
  title,
  subtitle,
  loading,
  productPhotoProfiles,
  purchaseProfiles,
}: EmptyViewProps) => {
  const [t] = useTranslation("portal");

  if (loading) {
    return <CircularProgress classes={{ root: styles.loader }} />;
  }

  return (
    <section className={styles.dashboardWrapper}>
      <img className={styles.emptyViewImg} src={imgSrc} alt={"Empty page"} />

      <section className={styles.placeholderSection}>
        {type === EmptyViewType.Purchases &&
        purchaseProfiles?.length &&
        productPhotoProfiles?.length ? (
          <p className={styles.placeholderTitle}>
            {t(
              "It appears that you currently do not have any purchase. Would you like to create one?"
            )}
          </p>
        ) : (
          <>
            <p className={styles.placeholderTitle}>{title}</p>
            {subtitle ? (
              <section className={styles.subtitleWrapper}>
                <ErrorOutline className={styles.titleIcon} color="primary" />
                <p className={styles.subtitle}>{subtitle}</p>
              </section>
            ) : null}
          </>
        )}

        <section className={styles.buttonsWrapper}>
          {(type === EmptyViewType.Purchases ||
            type === EmptyViewType.PurchaseProfiles) &&
          !purchaseProfiles?.length ? (
            <Button
              className={styles.btn}
              variant="contained"
              component={NavLink}
              size="medium"
              to={ArtificeRoutes.createPurchaseProfile}
              endIcon={<Add />}
            >
              {t("Create a Purchase Profile")}
            </Button>
          ) : null}
          {(type === EmptyViewType.Purchases ||
            type === EmptyViewType.ProductPhotoProfiles) &&
          !productPhotoProfiles?.length ? (
            <Button
              className={styles.btn}
              variant="contained"
              component={NavLink}
              size="medium"
              to={ArtificeRoutes.createProductPhotoProfiles}
              endIcon={<Add />}
            >
              {t("Create a Product Photo Profile")}
            </Button>
          ) : null}
          {type === EmptyViewType.Purchases &&
          purchaseProfiles?.length &&
          productPhotoProfiles?.length ? (
            <Button
              className={styles.btn}
              variant="contained"
              component={NavLink}
              size="medium"
              to={ArtificeRoutes.createPurchase}
              endIcon={<Add />}
            >
              {t("Make a Purchase")}
            </Button>
          ) : null}
        </section>
      </section>
    </section>
  );
};
