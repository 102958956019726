import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { departmentTypes } from "../../../constants";

interface DepartmentsProps {
  formControlProps: any;
}

export const Departments = ({ formControlProps }: DepartmentsProps) => {
  const [t] = useTranslation("common");

  return (
    <TextField
      select
      fullWidth
      margin="normal"
      label={t("Department")}
      {...formControlProps}
    >
      {departmentTypes.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
