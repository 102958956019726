import React from "react";
import { useTranslation } from "react-i18next";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import logo from "../../../../assets/img/footer-logo.svg";
import smallLogo from "../../../../assets/img/small-logo.svg";
import { HomeContainer } from "../../../../common/components";
import PrivacyPolicy from "../../../../assets/files/Privacy_Policy_Artifice_Global.pdf";
import TermsAndConditions from "../../../../assets/files/Terms_Conditions_Artifice_Global.pdf";
import styles from "./style.module.scss";

export const Footer = () => {
  const [t] = useTranslation("common");

  return (
    <section className={styles.footerWrapper}>
      <HomeContainer>
        <section className={styles.infoWrapper}>
          <img src={logo} alt="logo" className={styles.footerLogo} />
          <ul className={styles.contactInfo}>
            <li>
              <PhoneAndroidIcon />
              <a href="tel:+17608454526">(760) 845-4526</a>
            </li>
            <li>
              <LocationOnIcon />
              <p>250 Red Cliffs Blvd. Suite 4B-118 St. George UT 84790</p>
            </li>
            <li>
              <EmailIcon />
              <a href="mailto:ContactUs@artificeglobal.com">
                ContactUs@artificeglobal.com
              </a>
            </li>
          </ul>
          <ul className={styles.links}>
            <li>
              <a
                href={TermsAndConditions}
                download="Terms & Conditions Artifice Global.pdf"
              >
                {t("Terms and Conditions")}
              </a>
            </li>
            <li>
              <a
                href={PrivacyPolicy}
                download="Privacy Policy Artifice Global.pdf"
              >
                {t("Privacy Policy")}
              </a>
            </li>
          </ul>
          <img src={smallLogo} alt="logo" className={styles.footerSmallLogo} />
        </section>
      </HomeContainer>

      <span className={styles.copyright}>© 2023 Artifice Global LLC</span>
    </section>
  );
};
