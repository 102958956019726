import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import BrandingWatermarkOutlinedIcon from "@mui/icons-material/BrandingWatermarkOutlined";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import logoImg from "../../../../assets/img/logo.svg";
import { ArtificeRoutes } from "../../../../common";
import { useAuth } from "../../providers";
import styles from "./style.module.scss";

export const PortalNavigation = () => {
  const [t] = useTranslation("portal");
  const { user } = useAuth();

  return (
    <section className={styles.navigationWrapper}>
      <section className={styles.sectionWrapper}>
        <img src={logoImg} className={styles.logo} alt="logo" />
        <Button
          component={NavLink}
          className={styles.navButton}
          startIcon={<LibraryBooksOutlinedIcon />}
          color="dark"
          size="large"
          to={ArtificeRoutes.purchases}
        >
          {t("Purchases")}
        </Button>
        <Button
          component={NavLink}
          startIcon={<ContactMailOutlinedIcon />}
          className={styles.navButton}
          color="dark"
          to={ArtificeRoutes.purchaseProfiles}
          size="large"
        >
          {t("Purchase Profile(s)")}
        </Button>
        <Button
          component={NavLink}
          startIcon={<PhotoSizeSelectActualOutlinedIcon />}
          className={styles.navButton}
          color="dark"
          to={ArtificeRoutes.productPhotoProfiles}
          size="large"
        >
          {t("Product Photo Profiles")}
        </Button>
        <Button
          component={NavLink}
          startIcon={<BrandingWatermarkOutlinedIcon />}
          className={styles.navButton}
          color="dark"
          to={ArtificeRoutes.representedBrands}
          size="large"
        >
          {t("Represented Brands")}
        </Button>
      </section>
      <section className={styles.footerNavigation}>
        <Button
          component={NavLink}
          startIcon={<HomeRepairServiceOutlinedIcon />}
          className={styles.navButton}
          color="dark"
          to={ArtificeRoutes.services}
          size="large"
        >
          {t("Services")}
        </Button>
        <Button
          component={NavLink}
          startIcon={<PaymentsOutlinedIcon />}
          className={styles.navButton}
          color="dark"
          to={ArtificeRoutes.pricing}
          size="large"
        >
          {t("Pricing")}
        </Button>
        {user?.isOrgAdmin ? (
          <Button
            component={NavLink}
            startIcon={<SettingsOutlinedIcon />}
            className={styles.navButton}
            color="dark"
            to={ArtificeRoutes.userManagement}
            size="large"
          >
            {t("User Management")}
          </Button>
        ) : null}
      </section>
    </section>
  );
};
