import React from "react";
import { IPurchasePurchaseProducts } from "../../interfaces";
import { ProductView } from "../";
import { useProductList } from "../../hooks";

interface ProductsProps {
  products: IPurchasePurchaseProducts[];
  sellerName?: string;
}

export const ProductList = ({ products, sellerName }: ProductsProps) => {
  const { productExpanded, handleProductOrderChange } = useProductList();
  return (
    <>
      {products?.map((product, index) => (
        <ProductView
          product={product}
          index={index}
          key={index}
          count={products.length}
          sellerName={sellerName}
          productExpanded={productExpanded}
          handleProductOrderChange={handleProductOrderChange}
        />
      ))}
    </>
  );
};
