import React from "react";
import { ArtificeAdminPurchaseRoutesEnum } from "../../../../../common";
import { PurchaseCheckoutPage, PurchaseList, PurchaseViewPage } from "../pages";

export const PurchaseRoutes = [
  {
    index: true,
    element: <PurchaseList />,
  },
  {
    path: ArtificeAdminPurchaseRoutesEnum.View,
    element: <PurchaseViewPage />,
  },
  {
    path: ArtificeAdminPurchaseRoutesEnum.Checkout,
    element: <PurchaseCheckoutPage />,
  },
];
