import { ReactNode } from "react";
import { Modal, Paper } from "@mui/material";
import classnames from "classnames";
import styles from "./style.module.scss";

interface ArtificeModalProps {
  title: string | ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  open: boolean;
  onClose: () => void;
  classes?: {
    wrapper: string;
  };
}

export const ArtificeModal = ({
  title,
  children,
  footer,
  open,
  onClose,
  classes,
}: ArtificeModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classnames(styles.modalWrapper, classes?.wrapper)}>
        {typeof title === "string" ? (
          <p className={styles.title}>{title}</p>
        ) : (
          title
        )}
        <section className={styles.modalContent}>{children}</section>
        {footer}
      </Paper>
    </Modal>
  );
};
