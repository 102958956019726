import axios from "../../../../../common/api";
import { authEndpoints, IAcceptedInvitation } from "../../../common";

export class RegisterService {
  static acceptInvitation(token: string): Promise<IAcceptedInvitation> {
    return axios
      .post(authEndpoints.acceptInvitation, { token })
      .then((response) => response.data);
  }
}
