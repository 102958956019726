import * as React from "react";
import { useTranslation } from "react-i18next";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

export const PurchaseStatus = (props: GridRenderCellParams) => {
  const [t] = useTranslation("portal");
  return (
    <>
      {props?.value}
      {props?.row?.byInvoice && (
        <Tooltip title={t("By Invoice")} placement="top">
          <InfoOutlined />
        </Tooltip>
      )}
    </>
  );
};
