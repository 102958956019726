import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArtificeCheckbox } from "../../../../../../../common/components";
import { ProductPhotoProfileFormType } from "../../../interfaces";
import { getFormFieldProps } from "../../../../../../../common/utils";
import styles from "./style.module.scss";

interface ScreenShotsProps {
  formik: ProductPhotoProfileFormType;
}

export const ScreenShots = ({ formik }: ScreenShotsProps) => {
  const [t] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const { listingPage, orderConfirmation, checkoutReceipt } = formik.values;
  const handleListingPage = (val: boolean) =>
    formik.setFieldValue("listingPage", val);
  const handleOrderConfirmation = (val: boolean) =>
    formik.setFieldValue("orderConfirmation", val);
  const handleCheckoutReceipt = (val: boolean) =>
    formik.setFieldValue("checkoutReceipt", val);

  const formProps = getFormFieldProps(formik, t, tValidation);

  return (
    <section className={styles.verticalView}>
      <ArtificeCheckbox
        label={t("Listing Page")}
        checked={Boolean(listingPage)}
        onChange={handleListingPage}
      />
      <ArtificeCheckbox
        label={t("Order Confirmation")}
        checked={Boolean(orderConfirmation)}
        onChange={handleOrderConfirmation}
      />
      <ArtificeCheckbox
        label={t("Checkout Receipt")}
        checked={Boolean(checkoutReceipt)}
        onChange={handleCheckoutReceipt}
      />
      <TextField
        className={styles.notes}
        label={t("Notes")}
        multiline
        rows={2}
        {...formProps("screenShotsNotes", t("Notes"))}
      />
    </section>
  );
};
