import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { ProductPhotoProfileFormType } from "../../interfaces";
import { ProductPhotoProfileSaveModal } from "../product-photo-profile-save-modal";
import { ProductPhotoProfileFormInner } from "./ProductPhotoProfileFormInner";
import styles from "./style.module.scss";

interface ProductPhotoProfileFormProps {
  profileForm: ProductPhotoProfileFormType;
}

export const ProductPhotoProfileForm = ({
  profileForm,
}: ProductPhotoProfileFormProps) => {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation("portal");

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <>
      <form onSubmit={profileForm.handleSubmit}>
        <ProductPhotoProfileFormInner
          showNameSection
          profileForm={profileForm}
        />
      </form>

      <section className={styles.footer}>
        <section>
          <Button variant="contained" onClick={handleOpen}>
            {t("Save & Complete")}
          </Button>
        </section>
      </section>

      <ProductPhotoProfileSaveModal
        open={open}
        productPhotoProfileForm={profileForm}
        handleClose={handleClose}
      />
    </>
  );
};
