import React from "react";
import { useTranslation } from "react-i18next";
import {
  useProductPhotoProfileList,
  usePurchaseProfileList,
} from "../../hooks";
import { EmptyView, EmptyViewType } from "../empty-view";
import emptyViewImg from "../../../../../assets/img/purchases-empty-view.svg";

export const PurchasesEmptyView = () => {
  const [t] = useTranslation("portal");
  const { loading: photoProfileLoading, productPhotoProfiles } =
    useProductPhotoProfileList();
  const { loading: purchaseProfileLoading, purchaseProfiles } =
    usePurchaseProfileList();

  return (
    <EmptyView
      type={EmptyViewType.Purchases}
      imgSrc={emptyViewImg}
      title={t("Take a couple of minutes to complete Profiles")}
      subtitle={t(
        "To save time complete the profiles before making a purchase"
      )}
      productPhotoProfiles={productPhotoProfiles}
      purchaseProfiles={purchaseProfiles}
      loading={photoProfileLoading || purchaseProfileLoading}
    />
  );
};
