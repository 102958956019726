import { ArtificeAuthRoutesEnum } from "../../../common";
import {
  LogIn,
  RegisterRoute,
  ForgotPasswordSuccess,
  RegisterSuccess,
  VerifyEmail,
  ForgotPassword,
  ResetPassword,
  ResetPasswordSuccess,
} from "../pages";

export const AuthRoutes = [
  {
    path: ArtificeAuthRoutesEnum.Register,
    element: <RegisterRoute />,
  },
  {
    path: ArtificeAuthRoutesEnum.RegisterSuccess,
    element: <RegisterSuccess />,
  },
  {
    path: ArtificeAuthRoutesEnum.VerifyEmail,
    element: <VerifyEmail />,
  },
  {
    path: ArtificeAuthRoutesEnum.Login,
    element: <LogIn />,
  },
  {
    path: ArtificeAuthRoutesEnum.ForgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: ArtificeAuthRoutesEnum.ForgotPasswordSuccess,
    element: <ForgotPasswordSuccess />,
  },
  {
    path: ArtificeAuthRoutesEnum.ResetPassword,
    element: <ResetPassword />,
  },
  {
    path: ArtificeAuthRoutesEnum.ResetPasswordSuccess,
    element: <ResetPasswordSuccess />,
  },
];
