import { useTranslation } from "react-i18next";
import { IPurchaseProfile } from "../../../common/interfaces";
import { departmentTypes, industryTypes, states } from "../../../../../common";
import { formatPhoneNumber } from "../../../../../common/utils";
import { IShippingViewInfo } from "../../purchases/interfaces";

const defaultValue = "-";

export const usePurchaseProfileInfo = (purchaseProfile?: IPurchaseProfile) => {
  const [t] = useTranslation("portal");
  const [tCommon] = useTranslation("common");

  const industryType = industryTypes.find(
    ({ id }) => id === purchaseProfile?.industryType
  )?.name;

  const department = departmentTypes.find(
    ({ id }) => id === purchaseProfile?.department
  )?.name;

  const state = states.find(
    ({ id }) => id === purchaseProfile?.shippingInfo.state
  )?.name;

  const companyInfoSate = states.find(
    ({ id }) => id === purchaseProfile?.companyHQAddress.state
  )?.name;

  const departmentItem =
    purchaseProfile?.industryType === "brand"
      ? [
          {
            name: t("Department"),
            value: department ? tCommon(department) : defaultValue,
          },
        ]
      : [];

  const companyInfo = [
    {
      name: t("Company Name"),
      value: purchaseProfile?.companyName || defaultValue,
    },
    {
      name: t("Industry Type"),
      value: industryType ? tCommon(industryType) : defaultValue,
    },
  ];

  const companyInformationList = [
    [...companyInfo, ...departmentItem],
    [
      {
        name: t("Street"),
        value: purchaseProfile?.companyHQAddress.street || defaultValue,
      },
      {
        name: t("City"),
        value: purchaseProfile?.companyHQAddress.city || defaultValue,
      },
      {
        name: t("State"),
        value: companyInfoSate || defaultValue,
      },
      {
        name: t("Zip Code"),
        value: purchaseProfile?.companyHQAddress.zip || defaultValue,
      },
    ],

    [
      {
        name: t("First Name"),
        value: purchaseProfile?.clientFirstName || defaultValue,
      },
      {
        name: t("Last Name"),
        value: purchaseProfile?.clientLastName || defaultValue,
      },
      {
        name: t("Email Address"),
        value: purchaseProfile?.clientEmail || defaultValue,
      },
      {
        name: t("Direct Work Number"),
        value: formatPhoneNumber(
          purchaseProfile?.clientDirectWorkNumberCountryCode,
          purchaseProfile?.clientDirectWorkNumber,
          defaultValue
        ),
      },
      {
        name: t("Mobile Number"),
        value: formatPhoneNumber(
          purchaseProfile?.clientMobileNumberCountryCode,
          purchaseProfile?.clientMobileNumber,
          defaultValue
        ),
      },
    ],
  ];

  const shippingInfo: IShippingViewInfo = {
    storeAtArtifice: purchaseProfile?.storeAtArtifice || false,
    useShippingAccount: purchaseProfile?.useCorporateShippingAccount || false,
    deliveryService: purchaseProfile?.deliveryService || defaultValue,
    shippingAccountNumber:
      purchaseProfile?.corporateShippingAccount || defaultValue,
    companyName: purchaseProfile?.shippingInfo.companyName || defaultValue,
    firstName: purchaseProfile?.shippingInfo.firstName || defaultValue,
    lastName: purchaseProfile?.shippingInfo.lastName || defaultValue,
    email: purchaseProfile?.shippingInfo.email || defaultValue,
    phoneNumber:
      formatPhoneNumber(
        purchaseProfile?.shippingInfo.mobileNumberCountryCode,
        purchaseProfile?.shippingInfo.mobileNumber
      ) || defaultValue,
    street1: purchaseProfile?.shippingInfo.street1 || defaultValue,
    street2: purchaseProfile?.shippingInfo.street2 || defaultValue,
    city: purchaseProfile?.shippingInfo.city || defaultValue,
    state: state || defaultValue,
    zip: purchaseProfile?.shippingInfo.zip || defaultValue,
    country: purchaseProfile?.shippingInfo.country || defaultValue,
    shippingPreference:
      purchaseProfile?.shippingInfo.shippingPreferences || defaultValue,
  };

  return { companyInformationList, shippingInfo };
};
