import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  ProductPhotoProfileFormSchema,
  ProductPhotoProfileFormValuesType,
} from "../schemas";
import {
  ProductPhotoProfileFormService,
  ProductPhotoProfileService,
} from "../../../common";
import { ArtificeRoutes, useNotifications } from "../../../../../common";
import { FileError } from "../../../../../common/utils";

export const useCreateProductPhotoProfile = () => {
  const { t } = useTranslation("portal");
  const { showAlert } = useNotifications();
  const navigate = useNavigate();

  const createProductPhotoProfile = async (
    formValues: ProductPhotoProfileFormValuesType
  ) => {
    try {
      await ProductPhotoProfileService.createEntity(
        ProductPhotoProfileFormService.fromForm(formValues),
        formValues.productPackagingPhotoExamples,
        formValues.purchasedPhotoExamples
      );
      showAlert({
        text: t("Successfully created"),
        severity: "success",
      });
      navigate(ArtificeRoutes.productPhotoProfiles);
    } catch (err) {
      showAlert({
        text:
          err instanceof FileError
            ? t(
                "There was an error with {{name}}. Please try again or change it",
                { name: err }
              )
            : t("There was an error. Please try again"),
        severity: "error",
      });
    }
  };

  const initialValues = useMemo(
    () => ProductPhotoProfileFormService.formInitialValues(),
    []
  );

  const profileForm = useFormik({
    validationSchema: ProductPhotoProfileFormSchema,
    validateOnMount: true,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: createProductPhotoProfile,
  });

  return { profileForm };
};
