import { string } from "yup";
import { maxLength, maxLengthMessage } from "./max-length.const";

export const productNameValidation = string().max(
  maxLength.productName,
  maxLengthMessage.max50
);

export const productUnitPriceValidation = string().test(
  "maxDigitsAfterDecimal",
  "Wrong format",
  (unitPrice) => /^\d+(\.\d{1,2})?$/.test(unitPrice || "")
);

export const productURLValidation = string()
  .max(maxLength.productURL, maxLengthMessage.max2048)
  .url("Please enter a valid URL");

export const productNotesValidation = string().max(
  maxLength.notes,
  maxLengthMessage.max150
);

export const productDescriptionValidation = string().max(
  maxLength.productDescription,
  maxLengthMessage.max100
);

export const productColorValidation = string().max(
  maxLength.productColor,
  maxLengthMessage.max20
);

export const productSizeValidation = string().max(
  maxLength.productSize,
  maxLengthMessage.max10
);

export const artificeCaseNumberValidation = string().max(
  maxLength.artificeCaseNumber,
  maxLengthMessage.max30
);

export const internalCaseNumberValidation = string().max(
  maxLength.internalCaseNumber,
  maxLengthMessage.max30
);

export const thirdPartySiteValidation = string().max(
  maxLength.thirdPartySite,
  maxLengthMessage.max255
);
