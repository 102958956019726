import React, { ReactNode } from "react";
import { Grid, TextField } from "@mui/material";
import classnames from "classnames";
import { Currency } from "../../../../../common/components/currency";
import styles from "./style.module.scss";

export interface GridItem {
  xs: number;
  formField?: string;
  title: string | number | ReactNode;
  subtitle?: string | ReactNode;
  isEditable?: boolean;
  useTrans?: boolean;
  titleType: "text" | "currency";
  align?: "left";
  fontWeight?: "bold";
}

interface SummaryGridProps {
  gridItems: GridItem[];
  classes?: {
    gridWrapper?: string;
    gridItem?: string;
    title?: string;
    subtitle?: string;
  };
  fieldProps?: (field: string) => any;
}

export const SummaryGridRow = ({
  gridItems,
  classes,
  fieldProps,
}: SummaryGridProps) => (
  <>
    <Grid
      className={classnames(styles.gridWrapper, classes?.gridWrapper)}
      container
      rowSpacing={0}
      columnSpacing={{ xs: 1, sm: 2, md: 0 }}
    >
      {gridItems?.map((item) =>
        item.isEditable ? (
          <Grid
            className={classnames(
              styles.row,
              item.align === "left" ? styles.firstColumn : null,
              classes?.gridItem
            )}
            item
            xs={item.xs}
          >
            <TextField
              size="small"
              className={styles.input}
              {...(item.formField ? fieldProps?.(item.formField) : {})}
            />
          </Grid>
        ) : (
          <Grid
            className={classnames(
              styles.row,
              item.align === "left" ? styles.firstColumn : "",
              classes?.gridItem
            )}
            item
            xs={item.xs}
          >
            {item.titleType === "currency" ? (
              <Currency value={item.title} />
            ) : (
              <p
                className={classnames(
                  styles.title,
                  item.fontWeight === "bold" ? styles.bold : "",
                  classes?.title
                )}
              >
                {item.title}
              </p>
            )}
            {item.subtitle && (
              <p className={classnames(styles.subtitle, classes?.subtitle)}>
                {item.subtitle}
              </p>
            )}
          </Grid>
        )
      )}
    </Grid>
  </>
);
