import React, { Suspense } from "react";
import { useLoaderData, Await, useOutlet, Navigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { AuthProvider } from "../../providers";
import { ArtificeRoutes } from "../../../../common";
import { useInterceptors } from "../../../../common/hooks";
import { User } from "../../models";

interface AuthRouteLoaderProps {
  permission: keyof User;
}

export const AuthRouteLoader = ({ permission }: AuthRouteLoaderProps) => {
  const { userPromise } = useLoaderData() as { userPromise: Promise<User> };
  const outlet = useOutlet();
  useInterceptors();

  const Home = <Navigate to={ArtificeRoutes.home} />;

  return (
    <Suspense fallback={<LinearProgress />}>
      <Await
        resolve={userPromise}
        errorElement={Home}
        children={(user: User) =>
          user?.[permission] ? (
            <AuthProvider user={user}>{outlet}</AuthProvider>
          ) : (
            Home
          )
        }
      />
    </Suspense>
  );
};
