import { CreditCardTypeEnum } from "../../enums";
import visa from "../../../assets/img/visa.svg";
import mastercard from "../../../assets/img/mastercard.svg";
import amex from "../../../assets/img/amex.svg";
import discover from "../../../assets/img/discover.svg";
import jcb from "../../../assets/img/jcb.svg";
import dinersclub from "../../../assets/img/dinersclub.svg";
import generic from "../../../assets/img/generic.svg";
import styles from "./style.module.scss";

interface CreditCardIconProps {
  type: CreditCardTypeEnum;
}

const creditCardMap: Record<CreditCardTypeEnum, string> = {
  [CreditCardTypeEnum.Visa]: visa,
  [CreditCardTypeEnum.MasterCard]: mastercard,
  [CreditCardTypeEnum.AmericanExpress]: amex,
  [CreditCardTypeEnum.Discover]: discover,
  [CreditCardTypeEnum.JCB]: jcb,
  [CreditCardTypeEnum.DinersClub]: dinersclub,
};

export const CreditCardIcon = ({ type }: CreditCardIconProps) => {
  return (
    <img
      className={styles.card}
      src={creditCardMap[type] || generic}
      alt={type}
    />
  );
};
