export const industryTypes = [
  {
    id: "brand",
    name: "Brand",
  },
  {
    id: "law-firm",
    name: "Law Firm",
  },
  {
    id: "private-investigator",
    name: "Private Investigator",
  },
  {
    id: "industry-association",
    name: "Industry Association",
  },
];
