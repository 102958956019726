import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import BrandingWatermarkOutlinedIcon from "@mui/icons-material/BrandingWatermarkOutlined";
import logoImg from "../../../../assets/img/logo.svg";
import { ArtificeRoutes } from "../../../../common";
import styles from "./style.module.scss";

export const AdminNavigation = () => {
  const [t] = useTranslation("portal");

  return (
    <section className={styles.navigationWrapper}>
      <section className={styles.sectionWrapper}>
        <img src={logoImg} className={styles.logo} alt="logo" />
        <Button
          component={NavLink}
          className={styles.navButton}
          startIcon={<LibraryBooksOutlinedIcon />}
          color="dark"
          size="large"
          to={ArtificeRoutes.adminPurchases}
        >
          {t("Purchases")}
        </Button>
        <Button
          component={NavLink}
          startIcon={<CorporateFareOutlinedIcon />}
          className={styles.navButton}
          color="dark"
          to={ArtificeRoutes.adminOrganizations}
          size="large"
        >
          {t("Organizations")}
        </Button>
        <Button
          component={NavLink}
          startIcon={<ContactMailOutlinedIcon />}
          className={styles.navButton}
          color="dark"
          to={ArtificeRoutes.adminUserManagement}
          size="large"
        >
          {t("Users")}
        </Button>
        <Button
          component={NavLink}
          startIcon={<BrandingWatermarkOutlinedIcon />}
          className={styles.navButton}
          color="dark"
          to={ArtificeRoutes.adminBrands}
          size="large"
        >
          {t("Brands")}
        </Button>
      </section>
    </section>
  );
};
