import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PurchaseProfileService, IPurchaseProfile } from "../../../common";
import { ArtificeRoutes, useNotifications } from "../../../../../common";

export const usePurchaseProfile = () => {
  const { t } = useTranslation("portal");
  const { showAlert } = useNotifications();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [purchaseProfile, setPurchaseProfile] = useState<IPurchaseProfile>();
  const { id } = useParams();

  const getPurchaseProfile = async (profileId: number) => {
    try {
      setLoading(true);
      const data = await PurchaseProfileService.fetchEntityById(profileId);

      if (data?.id) {
        setPurchaseProfile({ ...data, canUpdate: true });
        setLoading(false);
      } else {
        throw new Error("no data");
      }
    } catch (err) {
      showAlert({
        text: t("There was an error. Please try again"),
        severity: "error",
      });
      navigate(ArtificeRoutes.purchaseProfiles);
    }
  };

  useEffect(() => {
    if (id) {
      getPurchaseProfile(Number(id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { purchaseProfile, loading, id, getPurchaseProfile };
};
