import React from "react";
import { ArtificeProductPhotoProfilesRoutesEnum } from "../../../../../common";
import {
  CreateProductPhotoProfile,
  EditProductPhotoProfile,
  ViewProductPhotoProfile,
  ProductPhotoProfileList,
} from "../pages";

export const ProductPhotoProfileRoutes = [
  {
    index: true,
    element: <ProductPhotoProfileList />,
  },
  {
    path: ArtificeProductPhotoProfilesRoutesEnum.Create,
    element: <CreateProductPhotoProfile />,
  },
  {
    path: ArtificeProductPhotoProfilesRoutesEnum.View,
    element: <ViewProductPhotoProfile />,
  },
  {
    path: ArtificeProductPhotoProfilesRoutesEnum.Edit,
    element: <EditProductPhotoProfile />,
  },
];
