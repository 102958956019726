import React from "react";
import { ProfileInfoListItem } from "../profile-info-list-item";
import styles from "./style.module.scss";

export interface InfoList {
  name: string;
  value?: string | number;
  secure?: boolean;
  valueType?: "text" | "currency" | "link";
  notes?: string;
  useTrans?: boolean;
  hideColon?: boolean;
}

interface ProfileInfoListProps {
  list: InfoList[][];
  type: string;
  classes?: {
    listItemName?: string;
    listItemValueWrapper?: string;
    listItemValue?: string;
  };
}

export const ProfileInfoList = ({
  list,
  type,
  classes,
}: ProfileInfoListProps) => {
  return (
    <>
      {list.map((column, index) => (
        <ul className={styles.list} key={`${type}-${index}`}>
          {column.map((item) => (
            <ProfileInfoListItem
              key={`${type}-${item.name}`}
              type={type}
              item={item}
              classes={classes}
            />
          ))}
        </ul>
      ))}
    </>
  );
};
