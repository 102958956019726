import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { PageTitle } from "../../../../common";
import { ArtificeRoutes } from "../../../../../../common";
import { usePurchase } from "../../hooks";
import { PurchaseInformation } from "../../components";
import styles from "./style.module.scss";

export const ViewPurchase = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("portal");
  const { purchase, isLoading } = usePurchase();
  const handleEditNavigation = () => navigate("edit");
  const handleSummaryNavigation = () => navigate("summary");

  return (
    <section>
      <PageTitle
        backUrl={ArtificeRoutes.purchases}
        loading={isLoading}
        title={t("Back to List of Purchases")}
        customAction={t("Summary")}
        canUpdate={purchase?.canEdit}
        onEdit={handleEditNavigation}
        onAction={handleSummaryNavigation}
      />
      {isLoading ? (
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <PurchaseInformation purchase={purchase} />
      )}
    </section>
  );
};
