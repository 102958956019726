import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { PageTitle } from "../../../../common";
import { ArtificeRoutes } from "../../../../../../common";
import { PurchaseForm, PreviewPurchaseModal } from "../../components";
import { useCreatePurchase } from "../../hooks";
import styles from "./style.module.scss";

export const CreatePurchase = () => {
  const [t] = useTranslation("portal");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { purchaseForm, purchaseProfiles, productPhotoProfiles, isLoading } =
    useCreatePurchase();

  return (
    <>
      <PageTitle
        backUrl={ArtificeRoutes.purchases}
        title={t("Make a Purchase")}
      />
      {isLoading ? (
        <CircularProgress classes={{ root: styles.loader }} />
      ) : (
        <PurchaseForm
          handlePreview={handleOpen}
          purchaseForm={purchaseForm}
          purchaseProfiles={purchaseProfiles}
          productPhotoProfiles={productPhotoProfiles}
        />
      )}
      <PreviewPurchaseModal
        open={open}
        handleClose={handleClose}
        purchaseForm={purchaseForm}
      />
    </>
  );
};
