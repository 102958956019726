import React from "react";
import { useTranslation } from "react-i18next";
import aboutUs from "../../../../assets/img/about-us.png";
import { HomeContainer } from "../../../../common/components";
import styles from "./style.module.scss";

export const AboutUs = () => {
  const [t] = useTranslation("common");

  return (
    <HomeContainer>
      <section id="about-us" className={styles.aboutUsWrapper}>
        <section className={styles.description}>
          <h2 className={styles.title}>{t("About Us")}</h2>

          <section className={styles.content}>
            <p>
              {t(
                "Artifice Global is a technology enabled services company. Artifice Global provides end-to-end solutions in the Intellectual Property space, specifically focused on Anti-counterfeiting, Product Diversion, and Illicit trade. Artifice Global provides services to law firms, corporations, industry associations and government entities. For more information on our services, please contact us."
              )}
            </p>
          </section>
        </section>

        <img src={aboutUs} alt="about-us" className={styles.aboutUsImg} />
      </section>
    </HomeContainer>
  );
};
