import React from "react";
import { useTranslation } from "react-i18next";
import { ProductPhotoProfileForm } from "../../components";
import { useCreateProductPhotoProfile } from "../../hooks";
import { PageTitle } from "../../../../common";
import { ArtificeRoutes } from "../../../../../../common";

export const CreateProductPhotoProfile = () => {
  const { t } = useTranslation("portal");
  const { profileForm } = useCreateProductPhotoProfile();

  return (
    <>
      <PageTitle
        backUrl={ArtificeRoutes.productPhotoProfiles}
        title={t("Create Product Photo Profile")}
        subTitle={t(
          "This is where you tell us exactly what screenshots, packaging and products photos you need."
        )}
      />
      <ProductPhotoProfileForm profileForm={profileForm} />
    </>
  );
};
