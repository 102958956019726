import React from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../../../common";
import { useEditPurchaseProfile } from "../../hooks";
import { PurchaseProfileForm } from "../../components";
import { ArtificeRoutes } from "../../../../../../common";
import styles from "./style.module.scss";

export const EditPurchaseProfile = () => {
  const [t] = useTranslation("portal");
  const { profileForm, id, profileName, loading } = useEditPurchaseProfile();

  return (
    <section className={styles.purchaseProfileWrapper}>
      <PageTitle
        loading={loading}
        title={profileName}
        backUrl={`${ArtificeRoutes.purchaseProfiles}/${id}`}
        subTitle={t("Each team member can create their own profile.")}
      />

      <PurchaseProfileForm profileForm={profileForm} editMode />
    </section>
  );
};
