import { useTranslation } from "react-i18next";
import { ArtificeRoutes } from "../../../../../common";

export const useCheckoutSuccess = () => {
  const [t] = useTranslation("portal");

  const successViewValues = {
    title: t("Request Complete!"),
    subtitle: t(
      "Thank You. Your Purchase Request has been received. We are now processing your order."
    ),
    buttonTo: ArtificeRoutes.purchases,
    buttonText: t("Continue Purchasing"),
  };

  return {
    successViewValues,
  };
};
