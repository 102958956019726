import { useState } from "react";

export const useProductList = () => {
  const [productOrderIndexExpanded, setProductOrderIndexExpanded] =
    useState<number>(0);

  const handleProductOrderChange =
    (index = 0) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      setProductOrderIndexExpanded(newExpanded ? index : -1);
    };

  return {
    productExpanded: (index: number) => index === productOrderIndexExpanded,
    handleProductOrderChange,
  };
};
