import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { PurchaseFormType } from "../../schemas";
import { getFormFieldProps } from "../../../../../../common/utils";
import {
  ArtificeNumericFormat,
  ArtificeMask,
} from "../../../../../../common/components";
import { BrandField } from "../brand-field";
import { IBrand } from "../../../represented-brands/interfaces";
import styles from "./style.module.scss";

const formatter = Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

interface ProductOrderDetailsProps {
  purchaseForm: PurchaseFormType;
  index: number;
  purchaseBrands: IBrand[];
  refetchBrands: () => Promise<void>;
  onProductRemove: () => void;
}

export const ProductOrderDetails = ({
  index,
  purchaseForm,
  purchaseBrands,
  refetchBrands,
  onProductRemove,
}: ProductOrderDetailsProps) => {
  const [t] = useTranslation("portal");
  const [tValidation] = useTranslation("validation");
  const fieldProps = getFormFieldProps(purchaseForm, t, tValidation);
  const productOrders = purchaseForm.values.productOrders;
  const purchaseFormValues = productOrders[index];

  const subTotal = useMemo(
    () =>
      formatter.format(
        Number(purchaseFormValues?.quantity) *
          Number(purchaseFormValues?.unitPrice)
      ),
    [purchaseFormValues?.quantity, purchaseFormValues?.unitPrice]
  );

  const unitPrice = fieldProps(`productOrders[${index}].unitPrice`);
  const quantity = fieldProps(`productOrders[${index}].quantity`);
  const unitPriceEmpty = !unitPrice.value && unitPrice.touched;
  const unitPriceError = unitPriceEmpty || unitPrice.error;

  return (
    <section>
      <p className={styles.subTitle}>{t("Product Details:")}</p>

      <section className={styles.flexRow}>
        <TextField
          fullWidth
          margin="normal"
          required
          className={styles.firstInput}
          label={t("Product Name")}
          {...fieldProps(
            `productOrders[${index}].productName`,
            t("Product Name")
          )}
        />
        <TextField
          fullWidth
          margin="normal"
          required
          label={t("Product URL")}
          {...fieldProps(
            `productOrders[${index}].productUrl`,
            t("Product URL")
          )}
        />
        <TextField
          fullWidth
          margin="normal"
          required
          className={styles.shortField}
          label={t("Quantity")}
          {...quantity}
          helperText={
            quantity.touched && !quantity.value ? t("Field required") : ""
          }
          error={!quantity.value && quantity.touched}
          InputProps={{
            inputProps: { mask: "000" },
            inputComponent: ArtificeMask,
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          required
          className={styles.shortField}
          label={t("Unit Price")}
          {...unitPrice}
          helperText={
            unitPriceEmpty ? t("Field required") : unitPrice.helperText
          }
          error={unitPriceError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className={unitPriceError ? styles.error : ""}>$</span>
              </InputAdornment>
            ),
            inputComponent: ArtificeNumericFormat,
          }}
        />
        <TextField
          fullWidth
          className={styles.subtotal}
          margin="normal"
          label={t("Subtotal")}
          {...fieldProps(`productOrders[${index}].subtotal`)}
          value={subTotal}
          InputProps={{
            readOnly: true,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <section className={styles.flexAlign}>
          <IconButton
            size="small"
            onClick={onProductRemove}
            disabled={productOrders.length === 1}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </section>
      </section>
      <section className={styles.flexRow}>
        <TextField
          fullWidth
          margin="normal"
          className={styles.firstInput}
          label={t("Product Description")}
          {...fieldProps(
            `productOrders[${index}].description`,
            t("Product Description")
          )}
        />
        <TextField
          fullWidth
          margin="normal"
          className={styles.shortField}
          label={t("Color")}
          {...fieldProps(`productOrders[${index}].color`, t("Color"))}
        />
        <TextField
          fullWidth
          margin="normal"
          className={styles.shortField}
          label={t("Size")}
          {...fieldProps(`productOrders[${index}].size`, t("Size"))}
        />
        <TextField
          fullWidth
          margin="normal"
          label={t("Notes")}
          {...fieldProps(`productOrders[${index}].notes`, t("Notes"))}
        />
      </section>
      <section className={styles.brandsWrapper}>
        <BrandField
          index={index}
          label="Brand"
          fieldName={`productOrders[${index}].brand`}
          purchaseForm={purchaseForm}
          value={purchaseForm.values.productOrders[index].brand || null}
          purchaseBrands={purchaseBrands}
          refetchBrands={refetchBrands}
        />
      </section>
    </section>
  );
};
