import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Divider, SwipeableDrawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { HashLink } from "react-router-hash-link";
import { LanguageSwitch } from "../../../../common/components";
import { scrollWithOffset } from "../../../../common/utils";
import { ArtificeRoutes } from "../../../../common";
import styles from "./style.module.scss";

export const MobileMenu = () => {
  const [t] = useTranslation("common");
  const [open, setState] = useState(false);

  const showNav = false;

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  return (
    <section className={styles.menuWrapper}>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon />
      </Button>
      <SwipeableDrawer
        className={styles.drawerWrapper}
        anchor="right"
        open={open}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{
            p: 2,
            height: 1,
          }}
        >
          <section className={styles.mainContent}>
            <nav>
              <Divider sx={{ mb: 2 }} />
              {showNav ? (
                <>
                  <Button
                    component={HashLink}
                    smooth
                    to="/#about-us"
                    onClick={toggleDrawer(false)}
                    color="dark"
                    size="large"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    {t("About Us")}
                  </Button>
                  <Button
                    component={HashLink}
                    smooth
                    to="/#services"
                    onClick={toggleDrawer(false)}
                    color="dark"
                    size="large"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    {t("Our Services")}
                  </Button>
                </>
              ) : null}
              <Button
                component={HashLink}
                smooth
                to="/#contact-us"
                onClick={toggleDrawer(false)}
                color="dark"
                size="large"
                scroll={(el) => scrollWithOffset(el)}
              >
                {t("Contact Us")}
              </Button>
            </nav>
            <section>
              <section className={styles.languageWrapper}>
                <LanguageSwitch />
              </section>
              <section className={styles.authBtns}>
                <Button
                  component={NavLink}
                  to={ArtificeRoutes.register}
                  onClick={toggleDrawer(false)}
                  size="large"
                  variant="contained"
                >
                  {t("Register")}
                </Button>

                <Button
                  size="large"
                  component={NavLink}
                  to={ArtificeRoutes.login}
                  onClick={toggleDrawer(false)}
                >
                  {t("Log in")}
                </Button>
              </section>
            </section>
          </section>
        </Box>
      </SwipeableDrawer>
    </section>
  );
};
