import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useMemo } from "react";
import { IPurchase } from "../../interfaces";
import { PurchasePaymentStatusEnum } from "../../../../../../common";
import { GridItem } from "../../../../common/components/summary-grid-row";
import { CheckoutFormSchema } from "../../components/summary-products/checkout-form.schema";
import { CheckoutFormService } from "../../components/summary-products/checkout-form.service";
import { PurchaseFeeItemTypeEnum } from "../../../../common/enums";
import { getPurchaseFee, getTotalProducts } from "./helpers";

export const useCheckoutInfo = (
  purchase?: IPurchase,
  isEditable?: boolean,
  showTotalHold?: boolean
) => {
  const [t] = useTranslation("portal");
  const isPurchaseCaptured =
    purchase?.paymentStatus &&
    [
      PurchasePaymentStatusEnum.INVOICE_APPROVED,
      PurchasePaymentStatusEnum.CAPTURED,
    ].includes(purchase?.paymentStatus);
  const showCapturedInTotal = showTotalHold || isPurchaseCaptured;

  const staticServicePrice = getPurchaseFee(
    PurchaseFeeItemTypeEnum.ArtificeServicesCost,
    purchase?.purchaseFeeItems
  );

  const sellerTax = getPurchaseFee(
    PurchaseFeeItemTypeEnum.SellerTax,
    purchase?.purchaseFeeItems
  );

  const shippingTax = getPurchaseFee(
    PurchaseFeeItemTypeEnum.ShippingTax,
    purchase?.purchaseFeeItems
  );

  const miscellaneous = getPurchaseFee(
    PurchaseFeeItemTypeEnum.Miscellaneous,
    purchase?.purchaseFeeItems
  );

  const estimatedHolds = getPurchaseFee(
    PurchaseFeeItemTypeEnum.AdditionalCosts,
    purchase?.purchaseFeeItems
  );

  const initialValues = useMemo(
    () =>
      CheckoutFormService.toForm({
        purchaseProducts: purchase?.purchaseProducts,
        sellerTax,
        shippingTax,
        miscellaneous,
      }),
    [sellerTax, shippingTax, miscellaneous, purchase?.purchaseProducts]
  );

  const checkoutForm = useFormik({
    validationSchema: CheckoutFormSchema,
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: () => {},
  });

  const totalProducts = getTotalProducts(checkoutForm.values?.products);
  const totalInitialProducts = getTotalProducts(purchase?.purchaseProducts);

  const totalTax =
    staticServicePrice +
    Number(checkoutForm.values?.sellerTax) +
    Number(checkoutForm.values?.shippingTax) +
    Number(checkoutForm.values?.miscellaneous);

  const totalHold = totalInitialProducts + staticServicePrice + estimatedHolds;
  const totalCapture = totalProducts + totalTax;
  const total = showCapturedInTotal ? totalCapture : totalHold;

  const caseInfoRow: GridItem[] = [
    {
      title: t("Artifice Case Number") || "",
      titleType: "text",
      xs: 3,
      align: "left",
    },
    {
      title: purchase?.artificeCaseNumber || "-",
      titleType: "text",
      xs: 3,
      align: "left",
      fontWeight: "bold",
    },
    {
      title: t("Internal Case Number") || "",
      titleType: "text",
      xs: 3,
      align: "left",
    },
    {
      title: purchase?.internalCaseNumber || "-",
      titleType: "text",
      xs: 3,
      align: "left",
      fontWeight: "bold",
    },
    {
      title: t("Seller Name / Seller URL") || "",
      titleType: "text",
      xs: 3,
      align: "left",
    },
    {
      title: purchase?.sellerRef || "-",
      titleType: "text",
      xs: 9,
      align: "left",
      fontWeight: "bold",
    },
  ];

  const productTitleRow: GridItem[] = [
    { title: t("Product Name") || "", titleType: "text", xs: 5, align: "left" },
    { title: t("Quantity") || "", titleType: "text", xs: 1 },
    { title: t("Unit Price") || "", titleType: "text", xs: 2 },
    { title: t("Cost") || "", titleType: "text", xs: 4 },
  ];

  const productInfoRow: GridItem[] =
    checkoutForm.values?.products?.flatMap((product, index) => {
      return [
        { title: product?.name || "", titleType: "text", xs: 5, align: "left" },
        {
          title: product?.quantity || "",
          titleType: "text",
          xs: 1,
          isEditable,
          formField: `products[${index}].quantity`,
        },
        {
          title: product?.unitPrice || "",
          titleType: "currency",
          xs: 2,
          isEditable,
          formField: `products[${index}].unitPrice`,
        },
        {
          title: Number(product?.quantity) * Number(product?.unitPrice) || "",
          titleType: "currency",
          xs: 4,
        },
      ];
    }) || [];

  const estimatedIncidentalHoldsRow: GridItem[] = [
    {
      title: t("Estimated Incidental Holds") || "",
      titleType: "text",
      subtitle:
        "AG places an additional 20% credit card hold to cover unknown costs such as, seller sales tax, changes in dynamic pricing, shipping costs, etc. This amount is purely a hold (not a charge) and once the transaction is complete, the credit card will be charged the actual costs, and any excess on the hold will be released. In the event that the actual costs exceed the amount of the hold, the actual costs incurred by AG will be charged to the client.",
      xs: 11,
      useTrans: true,
      align: "left",
    },
    {
      title: estimatedHolds,
      titleType: "currency",
      xs: 1,
    },
  ];

  const servicesHeaderRow: GridItem[] = [
    { title: t("Services") || "", titleType: "text", xs: 12, align: "left" },
  ];

  const staticServiceRow: GridItem[] = [
    {
      title: t("Order, Processing & Report") || "",
      titleType: "text",
      xs: 11,
      align: "left",
    },
    {
      title: staticServicePrice,
      titleType: "currency",
      xs: 1,
    },
  ];

  const sellerTaxChargeRow: GridItem[] = [
    {
      title: t("Seller Tax Charge") || "",
      titleType: "text",
      xs: 11,
      align: "left",
    },
    {
      title: sellerTax,
      titleType: "currency",
      xs: 1,
      isEditable,
      formField: "sellerTax",
    },
  ];

  const shippingTaxChargeRow: GridItem[] = [
    {
      title: t("Seller Shipping Charge") || "",
      titleType: "text",
      xs: 11,
      align: "left",
    },
    {
      title: shippingTax,
      titleType: "currency",
      xs: 1,
      isEditable,
      formField: "shippingTax",
    },
  ];

  const miscRow: GridItem[] = [
    {
      title: t("Misc") || "",
      titleType: "text",
      subtitle: t(
        "(Seller tax, shipping charges and miscellaneous costs will be set after purchase is made and will be taken from additional costs)"
      ),
      xs: 11,
      align: "left",
    },
    {
      title: miscellaneous,
      titleType: "currency",
      xs: 1,
      isEditable,
      formField: "miscellaneous",
    },
  ];

  const totalHoldRow: GridItem[] = [
    {
      title: t("Total Hold") || "",
      titleType: "text",
      xs: 11,
      align: "left",
    },
    { title: totalHold, titleType: "currency", xs: 1 },
  ];

  const totalRow: GridItem[] = [
    {
      title: showCapturedInTotal ? t("Total Cost") : t("Total Hold"),
      titleType: "text",
      xs: 11,
      align: "left",
    },
    { title: total, titleType: "currency", xs: 1 },
  ];

  return {
    sellerTax,
    shippingTax,
    miscellaneous,
    estimatedHolds,
    totalCapture,
    checkoutForm,
    caseInfo: caseInfoRow,
    productTitle: productTitleRow,
    productInfo: productInfoRow,
    estimatedIncidentalHolds: estimatedIncidentalHoldsRow,
    servicesHeader: servicesHeaderRow,
    staticService: staticServiceRow,
    sellerTaxCharge: sellerTaxChargeRow,
    shippingTaxCharge: shippingTaxChargeRow,
    misc: miscRow,
    totalRow,
    totalHoldRow,
  };
};
