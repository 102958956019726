import {
  SHIP_BY_GROUND,
  SHIP_OVERNIGHT,
  TWO_DAYS_SHIPPING,
} from "../../../../../common";
import { PurchaseFormType } from "../schemas";
import { IPurchase } from "../interfaces";
import { ProductPhotoProfileSectionEnum } from "../../../common/enums";

export const usePurchasePreview = (purchaseForm: PurchaseFormType) => {
  const formProps = purchaseForm.values;

  const shippingPreference = formProps.shippingAndStorage.shippingPreferences;

  const purchaseProducts = formProps.productOrders.map((product, index) => {
    const productPackageItems =
      product?.photoProfileSettings?.productPackagingPhotoExamples?.map(
        (item, index) => {
          const file = item.file ? URL.createObjectURL(item.file) : undefined;
          return {
            id: item.id,
            name: item.name,
            notes: item.notes,
            orderInList: index,
            type: ProductPhotoProfileSectionEnum.ProductPackaging,
            fileId: item.fileId,
            thumbnailUrl: item.thumbnail || file,
            url: item.original || file,
          };
        }
      ) || [];

    const purchasedPackageItems =
      product?.photoProfileSettings?.purchasedPhotoExamples?.map(
        (item, index) => {
          return {
            id: item.id,
            name: item.name,
            notes: item.notes,
            orderInList: index,
            type: ProductPhotoProfileSectionEnum.PurchasedItem,
            fileId: item.fileId,
            thumbnailUrl: item.thumbnail,
            url: item.original,
          };
        }
      ) || [];

    return {
      orderInList: index,
      brand: product.brand,
      url: product.productUrl,
      name: product.productName,
      quantity: Number(product.quantity),
      unitPrice: Number(product.unitPrice),
      profileName: product.photoProfileSettings.profileName,
      description: product.description,
      color: product.color,
      size: product.size,
      notes: product.notes,
      moqRequired: product.moqRequired || false,
      moqNotes: product.moqRequiredNotes,
      moqMaxBudget: Number(product.maxBudget),
      substituteUnavailable: product.substituteUnavailable || false,
      substituteUnavailableNotes: product.substituteUnavailableNotes,
      substituteSizeUnavailable: product.substituteSizeUnavailable || false,
      substituteSizeUnavailableNotes: product.substituteSizeUnavailableNotes,
      substituteColorUnavailable: product.substituteColorUnavailable || false,
      substituteColorUnavailableNotes: product.substituteColorUnavailableNotes,
      substituteQuantitiesUnavailable:
        product.substituteQuantitiesUnavailable || false,
      substituteQuantitiesUnavailableNotes:
        product.substituteQuantitiesUnavailableNotes,
      listingPage: product.photoProfileSettings.listingPage,
      orderConfirmation: product.photoProfileSettings.orderConfirmation,
      checkoutReceipt: product.photoProfileSettings.checkoutReceipt,
      screenShotNotes: product.photoProfileSettings.screenShotsNotes || "",
      allDocsIncluded: product.photoProfileSettings.allDocuments,
      docsReceiptMaterialNotes:
        product.photoProfileSettings.documentsNotes || "",
      shippingPackage: product.photoProfileSettings.shippingPackage,
      shippingLabels: product.photoProfileSettings.shippingLabelsOnly,
      disposeOuterPackage: product.photoProfileSettings.disposeOuterPackaging,
      outerPackageNotes: product.photoProfileSettings.outerPackagingNotes || "",
      productPackageTop: product.photoProfileSettings.productPackagingTop,
      productPackageTopNotes:
        product.photoProfileSettings.productPackagingTopNotes || "",
      productPackageBottom: product.photoProfileSettings.productPackagingBottom,
      productPackageBottomNotes:
        product.photoProfileSettings.productPackagingBottomNotes || "",
      productPackageFront: product.photoProfileSettings.productPackagingFront,
      productPackageFrontNotes:
        product.photoProfileSettings.productPackagingFrontNotes || "",
      productPackageBack: product.photoProfileSettings.productPackagingBack,
      productPackageBackNotes:
        product.photoProfileSettings.productPackagingBackNotes || "",
      packageItems: [...productPackageItems, ...purchasedPackageItems],
    };
  });

  const purchase: IPurchase = {
    internalCaseNumber: formProps.internalCaseNumber,
    artificeCaseNumber: formProps.artificeCaseNumber,
    purchaseBrand: formProps.purchaseBrand || null,
    sellerRef: formProps.thirdPartySite,
    storeAtArtifice: formProps.storeAtArtifice || false,
    notifyOfLeads: false,
    shippingInfo: {
      useShippingAccount:
        formProps.shippingAndStorage.useCorporateShippingAccount,
      deliveryService: formProps.shippingAndStorage.deliveryService,
      shippingAccountNumber:
        formProps.shippingAndStorage.corporateShippingAccount,
      companyName: formProps.shippingAndStorage.shipToCompanyName,
      firstName: formProps.shippingAndStorage.shipToFirstName,
      lastName: formProps.shippingAndStorage.shipToLastName,
      email: formProps.shippingAndStorage.shipToEmail,
      phone: formProps.shippingAndStorage.shipToMobileNumber,
      phoneCountryCode:
        formProps.shippingAndStorage.shipToMobileNumberCountryCode,
      street1: formProps.shippingAndStorage.shipToStreet1,
      street2: formProps.shippingAndStorage.shipToStreet2,
      city: formProps.shippingAndStorage.shipToCity,
      state: formProps.shippingAndStorage.shipToState,
      zip: formProps.shippingAndStorage.shipToZip,
      country: formProps.shippingAndStorage.shipToCountry,
      shipOvernight: shippingPreference === SHIP_OVERNIGHT,
      shipGround: shippingPreference === SHIP_BY_GROUND,
      ship2Days: shippingPreference === TWO_DAYS_SHIPPING,
    },
    purchaseProducts,
  };

  return { purchase };
};
