import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Alert } from "@mui/material";
import { HomeContainer } from "../../../../../common/components";
import { ArtificeRoutes } from "../../../../../common";
import { AuthService } from "../../../common";
import { forgotPasswordSchema } from "../schemas";
import { getFormFieldProps } from "../../../../../common/utils";
import styles from "./style.module.scss";

export const ForgotPassword = () => {
  const [t] = useTranslation("login");
  const [tValidation] = useTranslation("validation");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    validationSchema: forgotPasswordSchema,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues: { email: "" },
    onSubmit: async (values) => {
      try {
        await AuthService.forgotPassword(values.email);
        navigate(ArtificeRoutes.forgotPasswordSuccess);
      } catch (err) {
        setError(true);
      }
    },
  });

  useEffect(() => {
    setError(false);
  }, [setError, formik.values]);

  const fieldProps = getFormFieldProps(formik, tValidation);

  return (
    <HomeContainer>
      <section className={styles.loginWrapper}>
        <div className={styles.imgPreview} />

        <section className={styles.formWrapper}>
          <h1 className={styles.title}>{t("Forgot Password?")}</h1>
          <p className={styles.subTitle}>
            {t(
              "Please enter your email address. You will receive a link to create a new password via email."
            )}
          </p>

          {error ? (
            <Alert severity="error" className={styles.errorWrapper}>
              {t("Something went wrong, please try again")}
            </Alert>
          ) : null}

          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              autoFocus
              margin="normal"
              label={t("Email Address")}
              autoComplete="email"
              {...fieldProps("email", t("Email Address"))}
            />

            <section className={styles.footerLinks}>
              <Link
                className={styles.registerLink}
                component={NavLink}
                to={ArtificeRoutes.register}
                variant="body2"
              >
                <Trans
                  ns="login"
                  i18nKey="dontHaveAccount"
                  className={styles.checkboxLabel}
                >
                  Don't have an account?{" "}
                  <span className={styles.registerHint}>Register</span>
                </Trans>
              </Link>

              <Link
                className={styles.forgotPassword}
                component={NavLink}
                to={ArtificeRoutes.login}
                variant="body2"
              >
                {t("Back to Log In")}
              </Link>
            </section>

            <LoadingButton
              loading={formik.isSubmitting}
              className={styles.loginBtn}
              type="submit"
              variant="contained"
              size="large"
            >
              {t("Confirm")}
            </LoadingButton>
          </form>
        </section>
      </section>
    </HomeContainer>
  );
};
