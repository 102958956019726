import React from "react";
import { FormControl } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styles from "./style.module.scss";

interface ArtificeCheckboxProps {
  label: string | JSX.Element;
  checked: boolean;
  readOnly?: boolean;
  onChange?: (value: boolean) => void;
}

export const ArtificeCheckbox = ({
  checked,
  label,
  readOnly = false,
  onChange,
}: ArtificeCheckboxProps) => (
  <FormControl className={styles.checkboxWrapper}>
    <FormControlLabel
      control={
        <Checkbox
          readOnly={readOnly}
          color="primary"
          checked={checked}
          onChange={() => onChange?.(!checked)}
        />
      }
      label={<p className={styles.checkboxLabel}>{label}</p>}
    />
  </FormControl>
);
