import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { ArtificeRoutes } from "../../../../../common";
import { HomeContainer } from "../../../../../common/components";
import registrationImage from "../../../../../assets/img/register.png";
import styles from "./style.module.scss";

export const ForgotPasswordSuccess = () => {
  const [t] = useTranslation("common");

  return (
    <HomeContainer>
      <section className={styles.registerSuccessWrapper}>
        <img
          src={registrationImage}
          alt="registration-success"
          className={styles.registerSuccessImg}
        />
        <section className={styles.description}>
          <p className={styles.title}>{t("Password Reset")}</p>
          <p className={styles.subtitle}>
            {t(
              "We’ve sent you an email with a password reset link. If you haven’t received anything yet, please, check again in a few minutes. If not yet received, please, check the spam folder."
            )}
          </p>
          <section className={styles.footerActions}>
            <Button
              variant="contained"
              component={NavLink}
              size="large"
              to={ArtificeRoutes.home}
            >
              {t("Back to Home Page")}
            </Button>
          </section>
        </section>
      </section>
    </HomeContainer>
  );
};
