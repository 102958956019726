import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { ProfileInfoList } from "../../../../common";
import { useShippingInfo } from "../../hooks";
import { SHIPPING_PREFERENCES } from "../../../../../../common";
import { IShippingViewInfo } from "../../interfaces";
import styles from "./style.module.scss";

interface ShippingProps {
  shippingInfo: IShippingViewInfo;
  isPurchaseSection?: boolean;
}

export const ShippingView = ({
  shippingInfo,
  isPurchaseSection,
}: ShippingProps) => {
  const [t] = useTranslation("portal");
  const { shippingDetailsList, shipToList } = useShippingInfo(shippingInfo);

  const yes = t("Yes");
  const no = t("No");

  const shippingPreferences = SHIPPING_PREFERENCES.find(
    ({ value }) => value === shippingInfo.shippingPreference
  )?.label;

  return (
    <>
      <section className={styles.sectionContent}>
        <section>
          <span
            className={classnames(
              styles.listItemName,
              isPurchaseSection ? styles.textBold : null
            )}
          >
            {t("I would like Artifice to store my products.")}
          </span>
          <span
            className={classnames(
              styles.listItemValue,
              isPurchaseSection ? styles.listItemValueNormal : null
            )}
          >
            {" - "}
            {shippingInfo?.storeAtArtifice ? yes : no}
          </span>
        </section>
      </section>
      {shippingInfo?.storeAtArtifice ? null : (
        <>
          {shippingInfo?.useShippingAccount ? (
            <>
              <p className={styles.sectionSubTitle}>{t("Shipping Details")}:</p>
              <p
                className={classnames(
                  styles.subtext,
                  isPurchaseSection ? styles.textBold : null
                )}
              >
                {t("Save money by using your companies shipping account.")}
              </p>
              <section className={styles.sectionContent}>
                <ProfileInfoList
                  list={shippingDetailsList}
                  type="shippingDetailsList"
                  classes={
                    isPurchaseSection
                      ? {
                          listItemName: styles.textBold,
                          listItemValue: styles.listItemValueNormal,
                        }
                      : {}
                  }
                />
              </section>
              <hr className={styles.hr} />
            </>
          ) : null}
          <p className={styles.sectionSubTitle}>{t("Ship To")}:</p>
          <section className={styles.sectionContent}>
            <ProfileInfoList
              list={shipToList}
              type="shipToList"
              classes={
                isPurchaseSection
                  ? {
                      listItemName: styles.textBold,
                      listItemValue: styles.listItemValueNormal,
                    }
                  : {}
              }
            />
          </section>
          <p
            className={classnames(
              styles.sectionSubTitle,
              styles.shippingPreferences
            )}
          >
            {t("Shipping Preferences")}:
          </p>

          <section className={styles.sectionContent}>
            {shippingPreferences ? t(shippingPreferences) : "-"}
          </section>
        </>
      )}
    </>
  );
};
