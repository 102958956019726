import React from "react";
import { useTranslation } from "react-i18next";
import { LabelDisplayedRowsArgs } from "@mui/material";
import { GridPagination } from "@mui/x-data-grid";
import { GridPaginationActions } from "../grid-pagination-actions";

export const CustomPagination = (props: any) => {
  const { t } = useTranslation("common");

  return (
    <GridPagination
      ActionsComponent={GridPaginationActions}
      {...props}
      labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) =>
        `${paginationInfo.from}–${paginationInfo.to} ${t("of")} ${
          paginationInfo.count !== -1
            ? paginationInfo.count
            : `${t("more than")} ${paginationInfo.to}`
        }`
      }
      labelRowsPerPage={t("Rows per page:")}
    />
  );
};
