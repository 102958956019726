import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { Collapse, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { PurchaseFormType } from "../../schemas";
import { CollapsableButton } from "../collapsable-button";
import { ConfirmModal, IPurchaseProfileListItem } from "../../../../common";
import { StorageAndShipping } from "../../../purchase-profile/components";
import { ArtificeRadio } from "../../../../../../common/components";
import { useStorageAndShippingSettings } from "../../hooks";
import styles from "./style.module.scss";

interface StorageAndShippingProps {
  purchaseForm: PurchaseFormType;
  purchaseProfiles?: IPurchaseProfileListItem[];
  error: boolean;
}

interface IStatePurchaseProfileListItem extends IPurchaseProfileListItem {
  custom?: boolean;
}

const EMPTY_PROFILE = {} as IStatePurchaseProfileListItem;
const CUSTOM_PROFILE = { custom: true } as IStatePurchaseProfileListItem;

export const StorageAndShippingSettings = ({
  purchaseProfiles,
  purchaseForm,
  error,
}: StorageAndShippingProps) => {
  const [t] = useTranslation("portal");
  const [open, setOpen] = useState(true);

  const [profileToConfirm, setProfileToConfirm] =
    useState<IStatePurchaseProfileListItem>();
  const [value, setValue] =
    useState<IStatePurchaseProfileListItem>(EMPTY_PROFILE);
  const { profileForm, profileFormValues, fetchProfile } =
    useStorageAndShippingSettings(purchaseForm);

  const storeAtArtifice = purchaseForm.values.storeAtArtifice;

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);

  const handleConfirm = () => {
    if (profileToConfirm) {
      setValue(profileToConfirm);
      fetchProfile(profileToConfirm.id);
    }

    setProfileToConfirm(undefined);
  };

  const handleClose = () => {
    setProfileToConfirm(undefined);
  };

  const handlePurchaseProfileChange = (_: any, profile: any) => {
    if (value.custom) {
      return setProfileToConfirm(profile);
    }

    if (profile.id) {
      setValue(profile);
      fetchProfile(profile.id);
    }
  };

  const handleStoreAtArtificeChange = (storeAtArtifice: boolean) => {
    // reset touched state for purchase profile
    // since we have to recreating it
    if (!storeAtArtifice) {
      profileForm.resetForm();
    }
    purchaseForm.setFieldValue("storeAtArtifice", storeAtArtifice);
    profileForm.setFieldValue("skipValidation", storeAtArtifice);
    profileForm.setFieldValue(
      "isStorageAndShippingRequired",
      !storeAtArtifice,
      true
    );
  };

  useEffect(() => {
    if (profileForm.dirty) {
      setValue(CUSTOM_PROFILE);
      purchaseForm.setFieldValue("isStorageAndShippingCompleted", false);
    }
    purchaseForm.setFieldValue("shippingAndStorage", profileForm.values, true);
    purchaseForm.setFieldValue(
      "notifyOfLeads",
      profileForm.values.notifyOfLeads,
      false
    );

    purchaseForm.setTouched({
      ...purchaseForm.touched,
      shippingAndStorage: {},
    });
    // eslint-disable-next-line
  }, [profileFormValues]);

  useEffect(() => {
    if (purchaseForm.touched.shippingAndStorage) {
      profileForm.setTouched(
        {
          ...(purchaseForm.touched.shippingAndStorage as any),
          ...profileForm.touched,
        },

        true
      );
    }

    // eslint-disable-next-line
  }, [purchaseForm.touched.shippingAndStorage]);

  return (
    <>
      <ArtificeRadio
        checked={Boolean(storeAtArtifice)}
        label={t("Would you like Artifice to store your products?")}
        onChange={handleStoreAtArtificeChange}
      />
      {storeAtArtifice ? null : (
        <>
          <section className={styles.storageWrapper}>
            <FormControl classes={{ root: styles.purchaseProfilesWrapper }}>
              <Autocomplete
                options={purchaseProfiles || []}
                autoHighlight
                disableClearable
                onChange={handlePurchaseProfileChange}
                getOptionLabel={(option) => option.name || ""}
                value={value}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={t("Shipping Information")} />
                )}
              />
            </FormControl>
            <CollapsableButton
              open={open}
              onClick={() => setOpen((val) => !val)}
              label={t("View Shipping Information")}
            />
          </section>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <section className={styles.storageAndShippingWrapper}>
              <StorageAndShipping required formik={profileForm} />
            </section>
          </Collapse>
        </>
      )}

      <ConfirmModal
        title={t("Confirm")}
        open={Boolean(profileToConfirm)}
        confirmText={t("Do you want to change Storage & Shipping Information?")}
        confirmActionText={t("Confirm")}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};
