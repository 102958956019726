import axios from "../../../../common/api";
import { ProductPhotoProfileSectionEnum } from "../enums";
import {
  IFormAttachment,
  ICreateProductPhotoProfile,
  IProductPhotoProfileListItem,
  IProductPhotoProfile,
  IPackageItem,
  ICreateProductPhotoProfileResponse,
} from "../interfaces";
import { formatUrlWithPathParams } from "../../../../common/utils";
import { productPhotoEndpoints } from "../constants";
import { FileService } from "./file.service";

interface IUpdateEntityPayload {
  id: number;
  profile: ICreateProductPhotoProfile;
  formValues: {
    productPackagingPhotoExamples?: IFormAttachment[];
    purchasedPhotoExamples?: IFormAttachment[];
  };
}

export class ProductPhotoProfileService {
  static fetchEntities(): Promise<IProductPhotoProfileListItem[]> {
    return axios
      .get(productPhotoEndpoints.entity)
      .then((response) => response.data);
  }

  static fetchEntityById(id: number): Promise<IProductPhotoProfile> {
    return axios
      .get(formatUrlWithPathParams(productPhotoEndpoints.entityById, { id }))
      .then((response) => response.data);
  }

  static async createEntity(
    profile: ICreateProductPhotoProfile,
    productPackagingPhotoExamples?: IFormAttachment[],
    purchasedPhotoExamples?: IFormAttachment[]
  ): Promise<ICreateProductPhotoProfileResponse> {
    const packageItems =
      await ProductPhotoProfileService.uploadAllPhotoExamples(
        productPackagingPhotoExamples,
        purchasedPhotoExamples
      );

    return ProductPhotoProfileService.createProfile({
      ...profile,
      packageItems,
    });
  }

  static async updateEntity(payload: IUpdateEntityPayload) {
    const packageItems =
      await ProductPhotoProfileService.uploadAllPhotoExamples(
        payload.formValues.productPackagingPhotoExamples,
        payload.formValues.purchasedPhotoExamples
      );

    return ProductPhotoProfileService.updateProfile(payload.id, {
      ...payload.profile,
      packageItems,
    });
  }

  static deleteEntity(id: number) {
    return axios
      .delete(formatUrlWithPathParams(productPhotoEndpoints.entityById, { id }))
      .then((response) => response.data);
  }

  static async uploadAllPhotoExamples(
    productPackaging?: IFormAttachment[],
    purchasedPhoto?: IFormAttachment[]
  ): Promise<IPackageItem[]> {
    const productPackagingUploads =
      ProductPhotoProfileService.formatFilesForUpload(
        ProductPhotoProfileSectionEnum.ProductPackaging,
        productPackaging
      );

    const purchasedUploads = ProductPhotoProfileService.formatFilesForUpload(
      ProductPhotoProfileSectionEnum.PurchasedItem,
      purchasedPhoto
    );

    const uploads = [...productPackagingUploads, ...purchasedUploads].map(
      (attachment) =>
        attachment.file
          ? FileService.uploadProductImage(attachment.file).then((fileId) => ({
              ...attachment,
              fileId,
            }))
          : Promise.resolve(attachment)
    );

    return uploads.length ? Promise.all(uploads) : [];
  }

  static createProfile(
    profile: ICreateProductPhotoProfile
  ): Promise<ICreateProductPhotoProfileResponse> {
    return axios.post(productPhotoEndpoints.entity, profile);
  }

  static updateProfile(id: number, profile: ICreateProductPhotoProfile) {
    return axios.put(
      formatUrlWithPathParams(productPhotoEndpoints.entityById, { id }),
      profile
    );
  }

  static formatFilesForUpload(
    type: ProductPhotoProfileSectionEnum,
    attachments?: IFormAttachment[]
  ): (IPackageItem & { file?: File })[] {
    return (
      attachments?.map((attachment, index) => ({
        type,
        id: attachment.fileId ? attachment.id : undefined,
        fileId: attachment.fileId,
        name: attachment.name,
        notes: attachment.notes,
        orderInList: index,
        file: attachment.file,
      })) || []
    );
  }
}
