import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PurchaseService } from "../services";
import { IPurchase } from "../interfaces";

export const usePurchase = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [purchase, setPurchase] = useState<IPurchase>();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return;
    }

    setIsLoading(true);
    PurchaseService.fetchEntityById(Number(id)).then((response) => {
      setPurchase(response);
      setIsLoading(false);
    });
  }, [id]);

  return {
    id,
    purchase,
    isLoading,
  };
};
