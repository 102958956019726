import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  PurchaseProfileFormService,
  PurchaseProfileService,
} from "../../../common";
import {
  PurchaseProfilePageSchema,
  PurchaseProfileFormValuesType,
} from "../schemas";
import { ArtificeRoutes, useNotifications } from "../../../../../common";
import { usePurchaseProfile } from "./use-purchase-profile";

export const useEditPurchaseProfile = () => {
  const { t } = useTranslation("portal");
  const { showAlert } = useNotifications();
  const navigate = useNavigate();
  const { purchaseProfile, id, loading } = usePurchaseProfile();

  const initialValues = useMemo(() => {
    return {
      ...PurchaseProfileFormService.toForm(purchaseProfile),
      companyInformationCompleted: true,
      isStorageCompleted: true,
      isLeadsCompleted: true,
    };
  }, [purchaseProfile]);

  const editPurchaseProfile = async (
    formValues: PurchaseProfileFormValuesType
  ) => {
    try {
      await PurchaseProfileService.editPurchaseProfile(
        Number(id),
        PurchaseProfileFormService.fromForm(formValues)
      );
      showAlert({
        text: t("Successfully edited"),
        severity: "success",
      });
      navigate(`${ArtificeRoutes.purchaseProfiles}/${id}`);
    } catch (err) {
      showAlert({
        text: t("There was an error. Please try again"),
        severity: "error",
      });
    }
  };

  const profileForm = useFormik({
    validationSchema: PurchaseProfilePageSchema,
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: editPurchaseProfile,
  });

  return {
    id,
    loading,
    profileForm,
    profileName: purchaseProfile?.name,
  };
};
