import React, { ReactNode } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";

export interface CurrencyProps {
  value: number | string | ReactNode;
  classes?: {
    currencyWrapper?: string;
    currencyText?: string;
  };
}

const formatter = Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

export const Currency = ({ value, classes }: CurrencyProps) => {
  const formatedValue = formatter.format(Number(value));

  return (
    <section
      className={classnames(styles.currencyWrapper, classes?.currencyWrapper)}
    >
      <p className={classes?.currencyText}>${formatedValue}</p>
    </section>
  );
};
