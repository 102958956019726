import { useMemo, useState } from "react";
import { useFormik } from "formik";
import {
  IProductPhotoProfile,
  ProductPhotoProfileFormService,
  ProductPhotoProfileService,
} from "../../../common";
import { ProductPhotoProfileFormSchema } from "../../product-photo-profile/schemas/product-photo-profile-form.schema";
import { PurchaseFormType } from "../schemas";

export const useProductPhotoProfileSettings = (
  form: PurchaseFormType,
  index: number
) => {
  const [profile, setProfile] = useState<IProductPhotoProfile>();

  const initialValues = useMemo(() => {
    if (profile) {
      return ProductPhotoProfileFormService.toForm(profile);
    }

    if (form.initialValues.productOrders[index]?.photoProfileSettings) {
      return form.initialValues.productOrders[index].photoProfileSettings;
    }

    return ProductPhotoProfileFormService.formInitialValues();
  }, [profile, form.initialValues.productOrders, index]);

  const profileForm = useFormik({
    validationSchema: ProductPhotoProfileFormSchema,
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: () => {},
  });

  const fetchProfile = async (id: number) => {
    const data = await ProductPhotoProfileService.fetchEntityById(Number(id));
    profileForm.resetForm();
    setProfile(data);
  };

  return {
    profileForm,
    fetchProfile,
  };
};
