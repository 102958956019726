import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IPurchasePurchaseProducts } from "../interfaces";
import { InfoList, ProductPhotoProfile } from "../../../common";

const defaultValue = "-";

export const useProduct = (product?: IPurchasePurchaseProducts) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [t] = useTranslation("portal");

  const yes = t("Yes");
  const no = t("No");

  const subTotal = Number(product?.quantity) * Number(product?.unitPrice);
  const unitPrice = Number(product?.unitPrice);

  const productItems = product?.packageItems?.map((packageItem) => {
    return {
      fileId: packageItem.fileId || -1,
      id: packageItem.id || -1,
      orderInList: packageItem.orderInList,
      type: packageItem.type,
      notes: packageItem.notes || "",
      name: packageItem.name,
      thumbnailUrl: packageItem.thumbnailUrl || "",
      url: packageItem.url || "",
    };
  });

  const productPhotoProfileInfo: ProductPhotoProfile = {
    listingPage: product?.listingPage || false,
    orderConfirmation: product?.orderConfirmation || false,
    checkoutReceipt: product?.checkoutReceipt || false,
    screenShotNotes: product?.screenShotNotes || "",
    allDocsIncluded: product?.allDocsIncluded || false,
    docsReceiptMaterialNotes: product?.docsReceiptMaterialNotes || "",
    shippingPackage: product?.shippingPackage || false,
    shippingLabels: product?.shippingLabels || false,
    disposeOuterPackage: product?.disposeOuterPackage || false,
    outerPackageNotes: product?.outerPackageNotes || "",
    productPackageTop: product?.productPackageTop || false,
    productPackageTopNotes: product?.productPackageTopNotes || "",
    productPackageBottom: product?.productPackageBottom || false,
    productPackageBottomNotes: product?.productPackageBottomNotes || "",
    productPackageFront: product?.productPackageFront || false,
    productPackageFrontNotes: product?.productPackageFrontNotes || "",
    productPackageBack: product?.productPackageBack || false,
    productPackageBackNotes: product?.productPackageBackNotes || "",
    productItems: productItems || [],
  };

  const productGeneralList: InfoList[][] = [
    [
      {
        name: t("Product Description"),
        value: product?.description || defaultValue,
      },
      {
        name: t("Color"),
        value: product?.color || defaultValue,
      },
      {
        name: t("Size"),
        value: product?.size || defaultValue,
      },
      {
        name: t("Notes"),
        value: product?.notes || defaultValue,
      },
    ],
  ];

  const productMainList: InfoList[][] = [
    [
      {
        name: t("Product Name"),
        value: product?.name || defaultValue,
      },
      {
        name: t("Product URL"),
        value: product?.url || defaultValue,
        valueType: "link",
      },
      {
        name: t("Quantity"),
        value: product?.quantity || defaultValue,
      },
      {
        name: t("Unit Price"),
        value: unitPrice || 0,
        valueType: "currency",
      },
      {
        name: t("Subtotal"),
        value: subTotal || 0,
        valueType: "currency",
      },
    ],
    [
      {
        name: t("Brand Name"),
        value: product?.brand?.name || defaultValue,
      },
    ],
  ];

  const productSudstituionsList: InfoList[][] = [
    [
      {
        name: t("If MOQ required, buy?"),
        value: product?.moqRequired ? yes : no,
        hideColon: true,
      },
      ...(product?.moqRequired
        ? [
            {
              name: t("Notes"),
              value: product?.moqNotes || defaultValue,
            },
          ]
        : []),
      {
        name: t("If Item unavailable, substitute?"),
        value: product?.substituteUnavailable ? yes : no,
        hideColon: true,
      },
      ...(product?.substituteUnavailable
        ? [
            {
              name: t("Notes"),
              value: product?.substituteUnavailableNotes || defaultValue,
            },
          ]
        : []),
      {
        name: t("If size unavailable, substitute?"),
        value: product?.substituteSizeUnavailable ? yes : no,
        hideColon: true,
      },
      ...(product?.substituteSizeUnavailable
        ? [
            {
              name: t("Notes"),
              value: product?.substituteSizeUnavailableNotes || defaultValue,
            },
          ]
        : []),
      {
        name: t("If the color unavailable, substitute?"),
        value: product?.substituteColorUnavailable ? yes : no,
        hideColon: true,
      },
      ...(product?.substituteColorUnavailable
        ? [
            {
              name: t("Notes"),
              value: product?.substituteColorUnavailableNotes || defaultValue,
            },
          ]
        : []),
      {
        name: t("If quantities are not available, buy less?"),
        value: product?.substituteQuantitiesUnavailable ? yes : no,
        hideColon: true,
      },
      ...(product?.substituteQuantitiesUnavailable
        ? [
            {
              name: t("Notes"),
              value:
                product?.substituteQuantitiesUnavailableNotes || defaultValue,
            },
          ]
        : []),
    ],
    [
      ...(product?.moqRequired
        ? [
            {
              name: t("Max Product Budget"),
              valueType: "currency" as const,
              value: product?.moqMaxBudget || defaultValue,
            },
          ]
        : []),
    ],
  ];

  return {
    productMainList,
    productGeneralList,
    productPhotoProfileInfo,
    productSudstituionsList,
    tabValue,
    handleTabChange,
  };
};
